import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enJSON from '../../../packages/utils/src/i18n/en/index.json';
import esJSON from '../../../packages/utils/src/i18n/es/index.json';
import frJSON from '../../../packages/utils/src/i18n/fr/index.json';
import jaJSON from '../../../packages/utils/src/i18n/ja/index.json';
import ptJSON from '../../../packages/utils/src/i18n/pt/index.json';
import ruJSON from '../../../packages/utils/src/i18n/ru/index.json';
import zhJSON from '../../../packages/utils/src/i18n/zh/index.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enJSON,
    },
    es: {
      translation: esJSON,
    },
    fr: { translation: frJSON },
    ja: {
      translation: jaJSON,
    },
    pt: {
      translation: ptJSON,
    },
    ru: {
      translation: ruJSON,
    },
    zh: {
      translation: zhJSON,
    },
  },
  lng: 'en', // initial language of the App
  fallbackLng: 'en',
  returnEmptyString: false,
});
