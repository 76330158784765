import { LeaderboardTimeframe } from 'genopets-utils';

export const API_ROOT = import.meta.env.VITE_API_ROOT;

// USER ENDPOINTS
export const USER_EMIT_EVENT_ENDPOINT = `${API_ROOT}/user/emit-event`;
export const USER_ENDPOINT = `${API_ROOT}/user`;

// BATTLE ENDPOINTS
export const TELEGRAM_BATTLE_ENDPOINT = `${API_ROOT}/telegram-battles`;
export const JOIN_BATTLE_ENDPOINT = `${TELEGRAM_BATTLE_ENDPOINT}/join`;
export const ADD_CPU_TO_BATTLE_ENDPOINT = `${TELEGRAM_BATTLE_ENDPOINT}/cpu-join`;
export const ACTION_BATTLE_ENDPOINT = (id?: string) =>
  `${TELEGRAM_BATTLE_ENDPOINT}/${id}/action`;
export const FORCE_ACTION_BATTLE_ENDPOINT = (id?: string) =>
  `${TELEGRAM_BATTLE_ENDPOINT}/${id}/force-action`;

// PET ENDPOINTS
export const PET_ENDPOINT = `${API_ROOT}/pet`;
export const RENAME_PET_ENDPOINT = `${PET_ENDPOINT}/rename`;
export const TELEGRAM_PET_ENDPOINT = `${PET_ENDPOINT}/telegram`;

// MOVES ENDPOINTS
export const MOVES_ALL_ENDPOINT = `${API_ROOT}/moves?type=telegram`;
export const LEARN_NEW_MOVE_ENDPOINT = `${API_ROOT}/game/telegram-learn-move`;

// ENERGY ENDPOINTS
export const CONVERT_ENERGY_TO_XP_ENDPOINT = `${API_ROOT}/game/telegram-energy-to-xp`;

// GAME SETTINGS
export const GAME_SETTINGS_ENDPOINT = `${API_ROOT}/game`;
export const SPIN_WHEEL_SETTINGS_ENDPOINT = `${API_ROOT}/game/spin-wheel`;

// GET REFERRAL LINK ENDPOINT
export const REFERRAL_LINK_ENDPOINT = `${API_ROOT}/invite-codes/refer-telegram`;

// TELEGRAM ENDPOINT
export const NUDGE_THEM_ENDPOINT = (id?: string) =>
  `${TELEGRAM_BATTLE_ENDPOINT}/${id}/nudge`;

// LEADERBOARD ENDPOINT
export const LEADERBOARD_ENDPOINT = (
  page: number,
  timeframe: LeaderboardTimeframe,
) =>
  `${API_ROOT}/game/leaderboard-energy-points?page=${page}&isTelegram=true&timeframe=${timeframe}`;

// TASKS
export const TELEGRAM_TASKS_ENDPOINT = `${API_ROOT}/game/telegram-tasks`;
export const USER_ENERGY_POINTS_SUMMARY = `${API_ROOT}/game/energy-points-summary`;
export const UPDATE_USER_TELEGRAM_TASK_RECORD_ENDPOINT = `${API_ROOT}/game/user-telegram-task-record`;
export const ADD_TON_WALLET_ENDPOINT = `${API_ROOT}/user/add-ton-wallet`;
export const REMOVE_TON_WALLET_ENDPOINT = `${API_ROOT}/user/remove-ton-wallet`;

// IAP
export const PURCHASE_TICKETS_ENDPOINT = `${API_ROOT}/iap/purchase-tickets`;
export const PURCHASE_DAILY_TICKETS_WITH_MULTIPLIER_ENDPOINT = `${API_ROOT}/iap/purchase-daily-tickets-with-multiplier`;

export const CLAIM_DAILY_TICKETS_ENDPOINT = `${API_ROOT}/user/claim-telegram-daily-tickets`;

// airdrop
export const AIRDROP_PRIZES_ENDPOINT = `${API_ROOT}/telegram-airdrops`;
