import {
  NUDGE_THEM_ENDPOINT,
  TELEGRAM_BATTLE_ENDPOINT,
} from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import { Center } from '@chakra-ui/react';
import { BattleEntity } from 'genopets-utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Text } from '../ui/atoms/Text';
import { Button } from '../ui/molecules/Button';
import { Card } from '../ui/molecules/Card';
import { useUIBattleContext } from './context/useBattleContext';
import { initUtils } from '@tma.js/sdk-react';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';

export const WaitForPlayerToJoin = ({ battle }: { battle: BattleEntity }) => {
  const [nudged, setNudged] = useState(false);
  const { battleId, user } = useUIBattleContext();

  const { apiPut, apiDelete } = useAPI();

  const alert = useAlert();

  const [seconds, setSeconds] = useState<number>(
    Math.max(
      0,
      Math.trunc(((battle.battleExpiryTime ?? 0) - Date.now()) / 1000),
    ),
  );

  useEffect(() => {
    if (seconds > 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [seconds]);

  const nudgeThem = () => {
    apiPut(NUDGE_THEM_ENDPOINT(battleId), {})
      .then(() => {
        setNudged(true);
      })
      .catch((error: any) => {
        console.error(error);
        alert({ title: error?.message ?? 'Error' });
      });
  };

  const cancelInvite = () => {
    apiDelete(`${TELEGRAM_BATTLE_ENDPOINT}/${battleId}`)
      .then(() => {
        navigate('/home');
      })
      .catch((error: any) => {
        console.error(error);
        alert({ title: error?.message ?? 'Error' });
      });
  };

  const shareInviteLink = () => {
    const utils = initUtils();

    // Base URL for Telegram's share feature
    const baseUrl = `https://t.me/share/url`;

    const shareText = `Come battle me (Level ${battle.player1.petInfo.level}) in Genopets: Pixelton Arena!`;

    // Encode the parameters
    const encodedGameAppUrl = encodeURIComponent(battle.joinLink ?? '');
    const encodedShareText = encodeURIComponent(shareText);

    // Construct the final URL
    const link = `${baseUrl}?url=${encodedGameAppUrl}&text=${encodedShareText}`;

    utils.openTelegramLink(link);
  };

  const navigate = useNavigate();

  const sec = Math.trunc(seconds % 60) + '';

  const min = Math.trunc(seconds / 60) + '';

  return (
    <Center
      w="full"
      h="full"
      padding={2}
      position={'absolute'}
      top={0}
      left={0}
    >
      <Card w="full">
        <Text colorId="Black" textAlign={'center'} w="full">
          {`${min.length < 2 ? '0' + min : min}:${
            sec.length < 2 ? '0' + sec : sec
          }`}
        </Text>
        {seconds > 0 ? (
          <>
            <Text colorId="Black" textAlign={'center'} w="full">
              <TranslatedText
                translationKey={`waitingForOpponent`}
                defaultMessage={`Waiting for opponent`}
              />
              !
            </Text>
            <Center w="full" gap={2} flexDir="column">
              {user?.uid === battle.player1.id ? (
                <>
                  <Button colorId="Black" onClick={shareInviteLink}>
                    <TranslatedText
                      translationKey={`sendInvite`}
                      defaultMessage={`Send Invite`}
                    />
                  </Button>
                  <Button colorId="Black" onClick={cancelInvite}>
                    <TranslatedText
                      translationKey={`cancelBattle`}
                      defaultMessage={`Cancel battle`}
                    />
                  </Button>
                </>
              ) : (
                <Button colorId="Black" isDisabled={nudged} onClick={nudgeThem}>
                  {nudged ? (
                    <TranslatedText
                      translationKey={`youNudgedThem`}
                      defaultMessage={`You nudged them`}
                    />
                  ) : (
                    <TranslatedText
                      translationKey={`nudgeThem`}
                      defaultMessage={`Nudge Them`}
                    />
                  )}
                  !
                </Button>
              )}
            </Center>
            {nudged && (
              <Text colorId="Black" opacity={0.2}>
                <TranslatedText
                  translationKey={`telegramMessageSent`}
                  defaultMessage={`telegram message sent`}
                />
              </Text>
            )}
          </>
        ) : (
          <>
            <Text colorId="Black" textAlign={'center'} w="full">
              <TranslatedText
                translationKey={`yourOpponentDidNotJoin`}
                defaultMessage={`Your opponent did not join`}
              />
            </Text>
            <Center w="full" gap={2}>
              <Button
                colorId="Black"
                onClick={() => {
                  navigate('/home');
                }}
              >
                <TranslatedText
                  translationKey={`goHome`}
                  defaultMessage={`Go home`}
                />
              </Button>
            </Center>
          </>
        )}
      </Card>
    </Center>
  );
};
