import { Link } from '@/components/Link/Link.tsx';
import { Text } from '@/components/ui/atoms/Text';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Energy } from '@/components/ui/icons/Energy';
import { Ticket } from '@/components/ui/icons/Ticket';
import { Button } from '@/components/ui/molecules/Button';
import { Card } from '@/components/ui/molecules/Card';
import { Modal } from '@/components/ui/molecules/Modal';
import { Whittakr } from '@/components/ui/organisms/Whittakr';
import { BuyTicketsModal } from '@/components/viralLoop/BuyTicketsModal';
import {
  CONVERT_ENERGY_TO_XP_ENDPOINT,
  SPIN_WHEEL_SETTINGS_ENDPOINT,
} from '@/config/endpoints';
import { useUserData } from '@/context/FirestoreContext';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import { getRandomGif } from '@/images/spinner-gifs/getRandomGif';
import { initialOptions, sounds } from '@/lib/sounds';
import { Box, Center, Flex, useDisclosure } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSound from 'use-sound';
import {
  PixeltonSpinnerPrize,
  PixeltonSpinnerPrizeType,
  useSpinWheelSettings,
} from '../hooks/useSpinWheelSettings';
import { Wheel } from './Wheel';
import { useTrackEvent } from '@/components/battle/hooks/useTrackEvent';
import { useTranslation } from 'react-i18next';
import { PrizeModal } from './PrizeModal';
import { useMediaQueries } from '@/hooks/useMediaQuery';

export const Spinner = ({
  auto = false,
  ticketClaimedAmount,
}: {
  auto: boolean;
  ticketClaimedAmount?: number;
}) => {
  const { t } = useTranslation();
  const [playSpin, { stop: stopSpin }] = useSound(
    sounds.spinWheel,
    initialOptions,
  );
  const [playWin] = useSound(sounds.winWheel, {
    volume: 0.8,
    loop: false,
  });

  const [leveledUp, setLeveledUp] = useState(false);

  const [numOfTickets, setNumOfTickets] = useState<number>();

  const [spinning, setSpinning] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [selectedOutcome, setSelectedOutcome] =
    useState<PixeltonSpinnerPrize | null>(null);
  const [winGifSrc, setWinGifSrc] = useState('');
  const [printClaimed, setPrintClaimed] = useState(false);
  const [spinCount, setSpinCount] = useState(0);

  const settings = useSpinWheelSettings();

  const { userData } = useUserData();

  const ticketRequireToSpin = settings?.numOfTicketsRequired ?? 5;
  const hasEnoughTicketsToSpin =
    (userData?.telegramTickets ?? 0) >= ticketRequireToSpin;

  const { apiPost } = useAPI();

  const trackEvent = useTrackEvent();

  const alert = useAlert();

  const selections = settings?.prizes;

  const { onClose, isOpen, onOpen } = useDisclosure();

  const {
    onClose: onCloseBuy,
    isOpen: isOpenBuy,
    onOpen: onOpenBuy,
  } = useDisclosure();

  const { sm } = useMediaQueries();

  const wheelRef = useRef<SVGGElement>(null);

  const getOutcome = () => {
    if (!selections) return;
    if (!hasEnoughTicketsToSpin) {
      onOpenBuy();
      return;
    }
    const ticketAvailable = userData?.telegramTickets ?? 0;
    if (ticketAvailable < ticketRequireToSpin) {
      alert({ title: t('notEnoughtickets', `Not enough tickets`) });
      return;
    }
    setNumOfTickets(ticketAvailable - ticketRequireToSpin);
    setFetching(true);

    setSelectedOutcome(null);

    apiPost(SPIN_WHEEL_SETTINGS_ENDPOINT, {})
      .then(
        async (resp: {
          prizeType: PixeltonSpinnerPrizeType;
          prizeAmount: number;
        }) => {
          const result = selections.find(
            (s) => s.type === resp.prizeType && s.amount === resp.prizeAmount,
          );
          if (!result) {
            console.error(`Invalid prize`);
            alert({ title: `Invalid prize` });
            return;
          }
          setSpinCount(spinCount + 1);
          if (result.type === 'energy') {
            await apiPost(CONVERT_ENERGY_TO_XP_ENDPOINT, {}).then((data) => {
              setSelectedOutcome(result);
              if (data?.levelUp) {
                setLeveledUp(true);
              } else {
                setLeveledUp(false);
              }
            });
          } else {
            setSelectedOutcome(result);
          }
        },
      )
      .finally(() => {
        setFetching(false);
      });
  };

  const spinWheel = () => {
    if (spinning || selectedOutcome === null || !selections) return;

    // choose a random gif
    const gif = getRandomGif();
    setWinGifSrc(gif);

    // play sound
    playSpin();
    // spin wheel
    setSpinning(true);

    const targetIndex = selections.findIndex(
      (s) => s.amount === selectedOutcome.amount,
    );
    const sliceDegrees = 360 / selections.length;
    const targetRotation = 360 - targetIndex * sliceDegrees - sliceDegrees / 2;
    const extraRotations = 1440; // 4 full rotations
    const totalRotation = extraRotations + targetRotation;

    if (wheelRef.current) {
      wheelRef.current.style.transition =
        'transform 3s cubic-bezier(0.25, 0.1, 0.25, 1)';
      wheelRef.current.style.transform = `rotate(${totalRotation}deg)`;
    }
  };

  useEffect(() => {
    if (typeof numOfTickets === 'undefined') {
      setNumOfTickets(userData?.telegramTickets ?? 0);
    } else if (!spinning && !fetching) {
      setNumOfTickets(userData?.telegramTickets ?? 0);
    }
  }, [userData?.telegramTickets]);

  useEffect(() => {
    if (ticketClaimedAmount && !printClaimed && selections) {
      setPrintClaimed(true);
      alert({
        title: (
          <Flex alignItems={`center`} gap={`4px`}>
            <Ticket />
            <Text colorId={`Black`} mt={`1px`}>
              <TranslatedText
                translationKey={`ticketsClaimed`}
                defaultMessage={`${ticketClaimedAmount} claimed`}
                props={{ amount: ticketClaimedAmount }}
              />
              !
            </Text>
          </Flex>
        ),
      });
    }
  }, [ticketClaimedAmount, selections]);

  useEffect(() => {
    if (selectedOutcome && !spinning) {
      spinWheel();
      const timer = setTimeout(() => {
        setSpinning(false);
        stopSpin();
        onOpen();
        playWin();
      }, 3000); // Set timeout to match the 3s spin duration
      return () => clearTimeout(timer);
    }
  }, [selectedOutcome]);

  useEffect(() => {
    if (wheelRef.current) {
      wheelRef.current.style.transition = 'none';
      wheelRef.current.style.transform = 'rotate(0deg)';
    }
  }, []);

  useEffect(() => {
    if (!fetching && !spinning && auto) {
      // after pet leveling up, user clicked "spin again" button
      trackEvent(`telegramSpinButtonClicked`, {
        playerTktBalance: userData?.telegramTickets,
        ticketCost: ticketRequireToSpin,
        isSuccessful: hasEnoughTicketsToSpin,
      });
      getOutcome();
    }
  }, [auto, selections]);

  if (!selections)
    return (
      <Center w="full" h="full">
        <Text colorId="Black">
          <TranslatedText
            translationKey={'loading'}
            defaultMessage={'Loading'}
          />
          ...
        </Text>
      </Center>
    );

  return (
    <>
      <BuyTicketsModal
        isOpen={isOpenBuy}
        onClose={onCloseBuy}
        whittakrText={
          <>
            <Text w="full">
              <TranslatedText
                translationKey={`getMoreTickets`}
                defaultMessage={`get more tickets`}
              />
              !
            </Text>
          </>
        }
      />
      <PrizeModal
        onSpinAgain={() => {
          trackEvent(`telegramSpinAgainButtonClicked`, {
            playerTktBalance: userData?.telegramTickets,
            ticketCost: ticketRequireToSpin,
            isSuccessful: hasEnoughTicketsToSpin,
          });

          if (wheelRef.current) {
            wheelRef.current.style.transition = 'transform 0.5s linear';
            wheelRef.current.style.transform = 'rotate(0deg)';
          }
          onClose();
          getOutcome();
        }}
        isOpen={isOpen}
        setNumOfTickets={setNumOfTickets}
        numOfTickets={numOfTickets}
        selectedOutcome={selectedOutcome}
        onClose={onClose}
        leveledUp={leveledUp}
        winGifSrc={winGifSrc}
      />
      <Flex
        w="full"
        h="full"
        flexDirection={'column'}
        justifyContent={'flex-end'}
      >
        <Box>
          <Whittakr
            imgStyle={{ height: sm ? '120px' : '90px', width: 'auto' }}
            textBoxProps={{ top: '0px', left: '120px', padding: 2 }}
            triangleProps={{ left: '0', right: 'none' }}
            justifyContent={'flex-start'}
            padding={2}
          >
            <Text colorId="White" fontSize={{ base: '10px', sm: '14px' }}>
              <TranslatedText
                translationKey={`feelingLucky`}
                defaultMessage={`Feeling lucky`}
              />
              ?
            </Text>
            <Flex alignItems="center" gap={1}>
              <Text colorId="White" fontSize={{ base: '10px', sm: '14px' }}>
                <TranslatedText
                  translationKey={`youHave`}
                  defaultMessage={`you Have`}
                />
              </Text>
              <Ticket />
              <Text colorId="White" fontSize={{ base: '10px', sm: '14px' }}>
                {numOfTickets}
              </Text>
            </Flex>
          </Whittakr>
        </Box>
        <Box
          padding={{ base: '0 8px 8px 8px', sm: 2 }}
          w={{ base: '80%', sm: '100%' }}
          mx="auto"
        >
          <Wheel selections={selections} wheelRef={wheelRef} />
        </Box>
        <Card
          w="calc(100% + 32px)"
          flexDirection={'column'}
          gap={2}
          display={'flex'}
          margin="0 -16px -16px -16px"
        >
          <Button
            w="full"
            colorId="Black"
            display={'flex'}
            isDisabled={spinning}
            isLoading={fetching}
            onClick={() => {
              trackEvent(`telegramSpinButtonClicked`, {
                playerTktBalance: userData?.telegramTickets,
                ticketCost: ticketRequireToSpin,
                isSuccessful: hasEnoughTicketsToSpin,
              });
              getOutcome();
            }}
          >
            <Flex gap={2} alignItems={'center'} justifyContent={'center'}>
              <Text colorId="White">
                <TranslatedText
                  translationKey={`spin`}
                  defaultMessage={`Spin`}
                />
                !
              </Text>
              <Flex gap={1} alignItems={'center'}>
                <Ticket />
                <Text colorId="White">{ticketRequireToSpin}</Text>
              </Flex>
            </Flex>
          </Button>
          <Link to="/home" style={{ width: '100%' }}>
            <Button colorId="White" w="full">
              {ticketClaimedAmount ? (
                <>
                  {spinCount < 1 ? (
                    <TranslatedText
                      translationKey={`skip`}
                      defaultMessage={`skip`}
                    />
                  ) : (
                    <TranslatedText
                      translationKey={`goHome`}
                      defaultMessage={`Go Home`}
                    />
                  )}
                </>
              ) : (
                <TranslatedText
                  translationKey={`goBack`}
                  defaultMessage={`Go Back`}
                />
              )}
            </Button>
          </Link>
        </Card>
      </Flex>
    </>
  );
};
