const ticketBundleMap: Record<number, number> = {
  1: 30,
  5: 100,
  27: 500,
  58: 1000,
  150: 2500,
  300: 5000,
  600: 10000,
};

export const getTicketBundlePrice = (quantity: number): number =>
  ticketBundleMap[quantity] || quantity * ticketBundleMap[1];

export const ticketBundles = Object.entries(ticketBundleMap)
  .sort(([a], [b]) => Number(a) - Number(b))
  .map(([quantity, price]) => ({
    tickets: Number(quantity),
    stars: price,
  }));
