// Automatically import all GIFs and export them

// Importing each gif
import monkey_burgers from './monkey burgers.gif';
import running_astronaut from './running astronaut.gif';
import dance_hot_dog from './dance hot dog.gif';
import eyeball from './eyeball.gif';
import dog_chase_meat from './dog chase meat.gif';
import surfing_bird from './surfing bird.gif';
import rainbow_glasses from './rainbow glasses.gif';
import monkey_gaming from './monkey gaming.gif';
import frog from './frog.gif';
import snowy_mountains from './snowy mountains.gif';
import cheeseburger from './cheeseburger.gif';
import cheesburger_bugs from './cheesburger bugs.gif';
import fight from './fight.gif';
import sushi from './sushi.gif';
import strawberry from './strawberry.gif';
import breakfast from './breakfast.gif';
import monster from './monster.gif';
import juice from './juice.gif';
import snake from './snake.gif';
import monkey_glasses from './monkey glasses.gif';
import video_gaming from './video gaming.gif';
import sax_monkey from './sax monkey.gif';
import road_race from './road race.gif';
import pancakes from './pancakes.gif';
import lab from './lab.gif';
import popsicle from './popsicle.gif';
import dancing_donut from './dancing donut.gif';
import dancing_veggies from './dancing veggies.gif';
import coin_chase from './coin chase.gif';
import space_wars from './space wars.gif';
import vibes from './vibes.gif';
import drawing from './drawing.gif';
import polar_bear from './polar bear.gif';
import boxing_ring from './boxing ring.gif';
import happy_elephant from './happy elephant.gif';
import ghost from './ghost.gif';
import yoyo from './yoyo.gif';
import triangle from './triangle.gif';

// Exporting all gifs as an object
export default {
  monkey_burgers,
  running_astronaut,
  dance_hot_dog,
  eyeball,
  dog_chase_meat,
  surfing_bird,
  rainbow_glasses,
  monkey_gaming,
  frog,
  snowy_mountains,
  cheeseburger,
  cheesburger_bugs,
  fight,
  sushi,
  strawberry,
  breakfast,
  monster,
  juice,
  snake,
  monkey_glasses,
  video_gaming,
  sax_monkey,
  road_race,
  pancakes,
  lab,
  popsicle,
  dancing_donut,
  dancing_veggies,
  coin_chase,
  space_wars,
  vibes,
  drawing,
  polar_bear,
  boxing_ring,
  happy_elephant,
  ghost,
  yoyo,
  triangle,
};
