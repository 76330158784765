import { cloneDeep } from 'lodash';
import { BattlePlayerEntity } from '../models/battle-player.entity';
import { BattleNextAction, BattleState } from '../models/battle-state';
import { getPlayerData } from './battle-utils';
import { tickCoolDowns } from './cooldowns';
import { tickDodgeCoolDown } from './dodge';
import { tickStatusCooldowns } from './status';
/**
 * Apply all effect that should be apply at the end of the player turn
 * ex: - tick the cooldown of the move
 *     - tick status cooldown
 *     - apply special effect (poison, etc)
 * @param state state before current action
 * @param newState state after applying the action
 * @param playerId player that play the next turn
 * @returns opponent updated
 */
export function endTurnEffect(
  state: BattleState,
  newState: BattleState,
  playerId: string,
): BattleState {
  const stateUpdated = cloneDeep(newState);

  const playerUpdated = getPlayerData(stateUpdated, playerId);

  // check if it is the end of player turn
  // ref to opponent, tick the cooldown before its turn
  // do not tick if the dodge as not been used as next action will be same player again
  playerUpdated.pet = tickCoolDowns(playerUpdated.pet);
  playerUpdated.pet = tickStatusCooldowns(playerUpdated.pet);
  playerUpdated.dodgeCooldown = tickDodgeCoolDown(playerUpdated);

  return stateUpdated;

  return newState;
}
