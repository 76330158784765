export const UserIcon = ({ ...props }: any) => {
  const fill = props?.fill || `currentColor`;
  return (
    <svg width="21" height="24" viewBox="0 0 21 24" fill="none" {...props}>
      <rect
        x="6.62079"
        y="1.65527"
        width="7.44828"
        height="20.6897"
        fill={fill}
      />
      <rect
        x="4.96564"
        y="1.65527"
        width="1.65517"
        height="20.6897"
        fill={fill}
      />
      <rect
        width="1.65517"
        height="20.6897"
        transform="matrix(-1 0 0 1 15.3104 1.65527)"
        fill={fill}
      />
      <rect
        x="3.31036"
        y="4.96582"
        width="1.65517"
        height="7.44828"
        fill={fill}
      />
      <rect
        x="15.3104"
        y="4.96582"
        width="1.65517"
        height="7.44828"
        fill={fill}
      />
      <rect
        x="3.31036"
        y="19.0342"
        width="1.65517"
        height="3.31034"
        fill={fill}
      />
      <rect
        width="1.65517"
        height="3.31034"
        transform="matrix(-1 0 0 1 16.9656 19.0342)"
        fill={fill}
      />
      <rect
        x="1.65521"
        y="19.4482"
        width="1.65517"
        height="3.31034"
        fill={fill}
      />
      <rect
        width="1.65517"
        height="3.31034"
        transform="matrix(-1 0 0 1 18.6208 19.4482)"
        fill={fill}
      />
      <path d="M6.62075 0H13.6552V1.65517H6.62075V0Z" fill="black" />
      <path d="M1.65523 22.3448H19.4483V24H1.65523V22.3448Z" fill="black" />
      <path
        d="M4.96558 1.65517H6.62075V3.31034H4.96558V1.65517Z"
        fill="black"
      />
      <path
        d="M3.31041 3.31034H4.96558V4.96552H3.31041V3.31034Z"
        fill="black"
      />
      <path
        d="M1.65523 4.96552H3.31041V12.4138H1.65523V4.96552Z"
        fill="black"
      />
      <path
        d="M16.9656 4.96552H18.6208V12.4138H16.9656V4.96552Z"
        fill="black"
      />
      <path
        d="M13.6552 1.65517H15.3104V3.31034H13.6552V1.65517Z"
        fill="black"
      />
      <path
        d="M15.3104 3.31034H16.9656V4.96552H15.3104V3.31034Z"
        fill="black"
      />
      <path d="M15.3104 12.4138H16.9656V14.069H15.3104V12.4138Z" fill="black" />
      <path d="M3.31041 12.4138H4.96558V14.069H3.31041V12.4138Z" fill="black" />
      <path d="M4.96558 14.069H6.62075V15.7241H4.96558V14.069Z" fill="black" />
      <path
        d="M3.31041 17.3793H4.96558V19.0345H3.31041V17.3793Z"
        fill="black"
      />
      <path
        d="M15.3104 17.3793H16.9656V19.0345H15.3104V17.3793Z"
        fill="black"
      />
      <path
        d="M1.65523 19.0345H3.31041V20.6897H1.65523V19.0345Z"
        fill="black"
      />
      <path
        d="M16.9656 19.0345H18.6208V20.6897H16.9656V19.0345Z"
        fill="black"
      />
      <path
        d="M6.10352e-05 20.6897H1.65523V24H6.10352e-05V20.6897Z"
        fill="black"
      />
      <path d="M18.6208 20.6897H20.2759V24H18.6208V20.6897Z" fill="black" />
      <path d="M13.6552 14.069H15.3104V15.7241H13.6552V14.069Z" fill="black" />
      <path
        d="M12.0001 15.7241H15.3104V17.3793H12.0001V15.7241Z"
        fill="black"
      />
      <path
        d="M4.96558 15.7241H8.27592V17.3793H4.96558V15.7241Z"
        fill="black"
      />
    </svg>
  );
};
