import { useAlert } from '@/hooks/useAlert';
import { Box } from '@chakra-ui/react';
import { BattleEntity } from 'genopets-utils';
import { useEffect } from 'react';
import { useUIBattleContext } from './context/useBattleContext';
import { useTranslation } from 'react-i18next';

export const WaitingForOpponentToPlay = ({
  battle,
}: {
  battle?: BattleEntity;
}) => {
  const { t } = useTranslation();
  const alert = useAlert();

  const { user, forceAction } = useUIBattleContext();

  useEffect(() => {
    if (battle?.expiryTime) {
      const time = Math.max(0, battle.expiryTime - Date.now());
      if (time > 0) {
        const timer = setTimeout(() => forceAction(), time);
        return () => clearTimeout(timer);
      } else {
        forceAction();
      }
    }
  }, [battle]);

  useEffect(() => {
    if (battle?.expiryTime) {
      const time = Math.max(0, battle.expiryTime - Date.now());

      if (!alert.isActive(battle.id + '-wait')) {
        alert({
          id: battle.id + '-wait',
          title: t('playerXTurn', {
            defaultValue: `Player ${battle.player1.id === user?.uid ? '2' : '1'} turn`,
            player: battle.player1.id === user?.uid ? '2' : '1',
          }),
          duration: time,
          icon: 'true',
        });
      }
    }

    return () => {
      // stop the alert if the component is unmounted
      alert.close(battle?.id + '-wait');
    };
  }, [battle]);

  return <Box />;
};
