import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Energy } from '@/components/ui/icons/Energy';
import { Ticket } from '@/components/ui/icons/Ticket';
import { Center, Box } from '@chakra-ui/react';
import { BattleLevelBar } from '../BattleLevelBar';
import { ClaimRewardButton } from './ClaimRewardButton';
import { useUserData } from '@/context/FirestoreContext';
import { useUIBattleContext } from '../context/useBattleContext';
import {
  RootState,
  BattleState,
  BattleEntity,
  BattlePlayerEntity,
} from 'genopets-utils';
import { useSelector } from 'react-redux';
import { Text } from '@/components/ui/atoms/Text';
import { Card } from '@/components/ui/molecules/Card';
import { useState } from 'react';

export const BattleRewardsCard = ({
  battle,
  player,
}: {
  battle: BattleEntity;
  player?: BattlePlayerEntity | '';
}) => {
  const { user, pet } = useUIBattleContext();

  const { userData } = useUserData();

  const [showHomeButtons, setShowHomeButtons] = useState(false);

  const battleState = useSelector<RootState>(
    (state: RootState) => state.battle,
  ) as BattleState;

  const getEnergyRewarded = () => {
    if (battleState.winner === user?.uid) {
      return !!battle?.winnerTelegramEnergy;
    } else if (battleState.winner !== user?.uid) {
      return !!battle?.loserTelegramEnergy;
    }
  };

  return (
    <Box position="absolute" bottom={0} right={0} left={0}>
      <Card>
        {(battle.telegramRewardsProcessed &&
          getEnergyRewarded() &&
          !showHomeButtons) ||
        (showHomeButtons && (userData?.telegramTickets ?? 0) > 0) ? (
          <Center w="full">
            <Text colorId="Black">
              <TranslatedText
                translationKey={`ticketsLeft`}
                defaultMessage={`Tickets Left`}
              />
            </Text>
            <Ticket />
            <Text colorId="Black">{userData?.telegramTickets}</Text>
          </Center>
        ) : (
          <Center w="full">
            {!battle.telegramRewardsProcessed ? (
              <>
                <Text colorId={'Black'}>
                  <TranslatedText
                    translationKey={`loading`}
                    defaultMessage={`Loading`}
                  />
                  ...
                </Text>
              </>
            ) : (
              <Center w="full" flexDirection={'column'} gap={1}>
                <Text colorId={'Red'}>
                  <TranslatedText
                    translationKey={`youAreOutOfTickets`}
                    defaultMessage={`You're out of tickets`}
                  />
                </Text>
                {showHomeButtons && (userData?.telegramTickets ?? 0) === 0 && (
                  <Text colorId={'Red'}>
                    <TranslatedText
                      translationKey={`noTicketsNoEnergy`}
                      defaultMessage={`No Tickets = No Energy`}
                    />
                  </Text>
                )}
              </Center>
            )}
          </Center>
        )}
        {(!showHomeButtons ||
          (showHomeButtons && (userData?.telegramTickets ?? 0) > 0)) && (
          <Center w="full" gap={2}>
            <Text colorId={getEnergyRewarded() ? 'Black' : 'Red'}>
              <TranslatedText
                translationKey={`energyEarned`}
                defaultMessage={`Energy Earned`}
              />
            </Text>
            <Energy
              color={getEnergyRewarded() ? 'var(--Black)' : 'var(--Red)'}
            />
            <Text colorId={getEnergyRewarded() ? 'Black' : 'Red'}>
              {!battle.telegramRewardsProcessed
                ? '...'
                : battleState.winner === user?.uid
                  ? battle?.winnerTelegramEnergy ?? 0
                  : battle?.loserTelegramEnergy ?? 0}
            </Text>
          </Center>
        )}
        {player && battle.telegramRewardsProcessed && (
          <BattleLevelBar
            pet={pet}
            energyRewarded={
              (battleState.winner === user?.uid
                ? battle?.winnerTelegramEnergy
                : battle?.loserTelegramEnergy) ?? 0
            }
          />
        )}

        <ClaimRewardButton
          battle={battle}
          setShowHomeButtons={setShowHomeButtons}
          showHomeButtons={showHomeButtons}
        />
      </Card>
    </Box>
  );
};
