import { Text } from '@/components/ui/atoms/Text';
import { Button } from '@/components/ui/molecules/Button';
import { BattleMoveEntity, MoveType, Pet } from 'genopets-utils';
import { useState } from 'react';
import { useMoves } from '../hooks/useMoves';
import { MenuChooseMoveItem } from './MenuChooseMoveItem';
import { Center } from '@chakra-ui/react';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';

const EMPTY_MOVE = {
  name: 'Available slot',
  description: 'Empty Slots',
} as BattleMoveEntity;

export const MenuChooseMoves = ({
  pet,
  onBack,
  isBreak,
  onReplace,
}: {
  pet: Pet;
  onBack?: () => void;
  isBreak?: boolean; // undefined mean no filter break and no break are shown
  // eslint-disable-next-line no-unused-vars
  onReplace?: (moveToReplace?: string) => void;
}) => {
  const [selectedMove, setSelectedMove] = useState('');

  const moves = useMoves();

  const petMoves = (pet.telegramMoves ?? [])
    .map((m) => {
      const move = moves?.find((mv) => mv.augment === m);
      if (!move) return null;

      if (move.types.includes(MoveType.Break) && isBreak === false) {
        return null;
      }

      if (!move.types.includes(MoveType.Break) && isBreak === true) {
        return null;
      }

      return move;
    })
    .filter(Boolean);

  if (!moves?.length) {
    return (
      <Center w="full" h="full">
        <Text colorId="Black">
          <TranslatedText
            translationKey={`loading`}
            defaultMessage={`Loading`}
          />
          ...
        </Text>
      </Center>
    );
  }

  if (isBreak === true && petMoves.length < 1) {
    petMoves.push(EMPTY_MOVE);
  } else if (isBreak === false && petMoves.length < 3) {
    petMoves.push(EMPTY_MOVE);
  }

  return (
    <>
      {onReplace && (
        <Text colorId="Black">
          <TranslatedText
            translationKey={`chooseAMoveToReplace`}
            defaultMessage={`Choose a move to replace`}
          />
        </Text>
      )}
      {petMoves.map((m) => {
        const move = m;
        if (!move) return null;

        return (
          <MenuChooseMoveItem
            key={move.name}
            move={move}
            selected={selectedMove === move.name}
            onReplace={
              onReplace
                ? () => {
                    if (!selectedMove) return;
                    onReplace(
                      selectedMove === EMPTY_MOVE.name
                        ? undefined
                        : selectedMove,
                    );
                    onBack?.();
                  }
                : undefined
            }
            onClick={(mv) => setSelectedMove(mv === selectedMove ? '' : mv)}
          />
        );
      })}
      {onBack && (
        <Button colorId="Black" onClick={onBack} w="full">
          <TranslatedText
            translationKey={`goBack`}
            defaultMessage={`Go back`}
          />
        </Button>
      )}
    </>
  );
};
