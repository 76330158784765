import { useToast } from '@chakra-ui/react';
import { Toast } from '../components/ui/molecules/Toast';

export const useAlert = () => {
  const toast = useToast({
    position: 'top',
    duration: 2000,
    containerStyle: {
      width: 'calc(100% - 64px)',
      maxWidth: '100%',
      height: '100vh',
      marginLeft: '32px',
      marginRight: '32px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    render: (props) => {
      return (
        <Toast
          {...props}
          withImage={!!props.icon}
          onClose={() => props.onClose()}
        />
      );
    },
  });

  return toast;
};
