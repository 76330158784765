import { cloneDeep, isEmpty, keys } from 'lodash';
import { BattleMoveEntity } from '../models/battle-action.entity';
import { BattlePet } from '../models/battle-pet';
import { BattlePlayerEntity } from '../models/battle-player.entity';
import { BattleState } from '../models/battle-state';
import { PetStatisticsEnum } from '../models/statistics';
import {
  SpecialStatusEffectEnum,
  StatusEffect,
  StatusEffectEntity,
} from '../models/status-effect.entity';
import { getPlayerData } from './battle-utils';
import { applyCooldown } from './cooldowns';
import { checkThatMoveAreEqual } from './moves';

export function getStatusMultiplier(stage?: number) {
  // fast return if no stage
  if (!stage) {
    return 2 / 2;
  }

  if (stage > 0) {
    return (stage + 2) / 2;
  }
  if (stage < 0) {
    return 2 / (Math.abs(stage) + 2);
  }
  return 2 / 2;
}

export function getStatusValue(stat: StatusEffectEntity, ref?: number): number {
  if (!stat?.value) {
    return 0;
  }
  const value =
    stat.value.endsWith(`%`) && ref !== undefined
      ? Math.round((ref * parseFloat(stat.value!)) / 100)
      : parseInt(stat.value!, 10);

  if (!Number.isFinite(value)) {
    throw new Error(`Invalid status effect value: ${stat.value}`);
  }

  return value;
}

/**
 * Applies a status effect to a battle pet.
 *
 * @param {BattleMoveEntity} move - The battle move that caused the status effect.
 * @param {StatusEffectEntity['target']} target - The target of the status effect (used to filter the move status as self and opponent status are applied on different pets).
 * @param {BattlePlayerEntity} player - The battle player who owns the pet, the status effect.
 * @param {BattleState} state - The current battle state.
 * @return {BattleState} The updated battle state after applying the status effect.
 */
export function applyStatus(
  move: BattleMoveEntity,
  target: StatusEffectEntity['target'],
  player: BattlePlayerEntity,
  state: BattleState,
): BattleState {
  const { status } = move;
  const newState = cloneDeep(state);
  const playerUpdated = getPlayerData(newState, player.id);

  if (!status || isEmpty(status)) {
    return newState;
  }

  const validStatus = status.filter((s) => s.target === target);
  if (isEmpty(validStatus)) {
    return newState;
  }

  let petUpdated = playerUpdated.pet;
  validStatus.forEach((stat) => {
    if (!stat.statAffected) {
      const savedStatus = { ...stat };
      if (stat.effect === SpecialStatusEffectEnum[`Full Cooldown`]) {
        // put all move in cooldown
        player.moves?.forEach((m) => {
          playerUpdated.pet = applyCooldown(playerUpdated.pet, m, true);
        });
        petUpdated = playerUpdated.pet;
        playerUpdated.break.level = 0;
        // set duration to 1 to make it disappear from ui after 1 turn as it is a one time effect
        savedStatus.duration = 1;
      }
      if (stat.effect === SpecialStatusEffectEnum[`Increase Cooldown`]) {
        if (petUpdated.cooldowns) {
          keys(petUpdated.cooldowns).forEach((key) => {
            // only increase cooldown if it exists
            if (petUpdated.cooldowns![key]) {
              petUpdated.cooldowns![key] += parseInt(stat.value!, 10);
            }
          });
        }
        // set duration to 1 to make it disappear from ui after 1 turn as it is a one time effect
        savedStatus.duration = 1;
      }
      if (stat.effect === SpecialStatusEffectEnum[`Reduce Status duration`]) {
        petUpdated.status = petUpdated.status.map((s) => {
          if (s.target === `opponent` && s.duration) {
            return {
              ...s,
              duration: Math.max(s.duration! - parseInt(stat.value!, 10), 0),
            };
          }
          return s;
        });
        // set duration to 1 to make it disappear from ui after 1 turn as it is a one time effect
        savedStatus.duration = 1;
      }
      if (stat.effect === SpecialStatusEffectEnum[`Increase Break`]) {
        // add point break
        playerUpdated.break.level += parseInt(stat.value!, 10);
        // ensure max level is not exceeded
        playerUpdated.break.level = Math.min(
          playerUpdated.break.level,
          playerUpdated.break.maxLevel,
        );
        // set duration to 1 to make it disappear from ui after 1 turn as it is a one time effect
        savedStatus.duration = 1;
      }

      // CANNOT WORK WITH TELEGRAM BATTLE
      if (stat.effect === SpecialStatusEffectEnum[`Swap Moves`]) {
        const oldMoves = player.moves;
        const newMoves = player.petInfo.moves
          .filter((m) => !oldMoves.find((om) => checkThatMoveAreEqual(om, m)))
          .sort(() => Math.random() - 0.5);

        // take moves randomly, -1 for default move not taking into account
        let selectedMoves = newMoves.slice(0, oldMoves.length);

        if (selectedMoves.length !== oldMoves.length) {
          const missingMoveLength = oldMoves.length - selectedMoves.length;
          // fill the ap with old move selected
          const selectedOldMove = oldMoves.slice(0, missingMoveLength);
          selectedMoves = selectedMoves.concat(selectedOldMove);
        }
        playerUpdated.moves = selectedMoves;
        // set duration to 1 to make it disappear from ui after 1 turn as it is a one time effect
        savedStatus.duration = 1;
      }

      // TODO UPDATE TO BE USED IN TELEGRAM BATTLE
      if (stat.effect === SpecialStatusEffectEnum.Cleanse) {
        petUpdated.status = [];
        // set duration to 1 to make it disappear from ui after 1 turn as it is a one time effect
        savedStatus.duration = 1;
      }

      if (stat.effect === SpecialStatusEffectEnum.Shielded) {
        petUpdated.status = [
          ...petUpdated.status,
          // shielded represents a fix amount of shield, if it is define in percentage convert it to be flat amount of HP equivalent
          {
            ...savedStatus,
            value: `${getStatusValue(stat, petUpdated.base.HP)}`,
          },
        ];
      } else {
        // when the status does not modify the stats it is recorded as apply (other part of the system should control and handle these status)
        petUpdated.status = [
          ...petUpdated.status,
          // if no duration is defined, it is a permanent effect
          { ...savedStatus },
        ];
      }
    } else if (stat.name === SpecialStatusEffectEnum.Heal) {
      // if percent, poison is applied on full HP value (5% represent 5% of max HP each turn of the effect)
      const statsModifier = getStatusValue(
        stat,
        petUpdated.base[stat.statAffected],
      );

      // immediate effect
      petUpdated.HP += statsModifier;

      if (petUpdated.HP > petUpdated.base.HP) {
        petUpdated.HP = petUpdated.base.HP;
      }
    } else if (stat.statAffected === PetStatisticsEnum.HP) {
      // if percent, poison is applied on full HP value (5% represent 5% of max HP each turn of the effect)
      const statsModifier = getStatusValue(
        stat,
        petUpdated.base[stat.statAffected],
      );

      petUpdated.status = [
        ...petUpdated.status,
        { ...stat, statModifier: statsModifier },
      ];
    } else if (stat.value) {
      const statsModifier = getStatusValue(
        stat,
        petUpdated.base[stat.statAffected],
      );

      // no status effect should be percentage based anymore (except for hp)
      if (stat.value && !stat.value.endsWith(`%`)) {
        // TODO change this division by 10 if the airtable is updated with the new value
        let newStage =
          (petUpdated.statusModifier?.[stat.statAffected!] ?? 0) +
          statsModifier / 10;

        if (newStage > 4) {
          newStage = 4;
        } else if (newStage < -4) {
          newStage = -4;
        }

        petUpdated.statusModifier = {
          ...petUpdated.statusModifier,
          [stat.statAffected!]: Math.trunc(newStage),
        } as any;
      }

      // TODO should be removed as it is not used anymore ----------------------

      const newStat = petUpdated[stat.statAffected!] + statsModifier;

      if (newStat < 0) {
        petUpdated[stat.statAffected!] = 1;
      } else {
        petUpdated[stat.statAffected!] += statsModifier;
      }

      // -----------------------------------------------------------------------------

      petUpdated.status = [
        ...petUpdated.status,
        { ...stat, statModifier: statsModifier },
      ];
    }
  });

  return newState;
}

/**
 * Tick all moves cooldown of the pet by 1
 * The cooldowns represent the number of turn where the move cannot be used again
 * @param pet pet to update
 * @returns pet with stats updated
 */
export function tickStatusCooldowns(pet: BattlePet): BattlePet {
  const { status } = pet;
  if (!status || isEmpty(status)) {
    return pet;
  }

  const updatedStatus: StatusEffect[] = status.map((stat) => ({
    ...stat,
    duration: stat.duration && stat.duration - 1,
  }));

  const petUpdated = cloneDeep(pet);
  updatedStatus
    .filter((s) => s.duration === 0 && s.statAffected !== PetStatisticsEnum.HP)
    .forEach((stat) => {
      if (stat.statModifier) {
        petUpdated[stat.statAffected!] -= stat.statModifier;
        if (
          petUpdated.statusModifier?.[stat.statAffected!] &&
          !stat.value?.endsWith(`%`)
        ) {
          petUpdated.statusModifier[stat.statAffected!]! -=
            stat.statModifier / 10;
        }
      }
    });

  // remove part of HP each turn of the effect
  // for poison the value should be negative for heal positive
  updatedStatus
    .filter((s) => s.statAffected === PetStatisticsEnum.HP)
    .forEach((stat) => {
      if (stat.statModifier) {
        petUpdated[stat.statAffected!] += stat.statModifier;
        // cannot go over max HP
        petUpdated[stat.statAffected!] = Math.min(
          petUpdated[stat.statAffected!],
          petUpdated.base[stat.statAffected!],
        );
      }
    });

  return {
    ...petUpdated,
    appliedStatus: updatedStatus.filter(
      (s) => s.statAffected === PetStatisticsEnum.HP && s.statModifier,
    ),
    status: updatedStatus.filter(
      // remove effect that not longer applied
      // keep duration undefined as it represent permanent effect
      (s) => s.duration === undefined || s.duration > 0,
    ),
  };
}

export function checkIfParalyze(pet: BattlePet): boolean {
  return !!pet.status.find(
    (status) => status.effect === SpecialStatusEffectEnum.Paralyzed,
  );
}

export function checkIfEncrypted(pet: BattlePet): boolean {
  return !!pet.status.find(
    (status) => status.effect === SpecialStatusEffectEnum.Encrypt,
  );
}

export function getCounterAttackStatus(
  pet: BattlePet,
): StatusEffectEntity | undefined {
  return pet.status?.find(
    (status) => status.effect === SpecialStatusEffectEnum.Counter,
  );
}

export function checkIfCanCounterAttack(pet: BattlePet): boolean {
  // TODO validate that paralyzed pet cannot counter attack
  if (checkIfParalyze(pet)) {
    return false;
  }
  return !!getCounterAttackStatus(pet);
}

export function getShieldedStatus(
  pet: BattlePet,
): StatusEffectEntity | undefined {
  return pet.status?.find(
    (status) => status.effect === SpecialStatusEffectEnum.Shielded,
  );
}

export function checkIfIsShielded(pet: BattlePet): boolean {
  return !!getShieldedStatus(pet);
}
