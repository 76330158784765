export const Trophy = ({ ...props }) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.1999 4H21.9999V0H4.3999V4H0.399902V12H4.3999V14.4H5.9999V16H7.5999V17.6H10.7999V19.2H12.3999V20.8H9.9999V22.4H8.3999V24H6.7999V25.6H19.5999V24H17.9999V22.4H16.3999V20.8H13.9999V19.2H15.5999V17.6H18.7999V16H20.3999V14.4H21.9999V12H25.9999V4H25.1999ZM4.3999 10.4H1.9999V5.6H4.3999V10.4ZM5.9999 1.6H7.5999V5.6H5.9999V1.6ZM24.3999 10.4H21.9999V5.6H24.3999V10.4Z"
      fill="black"
    />
  </svg>
);
