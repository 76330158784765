import { createHash } from 'crypto';
import { base58 } from '@scure/base';
import { PetConfig } from './entities/pet.entity';

export function getPetRenderId(petConfig: PetConfig) {
  const propertiesToKeep = [
    `antennae`,
    `body`,
    `ears`,
    `emissionColor`,
    `eyes`,
    `head`,
    `horn`,
    `mane`,
    `material`,
    `petStage`,
    `primaryColor`,
    `secondaryColor`,
    `tail`,
    `tattoo`,
    `tattooIndex`,
    `tertiaryColor`,
    `wings`,
  ].sort();

  // Create a new object containing only the specified properties
  const filteredConfig: Partial<PetConfig> = {};
  propertiesToKeep.forEach((property) => {
    if (petConfig[property]) {
      filteredConfig[property] = petConfig[property];
    }
  });

  const jsonString = JSON.stringify(filteredConfig);

  const hash = createHash(`sha256`).update(Buffer.from(jsonString)).digest();
  const renderId = base58.encode(hash);

  return renderId;
}

export function getTelegramPetRenderId(petConfig: PetConfig) {
  const propertiesToKeep = [
    `antennae`,
    `body`,
    `ears`,
    `emissionColor`,
    `eyes`,
    `head`,
    `horn`,
    `mane`,
    `material`,
    `petStage`,
    `primaryColor`,
    `secondaryColor`,
    `tail`,
    `tattoo`,
    `tattooIndex`,
    `tertiaryColor`,
    `wings`,
  ].sort();

  // Create a new object containing only the specified properties
  const filteredConfig: Partial<PetConfig> = {};
  propertiesToKeep.forEach((property) => {
    if (petConfig[property]) {
      filteredConfig[property] = petConfig[property];
    } else if (petConfig[property] === undefined) {
      if (property === `tertiaryColor`) {
        filteredConfig[property] = `2C4D4D`;
      } else if (property === `material`) {
        filteredConfig[property] = `default`;
      } else if (property === `tattooIndex`) {
        filteredConfig[property] = -1;
      } else if (property !== `tattoo`) {
        filteredConfig[property] = `-1`;
      }
    }
  });

  const jsonString = JSON.stringify(filteredConfig);

  const hash = createHash(`sha256`).update(Buffer.from(jsonString)).digest();
  const renderId = base58.encode(hash);

  return renderId;
}

export function getHdrpPetRenderId(petConfig: PetConfig) {
  const renderId = getPetRenderId(petConfig);

  // "hdrp" postfix was used for the initial hdrp renders but these had quality issues.
  // "HDRP" postfix is used for the approved renders.
  return `${renderId}_HDRP`;
}
