import { PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { BattleActionEntity } from '../../models/battle-action.entity';
import { BattleState } from '../../models/battle-state';
import {
  BattleGameStateInGodModeEntity,
  checkVictory,
} from '../../utils/battle-utils';
import { useWrapperReducer } from '../../utils/useWrapperReducer';
import { updatePetWithGameState } from './updatePlayerGameState';

// useBlock is always the end of the player turn (used or not)
// but sometime this action is not needed (break move, attack dodged)
export function handleAction(
  state: BattleState,
  actionStore: PayloadAction<BattleActionEntity>,
) {
  const action = actionStore.payload;

  const newState = cloneDeep(state) as BattleState;

  const data = action.data as BattleGameStateInGodModeEntity;

  newState.player1 = updatePetWithGameState(newState.player1, data.p1);
  newState.player2 = updatePetWithGameState(newState.player2, data.p2);

  newState.actions.push({ ...actionStore, payload: { ...action } });

  return checkVictory(newState);
}

export const updateGameStateReducer = (
  state: Partial<BattleState>,
  action: PayloadAction<BattleActionEntity>,
) => useWrapperReducer(state, action, handleAction);
