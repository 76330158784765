import { createContext, ReactNode, useContext, useReducer } from 'react';
import { User } from 'firebase/auth';
import { useAnalytics } from '@/lib/firebase';
import { setUserId } from 'firebase/analytics';

type AuthActions =
  | { type: 'SIGN_IN'; payload: { user: User } }
  | { type: 'SIGN_OUT' };

type AuthState = {
  state: 'SIGNED_IN' | 'SIGNED_OUT' | 'UNKNOWN';
  currentUser?: User;
};

const AuthReducer = (_: AuthState, action: AuthActions): AuthState => {
  switch (action.type) {
    case 'SIGN_IN':
      return {
        state: 'SIGNED_IN',
        currentUser: action.payload.user,
      };

    case 'SIGN_OUT':
      return {
        state: 'SIGNED_OUT',
      };
  }
};

type AuthContextProps = {
  state: AuthState;
  // eslint-disable-next-line no-unused-vars
  dispatch: (value: AuthActions) => void;
};

export const AuthContext = createContext<AuthContextProps>({
  state: { state: 'UNKNOWN' },
  dispatch: () => {},
});

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(AuthReducer, { state: 'UNKNOWN' });

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthState = () => {
  const { state } = useContext(AuthContext);
  return {
    state,
  };
};

const useSignIn = () => {
  const { dispatch } = useContext(AuthContext);

  const analytics = useAnalytics();
  return {
    signIn: (user: User) => {
      if (analytics) {
        setUserId(analytics, user.uid);
      }
      dispatch({ type: 'SIGN_IN', payload: { user } });
    },
  };
};

const useSignOut = () => {
  const { dispatch } = useContext(AuthContext);
  return {
    signOut: () => {
      dispatch({ type: 'SIGN_OUT' });
    },
  };
};

export { useAuthState, useSignIn, useSignOut, AuthProvider };
