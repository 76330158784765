import { ItemsMap } from '../../items';
import { Pet, PetStats } from '../../pet';
import { AugmentSetBonusDefinitionsRecord } from '../../pet/stats/augment-set-bonus-definition.entity';
import { BattlePet } from '../models/battle-pet';
import { InitialGodModeData } from './battle-utils';

export const petPartsList = [
  `Antennae`,
  `Ears`,
  `Eyes`,
  `Horn`,
  `Mane`,
  `Tail`,
  `Wings`,
];

export const petAugmentsList = [
  `antennaeId`,
  `earsId`,
  `eyesId`,
  `hornId`,
  `maneId`,
  `tailId`,
  `wingsId`,
];

export function calculatePetStats(
  pet: Pet,
  items: ItemsMap,
  options?: {
    augSetBonusDefinitionsRecord?: AugmentSetBonusDefinitionsRecord;
    initDataForGodMode?: InitialGodModeData['p1'];
    telegramStats?: boolean;
  },
): BattlePet {
  const { augSetBonusDefinitionsRecord, initDataForGodMode, telegramStats } =
    options || {};

  const petStats = new PetStats(pet, items, {
    augSetBonusDefinitionsRecord,
    telegramStats,
  });

  const petBattle = {
    HP: initDataForGodMode?.HP || petStats.hp,
    ATK: initDataForGodMode?.ATK || petStats.atk,
    DEF: initDataForGodMode?.DEF || petStats.def,
    SPD: initDataForGodMode?.SPD || petStats.spd,
    ACC: initDataForGodMode?.ACC || petStats.acc,
    AGL: initDataForGodMode?.AGL || petStats.agl,
    STB: initDataForGodMode?.STB || petStats.stb,
  };

  return {
    // TODO should be reduce to petBattle.HP other stats are modified via modifiers
    ...petBattle,
    powerModifier: Math.log(pet.telegramLevel) / 3 + 1,
    base: petBattle,
    // saved in case the value change (to be able to replay)
    bonus: {
      HP: petStats.bonusHP,
      ATK: petStats.bonusATK,
      DEF: petStats.bonusDEF,
      SPD: petStats.bonusSPD,
      ACC: petStats.bonusACC,
      AGL: petStats.bonusAGL,
      STB: petStats.bonusSTB,
    },
    status: [],
  };
}
