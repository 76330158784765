import { PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep, isEmpty } from 'lodash';
import {
  BattleActionEntity,
  BattleMoveEntity,
} from '../../models/battle-action.entity';
import { BattleNextAction, BattleState } from '../../models/battle-state';
import { getOpponentData, getPlayerData } from '../../utils/battle-utils';
import { checkThatMoveAreEqual } from '../../utils/moves';
import { useWrapperReducer } from '../../utils/useWrapperReducer';

/**
 * Verify common error that could occur when passing an selectMoves action
 * this function verify that the battle is executed as expected (player order, block allowed, etc)
 * the error is stored in the state and then is thrown by the store consumer id needed
 * @param state current state
 * @param action new action
 * @returns the error if any or null
 */
function checkSelectMovesError(
  state: BattleState,
  action: PayloadAction<BattleActionEntity>,
) {
  if (state.nextAction === BattleNextAction.INIT || !state.player1?.id) {
    return `The battle have NOT been initialized`;
  }
  if (!state.player2?.id) {
    return `Missing opponent player`;
  }

  if (state.nextAction !== BattleNextAction.MOVE_SELECTION) {
    return `You cannot chose your move now`;
  }
  const player = getPlayerData(state, action.payload.playerId);

  if (!isEmpty(player.moves)) {
    return `You already chose your move set`;
  }
  const moves = action.payload.data as BattleMoveEntity[];
  const availableMoves = player.petInfo.moves;

  const notAvailableError =
    moves?.filter(
      (move) =>
        !availableMoves.find((petMove) => checkThatMoveAreEqual(move, petMove)),
    ) ?? [];

  if (notAvailableError.length > 0) {
    return `You chose a move not available for your pet : ${notAvailableError
      .map((move) => move.name)
      .join(`, `)}`;
  }

  return null;
}

/**
 * Move selection action
 *
 * @param {BattleState} state - The current state of the battle.
 * @param {PayloadAction<BattleActionEntity>} actionStore - The action to be handled.
 * @return {BattleState} The new state after handling the action.
 */
export function handleAction(
  state: BattleState,
  actionStore: PayloadAction<BattleActionEntity>,
) {
  const action = actionStore.payload;

  const newState = cloneDeep(state) as BattleState;
  const playerThatPlayedTheAction = getPlayerData(newState, action.playerId);

  const opponent = getOpponentData(newState, action.playerId);

  const moves = action.data as BattleMoveEntity[];

  playerThatPlayedTheAction.moves = moves;

  newState.actions.push({ ...actionStore });

  if (!isEmpty(opponent.moves)) {
    // both player have chose => Let start the battle
    newState.nextAction = BattleNextAction.PLAYER_CHOOSE;
  }

  return newState;
}

export const selectMovesReducer = (
  state: Partial<BattleState>,
  action: PayloadAction<BattleActionEntity>,
) => useWrapperReducer(state, action, handleAction, checkSelectMovesError);
