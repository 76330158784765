import { Box } from '@chakra-ui/react';
import { StatusEffectEntity } from 'genopets-utils';

export const StatusIndicator = ({ status }: { status: StatusEffectEntity }) => {
  if (!status.icon) return null;
  return (
    <>
      <Box boxSize={'16px'}>
        <img src={status.icon} alt={status.name} />
      </Box>
    </>
  );
};
