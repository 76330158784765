import { ADD_CPU_TO_BATTLE_ENDPOINT } from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../ui/atoms/Text';
import { useReadBattleFromFirestore } from '../hooks/useReadBattleFromFirestore';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';

const START_SECONDS = 2;

export const CreateOpenBattleModalContent = ({
  battleId,
  onClose,
}: {
  battleId: string;
  onClose: () => void;
}) => {
  const battle = useReadBattleFromFirestore({ battleId });
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (battle?.player2?.id) {
      navigate(`/battle/${battle.id}`);
    }
  }, [battle]);

  const [seconds, setSeconds] = useState(0);

  const { apiPut } = useAPI();

  const alert = useAlert();

  const addCpuToBattle = async () => {
    if (isLoading || !battle) return;

    setIsLoading(true);

    const payload = {
      battleId,
    };

    apiPut(ADD_CPU_TO_BATTLE_ENDPOINT, payload)
      .then((resp) => {
        console.log('added cpu to battle', resp);
        navigate(`/battle/${resp}`);
      })
      .catch((error: any) => {
        console.error(error);
        alert({ title: error?.message ?? 'Error' });
        onClose();
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (seconds === START_SECONDS) {
      addCpuToBattle();
    }

    if (seconds < START_SECONDS) {
      const timer = setTimeout(() => setSeconds(seconds + 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [seconds]);

  if (isLoading) {
    return (
      <>
        <Text colorId="Black">
          <TranslatedText
            translationKey={`matchFoundText`}
            defaultMessage={`Match found. Entering Battle`}
          />
          ...
        </Text>
      </>
    );
  }

  return (
    <>
      <Text colorId="Black">
        <TranslatedText
          translationKey={`searchingForOpponentText`}
          defaultMessage={`Searching`}
        />
        ...
        {seconds}
      </Text>
    </>
  );
};
