// Automatically import all GIFs and export them

// Importing each gif
import attack from './attack.gif';
import claimTicket from './claim tickets.gif';
import fireAttack from './fire attack.gif';
import fortify from './fortify.gif';
import lvlUp from './lvl up tip.gif';
import ouch from './ouch.gif';
import podium from './podium.gif';
import pokeThreetime from './poke 3 times.gif';
import powerUp from './power up.gif';
import rocket from './rocket.gif';
import ticketsEnergy from './tickets = energy.gif';
import toTheMoon from './to the moon.gif';
import usePump from './use pump.gif';

// Exporting all gifs as an object
export default {
  attack,
  claimTicket,
  fireAttack,
  fortify,
  lvlUp,
  ouch,
  podium,
  pokeThreetime,
  powerUp,
  rocket,
  ticketsEnergy,
  toTheMoon,
  usePump,
};
