import { ButtonProps, Button as ChakraButton } from '@chakra-ui/react';
import { ColorId } from '../colors';
import { Text } from '../atoms/Text';
import useSound from 'use-sound';
import { sounds } from '@/lib/sounds';
import { useHapticFeedback } from '@tma.js/sdk-react';

export const Button = ({
  colorId = 'Green',
  children,
  variant = 'primary',
  color,
  onClick,
  ...props
}: {
  colorId?: ColorId;
  variant?: 'primary' | 'secondary';
} & ButtonProps) => {
  const [play] = useSound(sounds.pressButton, { loop: false });
  const hapticFeedback = useHapticFeedback();
  return (
    <ChakraButton
      background={`var(--${colorId})`}
      color={colorId === 'White' ? 'var(--Black)' : 'var(--White)'}
      borderRadius={0}
      border={
        variant === 'secondary'
          ? `2px solid var(--${colorId === 'White' ? 'Black' : 'White'})`
          : 'none'
      }
      padding="16px 4px"
      minWidth="236px"
      height={'auto'}
      _hover={{}}
      onClick={(e) => {
        // play sound anyway as button are use with link, and in this case not onclick method is not used
        play();
        hapticFeedback.impactOccurred('rigid');
        if (onClick) {
          onClick(e);
        }
      }}
      {...props}
    >
      <Text lineHeight="14px" color={color} width={`full`}>
        {children}
      </Text>
    </ChakraButton>
  );
};
