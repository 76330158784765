/* eslint-disable max-classes-per-file */
import { PayloadAction } from '@reduxjs/toolkit';
import { BattleActionEntity, BattleMoveEntity } from './battle-action.entity';
import { BattlePlayerEntity } from './battle-player.entity';
import { BattleStatus } from './battle.entity';

export enum BattleNextAction {
  INIT = `INIT`,
  MOVE_SELECTION = `MOVE_SELECTION`,
  PLAYER_CHOOSE = `PLAYER_CHOOSE`,
  PLAYER_DODGE = `PLAYER_DODGE`,
}

export interface RawBattleState {
  id: string;

  nextPlayer?: string;

  nextAction: BattleNextAction | undefined;

  player1: BattlePlayerEntity;

  player2: BattlePlayerEntity;

  actions: PayloadAction<BattleActionEntity>[];

  status: BattleStatus;

  elementalMoves: BattleMoveEntity[];

  winner?: string;

  error?: string;
}

export interface BattleState extends RawBattleState {
  history?: RawBattleState[];

  withHistory?: boolean;
}
