import { MainContainer } from '@/components/battle/MainContainer';
import { Spinner } from '@/components/battle/spinner/Spinner';
import { bgSounds, initialOptions } from '@/lib/sounds';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useSound from 'use-sound';
import SpinnerBg from '../../images/spinner-bg.png';

export const SpinnerPage = () => {
  const [play, { stop, sound }] = useSound(bgSounds.spinner, initialOptions);

  useEffect(() => {
    if (sound) {
      play();
      return () => stop();
    }
  }, [sound]);

  const [searchParams] = useSearchParams();

  const auto = searchParams.get('auto');

  const ticketClaimedAmount = searchParams.get('tickets');

  return (
    <MainContainer
      background={SpinnerBg}
      backgroundPosition="center"
      backgroundSize="cover"
    >
      <Spinner
        auto={auto === 'true'}
        ticketClaimedAmount={
          ticketClaimedAmount ? +ticketClaimedAmount : undefined
        }
      />
    </MainContainer>
  );
};
