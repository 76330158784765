import { VStack, useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Text } from '../ui/atoms/Text';
import { Modal } from '../ui/molecules/Modal';
import { useUIBattleContext } from './context/useBattleContext';
import { Button } from '../ui/molecules/Button';
import { useNavigate } from 'react-router-dom';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';

export const BattleJoinErrorModal = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const { joinError } = useUIBattleContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (joinError) {
      onOpen();
    }
  }, [joinError]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} showBackButton={false}>
      <VStack>
        <Text colorId="Black">{joinError}</Text>
        <Text colorId="Black">
          <TranslatedText
            translationKey={`createOrJoinANewBattle`}
            defaultMessage={`Create or join a new battle`}
          />
          !
        </Text>

        <Button colorId="Black" onClick={() => navigate('home')} w="full">
          <TranslatedText
            translationKey={`goHome`}
            defaultMessage={`Go home`}
          />
        </Button>
      </VStack>
    </Modal>
  );
};
