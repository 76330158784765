// Import the GIFs object from the index.ts file
import gifs from './index';

// Function to get a random gif
export function getRandomGif() {
  const gifKeys = Object.keys(gifs); // Get all the keys (names of GIFs)
  const randomIndex = Math.floor(Math.random() * gifKeys.length); // Pick a random index
  const randomGifKey = gifKeys[randomIndex]; // Get the key for that random gif
  return (gifs as any)[randomGifKey]; // Return the corresponding gif
}
