import { useAlert } from '@/hooks/useAlert';
import { getPetName } from '@/utils/utils';
import { Box, VStack } from '@chakra-ui/react';
import {
  BattleState,
  BattleStatus,
  RootState,
  StatusEffect,
} from 'genopets-utils';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Text } from '../../ui/atoms/Text';
import { useUIBattleContext } from '../context/useBattleContext';
import { useTranslation } from 'react-i18next';

const getEffectApplied = (
  status: StatusEffect[],
  name: string,
  translateText: any,
) => {
  if (!status.length) {
    return null;
  }
  return status.map((status, i) => {
    return (
      <Box key={i}>
        <Text as="span" colorId="Black">
          {`${name}${translateText('possessiveS', { defaultValue: `'s` })} `}
        </Text>

        {status.value ? (
          <>
            <Text as="span" colorId="Black">
              {status.statAffected}
            </Text>
            <Text as="span" colorId="Black">
              {` ${translateText('went', { defaultValue: 'went' })}`}
            </Text>
            <Text
              as="span"
              colorId={status.value.startsWith(`-`) ? 'Red' : 'Green'}
            >
              {status.value.startsWith(`-`)
                ? ` ${translateText('down', { defaultValue: 'down' })}`
                : ` ${translateText('up', { defaultValue: 'up' })}`}
            </Text>
            <Text as="span" colorId="Black">
              {`! ${translateText('dueTo', { defaultValue: 'due to' })} ${status.name}`}
            </Text>
          </>
        ) : (
          <Text as="span" colorId="Black">
            {status.name}
          </Text>
        )}
      </Box>
    );
  });
};

export const useEndTurn = () => {
  const { endTurn, setEndTurn, playMoveAnimation } = useUIBattleContext();

  const { t: translateText } = useTranslation();

  const battleState = useSelector<RootState>(
    (state: RootState) => state.battle,
  ) as BattleState;

  const alert = useAlert();

  useEffect(() => {
    // wait for play move animation to be played ans toast to not be visible before end round
    if (endTurn && !playMoveAnimation) {
      // no next turn to print as battle is finished
      if (battleState.status === BattleStatus.ENDED) {
        setEndTurn(false);
        return;
      }
      const pet1FromState = battleState.player1.pet;

      const pet2FromState = battleState.player2.pet;

      const pet1Name = getPetName(
        battleState.player1.petInfo,
        'player 1',
      ) as string;

      const pet2Name = getPetName(
        battleState.player2.petInfo,
        'player 2',
      ) as string;

      const player = battleState.nextPlayer === battleState.player1.id ? 1 : 2;

      // check is the pet has status to resolve before playing its move
      if (
        (pet1FromState.appliedStatus?.length && player === 1) ||
        (pet2FromState.appliedStatus?.length && player === 2)
      ) {
        const title = (
          <VStack alignItems={'flex-start'}>
            {player === 1
              ? getEffectApplied(
                  pet1FromState.appliedStatus ?? [],
                  pet1Name,
                  translateText,
                )
              : getEffectApplied(
                  pet2FromState.appliedStatus ?? [],
                  pet2Name,
                  translateText,
                )}
          </VStack>
        );

        console.log(
          'effect applied',
          pet1FromState.appliedStatus,
          pet2FromState.appliedStatus,
        );

        alert({
          title,
          onCloseComplete: () => {
            setEndTurn(false);
          },
        });
      } else {
        setEndTurn(false);
      }
    }
  }, [endTurn, playMoveAnimation, battleState]);
};
