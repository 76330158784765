/**
 * Miro board: https://miro.com/app/board/uXjVOpWAPeI=/?moveToWidget=3458764529610283283&cot=14
 *
 * Feeding affects the Nutrition factor of a Pet's overall Mood Score.  It ticks down 50 points every 12
 * hours until it reaches 0.
 *
 * Constraints:
 *  - cannot be lower than 0
 *  - cannot be greater than 200
 *
 */

import { ONE_HOUR_IN_MS } from '../../time/time.constants';
import { createActionDecayFunction } from '../action-decay';

export const DECAY_IN_HRS_NUTRITION = 1;
export const DECAY_TICK_INTERVAL_NUTRITION =
  DECAY_IN_HRS_NUTRITION * ONE_HOUR_IN_MS;
export const DECAY_VALUE_NUTRITION = 4.17;
export const MAX_SCORE_NUTRITION = 200;

export const getNutritionScore = createActionDecayFunction(
  DECAY_TICK_INTERVAL_NUTRITION,
  DECAY_VALUE_NUTRITION,
  MAX_SCORE_NUTRITION,
  0,
  `deferred-capped`,
);
