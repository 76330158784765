/**
 * Miro board: https://miro.com/app/board/uXjVOpWAPeI=/?moveToWidget=3458764529610283723&cot=14
 *
 * Playing affects the Joy factor of a Pet's overall Mood Score.  It ticks down 60 points every 48
 * hours until it reaches 0.
 *
 * Constraints:
 *  - cannot be lower than 0
 *  - cannot be greater than 200
 *
 */

import { ONE_HOUR_IN_MS } from '../../time/time.constants';
import { createActionDecayFunction } from '../action-decay';

export const DECAY_TICK_INTERVAL_JOY = ONE_HOUR_IN_MS;
export const DECAY_VALUE_JOY = 1.25;
export const MAX_SCORE_JOY = 200;

export const getJoyScore = createActionDecayFunction(
  DECAY_TICK_INTERVAL_JOY,
  DECAY_VALUE_JOY,
  MAX_SCORE_JOY,
  0,
  `deferred-capped`,
);
