import { Box, UseToastOptions } from '@chakra-ui/react';
import { Card } from './Card';
import { Text } from '../atoms/Text';
import { useState, useEffect } from 'react';
import { isString } from 'lodash';
import { getRandomBattleGif } from '@/images/battle-gifs/getRandomBattleGif';

interface RenderProps extends UseToastOptions {
  /**
   * Function to close the toast
   */
  onClose(): void;
}

export const Toast = ({
  title,
  duration,
  withImage,
  onClose,
}: { withImage?: boolean } & RenderProps) => {
  const [seconds, setSeconds] = useState<number>(
    Math.trunc((duration ?? 0) / 1000),
  );

  useEffect(() => {
    if (seconds > 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timer);
    } else if (onClose) {
      onClose();
    }
  }, [seconds, onClose]);

  return (
    <Card alignItems={'center'} w="full">
      {withImage ? (
        <img
          src={getRandomBattleGif()}
          alt="funny gif"
          style={{ width: '100%', maxHeight: '50vh' }}
        />
      ) : null}
      <Box>
        {isString(title) ? (
          <Text color="var(--Black)" textAlign={'center'}>
            {title}
          </Text>
        ) : (
          title
        )}
        {withImage ? (
          <Text color="var(--Black)" textAlign={'center'}>
            {`0:${seconds < 10 ? '0' : ''}${seconds}`}
          </Text>
        ) : null}
      </Box>
    </Card>
  );
};
