import { PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import {
  BattleActionEntity,
  BattleDodgeEntity,
  BattleMoveEntity,
  DodgeResult,
  MoveType,
  Rolls,
} from '../../models/battle-action.entity';
import { BattleNextAction, BattleState } from '../../models/battle-state';
import {
  checkVictory,
  getOpponentData,
  getPlayerData,
} from '../../utils/battle-utils';
import {
  calculateDamage,
  calculateDamageWithBlock,
  processPetHit,
} from '../../utils/damage';
import {
  applyDodgeCooldown,
  calculateDamageReduction,
  calculateDodge,
} from '../../utils/dodge';
import { endTurnEffect } from '../../utils/end-turn';
import { commonErrorCheck } from '../../utils/error';
import { applyStatus, checkIfParalyze } from '../../utils/status';
import { useWrapperReducer } from '../../utils/useWrapperReducer';
import { useMove } from './battle';

/**
 * Verify common error that could occur when passing an useBlock action
 * this function verify that the battle is executed as expected (player order, block allowed, etc)
 * the error is stored in the state and then is thrown by the store consumer id needed
 * @param state current state
 * @param action new action
 * @returns the error if any or null
 */
function checkUseBlockError(
  state: BattleState,
  action: PayloadAction<BattleActionEntity>,
) {
  const error = commonErrorCheck(state, action);
  if (error) {
    return error;
  }

  if (state.nextAction !== BattleNextAction.PLAYER_DODGE) {
    return `You cannot perform this action, action should be ${state.nextAction}`;
  }

  if (state.nextPlayer) {
    const player = getPlayerData(state, state.nextPlayer);
    const dodge = action.payload.data as BattleDodgeEntity;
    // block action should be sent by the front anyway to resolve end turn action
    if (checkIfParalyze(player.pet) && dodge.used === true) {
      return `You cannot perform this action as your pet is paralyzed`;
    }

    if (player.dodgeCooldown && dodge.used === true) {
      return `You cannot perform this action as your dodging ability is in cooldown for ${player.dodgeCooldown} turn(s)`;
    }

    // take the last action saved
    const previousAction = state.actions[state.actions.length - 1];

    if (previousAction.type === useMove.type) {
      const move = previousAction.payload.data as BattleMoveEntity;
      if (move.types.includes(MoveType.Break) && dodge.used === true) {
        return `You cannot perform this action because break move cannot be dodge`;
      }
    }
  }

  return null;
}

// useBlock is always the end of the player turn (used or not)
// but sometime this action is not needed (break move, attack dodged)
export function handleAction(
  state: BattleState,
  actionStore: PayloadAction<BattleActionEntity>,
) {
  const action = actionStore.payload;

  let newState = cloneDeep(state) as BattleState;

  // this is consider as the start of the opponent turn (player attacked and dodge resolution is done)
  // we tick all element linked to the next player
  // tick before applying the status
  newState = endTurnEffect(state, newState, action.playerId);

  const playerThatPlayedTheAction = getPlayerData(newState, action.playerId);

  const opponent = getOpponentData(newState, action.playerId);

  const dodge = action.data as BattleDodgeEntity;

  let rolls = {} as Rolls;

  // take the last action saved
  const previousAction = newState.actions[newState.actions.length - 1].payload;

  let dodged;

  const move = previousAction.data as BattleMoveEntity;

  if (dodge.used === `auto`) {
    const shouldDodge =
      move.power > 0 ||
      (move.status?.filter((s) => s.target === `opponent`).length ?? 0) > 0;
    if (shouldDodge) {
      const { randomAccuracy, randomAgility, isDodgeSuccessful } =
        calculateDodge(
          playerThatPlayedTheAction.pet,
          opponent.pet,
          previousAction.data as BattleMoveEntity,
          action.rolls,
        );

      dodged = isDodgeSuccessful;

      rolls.randomAgility = randomAgility;
      rolls.randomAccuracy = randomAccuracy;
    }
  } else {
    if (dodge.used) {
      playerThatPlayedTheAction.dodgeCooldown = applyDodgeCooldown(
        playerThatPlayedTheAction,
      );
    }

    dodged = dodge.used && dodge.result === DodgeResult.SUCCESS;
  }

  // allow easy print in the app in case of dodge
  rolls.dodged = dodged ? 1 : 0;

  if (!dodged) {
    // obtain damage calculated during useMove action (damage are calculated before to know if the pet has dodge the attack)
    const { totalDamage } = calculateDamage(
      opponent.pet,
      playerThatPlayedTheAction.pet,
      previousAction.data as BattleMoveEntity,
      previousAction.rolls, // use rolls to replay damage calculation
    );

    let damage = totalDamage;

    const { damage: damageWithBlock, reductionPercentage } =
      calculateDamageWithBlock(
        damage,
        (action.rolls?.damageReduction as number) ||
          calculateDamageReduction(dodge),
      );

    damage = damageWithBlock;

    // this is not a roll but it will come from front user so we need to save it in order to replay
    rolls.damageReduction =
      (action.rolls?.damageReduction as number) || reductionPercentage;

    // current player get hit attacker is the opponent
    const { state: updatedState, rolls: counterRolls } = processPetHit(
      newState,
      damage,
      previousAction.data as BattleMoveEntity,
      opponent.id,
      action,
    );

    newState = updatedState;
    rolls = { ...rolls, ...counterRolls };

    // use to print damage in the app or debug
    rolls.damage = damage;
  }

  // apply the buff to the player acting (ex: ATK up)
  // applied after damage processed to avoid boosting current move
  // status are applied during block phase (in case block play a role regarding effect)
  // LOSE REFERENCE TO playerThatPlayedTheAction, opponent etc DO NOT USE THEM after
  newState = applyStatus(move, `self`, opponent, newState);

  newState.nextPlayer = playerThatPlayedTheAction.id;
  newState.nextAction = BattleNextAction.PLAYER_CHOOSE;

  newState.actions.push({ ...actionStore, payload: { ...action, rolls } });

  return checkVictory(newState);
}

export const useDodgeReducer = (
  state: Partial<BattleState>,
  action: PayloadAction<BattleActionEntity>,
) => useWrapperReducer(state, action, handleAction, checkUseBlockError);
