import { values } from 'lodash';
import { ElementEnum } from '../../models';
import { BattleItemEntity } from '../models/battle-action.entity';
import { BattlePet } from '../models/battle-pet';
import { BattlePlayerEntity } from '../models/battle-player.entity';
import { StatusEffectEntity } from '../models/status-effect.entity';
import { crystalsMint } from './battle-utils';

const ElementWheel = {
  [ElementEnum.Fire]: {
    strong: ElementEnum.Metal,
    week: ElementEnum.Water,
  },
  [ElementEnum.Water]: {
    strong: ElementEnum.Fire,
    week: ElementEnum.Earth,
  },
  [ElementEnum.Wood]: {
    strong: ElementEnum.Earth,
    week: ElementEnum.Metal,
  },
  [ElementEnum.Earth]: {
    strong: ElementEnum.Water,
    week: ElementEnum.Wood,
  },
  [ElementEnum.Metal]: {
    strong: ElementEnum.Wood,
    week: ElementEnum.Fire,
  },
};

/**
 * Returns the elemental status if any.
 *
 * @param {BattlePet} pet - The pet to search for the status effect in.
 * @return {StatusEffectEntity | undefined} The matching status effect or undefined
 * if no match is found.
 */
export function getElementalStatus(
  pet: BattlePet,
): StatusEffectEntity | undefined {
  return pet?.status?.find((status) =>
    values(crystalsMint).includes(status.name as ElementEnum),
  );
}

/**
 * Applies an elemental status to a battle item entity and returns the corresponding battle pet.
 *
 * @param {BattleItemEntity} item - The battle item entity to apply the elemental status to.
 * @param {BattlePlayerEntity} playerThatPlayedTheAction - The player entity that played the action.
 * @return {BattlePet} The corresponding battle pet after applying the elemental status.
 */
export function applyElementalStatus(
  item: BattleItemEntity,
  playerThatPlayedTheAction: BattlePlayerEntity,
): BattlePet {
  const status: StatusEffectEntity = {
    duration: 3,
    name: crystalsMint[item.mint],
    target: `self`,
  };

  const { pet } = playerThatPlayedTheAction;

  // get previous elemental status
  const elStatus = getElementalStatus(pet);

  // replace elemental status with new one
  if (elStatus) {
    // update each field to keep object reference
    elStatus.name = status.name;
    elStatus.duration = status.duration;
  } else {
    // if none add elemental status
    pet.status.push(status);
  }

  return pet;
}

/**
 * Determines if an elemental status is applied to the given BattlePet.
 *
 * @param {BattlePet} pet - The BattlePet to check.
 * @return {boolean} True if an elemental status is applied, false otherwise.
 */
export function isElementalStatusApplied(pet: BattlePet): boolean {
  return !!getElementalStatus(pet);
}

/**
 * Determines if an attacking element is strong against a defending element.
 *
 * @param {StatusEffectEntity} atkElement - the attacking element
 * @param {StatusEffectEntity} defElement - the defending element
 * @returns {boolean} - true if the attacking element is strong against the defending element, false otherwise.
 */
export function isElementStrongAgainst(
  atkElement?: StatusEffectEntity,
  defElement?: StatusEffectEntity,
): boolean {
  if (!atkElement || !defElement) return false;
  const atkStrong = ElementWheel[atkElement.name as ElementEnum].strong;
  return atkStrong === defElement.name;
}

/**
 * Determines if a given attack element is weak against a given defense element.
 *
 * @param {StatusEffectEntity} atkElement - The attacking element.
 * @param {StatusEffectEntity} defElement - The defending element.
 * @return {boolean} Returns true if the attack element is weak against the defense element, false otherwise.
 */
export function isElementWeekAgainst(
  atkElement?: StatusEffectEntity,
  defElement?: StatusEffectEntity,
): boolean {
  if (!atkElement || !defElement) return false;
  const atkWeek = ElementWheel[atkElement.name as ElementEnum].week;
  return atkWeek === defElement.name;
}
