/* eslint-disable no-unused-vars */
import { TELEGRAM_BATTLE_ENDPOINT } from '@/config/endpoints';
import { useUserData } from '@/context/FirestoreContext';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import { sounds } from '@/lib/sounds';
import { Box, Center } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { BattleStatus, Pet, getTelegramPetImage } from 'genopets-utils';
import { useEffect, useState } from 'react';
import useSound from 'use-sound';
import { useReadBattleFromFirestore } from '../battle/hooks/useReadBattleFromFirestore';
import { StarGreen } from '../ui/icons/StartGreen';
import { CreateBabyView } from './CreateBabyPet';

const MotionBox = motion(Box);

export const PetRunAway = ({
  setView,
  setPetName,
}: {
  setView: (x: CreateBabyView) => void;
  setPetName: (x: string) => void;
}) => {
  const [battleId, setBattleId] = useState('');
  const [pet, setPet] = useState<Pet>();
  const [showRun, setShowRun] = useState(false);

  const { userData } = useUserData();

  const battle = useReadBattleFromFirestore({ battleId });

  const [play] = useSound(sounds.dodge, {
    loop: false,
    volume: 0.8,
    interrupt: false,
  });

  const { apiGet } = useAPI();

  const alert = useAlert();

  useEffect(() => {
    if (!battle) {
      apiGet(TELEGRAM_BATTLE_ENDPOINT)
        .then(
          (resp: {
            [BattleStatus.ONGOING]: string[];
            [BattleStatus.ENDED]: string[];
          }) => {
            if (!resp) {
              return;
            }
            let id;
            if (resp[BattleStatus.ENDED]?.length) {
              id = resp[BattleStatus.ENDED][0];
            } else if (resp[BattleStatus.ONGOING]?.length) {
              id = resp[BattleStatus.ONGOING][0];
            } else {
              setView('WELCOME');
              return;
            }
            setBattleId(id);
          },
        )
        .catch((error: any) => {
          console.error(error);
          alert({ title: error?.message ?? 'Error' });
        });
    } else if (!pet && userData && battle) {
      const battlePet =
        battle.player1.id === userData.id
          ? battle.player1.petInfo
          : battle.player2.petInfo;
      setPet(new Pet(battlePet));
    }
  }, [battle, apiGet, userData]);

  useEffect(() => {
    if (pet) {
      const timeout = setTimeout(() => {
        setShowRun(true);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [pet]);

  return (
    <Center w="full" h="full" padding={2}>
      {pet && !showRun && (
        <Center w="200px" h="auto">
          <img src={getTelegramPetImage(pet.petConfigV2)} alt={` pet`} />
        </Center>
      )}
      {showRun && (
        <MotionBox
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            type: 'spring',
            stiffness: 50,
            damping: 20,
          }}
          onAnimationStart={() => play()}
          onAnimationComplete={() => {
            setPetName(pet?.name ?? '');
            setView('RAN');
          }}
        >
          <StarGreen width="172px" height="172px" />
        </MotionBox>
      )}
    </Center>
  );
};
