import { BoxProps } from '@chakra-ui/react';
import { TextWithShadow } from './TextWithShadow';

export const BlinkingText = ({ children, ...props }: BoxProps) => {
  return (
    <TextWithShadow animation="blink 0.6s infinite" {...props}>
      {children}
    </TextWithShadow>
  );
};
