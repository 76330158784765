import { configureStore } from '@reduxjs/toolkit';
import { middlewareLogger } from './logger';
import rootReducer from './reducers';

const LOG = process.env.VITE_SHOW_BATTLE_INFO;

export function createStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      process.env.NODE_ENV === `test`
        ? []
        : getDefaultMiddleware().concat(LOG ? middlewareLogger : []),
  });

  return store;
}

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
