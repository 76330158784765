import { Box, Flex } from '@chakra-ui/react';
import { BattleEntity, BattleState, RootState } from 'genopets-utils';
import { useSelector } from 'react-redux';
import { PetInformation } from '../PetInformation/PetInformation';
import { ReplayControls } from './ReplayControls';

export const BattleReplay = ({ battle }: { battle: BattleEntity }) => {
  const battleState = useSelector<RootState>(
    (state: RootState) => state.battle,
  ) as BattleState;

  const player = battleState?.player1;

  const opponent = battleState?.player2;

  return (
    <>
      <Box position={'relative'} w="full" h="full">
        {opponent && (
          <Flex position="absolute" top={0} right={0}>
            <PetInformation
              player={'player 2'}
              playerId={opponent.id}
              level={opponent.petInfo.level}
              breakLevel={opponent.break.level}
              hp={opponent.pet.HP}
              status={opponent.pet.status}
              maxHp={opponent.pet.base.HP}
              opponent
            />
          </Flex>
        )}
        {player && (
          <Flex position="absolute" bottom={0} left={0} w="full">
            <PetInformation
              player={'player 1'}
              playerId={player.id}
              level={player.petInfo.level}
              breakLevel={player.break.level}
              hp={player.pet.HP}
              maxHp={player.pet.base.HP}
              status={player.pet.status}
              flexShrink={0}
            />

            <ReplayControls battle={battle} />
          </Flex>
        )}
      </Box>
    </>
  );
};
