/* eslint-disable no-unused-vars */
import { PayloadAction } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';

import {
  ACTION_BATTLE_ENDPOINT,
  FORCE_ACTION_BATTLE_ENDPOINT,
  JOIN_BATTLE_ENDPOINT,
} from '@/config/endpoints';
import { useAuthState } from '@/context/UserContext';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import { useReadPetFromFirestore } from '@/hooks/useReadPetFromFirestore';
import { instanceToPlain } from 'class-transformer';
import { User } from 'firebase/auth';
import {
  BattleAction,
  BattleActionEntity,
  BattleDodgeEntity,
  BattleItemEntity,
  BattleMoveEntity,
  BattleState,
  Pet,
  RootState,
  SECOND_PLAYER_ID,
} from 'genopets-utils';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

// use to define the battle mode
// could also be used to block a status until animation have been played
export enum BattleMode {
  DEFAULT = `default`,
  BATTLE = `battle`,
  ENDED = `ended`,
  REPLAY = `replay`,
}

/**
 * Context for battle UI (could be in the store but we wanted to keep it separated as the store is used by the back office too)
 */
const UIBattleContext = React.createContext<{
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  moveAnimationPlayed: boolean;
  setMoveAnimationPlayed: React.Dispatch<React.SetStateAction<boolean>>;
  battleId: string | undefined;
  setBattleId: React.Dispatch<React.SetStateAction<string | undefined>>;
  sendAction: (
    type: BattleAction,
    data:
      | BattleMoveEntity[]
      | BattleMoveEntity
      | BattleDodgeEntity
      | BattleItemEntity,
  ) => void;
  mode?: BattleMode;
  setMode: React.Dispatch<React.SetStateAction<BattleMode>>;
  playMoveAnimation?: BattleActionEntity;
  setPlayMoveAnimation: React.Dispatch<
    React.SetStateAction<BattleActionEntity | undefined>
  >;
  user?: User;
  pet?: Pet;
  opponentPet?: Pet;
  forceAction: () => void;
  busyUi: boolean;
  joinError: string;
  endTurn: boolean;
  setEndTurn: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  loading: false,
  setLoading: () => console.log,
  moveAnimationPlayed: false,
  setMoveAnimationPlayed: () => console.log,
  battleId: undefined,
  setBattleId: () => console.log,
  sendAction: () => console.log,
  mode: undefined,
  setMode: () => console.log,
  playMoveAnimation: undefined,
  setPlayMoveAnimation: () => console.log,
  user: undefined,
  pet: undefined,
  opponentPet: undefined,
  forceAction: () => console.log,
  busyUi: false,
  joinError: '',
  endTurn: false,
  setEndTurn: () => console.log,
});

export const UIBattleProvider = (props: any) => {
  const { children } = props;

  const { id } = useParams();

  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState<BattleMode>(BattleMode.DEFAULT);

  const [playMoveAnimation, setPlayMoveAnimation] = useState<
    BattleActionEntity | undefined
  >();
  const [moveAnimationPlayed, setMoveAnimationPlayed] = useState(true);

  const [battleId, setBattleId] = useState<string | undefined>();

  const [joinError, setJoinError] = useState('');

  const [busyUi, setBusyUi] = useState(false);

  const [endTurn, setEndTurn] = useState(false);

  const alert = useAlert();

  useEffect(() => {
    const busy = playMoveAnimation || !moveAnimationPlayed || endTurn;

    setBusyUi(!!busy); // force boolean
    // loading is added to prevent the ui to be block without letting any chance to unblock it
    // (loading is tie to send action which should refresh the busyUi in case of block, and sendAction is tie to a button)
  }, [moveAnimationPlayed, playMoveAnimation, loading, endTurn]);

  const { state } = useAuthState();

  const { apiPut } = useAPI();

  useEffect(() => {
    if (searchParams.get('replay') === 'true') {
      setBattleId(id);
      setMode(BattleMode.REPLAY);
    }
  }, [searchParams]);

  useEffect(() => {
    const joinBattle = async (battleId: string) => {
      apiPut(JOIN_BATTLE_ENDPOINT, {
        battleId,
      })
        .then((resp) => {
          setBattleId(resp);
        })
        .catch((error: any) => {
          console.error(error);
          if (error?.message) {
            setJoinError(error.message);
          } else {
            alert({ title: error?.message ?? 'Error' });
          }
        });
    };

    if (id && !battleId && !(searchParams.get('replay') === 'true')) {
      console.log('joining battle', id);
      joinBattle(id);
    }
  }, [id, apiPut, state]);

  const battleState = useSelector<RootState>(
    (state: RootState) => state.battle,
  ) as BattleState;

  const user = state.currentUser;

  const { pet } = useReadPetFromFirestore({
    petId:
      battleState?.player1?.id === user?.uid
        ? battleState?.player1?.petInfo.id
        : battleState?.player2?.petInfo.id,
  });

  const { pet: opponentPet } = useReadPetFromFirestore({
    petId:
      battleState?.player1?.id === user?.uid
        ? battleState?.player2?.petInfo.id
        : battleState?.player1?.petInfo.id,
  });

  const putAction = async (action: PayloadAction<BattleActionEntity>) => {
    setLoading(true);

    await apiPut(
      ACTION_BATTLE_ENDPOINT(battleId),
      instanceToPlain(action),
    ).catch((error: any) => {
      console.error(error);
      alert({ title: error?.message });
      setLoading(false);
    });
  };

  const sendAction = (
    type: BattleAction,
    data:
      | BattleMoveEntity[]
      | BattleMoveEntity
      | BattleDodgeEntity
      | BattleItemEntity,
  ) => {
    if (!user) {
      console.error('no user detected');
      alert({ title: 'No user detected' });
      return;
    }

    const battleActionPayload: BattleActionEntity = {
      playerId: user.uid,
      data: data,
    };

    putAction({ type, payload: battleActionPayload });
  };

  const forceAction = async () => {
    if (battleState.player2.id === SECOND_PLAYER_ID) {
      // no force action in solo battle
      return;
    }

    setLoading(true);

    await apiPut(FORCE_ACTION_BATTLE_ENDPOINT(battleId), {}).catch(
      (error: any) => {
        console.error(error);
        alert({ title: error?.message });
        setLoading(false);
      },
    );
  };

  const context = {
    loading,
    setLoading,
    battleId,
    setBattleId,
    sendAction,
    mode,
    setMode,
    user,
    pet,
    opponentPet,
    forceAction,
    playMoveAnimation,
    setPlayMoveAnimation,
    moveAnimationPlayed,
    setMoveAnimationPlayed,
    busyUi,
    joinError,
    endTurn,
    setEndTurn,
  };

  return (
    <UIBattleContext.Provider value={context}>
      {children}
    </UIBattleContext.Provider>
  );
};

export const useUIBattleContext = () => {
  const context = React.useContext(UIBattleContext);
  if (context === undefined) {
    throw new Error(
      `useUIBattleContext must be used within a UIBattleProvider`,
    );
  }
  return context;
};
