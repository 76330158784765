import { PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { BattleActionEntity } from '../models/battle-action.entity';
import { BattleState } from '../models/battle-state';
import { withHistory } from './withHistory';

export const useWrapperReducer = (
  state: Partial<BattleState>,
  action: PayloadAction<BattleActionEntity>,
  handleAction: (
    s: BattleState,
    a: PayloadAction<BattleActionEntity>,
  ) => BattleState,
  checkUseMoveError?: (
    s: BattleState,
    a: PayloadAction<BattleActionEntity>,
  ) => string | null,
): BattleState => {
  const error = checkUseMoveError?.(state as BattleState, action);
  if (error) {
    const newState = cloneDeep(state) as BattleState;
    newState.error = error;
    return newState;
  }

  const newState = handleAction(
    { ...state, error: undefined } as BattleState,
    action,
  );

  return withHistory(state, newState);
};
