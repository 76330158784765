import { BattleMoveEntity, MoveType } from '../models/battle-action.entity';

/**
 * Calculates the new break level based on the current and maximum levels, and the used move.
 *
 * @param currentBreakLevel Current break level of the player
 * @param maxBreakLevel Maximum break level of the player
 * @param move The move used
 * @returns The new break level
 */
export function calculateBreakLevel(
  currentBreakLevel: number,
  maxBreakLevel: number,
  move: BattleMoveEntity,
): number {
  if (move.types.includes(MoveType.Minor)) {
    return Math.min(currentBreakLevel + 1, maxBreakLevel);
  }
  return currentBreakLevel;
}

/**
 * This function update the break level according the move used
 * Actually it reset the current level when a break move is used
 * in the future break move could required a number of break points and this function will subtract the break point needed to perform the attack
 * or any other behavior link the break points
 * @param currentLevel current break level
 * @param triggerLevel cost of the break move
 * @param move move used
 * @returns new break level
 */
export function updateBreakLevel(
  currentLevel: number,
  triggerLevel: number,
  move: BattleMoveEntity,
): number {
  // if move is a break move reset the level
  if (move.types.includes(MoveType.Break)) {
    return currentLevel - triggerLevel;
  }
  return currentLevel;
}

export const MAX_LEVEL_BREAK_MOVE = 3;
export const TRIGGER_LEVEL_BREAK_MOVE = 3;

export function initializeBreak() {
  return {
    level: 0,
    maxLevel: MAX_LEVEL_BREAK_MOVE,
    triggerLevel: TRIGGER_LEVEL_BREAK_MOVE,
  };
}
