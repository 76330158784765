import { useDisclosure, Flex, Box } from '@chakra-ui/react';
import { Text } from '../ui/atoms/Text';
import { Modal } from '../ui/molecules/Modal';
import { Button } from '../ui/molecules/Button';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Ticket } from '@/components/ui/icons/Ticket';
import useAPI from '@/hooks/useAPI';
import { USER_ENDPOINT } from '@/config/endpoints';

type NotCoinRewardType = 'bronze' | 'gold' | 'platinum';

const rewardTypeColor: Record<NotCoinRewardType, string> = {
  bronze: '#C37024',
  gold: '#B7A500',
  platinum: '#A4A4A4',
};

export const NotCoinModal = ({ ticketAmount = 0} : { ticketAmount: number;}) => {
  // eslint-disable-next-line no-unused-vars
  const { onOpen, onClose, isOpen } = useDisclosure({ isOpen: true });
  const { apiPost } = useAPI();


  let rewardType: NotCoinRewardType = 'bronze';

  if (ticketAmount > 3 && ticketAmount < 18) {
    rewardType = 'gold';
  } else if (ticketAmount >= 18) {
    rewardType = 'platinum';
  }


  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      showBackButton={false}
      contentProps={{ maxWidth: `320px` }}
    >
      <Box>
        <Text colorId="Black">
          You got a notcoin{' '}
          <Text as={`span`} color={rewardTypeColor[rewardType]}>
            {rewardType}
          </Text>{' '}
          Reward!
        </Text>

        <Flex mt={`16px`} alignItems={`center`}>
          <Text colorId="Black">You earned:</Text>
          <Flex alignItems={`center`}>
            <Ticket />
            <Text colorId="Black">{ticketAmount}</Text>
          </Flex>
        </Flex>

        <Button mt={`16px`} colorId="Black" onClick={() => {
          apiPost(`${USER_ENDPOINT}/post-partner-referral`, {})
        }} w="full">
          <TranslatedText translationKey={`claimExclamation`} defaultMessage={`claim!`} />
        </Button>
      </Box>
    </Modal>
  );
};
