import { useDisclosure, Flex, Box } from '@chakra-ui/react';
import { Text } from '../ui/atoms/Text';
import { Modal } from '../ui/molecules/Modal';
import { Button } from '../ui/molecules/Button';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { useEffect } from 'react';


export const NotCoinWelcomeModal = () => {
  // eslint-disable-next-line no-unused-vars
  const { onOpen, onClose, isOpen } = useDisclosure();

  useEffect(() => {
    onOpen();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      showBackButton={false}
    >
      <Box>
        <Text colorId="Black">
          Welcome!
        </Text>

        <Flex mt={`16px`} alignItems={`center`}>
          <Text colorId="Black">Summon your Genopet to receive your Notcoin bonus reward!</Text>
        </Flex>

        <Button mt={`16px`} colorId="Black" w="full" onClick={() => {
          onClose();
        }}>
          <TranslatedText translationKey={`continue`} defaultMessage={`continue`} />
        </Button>
      </Box>
    </Modal>
  );
};
