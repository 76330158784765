export const Up = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6.99999 17V18H17V17H6.99999Z" fill="currentColor" />
    <path d="M6 17L6.99999 17L7 15H6V17Z" fill="currentColor" />
    <path
      d="M7 15L8.00001 15L7.99999 13L7.00001 13L7 15Z"
      fill="currentColor"
    />
    <path d="M7.99999 13H8.99999L9 11L7.99999 11V13Z" fill="currentColor" />
    <path d="M9 11H10L10 9L9 8.99999V11Z" fill="currentColor" />
    <path d="M10 9H11L11 6.99999L10 6.99997V9Z" fill="currentColor" />
    <path d="M13 6.99999V5.99999H11V6.99999H13Z" fill="currentColor" />
    <path d="M13 9H14V6.99997L13 6.99999L13 9Z" fill="currentColor" />
    <path d="M14 11H15V8.99999L14 9L14 11Z" fill="currentColor" />
    <path d="M15 13H16V11L15 11L15 13Z" fill="currentColor" />
    <path d="M16 15L17 15L17 13L16 13L16 15Z" fill="currentColor" />
    <path d="M17 17L18 17V15H17L17 17Z" fill="currentColor" />
    <path
      d="M7 15V17H17V15H16V13H15V11H14V9H13V7H11V9H10V11H9V13H8V15H7Z"
      fill="currentColor"
    />
  </svg>
);
