export const MailWhite = ({ ...props }) => {
  return (
    <svg
      width="25"
      height="16"
      viewBox="0 0 25 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.5698 0H22.5662H21.5704H20.5667H19.5709H18.5673H17.5715H16.5678H15.572H14.5684H13.5726H12.569V0.00129981H11.5656H10.5698H9.56614H8.57032H7.56695H6.57113H5.5675H4.57168H3.56832H2.57249H1.56809H0.572266V0.999983V2.00101V2.99943V4.00046V4.99888V6.00016V6.99859V7.99987V9.00323V9.99906V11.0024V11.9982V13.0019V14.0006V15.0016V16H1.56809H2.57171H3.56754H4.5709H5.56672H6.57035H7.56617H8.56954H9.56536H10.569H11.5648H12.569V15.9987H13.5723H14.5681H15.5718H16.5676H17.5709H18.5668H19.5704H20.5662H21.5696H22.5654H23.569H24.5649V15V13.999V13.0006V11.9972V11.0014V9.99776V9.00194V7.99831V6.99729V5.99886V4.99784V3.99915V2.99813V1.99945V0.998424V0H23.5698ZM22.5659 2.99813H21.5701V3.99915H20.5667V4.99784H19.5709V5.99886H18.5673V6.99729H17.5715V7.99831V9.00194H18.5673V9.99776H19.5709V11.0014H20.5667V11.9972H21.5701V13.0006H22.5659V13.999H21.5701H20.5667V13.0006H19.5709V11.9972H18.5673V11.0014H17.5715V9.99776H16.5678V9.00194H15.572V9.99776H14.5684V11.0014H13.5726V11.9972H12.569V11.9982H11.5656V11.0024H10.5698V9.99906H9.56614V9.00323H8.57032V9.99906H7.56695V11.0024H6.57113V11.9982H5.5675V13.0019H4.57168V14.0006H3.56832H2.57249V13.0019H3.56832V11.9982H4.57168V11.0024H5.5675V9.99906H6.57113V9.00323H7.56695V7.99987V6.99859H6.57113V6.00016H5.5675V4.99888H4.57168V4.00046H3.56832V2.99943H2.57249V2.00101H3.56832H4.57168V2.99943H5.5675V4.00046H6.57113V4.99888H7.56695V6.00016H8.57032V6.99859H9.56614V7.99987H10.5698V9.00323H11.5656V9.99906H12.569V9.99776H13.5723V9.00194H14.5681V7.99831H15.5718V6.99729H16.5676V5.99886H17.5709V4.99784H18.5668V3.99915H19.5704V2.99813H20.5662V1.99945H21.5696H22.5654V2.99813H22.5659Z"
        fill="white"
      />
    </svg>
  );
};
