import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import {
  TELEGRAM_BATTLE_ENDPOINT,
  TELEGRAM_PET_ENDPOINT,
  USER_EMIT_EVENT_ENDPOINT,
} from '@/config/endpoints';
import { useUserData } from '@/context/FirestoreContext';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import DefaultPet from '@/images/pixelton_cycle.gif';
import Whittakr from '@/images/whittakr.png';
import { sounds } from '@/lib/sounds';
import { Box, Center, Flex, VStack, useDisclosure } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Pet, SECOND_PLAYER_ID, TELEGRAM_REFERRAL_CODE_NOTCOIN, getTelegramPetImage } from 'genopets-utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSound from 'use-sound';
import { BlinkingText } from '../ui/atoms/BlinkingText';
import { Text } from '../ui/atoms/Text';
import { Next } from '../ui/icons/Next';
import { StarYellow } from '../ui/icons/StartYellow';
import { Button } from '../ui/molecules/Button';
import { Card } from '../ui/molecules/Card';
import { Modal } from '../ui/molecules/Modal';
import { PetRunAway } from './PetRunAway';
import { NotCoinWelcomeModal } from '../notcoin/NotCoinWelcomeModal';

// key format defined to map with Lokalise
const getTranslateQuestionKey = (index: number) => `summoningQuestion${index}`;
const questions = [
  'Openness',
  'Conscientiousness',
  'Agreeableness',
  'Extraversion',
  'Neuroticism',
];

// key format defined to map with Lokalise
const getAnswerChoiceTranslateKey = (
  questionIndex: number,
  choiceIndex: number,
) => `summonQuestion${questionIndex}Choice${choiceIndex}`;
const chooses = [
  [`Life's an adventure!`, 'Safety first'],
  [`I'll fix it!`, 'Not my problem'],
  [`I'm blunt`, 'I try not to offend'],
  [`Let's go out!`, `Netflix n chill`],
  [`Panic Attack!`, `Cool as a cucumber`],
];

// key format defined to map with Lokalise
const getResultTranslateKey = (questionIndex: number, choiceIndex: number) =>
  `summonResultQuestion${questionIndex}Choice${choiceIndex}`;
const result = [
  [
    'You love exploring new ideas and creative experiences!',
    'You prefer sticking to familiar routines and straightforward thinking.',
  ],
  [
    "You're organized, reliable, and always get things done efficiently.",
    'You tend to be spontaneous and less concerned with details.',
  ],

  [
    "You're kind-hearted, cooperative, and get along well with others.",
    'You can be skeptical and sometimes prefer to challenge others.',
  ],

  [
    'You thrive in social settings and love meeting new people.',
    'You enjoy solitude and often prefer quiet, peaceful environments.',
  ],

  [
    'You frequently experience strong emotions and can get stressed easily.',
    "You're generally calm, relaxed, and handle stress well.",
  ],
];

export const Answer = ({ pet }: { pet?: Pet }) => {
  const { userData } = useUserData();
  const navigate = useNavigate();

  const { apiPost } = useAPI();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  if (!pet) return null;

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={false}
        contentProps={{
          marginTop: 'auto',
          marginBottom: '0px',
          padding: '8px',
          maxW: 'none',
        }}
      >
        <Text colorId="Black">{pet.description}</Text>
      </Modal>
      <Text w="full" colorId="Black" textAlign="center">{`tada !!`}</Text>
      <Text w="full" colorId="Black" textAlign="center">
        <TranslatedText
          translationKey={`meetYourGenopet`}
          defaultMessage={`Meet your genopet`}
        />
      </Text>

      <Flex gap={2} onClick={() => onOpen()} cursor="pointer">
        <Text
          colorId="Black"
          w="full"
          textOverflow="ellipsis"
          display="-webkit-box"
          style={{ WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}
          overflow="hidden"
          whiteSpace="normal"
        >
          {pet.description}
        </Text>
        <Center>
          <Next color="black" />
        </Center>
      </Flex>

      <Button
        colorId="Black"
        onClick={() => {
          setIsButtonLoading(true);

          apiPost(USER_EMIT_EVENT_ENDPOINT, {
            userId: userData?.id,
            eventName: `telegramPetSummonStep`,
            eventData: {
              step: `letsBattle`,
            },
          });

          apiPost(TELEGRAM_BATTLE_ENDPOINT, {
            opponent: SECOND_PLAYER_ID,
          })
            .then((response) => {
              navigate(`/battle/${response}`);
            })
            .catch(() => {
              navigate(`/battle-stats`);
            })
            .finally(() => setIsButtonLoading(false));
        }}
        isLoading={isButtonLoading}
        w="full"
      >
        <TranslatedText
          translationKey={`letsBattle`}
          defaultMessage={`Let's Battle`}
        />
      </Button>
    </>
  );
};

const Question = ({
  choices,
  index,
  handleAnswer,
}: {
  choices: string[];
  index: number;
  // eslint-disable-next-line no-unused-vars
  handleAnswer: (x: number) => void;
}) => {
  const { apiPost } = useAPI();
  const { userData } = useUserData();

  return (
    <>
      <Box
        position="absolute"
        top={'-227px'}
        left={0}
        right={0}
        display="flex"
        justifyContent={'center'}
      >
        <img
          src={DefaultPet}
          alt={'pet'}
          style={{
            width: '227px',
            height: 'auto',
          }}
        />
      </Box>
      <Text w="full" colorId="Black" textAlign="center">
        <TranslatedText
          translationKey={`summonPetQuestionLabel`}
          defaultMessage={`question ${index + 1} of ${questions.length}`}
          props={{
            questionNumber: index + 1,
            questionLength: questions.length,
          }}
        />
      </Text>
      <Text w="full" colorId="Black" textAlign="center">
        <TranslatedText
          translationKey={getTranslateQuestionKey(index + 1) as any}
          defaultMessage={questions[index]}
        />
      </Text>
      {choices.map((choice, i) => (
        <Button
          key={i}
          colorId="Black"
          onClick={() => {
            handleAnswer(i);

            apiPost(USER_EMIT_EVENT_ENDPOINT, {
              userId: userData?.id,
              eventName: `telegramPetSummonStep`,
              eventData: {
                step: `question${index + 1}`,
              },
            });
          }}
          w="full"
        >
          <TranslatedText
            translationKey={
              getAnswerChoiceTranslateKey(index + 1, i + 1) as any
            }
            defaultMessage={choice}
          />
        </Button>
      ))}
    </>
  );
};

const MotionBox = motion(Box);

export type CreateBabyView =
  | 'RUN'
  | 'RAN'
  | 'RESULT'
  | 'ANSWER'
  | 'WELCOME'
  | 'INIT'
  | 'MEET'
  | 'ERROR'
  | 'QUESTION';

export const CreateBabyPet = () => {
  const [isCreatingBattle, setIsCreatingBattle] = useState(false);
  const [view, setView] = useState<CreateBabyView>('RUN');
  const [answers, setAnswers] = useState<number[]>([]);
  const [showPetDescription, setShowPetDescription] = useState(false);
  const [petName, setPetName] = useState('');
  const navigate = useNavigate();
  const [playSound] = useSound(sounds.summoned, {
    volume: 0.8,
    interrupt: false,
    loop: false,
  });

  const { apiPost } = useAPI();

  const alert = useAlert();

  const handleAnswer = (i: number) => {
    if (answers.length + 1 === questions.length) {
      setView('RESULT');
    }
    setAnswers((prev) => [...prev, i]);
  };

  const [loading, setLoading] = useState(false);
  const [showPet, setShowPet] = useState(false);

  const [pet, setPet] = useState<Pet>();

  const { userData } = useUserData();

  const getPet = () => {
    setView('ANSWER');
    if (!pet && answers.length === questions.length) {
      setLoading(true);

      apiPost(`${TELEGRAM_PET_ENDPOINT}`, {
        answers,
      })
        .then((pet) => {
          setPet(pet);
        })
        .catch((error: any) => {
          alert({
            title: error.message || 'Error',
          });
          setView('ERROR');
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!userData?.id) return;

    apiPost(USER_EMIT_EVENT_ENDPOINT, {
      userId: userData?.id,
      eventName: `telegramPetSummonStarted`,
      eventData: {
        telegramUsername: userData?.telegramUsername,
        referralCodeUsed: userData?.referralCodeUsed,
      },
    });
  }, [userData?.id]);

  useEffect(() => {
    if (showPet) {
      const timeout = setTimeout(() => {
        setShowPetDescription(true);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [showPet]);

  const renderSwitch = (view: string) => {
    switch (view) {
      case 'RAN':
        return (
          <>
            <Text colorId="Black" textAlign="center">
              <TranslatedText
                translationKey={`petRanAway`}
                defaultMessage={`oh no! ${petName} ran away!`}
                props={{ pet: petName }}
              />
              !
            </Text>

            <Button
              colorId="Black"
              w="full"
              onClick={() => {
                setView('INIT');

                apiPost(USER_EMIT_EVENT_ENDPOINT, {
                  userId: userData?.id,
                  eventName: `telegramPetSummonStep`,
                  eventData: {
                    step: `petRanAway`,
                  },
                });
              }}
            >
              wtf
            </Button>
          </>
        );
      case 'INIT':
        return (
          <>
            <Text colorId="Black" textAlign="center">
              <TranslatedText
                translationKey={`claimYourOwnGenopet`}
                defaultMessage={`Claim your own Genopet to Battle for Airdrops`}
              />
            </Text>

            <Button
              colorId="Black"
              w="full"
              onClick={() => {
                setView('MEET');

                apiPost(USER_EMIT_EVENT_ENDPOINT, {
                  userId: userData?.id,
                  eventName: `telegramPetSummonStep`,
                  eventData: {
                    step: `claimGenopetToBattleForAirdrops`,
                  },
                });
              }}
            >
              <TranslatedText translationKey={`ok`} defaultMessage={`ok`} />
            </Button>
          </>
        );
      case 'WELCOME':
        return (
          <>
            {userData?.referralCodeUsed === TELEGRAM_REFERRAL_CODE_NOTCOIN && !userData.telegramPet && <NotCoinWelcomeModal />}

            <Text colorId="Black" textAlign="center">
              <TranslatedText
                translationKey={`welcomeToPixeltonArena`}
                defaultMessage={`Welcome to Pixelton Arena!`}
              />
            </Text>

            <Button
              colorId="Black"
              w="full"
              isDisabled={isCreatingBattle}
              onClick={() => {
                // keeping this around in case we want to have the user
                // go through the summoning process first again in the future.
                setView('MEET');
                
                // have player go straight to an onboarding CPU battle
                // then have them summon a pet.
                /*
                setIsCreatingBattle(true);
                apiPost(TELEGRAM_BATTLE_ENDPOINT, {
                  opponent: SECOND_PLAYER_ID,
                  isOnboarding: true,
                })
                  .then((resp) => {
                    navigate(`/battle/${resp}`);
                  })
                  .catch((error: any) => {
                    console.error(error);
                    alert({ title: error?.message ?? 'Error' });
                    setIsCreatingBattle(false);
                  });
                  */
              }}
            >
              <TranslatedText translationKey={`ok`} defaultMessage={`ok`} />
            </Button>
          </>
        );
      case 'MEET':
        return (
          <>
            <Box
              position="absolute"
              top={'-311px'}
              left={0}
              right={0}
              display="flex"
              justifyContent={'center'}
            >
              <img
                src={Whittakr}
                alt={'Whittakr'}
                style={{
                  width: '311px',
                  height: '311px',
                  transform: 'rotate(-7.786deg)',
                  transformOrigin: 'center',
                }}
              />
            </Box>
            <Text colorId="Black" textAlign="center">
              <TranslatedText
                translationKey={`timeToBattle`}
                defaultMessage={`Time to battle`}
              />
              !
            </Text>
            <Text colorId="Black" textAlign="center">
              <TranslatedText
                translationKey={`answerFiveQuestions`}
                defaultMessage={`Answer 5 personality questions to find your Genopet.`}
              />
            </Text>

            <Button
              colorId="Black"
              w="full"
              onClick={() => {
                setView('QUESTION');

                apiPost(USER_EMIT_EVENT_ENDPOINT, {
                  userId: userData?.id,
                  eventName: `telegramPetSummonStep`,
                  eventData: {
                    step: `timeToBattle`,
                  },
                });
              }}
            >
              <TranslatedText translationKey={`ok`} defaultMessage={`ok`} />
            </Button>
          </>
        );
      case 'QUESTION':
        return (
          <Question
            choices={chooses[answers.length]}
            index={answers.length}
            handleAnswer={handleAnswer}
          />
        );
      case 'RESULT':
        return (
          <VStack>
            <Text colorId="Black" textAlign="left" w="full">
              <TranslatedText translationKey={'okay'} defaultMessage={'okay'} />
              !
            </Text>
            {answers.map((answer, i) => (
              <Text
                key={i}
                colorId="Black"
                textAlign="left"
                w="full"
                mt={i === 0 ? 0 : 4}
              >
                <TranslatedText
                  translationKey={
                    getResultTranslateKey(i + 1, answer + 1) as any
                  }
                  defaultMessage={result[i][answer]}
                />
              </Text>
            ))}
            <Button
              colorId="Black"
              w="full"
              onClick={() => {
                getPet();

                apiPost(USER_EMIT_EVENT_ENDPOINT, {
                  userId: userData?.id,
                  eventName: `telegramPetSummonStep`,
                  eventData: {
                    step: `meetMyGenopet`,
                  },
                });
              }}
            >
              <TranslatedText
                translationKey={`meetMyGenopet`}
                defaultMessage={`meet my genopet`}
              />
              !
            </Button>
          </VStack>
        );
      case 'ANSWER':
        return <Answer pet={pet} />;
      default:
        return (
          <Text colorId="Black" textAlign="center" w="full">
            {'An error occurred please retry later'}
          </Text>
        );
    }
  };

  if (loading)
    return (
      <Center w="full" h="full">
        <BlinkingText w="full" textAlign={'center'}>
          <TranslatedText
            translationKey={`summoningLoadingLabel`}
            defaultMessage={`Ok! Summoning the perfect Genopet for you…`}
          />
        </BlinkingText>
      </Center>
    );

  if (pet)
    return (
      <Center w="full" h="full" justifyContent={'flex-end'} flexDir={'column'}>
        <Center w="full" h={showPetDescription ? '210px' : 'full'}>
          {showPet ? (
            <Center w="200px" h="auto">
              <img src={getTelegramPetImage(pet.petConfigV2)} alt={`my pet`} />
            </Center>
          ) : (
            <MotionBox
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{
                type: 'spring',
                stiffness: 50,
                damping: 20,
              }}
              onAnimationStart={() => playSound()}
              onAnimationComplete={() => setShowPet(true)}
            >
              <StarYellow width="172px" height="172px" />
            </MotionBox>
          )}
        </Center>
        {showPetDescription && (
          <Card
            w="full"
            flexDirection={'column'}
            gap={2}
            display={'flex'}
            zIndex={2}
          >
            {renderSwitch(view)}
          </Card>
        )}
      </Center>
    );

  return (
    <>
      <Center
        w="full"
        h="full"
        alignItems={
          view === 'RUN' ||
          view === 'RAN' ||
          view === 'WELCOME' ||
          view === 'RESULT' ||
          view === 'INIT'
            ? 'center'
            : 'flex-end'
        }
        maxH="calc(100vh - 16px)"
        overflow={'auto'}
      >
        {pet ? null : (
          <>
            {view === 'RUN' ? (
              <PetRunAway setView={setView} setPetName={setPetName} />
            ) : (
              <Card
                w="full"
                flexDirection={'column'}
                gap={2}
                display={'flex'}
                zIndex={2}
                position={'relative'}
              >
                {renderSwitch(view)}
              </Card>
            )}
          </>
        )}
      </Center>
    </>
  );
};
