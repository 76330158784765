import { camelCase, isObject } from 'lodash';
import {
  AugmentClassVariant,
  isAugmentClassVariantGreaterOrEqual,
  ItemsMap,
} from '../../items/items.utils';
import { Pet, PetConfigPartKey } from '../entities/pet.entity';
import {
  AugmentSetBonusDefinitionsRecord,
  PartConfig,
} from './augment-set-bonus-definition.entity';

function calculateBonusStat(baseStat: number, bonusFormula: string): number {
  const value = bonusFormula.substring(1); // Extract the value (number with optional %)

  if (value.endsWith(`%`)) {
    const percentage = parseFloat(bonusFormula);
    if (!Number.isNaN(percentage)) {
      return Math.round(baseStat * (percentage / 100));
    }
  } else {
    const intValue = parseInt(bonusFormula, 10);
    if (!Number.isNaN(intValue)) {
      return intValue;
    }
  }
  // Invalid bonus format, return 0
  return 0;
}

export enum PetMainStatEnum {
  'HP' = `HP`,
  'Attack' = `Attack`,
  'Defense' = `Defense`,
  'Speed' = `Speed`,
}

export enum PetStatEnum {
  'HP' = `HP`,
  'Attack' = `Attack`,
  'Defense' = `Defense`,
  'Speed' = `Speed`,
  'Accuracy' = `Accuracy`,
  'Agility' = `Agility`,
  'Stability' = `Stability`,
}

export class PetStats {
  constructor(
    pet: Pet,
    itemsMap: ItemsMap,
    options?: {
      augSetBonusDefinitionsRecord?: AugmentSetBonusDefinitionsRecord;
      levelOverride?: number;
      telegramStats?: boolean;
    },
  ) {
    const { augSetBonusDefinitionsRecord, levelOverride, telegramStats } =
      options || {};

    const { level: classicLevel, telegramLevel, isGenesis, petConfigV2 } = pet;

    const level =
      levelOverride ?? (telegramStats ? telegramLevel : classicLevel);

    const baseStat = isGenesis ? 44 + (level - 22) : 20 + level - 1;
    const equippedAugmentIds = pet.getEquippedAugmentIds();

    this.baseHP = isGenesis ? 98 + 2 * (level - 22) : 50 + 2 * (level - 1);
    this.baseATK = baseStat;
    this.baseDEF = baseStat;
    this.baseSPD = baseStat;
    this.baseACC = (this.baseATK + this.baseSPD) / 4;
    this.baseAGL = (this.baseATK + this.baseDEF) / 4;
    this.baseSTB = (this.baseDEF + this.baseSPD) / 4;

    equippedAugmentIds.forEach((augmentId) => {
      const augment = itemsMap.get(augmentId);
      const { metadata } = augment ?? {};

      if (metadata != null) {
        this.bonusHP += metadata.HP
          ? calculateBonusStat(this.baseHP, metadata.HP!)
          : 0;
        this.bonusATK += metadata.Attack
          ? calculateBonusStat(this.baseATK, metadata.Attack!)
          : 0;
        this.bonusDEF +=
          metadata.Defense != null
            ? calculateBonusStat(this.baseDEF, metadata.Defense!)
            : 0;
        this.bonusSPD +=
          metadata.Speed != null
            ? calculateBonusStat(this.baseSPD, metadata.Speed!)
            : 0;

        this.bonusACC +=
          metadata.Accuracy != null
            ? calculateBonusStat(this.baseACC, metadata.Accuracy!)
            : 0;

        this.bonusAGL +=
          metadata.Agility != null
            ? calculateBonusStat(this.baseAGL, metadata.Agility!)
            : 0;

        this.bonusSTB +=
          metadata.Stability != null
            ? calculateBonusStat(this.baseSTB, metadata.Stability!)
            : 0;
      }
    });

    Object.values(augSetBonusDefinitionsRecord ?? {}).forEach((definition) => {
      const { augmentSet, bonusConfig } = definition;

      const partKeys = Object.keys(augmentSet);
      for (let i = 0; i < partKeys.length; i++) {
        const partKey = partKeys[i] as PetConfigPartKey;
        const partConfig = augmentSet[partKey] as PartConfig;

        if (isObject(partConfig)) {
          const { variant, itemClass, style, id: partConfigId } = partConfig;
          const partId = `${partKey}Id`;
          const configPartId = petConfigV2[partId] as string;
          if (!configPartId) return;

          const equippedAugmentId = Pet.getAugmentId(configPartId);
          const equippedAugmentData = itemsMap.get(equippedAugmentId);

          const isSpecificPartConfig = !!partConfigId;
          const isMinClassVariantPartConfig = !!(style && itemClass && variant);
          const isStylePartConfig = !!(style && !itemClass && !variant);

          if (isSpecificPartConfig && partConfigId !== equippedAugmentId) {
            return;
          }

          if (isMinClassVariantPartConfig) {
            const augClassVariant = camelCase(
              `${itemClass} ${variant}`,
            ) as AugmentClassVariant;
            const equippedClassVariant = camelCase(
              `${equippedAugmentData?.class} ${
                equippedAugmentData?.variant ?? `Basic`
              }}`,
            ) as AugmentClassVariant;

            if (
              !(
                equippedAugmentData?.metadata?.Style === style &&
                isAugmentClassVariantGreaterOrEqual(
                  equippedClassVariant,
                  augClassVariant,
                )
              )
            ) {
              return;
            }
          }

          if (isStylePartConfig && style !== petConfigV2[partKey]) {
            return;
          }

          // defensive coding where if the partConfig is not valid.
          if (
            !isSpecificPartConfig &&
            !isMinClassVariantPartConfig &&
            !isStylePartConfig
          ) {
            return;
          }
        } else {
          // not a valid PartConfig so disregard any bonuses defined.
          return;
        }
      }

      // apply bonusConfig if the petConfig matches the augmentSet above
      this.bonusHP += bonusConfig.HP
        ? calculateBonusStat(this.baseHP, bonusConfig.HP)
        : 0;
      this.bonusATK += bonusConfig.Attack
        ? calculateBonusStat(this.baseATK, bonusConfig.Attack)
        : 0;
      this.bonusDEF +=
        bonusConfig.Defense != null
          ? calculateBonusStat(this.baseDEF, bonusConfig.Defense)
          : 0;
      this.bonusSPD +=
        bonusConfig.Speed != null
          ? calculateBonusStat(this.baseSPD, bonusConfig.Speed)
          : 0;

      this.bonusACC +=
        bonusConfig.Accuracy != null
          ? calculateBonusStat(this.baseACC, bonusConfig.Accuracy)
          : 0;

      this.bonusAGL +=
        bonusConfig.Agility != null
          ? calculateBonusStat(this.baseAGL, bonusConfig.Agility)
          : 0;

      this.bonusSTB +=
        bonusConfig.Stability != null
          ? calculateBonusStat(this.baseSTB, bonusConfig.Stability)
          : 0;
    });
  }

  private baseHP: number;

  private baseATK: number;

  private baseDEF: number;

  private baseSPD: number;

  private baseACC: number;

  private baseAGL: number;

  private baseSTB: number;

  public bonusHP = 0;

  public bonusATK = 0;

  public bonusDEF = 0;

  public bonusSPD = 0;

  public bonusACC = 0;

  public bonusAGL = 0;

  public bonusSTB = 0;

  get hp() {
    return this.baseHP + this.bonusHP;
  }

  get atk() {
    return this.baseATK + this.bonusATK;
  }

  get def() {
    return this.baseDEF + this.bonusDEF;
  }

  get spd() {
    return this.baseSPD + this.bonusSPD;
  }

  get acc() {
    return this.baseACC + this.bonusACC;
  }

  get agl() {
    return this.baseAGL + this.bonusAGL;
  }

  get stb() {
    return this.baseSTB + this.bonusSTB;
  }
}
