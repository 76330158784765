/* eslint-disable max-classes-per-file */
import { ApiProperty, ApiPropertyOptional } from '@nestjs/swagger';
import { IsString } from 'class-validator';
import {
  BattleGameStateInGodModeEntity,
  PlayerGameStateInGodMode,
} from '../utils/battle-utils';
import { StatusEffectEntity } from './status-effect.entity';
import type { Element } from '../../models';

export type RollType =
  | 'power'
  | 'damageReduction'
  | 'critical'
  | 'dodged'
  | 'counter'
  | 'paralyze'
  | 'randomAgility'
  | 'randomAccuracy'
  | 'damage'
  | 'atkElementalCoefficient'
  | 'defElementalCoefficient';

export type Rolls = Partial<Record<RollType, number | number[]>>;

export enum MoveType {
  'ATK' = `ATK`,
  'MOD' = `MOD`,
  'DEF' = `DEF`,
  'Break' = `Break`,
  'Minor' = `Minor`,
}

export enum AnimationType {
  'Melee' = `Melee`,
  'Ranged' = `Ranged`,
  'Hack' = `Hack`,
  'Power' = `Power`,
}

export enum DodgeResult {
  'FAILED' = `FAILED`,
  'SUCCESS' = `SUCCESS`,
  'CRITICAL_FAILED' = `CRITICAL_FAILED`,
}

export class BattleDodgeEntity {
  @ApiPropertyOptional({
    example: true,
    description: `Define is the block has been used`,
  })
  used: boolean | 'auto';

  @ApiPropertyOptional({
    example: `CRITICAL_FAILED`,
    description: `REsult of the dodge minigame`,
  })
  result: DodgeResult;
}

export class BattleItemEntity {
  mint: string;
}

export class BattleMoveEntity {
  @ApiProperty({
    example: `Tackle`,
    description: `Name of the move`,
  })
  name: string;

  @ApiPropertyOptional({
    example: `ATK`,
    description: `Type of the move, ATK, MOD, DEF for classic moves, Break for break move, Minor for simple move (default move without cooldown)`,
  })
  types: MoveType[];

  @ApiPropertyOptional({
    example: `A powerful attack`,
    description: `summary of the move`,
  })
  summary?: string;

  @ApiPropertyOptional({
    example: `A powerful attack`,
    description: `Description of the move`,
  })
  description?: string;

  @ApiPropertyOptional({
    example: `format: move{MoveName}, example: moveApeAttack`,
    description: `Key reference in lokalise for the move description translation`,
  })
  lokaliseKeyDescription?: string;

  @ApiPropertyOptional({
    example: `format: moveName{MoveName}, example: moveNameApeAttack`,
    description: `Key reference in lokalise for the move name translation`,
  })
  lokaliseKeyName?: string;

  @ApiPropertyOptional({
    example: `[DMG]`,
    description: `Label printed for the type of the move`,
  })
  categories?: string[];

  @ApiPropertyOptional({
    example: 18,
    description: `Min level of the pet to have the move`,
  })
  minLevel?: number;

  @ApiPropertyOptional({
    example: `Jump`,
    description: `Description of the move animation`,
  })
  animationType?: AnimationType;

  @ApiPropertyOptional({
    example: `https://i.imgur.com/xxxxxxx.png`,
    description: `Url of the sound file`,
  })
  sound?: string;

  @ApiPropertyOptional({
    example: `https://i.imgur.com/xxxxxxx.png`,
    description: `Url of the image file to play on the attacking pet when the move is used`,
  })
  userImage?: string;

  @ApiPropertyOptional({
    example: `https://i.imgur.com/xxxxxxx.png`,
    description: `Url of the image file to play on the defending pet when the move is used`,
  })
  targetImage?: string;

  @ApiPropertyOptional({
    example: `https://i.imgur.com/xxxxxxx.png`,
    description: `Url of the image file to play when the move is used`,
  })
  projectileImage?: string;

  @ApiProperty({
    example: 40,
    description: `Power of the move`,
  })
  power: number;

  @ApiPropertyOptional({
    example: `Fire`,
    description: `Element of the move. Could be Fire, Water, Wood, Earth, Metal and undefined if neutral`,
    type: String,
  })
  element?: Element;

  @ApiProperty({
    example: 3,
    description: `Cooldown of the move (in turns)`,
  })
  cooldown: number;

  @ApiProperty({
    example: 3,
    description: `Number of hit performed be the attack (undefined = 1 hit)`,
  })
  hitNumber?: number;

  @ApiPropertyOptional({
    example: `Virtu`,
    description: `The augment name`,
  })
  augment?: string;

  @ApiPropertyOptional({
    example: [
      {
        duration: 3,
        value: `-50%`,
        name: `Mega Debuff`,
        target: `opponent`,
        statAffected: `ATK`,
      },
      {
        target: `opponent`,
        statAffected: `DEF`,
        name: `Mega Debuff`,
        value: `-50%`,
        duration: 3,
      },
      {
        name: `Mega Debuff`,
        value: `-50%`,
        target: `opponent`,
        duration: 3,
        statAffected: `SPD`,
      },
      {
        value: `-50%`,
        duration: 3,
        statAffected: `ACC`,
        name: `Mega Debuff`,
        target: `opponent`,
      },
      {
        name: `Mega Debuff`,
        value: `-50%`,
        target: `opponent`,
        duration: 3,
        statAffected: `AGL`,
      },
      {
        statAffected: `STB`,
        duration: 3,
        value: `-50%`,
        target: `opponent`,
        name: `Mega Debuff`,
      },
    ],
    description: `Status Effect applied by the move`,
  })
  status?: StatusEffectEntity[];
}

export interface BattleSetBreakMove {
  playerId: string;
  move: BattleMoveEntity;
}

export class MovesMapEntity {
  [augment: string]: BattleMoveEntity;
}

export class BattleActionEntity {
  @IsString()
  @ApiProperty({
    example: `EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v`,
    description: `Id of the player that play the action`,
  })
  playerId: string;

  @ApiProperty({
    example: {
      name: `Cooldown`,
      cooldown: 4,
      break: false,
      description: `Cooldown`,
      power: 20,
      status: [],
    },
    examples: {
      move: {
        name: `Cooldown`,
        cooldown: 4,
        break: false,
        description: `Cooldown`,
        power: 20,
        status: [],
      },
      block: {
        used: true,
        reductionPercentage: 20,
      },
    },
    description: `data of the action played`,
  })
  // BattleMoveEntity[] for selected Move, the other for use move and use block
  data:
    | BattleMoveEntity[]
    | BattleMoveEntity
    | BattleDodgeEntity
    | BattleItemEntity
    | BattleGameStateInGodModeEntity
    | PlayerGameStateInGodMode;

  @ApiProperty({
    example: { dodge: 0, power: 10 },
    description: `Rolls performed when processing the action (used for replay)`,
  })
  rolls?: Rolls;
}
