import { Button } from '@/components/ui/molecules/Button';
import { MenuItemPop } from '@/components/ui/molecules/MenuItemPop';
import { Modal } from '@/components/ui/molecules/Modal';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { BattleState } from 'genopets-utils';
import { useState } from 'react';
import { Text } from '../../ui/atoms/Text';

const LIMIT = 6;
export const ReplayLastActionState = ({
  actions,
}: {
  actions: BattleState['actions'];
}) => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: onOpenModal,
    isOpen: isOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const [page, setPage] = useState(0);
  const [selectedAction, setSelectedAction] = useState<
    BattleState['actions'][0] & { index: number }
  >();

  return (
    <>
      <Modal isOpen={isOpenModal} onClose={onCloseModal}>
        <Text colorId="Black" w="full">
          Type
        </Text>
        <Text colorId="Black" w="full" fontSize={'8px'}>
          {`${selectedAction?.index} ${selectedAction?.type}`}
        </Text>
        <Text
          colorId="Black"
          w="full"
          mt={2}
          pt={2}
          borderTop={`3px solid var(--Black)`}
        >
          Data
        </Text>
        {selectedAction?.payload.data
          ? Object.entries(selectedAction?.payload.data).map((entries, i) => {
              return (
                <Text
                  colorId="Black"
                  fontSize={'8px'}
                  w="full"
                  key={i}
                >{`${entries[0]} : ${JSON.stringify(entries[1])}`}</Text>
              );
            })
          : 'NO Rolls'}
        <Text
          colorId="Black"
          w="full"
          mt={2}
          pt={2}
          borderTop={`3px solid var(--Black)`}
        >
          Rolls
        </Text>
        {selectedAction?.payload.rolls
          ? Object.entries(selectedAction?.payload.rolls).map((entries, i) => {
              return (
                <Text
                  colorId="Black"
                  fontSize={'8px'}
                  w="full"
                  key={i}
                >{`${entries[0]} : ${entries[1]}`}</Text>
              );
            })
          : 'NO Rolls'}
      </Modal>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Text colorId="Black" w="full" mb={2}>
          {`Actions`}
        </Text>

        <Box w="full">
          {actions
            .map((a, i) => ({ ...a, index: i }))
            .slice(page * LIMIT, (page + 1) * LIMIT)
            .map((action, i) => {
              return (
                <Flex key={i} flexDirection={'column'}>
                  <MenuItemPop
                    color={`var(--Black)`}
                    w="full"
                    onClick={() => {
                      setSelectedAction(action);
                      onOpenModal();
                    }}
                  >
                    <Flex flexDirection={'column'} w="calc(100% - 24px)">
                      <Text colorId="Black" fontSize="10px">
                        {`${action.index} ${action.type}`}
                      </Text>
                      <Text
                        colorId="Black"
                        fontSize="10px"
                        w="full"
                        textOverflow={'ellipsis'}
                        whiteSpace={'nowrap'}
                        overflow={'hidden'}
                      >
                        {`by ${action.payload.playerId}`}
                      </Text>
                    </Flex>
                  </MenuItemPop>
                </Flex>
              );
            })}
        </Box>
        <Flex gap={1}>
          <Button
            colorId="Black"
            isDisabled={page === 0}
            onClick={() => setPage(page - 1)}
            minW={0}
            padding="4px"
            w="full"
          >
            Prev
          </Button>
          <Button
            colorId="Black"
            isDisabled={(page + 1) * LIMIT > actions.length}
            onClick={() => setPage(page + 1)}
            minW={0}
            padding="4px"
            w="full"
          >
            Next
          </Button>
        </Flex>
      </Modal>
      <Flex flexDirection="column" w="full">
        <Text colorId="Black" w="full">
          {`Last Action:`}
        </Text>
        <MenuItemPop color={`var(--Black)`} w="full" onClick={onOpen}>
          <Text
            colorId="Black"
            w="full"
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            fontSize={'10px'}
          >
            {`${actions.at(-1)?.type}`}
          </Text>
        </MenuItemPop>
      </Flex>
    </>
  );
};
