export const Energy = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8.0048 1H20.9764V2.62963H8.0048V1Z" fill="currentColor" />
    <path
      d="M6.88127 2.62963H18.2442V4.25926H6.88127V2.62963Z"
      fill="currentColor"
    />
    <path
      d="M6.06416 4.25926H16.8773V5.88889H6.06416V4.25926Z"
      fill="currentColor"
    />
    <path
      d="M5.24705 5.88889H14.7204V7.51852H5.24705V5.88889Z"
      fill="currentColor"
    />
    <path
      d="M11.6818 8.12963H13.316V9.75926H11.6818V8.12963Z"
      fill="currentColor"
    />
    <path
      d="M12.8564 7.00926H14.4906V8.63889H12.8564V7.00926Z"
      fill="currentColor"
    />
    <path
      d="M14.2353 5.78704H15.8695V7.41667H14.2353V5.78704Z"
      fill="currentColor"
    />
    <path
      d="M15.512 4.46296H17.1462V6.09259H15.512V4.46296Z"
      fill="currentColor"
    />
    <path
      d="M16.7887 3.18981H18.423V4.81944H16.7887V3.18981Z"
      fill="currentColor"
    />
    <path
      d="M18.1676 1.81481H19.8018V3.44444H18.1676V1.81481Z"
      fill="currentColor"
    />
    <path
      d="M4.42994 7.51852H12.933V9.14815H4.42994V7.51852Z"
      fill="currentColor"
    />
    <path
      d="M3.61283 9.14815H11.8605V10.7778H3.61283V9.14815Z"
      fill="currentColor"
    />
    <path d="M3 10.7778H18.0144V12.4074H3V10.7778Z" fill="currentColor" />
    <path
      d="M9.38367 14.037H16.9164L15.4099 15.6667H9.38367V14.037Z"
      fill="currentColor"
    />
    <path
      d="M8.87298 15.6667H14.3565V17.2963H8.87298V15.6667Z"
      fill="currentColor"
    />
    <path
      d="M8.0048 17.2963H11.8356V18.9259H8.0048V17.2963Z"
      fill="currentColor"
    />
    <path
      d="M6.88127 18.4676H10.4097V20.0972H6.88127V18.4676Z"
      fill="currentColor"
    />
    <path
      d="M6.21737 19.8935H9.23843V21.5231H6.21737V19.8935Z"
      fill="currentColor"
    />
    <path
      d="M5.60454 21.3704H9.38367V23H5.60454V21.3704Z"
      fill="currentColor"
    />
    <path
      d="M8.87298 19.8935H10.5072V21.5231H8.87298V19.8935Z"
      fill="currentColor"
    />
    <path
      d="M10.2008 18.6713H11.835V20.3009H10.2008V18.6713Z"
      fill="currentColor"
    />
    <path
      d="M11.6818 17.2963H13.316V18.9259H11.6818V17.2963Z"
      fill="currentColor"
    />
    <path
      d="M12.8564 16.0231H14.4906V17.6528H12.8564V16.0231Z"
      fill="currentColor"
    />
    <path
      d="M14.2353 14.6991H15.8695V16.3287H14.2353V14.6991Z"
      fill="currentColor"
    />
    <path
      d="M15.4099 13.4259H17.0441V15.0556H15.4099V13.4259Z"
      fill="currentColor"
    />
    <path
      d="M16.7887 12.1019H18.423V13.7315H16.7887V12.1019Z"
      fill="currentColor"
    />
    <path
      d="M18.0144 10.7778H19.6486V12.4074H18.0144V10.7778Z"
      fill="currentColor"
    />
    <path
      d="M11.6818 9.60648H21.1296V11.2361H11.6818V9.60648Z"
      fill="currentColor"
    />
    <path d="M3 12.4074H16.9164V14.037H3V12.4074Z" fill="currentColor" />
  </svg>
);
