export const Star = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="105"
      height="99"
      viewBox="0 0 105 99"
      fill="none"
      {...props}
    >
      <path
        d="M52.5 0L64.736 37.6586H104.333L72.2983 60.9329L84.5343 98.5914L52.5 75.3171L20.4657 98.5914L32.7017 60.9329L0.667419 37.6586H40.264L52.5 0Z"
        fill="currentColor"
      />
    </svg>
  );
};
