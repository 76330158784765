import { MainContainer } from '@/components/battle/MainContainer';
import { CreateBabyPet } from '@/components/CreateBaby/CreateBabyPet';
import { bgSounds, initialOptions } from '@/lib/sounds';
import { useEffect } from 'react';
import useSound from 'use-sound';

export const CreateBabyPage = () => {
  const [play, { stop, sound }] = useSound(bgSounds.summon, initialOptions);

  useEffect(() => {
    if (sound) {
      play();
      return () => stop();
    }
  }, [sound]);

  return (
    <MainContainer
      backgroundPosition="bottom left -100px"
      backgroundSize="auto"
    >
      <CreateBabyPet />
    </MainContainer>
  );
};
