export const petStatisticsList = [
  `HP`,
  `ATK`,
  `DEF`,
  `SPD`,
  `ACC`,
  `AGL`,
  `STB`,
] as const;

export type PetStatistics = (typeof petStatisticsList)[number];

export enum PetStatisticsEnum {
  'HP' = `HP`,
  'ATK' = `ATK`,
  'DEF' = `DEF`,
  'SPD' = `SPD`,
  'ACC' = `ACC`,
  'AGL' = `AGL`,
  'STB' = `STB`,
}

export type PetStatisticsType = Record<PetStatistics, number>;
