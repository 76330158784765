import { MainContainer } from '@/components/battle/MainContainer';
import { Leaderboard } from '@/components/leaderboard/Leaderboard';
import InitBackground from '../../images/leaderboard-bg.png';
import useSound from 'use-sound';
import { bgSounds, initialOptions } from '@/lib/sounds';
import { useEffect } from 'react';

export const BattleLeaderboardPage = () => {
  const [play, { stop, sound }] = useSound(
    bgSounds.leaderboard,
    initialOptions,
  );

  useEffect(() => {
    if (sound) {
      play();
      return () => stop();
    }
  }, [sound]);

  return (
    <MainContainer
      padding={0}
      background={InitBackground}
      backgroundPosition="top"
      backgroundSize="100% auto"
    >
      <Leaderboard />
    </MainContainer>
  );
};
