import { useUIBattleContext } from '@/components/battle/context/useBattleContext';
import { useAuthState } from '@/context/UserContext';
import { sounds } from '@/lib/sounds';
import { getPetName } from '@/utils/utils';
import { Box, Flex, FlexProps, useDisclosure } from '@chakra-ui/react';
import { useHapticFeedback } from '@tma.js/sdk-react';
import { Variants, motion } from 'framer-motion';
import {
  AnimationType,
  BattleMoveEntity,
  BattleState,
  DEFAULT_TELEGRAM_PET,
  MoveType,
  RootState,
  StatusEffectEntity,
  getTelegramPetImage,
} from 'genopets-utils';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useSound from 'use-sound';
import CleanShot from '../../../images/clean-shot.png';
import { Text } from '../../ui/atoms/Text';
import { Bar } from '../../ui/molecules/Bar';
import { BreakIndicator } from '../../ui/molecules/BreakIndicator';
import { StatusIndicators } from './StatusIndicators';
import { StatusInformationModal } from './StatusInformationModal';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';

// component that allow to dynamically change the sound
const PlaySound = ({ sound }: { sound: string }) => {
  const [playSound] = useSound(sound, {
    volume: 0.8,
    interrupt: false,
    loop: false,
  });

  useEffect(() => {
    if (playSound) {
      playSound();
    }
  }, [playSound]);

  return <></>;
};

const petSize = 300;

const opponentPetSize = 170;

const halfPetSize = petSize / 2;

const halfOpponentPetSize = opponentPetSize / 2;

export const PetInformation = ({
  playerId,
  player,
  level,
  breakLevel,
  status,
  hp,
  maxHp,
  opponent = false,
  ...props
}: {
  playerId: string;
  player: string;
  level: number;
  breakLevel: number;
  hp: number;
  maxHp: number;
  status?: StatusEffectEntity[];
  opponent?: boolean;
} & FlexProps) => {
  const {
    playMoveAnimation,
    moveAnimationPlayed,
    setMoveAnimationPlayed,
    pet: petFromDb,
    opponentPet: opponentPetFromDb,
  } = useUIBattleContext();
  useUIBattleContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [chargeVisible, setChargeVisible] = useState(false);
  const [impactVisible, setImpactVisible] = useState(false);
  const [projectileVisible, setProjectileVisible] = useState(false);
  const [animationToPlay, setAnimationToPlay] = useState<
    'hidden' | 'melee' | 'meleeOpponent' | 'shake'
  >('hidden');
  const [sound, setSound] = useState('');

  const [playSound] = useSound(sounds.dodge, {
    volume: 0.8,
    interrupt: false,
    loop: false,
  });

  const [savedHp, setSavedHp] = useState(0);
  const [printHp, setPrintHp] = useState(maxHp);
  const [damage, setDamage] = useState(0);

  const { state } = useAuthState();

  const user = state.currentUser;

  const hapticFeedback = useHapticFeedback();

  const battleState = useSelector<RootState>(
    (state: RootState) => state.battle,
  ) as BattleState;

  const petFromBattleState = useMemo(() => {
    return battleState?.player1?.id === user?.uid
      ? battleState?.player1?.petInfo
      : battleState?.player2?.petInfo;
  }, [battleState]);

  const opponentPetFromBattleState = useMemo(() => {
    return battleState?.player1?.id === user?.uid
      ? battleState?.player2?.petInfo
      : battleState?.player1?.petInfo;
  }, [battleState, user]);

  const pet = petFromDb ?? petFromBattleState;

  const opponentPet = opponentPetFromDb ?? opponentPetFromBattleState;

  useEffect(() => {
    if (!savedHp) {
      setSavedHp(hp);
    } else if (hp !== savedHp) {
      setSavedHp(hp);
      setDamage(savedHp - hp > 0 ? ((savedHp - hp) * 100) / maxHp : 0);
    }

    if (hp <= 0 && moveAnimationPlayed && playMoveAnimation) {
      setSound(sounds.faint);
    }
  }, [hp]);

  useEffect(() => {
    if (impactVisible) {
      if (damage > 20) {
        hapticFeedback.notificationOccurred('success');
      } else if (damage > 5) {
        hapticFeedback.notificationOccurred('error');
      } else if (damage > 0) {
        hapticFeedback.notificationOccurred('warning');
      }
      setPrintHp(hp);
    }
  }, [impactVisible]);

  useEffect(() => {
    let timeout: any;
    let timeout1: any;
    let timeout2: any;
    let timeout3: any;
    let timeout4: any;
    if (playMoveAnimation && !moveAnimationPlayed) {
      const move = playMoveAnimation.data as BattleMoveEntity;
      const isMeleeMove =
        move.animationType === AnimationType.Melee ||
        move.types.includes(MoveType.Minor); // Poke is consider as melee move
      const isRangedMove = move.animationType === AnimationType.Ranged;
      const isPowerMove = move.animationType === AnimationType.Power;
      const isHackMove = move.animationType === AnimationType.Hack;

      const isDodged =
        battleState.actions[battleState.actions.length - 1].payload.rolls
          ?.dodged === 1;

      const isNotDodged = !isDodged;

      const initialWait = 200;

      // wait 500 ms shake effect
      const shakeTime = 500;

      const impactTime = 1200;

      const chargeTime = 1200;

      if (isHackMove) {
        timeout = setTimeout(
          () => {
            const sound = (playMoveAnimation.data as BattleMoveEntity).sound;
            if (sound) {
              const soundPath = sound;
              setSound(soundPath);
            }
            setAnimationToPlay(
              playMoveAnimation.playerId === playerId ? 'shake' : 'hidden',
            );
          },
          initialWait, // wait 200ms before starting the animation
        );

        timeout1 = setTimeout(() => {
          setChargeVisible(true);
          setImpactVisible(isNotDodged);
          if (isDodged) {
            playSound();
          }
        }, initialWait + shakeTime);

        timeout2 = setTimeout(
          () => {
            setChargeVisible(false);
            setImpactVisible(false);
          },
          initialWait + shakeTime + impactTime,
        );

        timeout3 = setTimeout(
          () => {
            setMoveAnimationPlayed(true);
            setAnimationToPlay('hidden');
            setSound(''); // return sound to '' to unmount the component
          },
          initialWait + shakeTime + impactTime + 200,
        ); // wait 200ms after the animation  before allowing ui update
      } else if (isPowerMove) {
        timeout = setTimeout(
          () => {
            const sound = (playMoveAnimation.data as BattleMoveEntity).sound;
            if (sound) {
              const soundPath = sound;
              setSound(soundPath);
            }
            setChargeVisible(true);
          },
          initialWait, // wait 200ms before starting the animation
        );

        timeout1 = setTimeout(() => {
          setChargeVisible(false);
          setAnimationToPlay(
            playMoveAnimation.playerId === playerId ? 'shake' : 'hidden',
          );
        }, initialWait + chargeTime);

        timeout2 = setTimeout(
          () => {
            setImpactVisible(isNotDodged);
            if (isDodged) {
              playSound();
            }
          },
          initialWait + chargeTime + shakeTime,
        );

        timeout4 = setTimeout(
          () => {
            setImpactVisible(false);
          },
          initialWait + chargeTime + shakeTime + impactTime,
        );

        timeout3 = setTimeout(
          () => {
            setMoveAnimationPlayed(true);
            setAnimationToPlay('hidden');
            setSound(''); // return sound to '' to unmount the component
          },
          initialWait + chargeTime + shakeTime + impactTime + 200,
        ); // wait 200ms after the animation  before allowing ui update
      } else if (isMeleeMove) {
        timeout = setTimeout(
          () => {
            setAnimationToPlay(
              playMoveAnimation.playerId === playerId
                ? opponent
                  ? 'meleeOpponent'
                  : 'melee'
                : 'hidden',
            );
            const sound = (playMoveAnimation.data as BattleMoveEntity).sound;

            if (sound) {
              const soundPath = sound;
              setSound(soundPath);
            }
          },
          initialWait, // wait 200ms before starting the animation
        );

        timeout1 = setTimeout(() => {
          setImpactVisible(isNotDodged);
          if (isDodged) {
            playSound();
          }
        }, initialWait + 100);

        timeout2 = setTimeout(
          () => {
            setImpactVisible(false);
          },
          initialWait + impactTime + 100,
        );

        timeout3 = setTimeout(
          () => {
            setMoveAnimationPlayed(true);
            setAnimationToPlay('hidden');
            setSound(''); // return sound to '' to unmount the component
          },
          initialWait + impactTime + 300,
        ); // wait 200ms after the animation  before allowing ui update
      } else if (isRangedMove) {
        timeout = setTimeout(
          () => {
            setAnimationToPlay(
              playMoveAnimation.playerId === playerId ? 'shake' : 'hidden',
            );
            const sound = (playMoveAnimation.data as BattleMoveEntity).sound;

            if (sound) {
              const soundPath = sound;
              setSound(soundPath);
            }
          },
          initialWait, // wait 200ms before starting the animation
        );

        // animation projectile 600ms
        timeout4 = setTimeout(() => {
          setProjectileVisible(true);
        }, shakeTime + initialWait);

        timeout1 = setTimeout(
          () => {
            setImpactVisible(isNotDodged);
            if (isDodged) {
              playSound();
            }
          },
          shakeTime + initialWait + 100,
        );

        // scale = 1 at 300 ms

        timeout2 = setTimeout(
          () => {
            setImpactVisible(false);
          },
          shakeTime + initialWait + impactTime + 100,
        );

        timeout3 = setTimeout(
          () => {
            setMoveAnimationPlayed(true);
            setAnimationToPlay('hidden');
            setProjectileVisible(false);
            setSound(''); // return sound to '' to unmount the component
          },
          shakeTime + initialWait + impactTime + 300,
        ); // wait 200ms after the animation  before allowing ui update
      } else {
        // no animation to play trigger the alert and move to next player action
        setMoveAnimationPlayed(true);
      }

      return () => {
        clearTimeout(timeout);
        clearTimeout(timeout1);
        clearTimeout(timeout2);
        clearTimeout(timeout3);
        clearTimeout(timeout4);
      };
    }
  }, [playMoveAnimation, moveAnimationPlayed]);

  const projectileVariants: Variants = {
    hidden: { scale: 0 },
    ranged: {
      scale: [0, 1, 0],
      transition: {
        transition: {
          duration: 0.6,
          ease: 'easeOut', // Bezier curve for the animation
          times: [0, 0.5, 1], // Keyframe times for back and forth
        },
      },
    },
  };

  // window - information height - pet height/2 - information height - pet height/2 - padding
  const yTranslate = useMemo(
    () =>
      window?.innerHeight
        ? window.innerHeight - 96 - halfPetSize - 96 - halfOpponentPetSize - 16
        : 0,
    [],
  );

  // window - pet1 width /2 - pet2 width /2 - padding
  const xTranslate = useMemo(
    () =>
      window?.innerWidth
        ? window.innerWidth - halfPetSize - halfOpponentPetSize / 2 - 16
        : 0,
    [],
  );

  const petImageMargin = 40;

  const attackVariants: Variants = {
    hidden: { x: 0, y: 0 },
    melee: {
      x: [0, xTranslate - petImageMargin, 0],
      y: [0, -yTranslate + petImageMargin, 0],
      zIndex: 3,
      transition: {
        duration: 0.6,
        ease: [0.68, -0.6, 0.32, 1.6], // Bezier curve for the animation
      },
    },
    meleeOpponent: {
      x: [0, -xTranslate + petImageMargin, 0],
      y: [0, yTranslate - petImageMargin, 0],
      zIndex: 3,
      transition: {
        duration: 0.6,
        ease: [0.68, -0.6, 0.32, 1.6], // Bezier curve for the animation
      },
    },
    shake: {
      x: [0, -5, 5, -5, 5, -5, 5, 0], // Shake effect
      transition: { duration: 0.5, ease: 'easeInOut' },
    },
  };

  const image = (
    <Box
      position={'relative'}
      height={'100px'}
      w="full"
      pointerEvents={'none'}
      zIndex={opponent ? 1 : 5}
    >
      {/* pet image */}
      <motion.div
        style={{
          position: 'absolute',
          height: opponent ? opponentPetSize : petSize,
          width: opponent ? opponentPetSize : petSize,
          top: opponent ? '0' : undefined,
          bottom: opponent ? undefined : '0',
          left: opponent ? undefined : '0',
          right: opponent ? '0' : undefined,
        }}
        initial="hidden"
        animate={animationToPlay}
        variants={attackVariants}
      >
        {hp <= 0 && moveAnimationPlayed ? (
          <img src={CleanShot} alt={`CleanShot`} />
        ) : (
          <>
            {/* projectile image for player */}
            {playMoveAnimation &&
              !opponent &&
              (playMoveAnimation?.data as BattleMoveEntity).projectileImage && (
                <motion.div
                  style={{
                    position: 'absolute',
                    top: opponent ? halfOpponentPetSize : undefined,
                    bottom: opponent ? undefined : halfPetSize,
                    left: opponent ? undefined : halfPetSize,
                    right: opponent ? halfOpponentPetSize : undefined,
                    transformOrigin: opponent ? 'top right' : 'bottom left',
                  }}
                  initial="hidden"
                  animate={
                    projectileVisible && playMoveAnimation.playerId === playerId
                      ? ['ranged']
                      : 'hidden'
                  }
                  variants={projectileVariants}
                >
                  <img
                    src={
                      (playMoveAnimation?.data as BattleMoveEntity)
                        .projectileImage
                    }
                    alt="projectile"
                    style={{
                      height: yTranslate,
                      minWidth: xTranslate,
                      transform: !opponent ? `` : `rotate(180deg)`,
                    }}
                  />
                </motion.div>
              )}

            {pet && opponentPet && (
              <img
                src={
                  opponent
                    ? getTelegramPetImage(opponentPet.petConfigV2)
                    : getTelegramPetImage(pet.petConfigV2, 'back')
                }
                alt={`${opponent ? 'opponent' : 'player'} pet`}
                style={{ position: 'absolute' }}
              />
            )}

            {/* charge image */}
            {playMoveAnimation &&
              (playMoveAnimation?.data as BattleMoveEntity).userImage && (
                <img
                  src={(playMoveAnimation?.data as BattleMoveEntity).userImage}
                  style={{
                    opacity:
                      chargeVisible && playMoveAnimation.playerId === playerId
                        ? 1
                        : 0,
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                  alt="impact"
                />
              )}

            {/* impact image */}
            {playMoveAnimation &&
              (playMoveAnimation?.data as BattleMoveEntity).targetImage && (
                <>
                  {
                    <img
                      src={
                        (playMoveAnimation?.data as BattleMoveEntity)
                          .targetImage
                      }
                      style={{
                        opacity:
                          impactVisible &&
                          playMoveAnimation.playerId !== playerId
                            ? 1
                            : 0,
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                      }}
                      alt="impact"
                    />
                  }
                </>
              )}

            {/* projectile image for opponent*/}
            {playMoveAnimation &&
              opponent &&
              (playMoveAnimation?.data as BattleMoveEntity).projectileImage && (
                <motion.div
                  style={{
                    position: 'absolute',
                    top: opponent ? halfOpponentPetSize : undefined,
                    bottom: opponent ? undefined : halfPetSize,
                    left: opponent ? undefined : halfPetSize,
                    right: opponent ? halfOpponentPetSize : undefined,
                    transformOrigin: opponent ? 'top right' : 'bottom left',
                  }}
                  initial="hidden"
                  animate={
                    projectileVisible && playMoveAnimation.playerId === playerId
                      ? ['ranged']
                      : 'hidden'
                  }
                  variants={projectileVariants}
                >
                  <img
                    src={
                      (playMoveAnimation?.data as BattleMoveEntity)
                        .projectileImage
                    }
                    alt="projectile"
                    style={{
                      height: yTranslate,
                      minWidth: xTranslate,
                      transform: !opponent ? `` : `rotate(180deg)`,
                    }}
                  />
                </motion.div>
              )}
          </>
        )}
      </motion.div>
    </Box>
  );

  return (
    <>
      {sound && <PlaySound sound={sound} />}
      <StatusInformationModal
        isOpen={isOpen}
        onClose={onClose}
        status={status}
        playerId={playerId}
      />
      <Flex
        flexDirection="column"
        padding={opponent ? '0 16px' : '16px'}
        w={`45vw`}
        cursor={'pointer'}
        {...props}
        onClick={() => {
          onOpen();
        }}
      >
        {!opponent ? image : null}
        <Box>
          <Text textAlign={opponent ? 'right' : 'left'}>
            {getPetName(opponent ? opponentPet : pet, player) ?? player}
          </Text>

          <Text textAlign={opponent ? 'right' : 'left'}>
            <TranslatedText translationKey={`textLvl`} defaultMessage={`Lvl`} />
            {` ${level}`}
          </Text>
        </Box>
        <Bar toProgress={`${(Math.max(printHp, 0) * 100) / maxHp}%`} />
        <BreakIndicator
          level={breakLevel}
          reverse={opponent}
          printHand={pet.id === DEFAULT_TELEGRAM_PET}
        />
        <Flex
          flexDir={'column'}
          alignItems={opponent ? 'flex-end' : 'flex-start'}
        >
          <StatusIndicators status={status} />
        </Flex>
        {opponent ? image : null}
      </Flex>
    </>
  );
};
