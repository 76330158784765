import { MOVES_ALL_ENDPOINT } from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { BattleMoveEntity } from 'genopets-utils';
import { useEffect, useState } from 'react';

export const useMoves = () => {
  const [moves, setMoves] = useState<BattleMoveEntity[]>();

  const { apiGet } = useAPI();

  useEffect(() => {
    if (!moves) {
      apiGet(`${MOVES_ALL_ENDPOINT}`)
        .then((resp) => {
          setMoves(resp);
        })
        .catch((error: any) => console.error(error));
    }
  }, [moves, apiGet]);

  return moves;
};
