import { Flex } from '@chakra-ui/react';
import { Button } from '../ui/molecules/Button';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';

export const GoHomeButtons = ({
  children,
  buttonTextKey = 'nextBattle',
}: {
  children?: ReactNode;
  buttonTextKey?: 'nextBattle' | 'battleAnyway';
} = {}) => {
  const navigate = useNavigate();
  return (
    <Flex w="full" gap={2} flexDirection="column">
      <Button
        colorId="Black"
        w="full"
        onClick={() => navigate('/home?auto=true')}
      >
        <TranslatedText
          translationKey={buttonTextKey}
          defaultMessage={
            buttonTextKey == 'nextBattle' ? 'Next Battle' : 'Battle Anyway'
          }
        />
      </Button>

      {children}

      <Button colorId="White" w="full" onClick={() => navigate('/home')}>
        <TranslatedText translationKey={'goHome'} defaultMessage={'Go Home'} />
      </Button>
    </Flex>
  );
};
