import { useAlert } from '@/hooks/useAlert';
import { useFirestore } from '@/lib/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { UserData } from 'genopets-utils';
import { useEffect, useState } from 'react';
import { Unsubscribe } from 'redux';

const GAME_ACCOUNT_COLLECTION = `game-accounts`;

export const useReadUserFromFirestore = ({ userId }: { userId?: string }) => {
  const [user, setUser] = useState<UserData>();

  const db = useFirestore();
  const alert = useAlert();

  useEffect(() => {
    let unsub: Unsubscribe;
    if (db && userId) {
      unsub = onSnapshot(
        doc(db, GAME_ACCOUNT_COLLECTION, userId),
        (document) => {
          const data = document.data() as UserData;

          setUser({ ...data, id: document.id });
        },
        (error) => {
          console.error(error);
          alert({ title: error.message });
        },
      );
    }
    return () => {
      unsub?.();
    };
  }, [db, userId]);

  return user;
};
