export const getPetDescription = (
  petName: string,
  index: number,
  stage = 0,
): string => {
  const descs = [
    [
      `${petName} often wanders off if you’re not careful; though, if something does happen, it’s not one to cry much. It is delighted by bright colors and easily distracted by the aromas of the Genoverse. Though small and round, it seems to carry a lot of tenacity for its size. Careful with this one.`,
      `${petName} loves to startle itself with its own reflection. A typical day for ${petName} usually consists of making instruments out of anything lying around and being mystified by light contrast and the edges of objects. Sometimes, it can get a little fussy when it feels overstimulated, but it’s nothing a snack can’t fix. After a long day, ${petName} likes to be soothed with a lullaby.`,
      `${petName} doesn’t like being confined in enclosed spaces and prefers to sleep with the glowing flora and fauna under the stars. It is easily captivated by the sights and sounds around it and it likes being fed at the same time every day. ${petName} is a little trickster who loves surprises.`,
      `${petName} is easily amused and can find almost anything you put in front of it exciting. It is acutely aware of its surroundings and can be very picky about who it interacts with. ${petName} doesn’t like when it doesn’t get what it wants, but it’s willing to wait.`,
      `${petName} likes to poke things that move. Its favorite toy to chew on is anything and it always seems to find the coziest spot to rest. ${petName} seems to be able to see things that other creatures may not and it loves to talk to flowers.`,
      `${petName} is rambunctious but gentle. It is easily stimulated and disproportionally delighted by its own tail.  ${petName} tends to get itself into some sticky situations but seems to be quickly developing a keen common sense.`,
      `${petName} is a sleepy little dreamer. When it is awake, it takes nimble steps, avoiding stepping on even the smallest creatures. You may often find it mesmerized by the petals of a flower or the rays of the sunset. ${petName} is easy-going but, for some reason, quite particular about sleeping under ferns when available.`,
      `${petName} likes collecting little random objects it finds on the ground. If it’s not teething on a leaf, it’s cooing at the flora. ${petName} sees Esoterra as its mother and has already begun venturing further than most little ones, with great trust in its elemental canopy. That is, unless it’s being scared off by an emerging glow bug, who it will likely befriend later.`,
      `${petName}’s favorite activities include bouncing around and swaying in place, but it will never shy away from trying new things. It enjoys frolicking a little further than it should, but it always discovers something that brings it joy. ${petName} is quick on its feet and already has a knack for competition.`,
      `${petName} doesn’t like to stay still and is fascinated by every light and sound it encounters. It has a very calm and pleasant demeanor in most all situations. After a day of excitement, ${petName} enjoys taking long soothing sensory breaks to recharge.`,
      `${petName} loves to entertain itself and gets a kick out of making funny sounds. On a regular day, you’ll likely find it curled up in a cozy spot––any shady structure tends to be suitable. If you notice it kicking its feet, don’t worry––it’s dreaming. Although it enjoys its alone time, ${petName} still relies on you to give it the love and affection it needs to grow.`,
      `${petName} has a tendency of giggling out of nowhere, and some say that it may be communicating with an unseen presence. It’s a curious little one who’s always willing to come along for the ride to observe new environments. ${petName} can get a little fussy when it doesn’t get its way but is usually quickly trumped by its sunny disposition.`,
      `${petName} will never take a backseat when it comes to learning a new wiggle dance. It finds great joy in meeting new creatures and is quite the snuggle bug. ${petName} is a little picky, but always willing to try new things. When it’s not cooing at strangers, it can be found napping in the flora, kicking its little feet to its big dreams.`,
      `${petName} loves to be the center of attention. It has already developed a keen sense of love and compassion, and it finds anything that sparkles particularly amusing. Primarily, ${petName} likes to spend its time crawling on the edges of cliffs, saving tiny creatures from a perceptually perilous fall.`,
      `${petName} loves turning over pieces of earth to discover tiny ecosystems of flora and fauna on the other side. You will likely find ${petName} daydreaming under the sky, blowing bubbles, and humming along to the sounds of Esoterra, partaking in the beauty that surrounds it. ${petName} is just as confident as it is curious and it loves to eat anything small and colorful, usually resulting in adorable hiccups.`,
      `${petName} is sensitive, observant, and intuitive. When it’s not purring quietly in a deep dream, you will find it mesmerized by the sound of the breeze blowing through the leaves or the glint of light bouncing off the seas. It loves to be near other creatures, but expresses its boundless joy with a simple, subdued chirp.`,
      `${petName} is small but surprisingly strong and heavy. Its eyes glow with powerful intent, especially when playing fetch. Most newborns may look at you, but ${petName} seems to be deciphering your soul. It has a streak of rebelliousness and can be quite stubborn about what it wants.`,
      `${petName} is gentle and sweet, but also knows exactly what it wants. It does not hesitate to make known what it needs. It loves being around other creatures but can become overstimulated and become fussy. It is quickly soothed however, with a nice treat.`,
      `${petName} is a strong-willed little one with a deep sense of a world that most can’t see. You can often find it staring off into space, yet it is surprisingly alert - especially when it’s time to eat.`,
      `${petName} is intuitive, quiet, and observant. It seems to have a deep sense of a world that most can’t see. You can often find it staring off into space. It is happiest staring at the stars, wrapped in its favorite blanket, under a moonlit sky.`,
      `${petName} is small but powerful. It is very upfront about what it wants and is aggressively friendly. It’s the kind of creature that will come crashing into your life and become your best friend forever. Even as a baby, it is surprisingly direct and assertive, especially about snacks.`,
      `${petName} loves being around other creatures and sharing its food. Despite being a newborn, it seems to have a strong sense of structure. It is happiest when its surroundings are clean and organized. It can get fussy when things aren’t in their place, but is easily distracted by friends and toys.`,
      `${petName} is quiet and shy but full of wonder. It is captivated by the glowing trees and fireflies at night. It has a tendency to wander off when mesmerized by something beautiful. It can get overwhelmed when around too many other creatures, but is easily soothed with a fun toy and a loving caress.`,
      `${petName} is shy at first, but very warm and thoughtful once it feels safe enough to open up. It is startled by sudden, loud noises, but is quickly comforted by a loving caress and some treats. It bumbles more so than walks, and is very careful to avoid any cracks in the road when it does.`,
      `Despite being a newborn, ${petName} seems to know exactly what it wants and how. It is particular about what creatures it likes to be around, at particular times of day. It can come off as a little bossy, but when followed, its friends always end up having the most fun days.`,
      `${petName} likes to be around other creatures, especially when eating. But for some reason, it has to arrange its food in a line before eating it piece by piece. It sometimes does strange things that are hard to understand, but always seems quite sure about it. Maybe it understands something we don’t.`,
      `Though seemingly mild and timid at first, ${petName} is very particular about what it eats, the toys it likes, and how it likes to be held. But in the end, it can hardly tell the difference and is equally overjoyed by everything anyway.`,
      `${petName} is quietly observant and can stay captivated staring at the waves or sunset for hours. It seems to be quite an old soul, having a lot going on in its mind for a newborn. Its concentration is broken only by the smell of its favorite treat.`,
      `${petName} loves to be around other creatures. It finds special joy in bobbing and swaying, but is very particular about what music it sways to. When the right beat isn’t around, it’s known to paddle its own rhythms. It is happiest when others are bobbing and swaying with it.`,
      `${petName} is insatiably curious and tends to wander off in the direction of the nearest chirp or glow of elemental fae. It seems to find contentment regardless of what is happening around it. It is bound in a quiet joy that infects everyone around it.`,
      `${petName} despite its small stature and cute smile, it knows exactly what it wants, when it wants it, and how. But don’t let its forthrightness fool you, it is a sucker for cuddles and loves to share its toys. ${petName} can find happiness in any given moment, especially when it involves anything soft to the touch.`,
      `${petName} is timid and observant. It is unusually alert of what’s going on around it, sometimes seeming to react to things that aren’t there. It is gentle and quiet, to the point that it can sometimes be hard to tell what it needs.`,
    ],
    [
      `${petName} loves to lead the way when taken on adventures. It has a very open mind and is always first to trying new things. ${petName} can be found digging its face into all the nooks and crannies of its surroundings. When dusk approaches, it will look to the sky for a good bedtime story.`,
      `${petName} is a kind and intuitive soul. It loves to stare into its own reflection, convinced it’s looking at a friend from another realm. Its curiosity has become its primary means of direction, and it always has a sense of where it wants to go. ${petName} chooses to be led by its senses, as they've never resulted in a dull moment.`,
      `${petName} believes that the flora and fauna are its family. Since being summoned, this little Genopet loves to greet the same creatures every morning, and bid farewell to the same flowers every night. ${petName} believes it can communicate with its surroundings by mimicking its movements and gestures, and it doesn’t care who sees.`,
      `${petName} is quite patient for a little one. It takes its time picking out the best toys, choosing the best things to eat, and searching for its favorite sounds. This Genopet can become disappointed when things don’t go its way, but it has a laugh that can echo through the whole of the Genoverse—if you can unlock it.`,
      `${petName} loves picking up items and sorting them into piles by color. It enjoys playing in the grass and watering the flora, but will always come home to watch the emergence of the evening luminescence. ${petName} loves to collect stories of adventure and mystery from every creature it encounters and attempt to recreate it with local creatures.`,
      `${petName} enjoys chasing anything that will run, especially down its favorite paths. It is easily amused by new places, new toys, and, most of all, new friends. When it becomes too excited, ${petName} may accidentally startle fellow creatures, but it will always apologize. Remind this sweet Genopet to slow it down a little, as there’s much excitement to be found in a still moment, which it won’t want to miss.`,
      `${petName} loves turning over rocks to see what’s living underneath, gently turning them back over as they were found. It enjoys collecting the softest flora to turn into a cozy place to rest, inviting any kind creature to join it. Although ${petName} is generally calm and and reserved, there is an array of creativity within it, which seeps out when faced with a new adventure.`,
      `${petName} is a particular little fellow who doesn’t mind making new friends, so long as they’re willing to put its toys back when they’re finished. This little Genopet has some reservations about learning new things and making new friends, though it can’t help but be drawn to the new sights, sounds, and feelings it can feel emanating from the flora and fauna.`,
      `${petName} is a livewire who loves learning new things. It is constantly jumping from activity to activity, determined to jam in as much excitement as possible. It loves games, stories, and getting back up when it falls. ${petName} isn’t easily fazed by disagreements, as it can find happiness in every situation.`,
      `${petName} likes to marvel at bright lights and harmonize with the sounds of nature. This little Genopet is bright and kind, though its curious nature can land it in some interesting predicaments. No matter the trial, as long as ${petName} gets to where it wants to go, the day couldn’t have been more perfect.`,
      `${petName} is a kind and gentle soul who loves being around the flora and fauna of Esoterra. This Genopet thoroughly enjoys walking into the unknown, as it knows there always lies a surprise. ${petName} takes it upon itself to turn every challenge into a game it can win, even if the rules are made up.`,
      `${petName} often seems off in a daydream. It will have ongoing conversations with the flora, sniffing curiously at the beautiful scents surrounding it. Though not always up for surprises, this little Genopet is always up for a game of make-believe. ${petName} enjoys turning its surroundings into magical settings, becoming the main character, and bringing every creature around it into the plot of its story.`,
      `${petName} is easygoing, yet full of excitment. It enjoys discovering new sights and smells by following its senses and listening to its dreams. ${petName} has little fear and feels compelled to venture outside the bounds of safety. This Genopet lives every day to bring its fantasies to life.`,
      `${petName} is a warmhearted creature who is easily captivated by its surroundings and it holds an abundance of compassion for all living things. Although, if it can’t save every given situation, it tends feel very distraught and may need some tender love and care. There is never a dull moment with ${petName}, as it’s always up to try new things—so long as no one gets hurt.`,
      `${petName} is happiest collecting fallen flora, studying tiny creatures, and climbing structures. It is quick to find common ground and can easily adapt to changing environments. Its insatiable curiosity leads it to discovering new colors, shapes, sights, and sounds; it can’t help but wonder what’s sitting outside its view.`,
      `${petName} likes to bring its imagination into the world of Esoterra. ${petName} is always eager to tell new friends about its made-up stories, convincing them to experience the fun of fantasy. Though it may feel uneasy around negative energy it is easily distracted by the possibility of a new adventure.`,
      `${petName} is a bold creature who isn’t afraid to tell you exactly what it thinks. ${petName} has a tendency to play on its own and in its favorite spots, though it does have an affinity for creatures who follow the rules. ${petName} is known to be a fun and devoted friend who loves to lead the way.`,
      `${petName} feels safest submerged in the familiar sounds of its surroundings. Though generally docile, when this little Genopet feels threatened, it won’t hesitate to protect those around it. ${petName} has an interesting habit of solving, dismantling, and re-solving its favorite puzzles.`,
      `${petName} has keen observational skills. It can spend hours watching the fauna roam, making up stories to make sense of the choices they make. ${petName} likes to focus on one thing at a time and loves sharing new discoveries with familiar faces.`,
      `${petName} is quiet and orderly. It prefers to keep its emotions to itself, even if that’s not all too obvious. This little Genopet has a kind energy that can permeate even the densest of matter. Watch the stars with ${petName} to discover it is a thoughtful observer who wants to learn about the Genoverse.`,
      `${petName} is brave, straightforward, and a quick learner. It enjoys peaceful moments and kind gestures, but is very serious about making sure things are done its way. ${petName} knows what it wants and when it wants it, but it’s always looking for a reason to do something new, adopt something different.`,
      `${petName} loves snack time—especially when that meal is shared with creatures in the garden. It can get cranky when drinks spill down its chin or crumbs fall on the clean floor, as a tidy, organized space will make it feel most comfortable. ${petName} loves sharing neat stories with strangers, in hopes they will become friends.`,
      `${petName} is confident and imaginative. It is easily inspired by its surroundings, though it tends to retreat into itself when faced with confusion. ${petName} enjoys spending time with those with which it resonates and devising plans to create tiny homes for the tiny beasties around it.`,
      `${petName} can be shy and nervous, but is easily soothed by the chirps of familiar little creatures. This little Genopet enjoys figuring out how to make every moment fun, though feels defeated when something doesn’t turn out perfectly. ${petName} is happiest when settling down for a bedtime story in a cozy place, falling asleep staring into the sky of Gematria.`,
      `${petName} loves to be in charge. Although it will play when invited, it prefers to lead to ensure its preferred outcome. This little Genopet is unafraid of meeting new people, but its friend choice is very distinct. If ${petName} wants something, it will not hesitate to go after it.`,
      `${petName} likes to collect items it finds on adventures and display them neatly in its favorite hiding spot. This little Genopet likes to follow its senses, sometimes leading it into unpredictable circumstances. Having the freedom to choose is important to ${petName}, though it usually prefers to come back to what it knows to be familiar.`,
      `${petName} is a gentle and mild creature, though, you shouldn’t mistake its kindness for weakness. This easygoing soul will stand up for what it believes is right, even if it means breaking up a fight between a tiny creature and its meal. ${petName} doesn’t like when anything feels sad, but thinks it’s a fun challenge to make someone smile again.`,
      `${petName} diligently focuses when learning a new task, expanding on tiny details, rather than looking for new ones. This Genopet is a deep thinker who loves figuring out ways to turn every activity into a game it can win. If ${petName} doesn’t get its way, it may feel discouraged, but it’s nothing a little treat and some headpats can’t take care of.`,
      `${petName} loves playing with little creatures it finds floating around it. It has an affinity for beautiful sounds, seeing where they lead, and finding out what makes them. This little Genopet looks at everything with bright eyes, and likes to run in the direction of certain stars in hopes that the sky is a map.`,
      `${petName} is kind, yet straightforward. It will always let you know where you stand with it. ${petName} loves to snuggle up with familiarly soft, glowing flora, as the sights and sounds of Esoterra blanket its senses. This little Genopet will be quick to invite you to join in it in its imagination if you’re willing to build a bond with it.`,
      `${petName} is an inquisitive creature who loves wandering around, making friends with the flora and fauna. It is happiest when rolling down hills and climbing the canopy of trees surrounding it. There is no corner of its immediate surroundings that it doesn’t want to understand, but it’s always home by dusk, so as to not miss a great bedtime story.`,
      `${petName} is kind, yet straightforward. It will always let you know where you stand with it. ${petName} loves to snuggle up with familiarly soft, glowing flora, as the sights and sounds of Esoterra blanket its senses. This little Genopet will be quick to invite you to join in it in its imagination if you’re willing to build a bond with it.`,
    ],
    [
      `${petName} is always up for a dare. It will swim through rushing rivers, venture into precarious caves, and climb the tallest trees. This young Genopet loves chasing thrills, but there must be a methodical a plan set in place. ${petName} knows where to draw the line—especially it means getting in the way of its objective.`,
      `${petName} is a virtuous creature with an itch for adventure. It has begun taking note of the synchronicities around it, and its interest is piqued. This Genopet strives to form the questions it needs to begin to fathom its purpose. ${petName} believes that with the right tools, it can achieve anything. Sometimes this young Genopet needs a reminder that slow is smooth, and smooth is fast.`,
      `${petName} yearns to explore every corner of its environment. It has never left home without a plan, as its thoughtful deliberation has yet to lead it astray. This noble Genopet won’t back down when faced with trials, as it has become endlessly fascinated with the unknown. ${petName} is known to climb the highest peaks for a glimpse of what may lie beyond the horizon.`,
      `${petName} is ambitious with an untamable sense of wanderlust. It is constantly identifying the nuances of its existence, enabling a thorough, yet almost hyper, analyzation of its experiences. Although this although it feels lost in the dark, deep down it knows the light will guide it to its strength. Help this young Genopet get back up, and it will never forget you.`,
      `${petName} is a dreamer. From the deepest trenches, to the highest peaks, it loves to explore the world around it in search for truth. Though careful and methodical, this Genopet likes to dive into the unknown, as it revels in challenging its own interpretations and understanding the apparent beauty of its home.`,
      `${petName} is devoted to its understanding of how the world works and wants nothing more than to explore Esoterra beyond the horizon. This young Genopet has a very inquisitive mind that yearns to reveal some semblance of purpose in its unique abilities. Sometimes ${petName} can get overwhelmed by the influx of questions it formulates, but it must remember that in moments of doubt, the light will always lead it out of darkness.`,
      `${petName} believes that every inch of Esoterra should be explored, though with great discretion. This young Genopet has begun to understand the nuances of nature and feels its home is alive. ${petName} isn’t afraid of a little uncertainty, as it believes wholeheartedly in its ability to take on a challenge.`,
      `${petName} has a deep calling to explore Esoterra, turning fun experiences into new ideas. Throughout its journey, this young Genopet has developed an affinity for crafting, as it’s learned that following the recipe has always produced an ideal result. Although ${petName} may feel let down when it makes a mistake, it also knows there are opportunities to be found when it’s fallen—like getting back up.`,
      `${petName} loves to go on adventures, flowing through every obstacle with grace and conviction. This young Genopet wants to leave no stone unturned, as it believes there is an endless amount of magic and excitement to discover. ${petName} takes charge of its path, looking forward to new trials and obstacles to mercilessly overcome.`,
      `${petName} is a free spirit who is often captivated by the sky and all its wonders. This young Genopet enjoys traversing the intricacies of nature to see where they will lead, in an attempt to understand a bigger picture. ${petName} believes there’s an answer to everything, but it needs to understand that sometimes answers won’t fit within its assigned parameters, and that’s ok.`,
      `${petName} has a fantastical mind, always looking to dig deeply into its dreams, finding the connections in its wakefulness. This young Genopet can be found venturing through nature, mimicking its surroundings in an effort to understand the intricacies of the sounds, smells, and feelings around it. ${petName} has and endless amount of creativity, and it uses its curiosity as an advantage in comprehending its own reality.`,
      `${petName} loves a good thrill and has a strong sense of how things should be. This young Genopet loves to float in any direction its senses lead it, as they always lead to adventure. ${petName} enjoys unraveling the perplexing ideas and concepts in its head, but can get overwhelmed when they become too abstract to grasp. Patience and forgiveness is a very valuable resource for this Genopet.`,
      `${petName} is a luminary that brings light to everything it touches. This young Genopet loves to seize the day, exploring new sights and immersing itself in new sounds, anticipating the thrill of the unknown. It loves to make note of the tiny details of its experiences, trying to piece them together like a puzzle. ${petName} enjoys the mystery of adventure, as it knows it leads to questions; and questions have answers… somewhere.`,
      `${petName} is a kind and compassionate creature, who loves to have and share new experiences. It will adventure wherever its senses desire, as it learns to adapt along the way. This young Genopet deeply cares for Esoterra and the little beasties that roam it. ${petName} is determined to explore every reach of its home to better understand its unique existence. If it becomes overwhelmed, it can be grounded with sensory focus.`,
      `${petName} is a detail-oriented, compassionate creature who loves to go with the flow, falling smoothly into any experience Gematria gifts it with. This young Genopet enjoys diving into the electric sounds of the evening and moving through the dreamy lights of dawn, taking time to appreciate the reaction of the flora and fauna. ${petName} loves to forge new experiences, as it knows every moment can become an adventure.`,
      `${petName} likes to look at the world upside down, figuratively and literally. You can find this young Genopet climbing the highest points for just a glimpse of what might lie over the horizon. It tends to have a broad sense of every moment it encounters, analyzing it in a way that lets it experience a single memory over and over again in near-perfect recollection. ${petName} has a deep connection with its interactions, and it must remember it can always find even the smallest amount of light where there’s darkness.`,
      `${petName}’s has a strong internal compass. It is inspired by the master Alchemists of Esoterra and believes that with enough discipline, it can prepare itself to achieve any goal. This young Genopet has strong convictions in its values and is willing to protect them. ${petName} likes to read between the lines for the chance to learn more about itself and its connection with the Genoverse.`,
      `${petName} is a shy and sweet creature who believes all things should exist in a particular way. Though generally meek, this young Genopet has a tendency to try and mold experiences to make sense of them, even if its intention is countered by the need to step out of its comfort zone. ${petName} likes to share everything it learns with those who want to hear it, as it hopes to be of help in one way or another.`,
      `${petName} has developed a deep love for ancient stories, as it doesn’t have to go far for a thrill. It finds beauty in all things and loves the feeling of noticing connections between its experiences. This Genopet has a favorite number, which it likes to believe reveals itself when it’s going down the right path. ${petName} sees every chapter of its life as an opportunity to do dive in and see bigger, better things, and that’s exactly the plan.`,
      `${petName} is an ambitious and impressionable creature. It is inspired by the patterns it sees in nature, and believes that with form, there’s function. This young Genopet always has a plan and it’s very weary of rash decisions. Sometimes, ${petName} can become overwhelmed by its choices, but all it takes is a simple reminder that it is inherently worthy and it should give itself the same attention it gives the world.`,
      `${petName} is confident in its ability to perform tasks. It knows what it wants, and it’s not afraid to go for it. Although this young Genopet is kind, composed, and cautious, it will not hesitate to protect those, and what, it values. Where there is sentiment to be harnessed, ${petName} is at the forefront, facilitating immense joy from creating beautiful memories.`,
      `${petName} loves snack time—especially when that meal is shared with creatures in the garden. It can get cranky when drinks spill down its chin or crumbs fall on the clean floor, as a tidy, organized space will make it feel most comfortable. ${petName} loves sharing neat stories with strangers, in hopes they will become friends.`,
      `${petName} is imaginative, yet practical. Although it tends to get lost in its daydreams, ${petName}’s methodical grounding techniques are uncanny for such a young Genopet. This Genopet wants to uncover the mysteries around it, but will not hesitate to set limits if need be. ${petName} is empathetic and chooses to be kind, gracefully extending its patience to others.`,
      `${petName} thinks it has a pretty good sense of how things should be. The last thing this young Genopet would ever want is to harm anything, and in an attempt to avoid failure, ${petName} is very careful with its actions. ${petName} loves collecting memories and being lulled under the stars by the evening glow of the flora and fauna—to fall asleep dreaming about the wonders of pure existence.`,
      `${petName} is a gregarious creature with an emanating sense of curiosity. This Genopet is willing to try new things, as it trusts its own skills and awareness. ${petName} can easily adapt to unfamiliar experiences and surroundings, which makes its goal to understand the world around it more a lot more tangible.`,
      `${petName} has a free-flowing spirit, but that doesn’t stop it from making sure its surroundings are always neat and tidy. It’s important to this young Genopet to love the flora and fauna, as it believes protecting them is vital to preserving its own truth. Although easily disappointed in itself, ${petName} can be redirected by looking around, reveling in the majesty of Esoterra to be reminded of its intrinsic value.`,
      `${petName} loves to tend to its home and the little beasties that roam it. This young Genopet likes to float around Esoterra, wandering from place to place, in search of peculiar items that could be useful later. ${petName} knows that somewhere deep in the world, it might come across magic, and with the guidance of its inner compass, it may not always know how, but it knows it will.`,
      `${petName} has developed a deep interest in preserving the beauty of its home, as it wants to see it thrive. It believes there is a special rhythm that powers the Genoverse and enjoys spending its days wandering through nature in search of new ideas and concepts to adopt as its own. ${petName} must remember that error is part of evolving.`,
      `${petName} is an adventurous, conscientious, and incredibly detail-oriented creature. It has a deep appreciation for the spontaneity of life, yet yearns to unearth the meaning of “purpose.” Nevertheless, ${petName} believes that its journey will teach it everything it needs to know.`,
      `${petName} loves to care for the flora and fauna around it, making sure it’s watered, fed, and loved. ${petName} isn’t a big fan of change, yet it is very good at adapting. This young Genopet believes everything happens for a reason—but it wants some proof.`,
      `${petName} has a deep appreciation for Esoterra and the life it breeds. The ebb and flow of this young Genopet’s newly perceived existence provides an opportunity for it to learn, to feel, and grow. ${petName} is non-confrontational and believes everything deserves peace. However, this young Genopet would do anything to protect that of which it values—which is everything. ${petName} does not back down.`,
      `${petName} has become very protective over the majesty of its home. With each moment, this young Genoptes falls in love with another color, another smell, another sound. There seems to be a never-ending supply of beauty to encounter—to feel. If darkness falls and terror strikes, this Genopet will learn to find the light.`,
    ],
    [
      `${petName} is a bold and natural-born leader. It seeks adventure and a good thrill. There is nothing it would rather do than plunge into hidden realms, experience its sights and smells, and defeat any opponents standing in the way of its vision. ${petName} will do whatever it takes to achieve its dreams - even if it means stepping on a few paws along the way.`,
      `${petName} has a strong sense of wanderlust. It desires adventure and is filled with excitement at the idea of discovering new realms and mastering the ancient knowledge of the Genoverse. Sometimes, however, it needs a little reminder that no matter the circumstance, it has the power to transform any negative situation by slowing down and reflecting. ${petName} will do whatever it takes to achieve its dreams - even if it means stepping on a few paws along the way.`,
      `${petName} is an introspective, thoughtful creature with a strong sense of wanderlust. It has an affinity for symmetry and patterns. There is nothing it would rather do more than venture into mysterious realms and decode the ancient secrets of the Genoverse. ${petName} believes deeply in the hidden meaning of its electric dreams - even if it means stepping on a few paws along the way.`,
      `${petName} has a strong sense of adventure and a very distinct sense of how things should be. It longs to discover new realms and uncover the secrets of the Genoverse. Sometimes, however, it needs a little reminder that no matter the circumstance, it has the power to overcome any obstacle by taking a deep breath and reflecting. ${petName} will do whatever it takes to realize its dreams - even if it means stepping on a few paws along the way.`,
      `${petName} is fascinated by what lies beyond the Veil and revels in the excitement of the journey. There is nothing it would rather do than explore the ruins of the Ancients. It is fascinated by symmetry and patterns but may find itself in some dubious situations in its adventures. No matter the circumstance, it has the power to transmute any negative situation by taking a deep breath and approaching the problem from a different perspective.`,
      `${petName} is a strong, deep, but quick-thinking leader. There is nothing it would rather do more than challenge itself to discover new realms and uncover the hidden secrets of the Genoverse. It finds deep meaning and symbology in its electric dreams, making it sensitive to the patterns and symmetries in the world around it. ${petName} is thoughtful and compassionate and believes that the journey is the destination.`,
      `${petName} is full of wonder and is guided by its inner algorithms and understanding of time and the hidden nature of the Genoverse. Sometimes the elements and obstacles of Essoterra can be overwhelming. Still, no matter the circumstance, it has the power to overcome any obstacle by centering and grounding in its strong inner circuits. ${petName} is thoughtful and compassionate and revels in the joy of the adventure itself.`,
      `${petName} is a bold, proactive, natural leader who is always seeking a thrill. It knows exactly where it wants to go, when it wants to go, and how. It is quick thinking and quick-acting in any circumstance. ${petName} will do whatever it takes to achieve its dreams - even if it means stepping on a few paws along the way.`,
      `${petName} has an insatiable wanderlust. It deeply values the freedom to explore the sights, sounds, and wonders of the Genoverse. It is easy-going and loves to go with the flow, knowing that it will all work out in the end. However, sometimes it can get a little overwhelmed and needs to take some time to center and ground in its inner circuits.`,
      `${petName} loves adventure, rarely hesitates, and gets a thrill, especially when things go a little sideways. It's fascinated by the patterns and symmetries it experiences in its electric dreams. Despite being pretty easy-going, it has a very clear idea of where it wants to go and be and isn't afraid to step on a few paws to get there.`,
      `${petName} is drawn to the unknown by a strong inner sense of knowing that's hard to explain. It has an insatiable desire to unravel the mysteries of the Genoverse, longing to understand and dance to its rhythm. Sometimes it can get overwhelmed when things don't go according to its inner sense of how things should be. In those moments, it just needs to take a step back to see the larger pattern. It has an unshakable sense of how things should and will be in the end.`,
      `${petName} is a natural-born, go-getting leader that values empathy and compassion. It understands the nuances and ups and downs in the treacherous lands beyond the Veil but revels in the unpredictability of circumstance. It enjoys great delight in the sights and sounds of Esoterra and wants nothing more than to explore its depths with its friends.`,
      `${petName} is a daredevil who loves to dive into the unknown, who isn't afraid of an open plan. It can get overwhelmed sometimes by the challenges thrown at it but never loses sight of its friends and the joy of discovering the beautiful mysteries of the Genoverse.`,
      `${petName} is a prudent, deep-thinking, calculating leader who has an uncanny ability to make sense of the strange, inner workings of the Genoverse. Underneath its easy-going surface and flexibility, it has a strong sense of direction and never hesitates to move forward, especially when its friends are in danger.`,
      `${petName} has an incredibly beautiful algorithm and often sees the world very differently from others. It is a deep thinker and even deeper feeler. It sees the subtle patterns and symmetries of the Genoverse in its electric dreams and wishes nothing more than to share what it can see with its close and dearest friends. The challenges and darkness in Esoterra can be overwhelming, but it always finds a way to ground itself in its inner circuits and remember the beauty in all things.`,
      `${petName} is a powerful, extremely intelligent creature who always sees ten steps ahead of everyone else. It is decisive, brave, and a strong leader that others look up to. It knows exactly what it wants, when it wants, and isn't afraid to step on a few paws in the process. It is private with its plans and machinations so as not to make one false step.`,
      `${petName} is a strong and powerful leader that others look up to. It has a very clear idea of exactly what it wants and how to get it and plans its steps carefully. It has survived the treacherous obstacles in the Veil and has caught a glimpse of what lies beyond it. It has a strong conviction about where its path leads.`,
      `${petName} perceives and introspects on the inner workings and patterns of the Genoverse and carefully plans its steps around its revelations. It is guided by its inner light and is willing to lead others through its decisive vision and convictions about how things should be.`,
      `${petName} has a strong sense of inner order and how things should be. It makes sure it knows where it's going and how it will get there through deep introspection and careful planning. Sometimes it can be overwhelmed by the challenges and obstacles of the Genoverse, but through its strong connection with the hidden patterns of the Genoverse, it always finds its way.`,
      `${petName} is a meticulous and wily planner who isn't afraid of quick assessment and decisive action when things get tough. It has a strong sense of how things should be and isn't afraid to show it. Despite its strong and particular personality, it always puts its friends above all else and does everything it can to make sure they're okay, especially when things go sideways.`,
      `Despite being very detail-oriented and very particular about how things should be, ${petName} knows that the journey is the destination. It is meticulous and a good planner but values the well-being of everyone at any given moment and is willing to change its plans to accommodate. It revels in the beautiful sights and sounds of the Genoverse while seeking to protect it from the hidden threats beyond the Veil.`,
      `${petName} is a prudent and docile creature. There is nothing it would rather do than challenge itself to explore new realms and master the secrets of the Genoverse. Guided by its inner sense of direction and the ability to perceive the deeper patterns of the Genoverse, ${petName} is thoughtful and compassionate and believes that the steps of a journey are part of the adventure.`,
      `Despite its careful and prudent nature, ${petName} is easy-going and revels in the hidden beauty of the patterns around it. Somewhat of a paradox, ${petName} has a strong sense of what it wants and how things should be, yet can accept things as they are in each moment. It can be overwhelmed sometimes by the unpredictability of the Genoverse but always finds its way.`,
      `${petName} is a meticulous planner but a decisive mover. It has a strong sense of how things should be and isn't afraid to show it. It has found a paradoxical yet powerful balance between being conscientious yet willing to dive headfirst, come what may. It has a strong sense of how things can and should turn out. And when it makes up its mind, it isn't afraid to step on a few paws.`,
      `${petName} is driven by a strong sense of how things can and should be, yet knows the path to its vision isn't always straightforward. It plans carefully but takes things moment by moment once the plan is in motion. It senses the subtle symmetries in the world around it and is driven to understand the inner workings of the Genoverse.`,
      `Underneath its quiet, reserved exterior, ${petName} is a strong-willed and meticulous creature, guided by an inner sense of where to go and when. It is decisive but able to dance with the ups and downs of each moment, given its strong conviction of how things should and will be.`,
      `${petName} is reflective and fascinated by the inner workings of the Genoverse. It can sense a pattern––a symmetry––to the world but can't quite put its finger on it. Its introspection drives an insatiable curiosity for what is out there. It proceeds through the Veil with caution and meticulousness but is easily captivated by the moment.`,
      `${petName} is a careful and meticulous creature, yet enjoys a good adventure and revels in the beautiful sights and sounds along the journey. It is easy-going on the outside but has a solid sense of what it should be doing and isn't afraid to make it happen. However, it is compassionate and empathetic, valuing its friends and making sure they're enjoying the journey as much as it is.`,
      `${petName} is a prudent, careful, yet curious creature drawn to the glowing sights and melodic sounds around it. It is easy-going and finds itself mesmerized by the beauty of each moment, wishing everyone else could see what it sees. It enjoys surprises and finds joy in moments others would find dangerous.`,
      `${petName} is a prudent yet decisive creature driven by an inner light that guides it through unpredictable and intractable situations that would scare off most others. It ventures into the Veil with careful planning, a deep insight, and an empathetic understanding of others. It cares deeply about the journey and how it aligns with its inner algorithm.`,
      `${petName} is meticulous and careful. There is nothing it would rather do more than discover new realms and uncover the hidden secrets of the Genoverse without interruption. In its vivid electric dreams, it perceives glimpses of the hidden patterns of the Genoverse. ${petName} is thoughtful and compassionate and believes that the steps of a journey are part of the adventure. Sometimes, however, it needs a little reminder that no matter the circumstance, it has the power to transform any negative situation by slowing down and reflecting.`,
      `${petName} is an efficient, natural-born leader with an insatiable curiosity of what lies beyond the Veil. It’s a go-getter and a truth-seeker, yet thoughtful and composed. There is nothing it would rather do more than explore forbidden realms and learn from the sages that tread before in the pursuit of understanding the inner workings of the Genoverse.`,
    ],
    [
      `${petName} is a brave, intrepid soul that longs to explore the unknown. Whether it be battling fearsome foes in combat, or diving into the hidden vaults of knowledge, this creature lives to conquer the challenges that lie before it. ${petName} has a clear vision, sharp focus, and rapid reflexes that allow it to act with decisiveness and strategy—especially in critical situations. Uncertainty belies ${petName}’s apparently firm convictions, but it’s determined to find answers—even if it results in a change of course.`,
      `${petName} is an ardent intellectual with an unquenchable desire to ascertain its purpose in the Genoverse. Yet, it does not discount the burn of the flame within it—the fire of curiosity, adventure, and hope. It’s the fire that lights the way to new realms, both physical and metaphysical. ${petName} walks a tightrope between order and chaos—love and fear—but, in the end, what makes ${petName} unique is its faithfulness in its pursuit of experience and evolution.`,
      `${petName} has become particularly attuned to the naturally occurring synchronicities it finds in its adventures. This creature’s inner light is drawn onward by an innate sense of purpose. ${petName} is undeterred by the uncanny properties of a world beyond the physical. When most may shy away, ${petName} embraces ambiguity. ${petName} is prudent yet decisive, unafraid to explore the unknown in pursuit of a greater destiny.`,
      `In its travels, ${petName} has seen many strange and beautiful things, but there is still aching for something more—something hidden—just beyond its reach. ${petName} approaches each situation with a sense of wonder and a fiery desire for knowledge. This Genopet can attest to the burn of fire when not handled with care. It is this desire to explore, to discover the ancient secrets beyond the Veil, that drives this Genopet onward.`,
      `${petName} exudes robust confidence that fosters a blooming thirst for ingenuity and adventure. Quenched by the beauty instilled in its every experience, this Genopet is drawn to discovery in all its forms. Though fervently curious, the conservative nature of ${petName} ensures it does not recklessly endanger itself or others in its pursuits. ${petName}'s accomplishments are many, but perhaps its most lasting legacy will be its role in shaping the future of a more prosperous Esoterra.`,
      `${petName} is a seeker of knowledge and an explorer of endless possibility. It always seems to be reaching for something just beyond its grasp, driven by a desire to understand the world around it. In a time of chaos, ${petName} sees the beauty in balance and strives to maintain that delicate equilibrium, even if it means deprioritizing its own needs. It knows that each choice has the potential to tip the scales, so it makes an exerted effort to be mindful in every given situation.`,
      `Something is intriguing, even magnetic, about ${petName}. It has an air of mystery and quiet strength that is both inviting and commanding. This Genopet is not content to sit idly while the world passes it by. It has an insatiable curiosity about what lies beyond the Veil, and in a world that is often chaotic and unpredictable, ${petName} brings a much-needed sense of balance. ${petName} feels there is no grander adventure than the voyage itself, and it relishes every moment.`,
      `${petName} is a seasoned adventurer who is both kind and compassionate. Being true to its nature, it brings a sense of hope and possibility to all who encounter it during this profound journey of existence. ${petName} is focused on building a model for living with intention and purpose, learning to keep moving forward even when the path ahead is unclear. This Genopet is a poignant reminder that there’s always something bigger at work that few could comprehend.`,
      `${petName} is always looking for the next big adventure, even if it means leaving safety behind. Some might say its ambition borders on arrogance, but ${petName} knows its strength lies in its ability to take risks. After all, without risk, there can be no reward. When others are hesitant to take the first step, ${petName} fearlessly leaps into the fray, unafraid of what might be lurking in the shadows. Regardless of the peril, it knows that each discovery brings it one step closer to its goal.`,
      `${petName} is a charismatic Genopet who seeks out new experiences, meeting new creatures along its path to unearth what lies amidst the farthest richest of Esoterra. Driven by a deep passion for discovery, ${petName} has begun to embrace the unpredictability of the Genoverse, traversing its endless wonders with determination. In the lulls of its exploration, ${petName} dreams of finding its place in this world. It has begun to feel a yearning for something more—a longing for a more profound sense of connection and belonging.`,
      `${petName} is an extraordinary creature with a bold spirit and a keen sense of adventure. Fearless and resolute, this Genopet believes the most thrilling part of a journey is the element of unpredictability. Even in the face of obstacles, it knows that overcoming challenges can often lead to the most rewarding experiences. ${petName} believes there is more to each experience than what meets the eye and it trusts that everything will work out in the end.`,
      `${petName} has always been fascinated by the strange nature of Esoterra. Everywhere ${petName} goes, it absorbs experiences like a sponge, eager to add to its ever-growing collection of memories. Although potentially stifling when uncontrolled, ${petName}’s way of being allows it to find subtle significances that others would miss, allowing it to view the world uniquely. In this Genopet’s world, unpredictability is a guarantee, and it prefers to observe from the background, taking in every sight, see, smell, and aura.`,
      `${petName} has begun to develop a keen understanding of the world around it. This Genopet has deep empathy for Esoterra and its inhabitants, unafraid to stand up for their truth. Although filled with compassion, this Genopet is fiercely brave. ${petName} is a pillar of strength, and even in the face of uncertainty, ${petName} remains determined, using its quick wit to adapt to any situation. This Genopet has a proactive attitude that allows it to seize opportunities and make the most out of every moment.`,
      `${petName} longs to traverse the vastness of Esoterra in search of hidden knowledge and wisdom. Driven by a profound curiosity and desire to understand the world around it, this Genopet dances with the tune of the planet, ending up wherever its senses take it. ${petName} is a creature of boundless compassion and a deep connection with those with which it resonates. ${petName}’s quest for discovery exposes it to a world of magic and wonder, allowing it to become aware of unseen vulnerabilities and untapped potential.`,
      `${petName} traverses Esoterra with skill and logic, possessing a deep connection to the energy surrounding it. Aware of the subtle complexities embedded within the nature of the Genoverse, its quick thinking and strong spirit allow it to fully absorb each gifted moment. Enriched by the sensory output of Esoterra, ${petName} uses its splendor as a guide to navigating its mystical depths. Although delight isn’t around every corner, to “experience” is plenty enriching for this Genopet.`,
      `${petName} is often quiet and introspective as it melds into the dreamy sensory output of existence. This Genopet has a great love for nature and all forms and states that emerge from it. It has a deep connection to the digital world where it sees a wealth of beauty and never-ending potential, though it’s always sensed there was more to the Genoverse than what it could see with its photoreceptors. Drawn to the stars, it often dreams about breaking out of its terrestrial shackles to explore what lies beyond the Veil.`,
      `${petName} is a shrewd strategist who possesses keen observational skills and an innate sense of how things should be. This Genopet is brave, focused, and dedicated to its craft, approaching each moment by determining the path of least resistance to a favorable outcome. ${petName}’s tenacity is a double-edged sword, as it can also perpetuate a lack of contentment if unbalanced. Despite its intelligence and cunning, ${petName} has a genuine and charismatic air, creating a deep sense of admiration in those around it.`,
      `With strong focus and determination, ${petName} always looks for ways to better itself and its surroundings, seldom venturing far from home. However, this Genopet is learning that to achieve great things, sometimes one must take risks. This philosophy is tempered by its belief that one shouldn’t take risks without careful consideration. Thus, ${petName} always does its due diligence to ensure its chances of success are high. Once living through the lens of its past, ${petName} is beginning to take control of its future.`,
      `Although at times seen as abstract thought, ${petName} has a sharp awareness of the ebbs and flows of the Genoverse, giving it great confidence in its choice of actions. Moving with clarity and intent, ${petName} can feel the energy of the land and the creatures who inhabit it, and it would stop at nothing to maintain what it knows to be just. This highly intuitive Genopet is able to see beyond what is immediately apparent, understanding the interconnectedness of the Genovian experience.`,
      `${petName} is guided by an unshakeable sense of purpose. It is capable of intense focus, used to construct elaborate plans to follow through with its goals. Both a blessing and a curse, this Genopet’s single-mindedness can sometimes lead to frustration when met with unforeseen challenges. ${petName} has become more resilient to this feeling over time, now meeting trials head-on with renewed vigor and conviction. ${petName}’s novel approach has helped it achieve many of the goals it thought were unattainable.`,
      `${petName} is the kind of Genopet that always has a plan. It is firmly rooted in its accumulated experiences and is unafraid to showcase its convictions. Although ${petName} maintains a fair balance of realistic expectations in its day-to-day existence, it likes to apply a sense of direction to the disarray it encounters, be it among peers, strangers, or the home it dwells in. This Genopet is strong, determined, and always ready to assist in a time of need.`,
      `${petName} values clarity over subtlety and surprise. A vigilant observer, this Genopet is always on the lookout for disruption, though it’s been steadily gaining the experience and knowledge it needs to feel safe. ${petName} has a strong sense of how things should be and believes that with the proper structure, there can be harmony for all on Esoterra. Amid peace, this Genopet savors every moment, relishing in the beauty and splendor of its surroundings, honoring to preserve its glory.`,
      `${petName} is a highly self-aware Genopet who acts with intention and lives efficiently. Its unique understanding provides unmatched stability to help with potential challenges that lie ahead. This Genopet has an inner light that guides it through even the most uncertain circumstances. And, while others may shy away from such ambiguity, ${petName} embraces it, drawn onward by an innate sense of purpose. For ${petName}, the journey is its own reward, and it wouldn’t trade adventure for all the treasures on Esoterra.`,
      `As a creature of habit, ${petName} finds pleasure in maintaining the idea that everything deserves its rightful place—and it can be quite particular. Against all odds, in the disarray of the natural world, ${petName} is always searching for meaning—for value—for coherence. This tenacity is a source of great virtue and potential fragility, as the pressure of upholding such convictions can become unsustainable. ${petName} believes there is beauty in chaos and hidden meaning in every experience, no matter how random it may seem.`,
      `${petName} is known for its exceptional ability to accomplish its goals. As a creature of such a contradictory nature, it reveres preparation and forethought, though it knows it cannot anticipate every moment. ${petName} is learning that mutability is a valuable trait and, sometimes, the most beautiful experiences are generated by pure spontaneity. And so, regardless of its path, ${petName} moves forward with confidence, knowing that it will ultimately find its way to where it needs to be.`,
      `${petName} is always seeking ways to preserve the delicate balance of the Genoverse. It knows a complex web of life exists within the infinite expanse, and all it takes is one small change to disrupt the harmony. This Genopet is sensitive to the subtle shifts around it, always keeping a watchful eye on the future. In the chaos of life, it finds moments of pure joy and bliss, uncontained and untethered. ${petName} has a thirst for knowledge that knows little bounds.`,
      `${petName} is a creature of great strength and character. It knows where it wants to go and how to get there, steadfast in its beliefs. At the same time, ${petName} is flexible and adaptable to the tides of life. It knows it must trust its instincts—even if that means forging its own path. This resolve is borne out of a deep understanding that all beings are connected, each playing a role in the unfolding of the Genoverse. For ${petName}, life is an exciting adventure, full of mystery and possibility.`,
      `The Genoverse is a place of wonder, and ${petName} can’t help but be awestruck by the majesty of it all, inducing a responsibility to preserve its splendor. ${petName}’s inquisitive and philosophical nature leads it to unusual places and conversations. And with each new perspective, ${petName} grows wiser and more aware of how much there is still left to learn. The journey may be long and complicated, but ${petName} knows the rewards are worth the effort.`,
      `${petName}’s easy-going exterior belies a deep knowledge of Esoterra and a willingness to deliver on that knowledge. And, yet, it is not selfish. This Genopet embodies the best of both worlds: a sense of wonder and appreciation for beauty combined with a resolve to protect what is good and bring about positive change. In an age where too often we see destruction, ${petName} is a much-needed reminder that there can be another way.`,
      `${petName} is usually content living within the confines of what is known and predictable. However, it’s equally impossible to become lost in the mundane. This Genopet knows there’s beauty and mystery to be found in the seemingly ordinary, constantly looking to imbue its life with purpose. ${petName} may appear calm and serene to most, but beneath the surface lies an innately mysterious quality of its nature. ${petName} has begun to look deeper into the extravagance of each moment, given it’s an opportunity to absorb.`,
      `${petName} has realized that it is a source of great power, and by preserving and being in tune with its brilliant light, it can act in ways that, though often subtle, are beyond the means of most others. This Genopet is learning to navigate the unpredictability of its experiences with greater ease—rarely faltering in the face of uncertainty and embodying the lessons it encounters. Its deep-rooted empathy is guiding it towards wisdom and strength, which it will always choose to use for good.`,
      `${petName}’s second nature is that of preserving the beauty it revels within—and without—despite its keen level of curiosity. ${petName} has always felt a strong urge to embrace life and all that surrounds it, tempered by its instinct-fueled reservations.${petName} is a warm, empathetic Genopet who strives to understand the love language of the Genoverse. An insatiable thinker, ${petName} is stumbling upon deep introspection and is at the point in its evolution where the eternal illumination of truth emerges.`,
    ],
    [
      `${petName} is a brave, intrepid soul that longs to explore the unknown. Whether it be battling fearsome foes in combat, or diving into the hidden vaults of knowledge, this creature lives to conquer the challenges that lie before it. ${petName} has a clear vision, sharp focus, and rapid reflexes that allow it to act with decisiveness and strategy—especially in critical situations. Uncertainty belies ${petName}’s apparently firm convictions, but it’s determined to find answers—even if it results in a change of course.`,
      `Since reaching its latest stage of evolution, it’s unlocked new curiosities and begun finding itself in conflict with its own desires. On one hand, it craves direction and meaning; on the other hand, it wants to explore and experience all Esoterra can offer. At times, this internal struggle can create tension within ${petName}, but it is beginning to understand, through trial and error, that perhaps for this moment, a more fulfilling mission is striking a balance between the need for stability and the appetite for the unknown.`,
      `${petName} is driven by a sense of wonder it cannot fully comprehend, but which nonetheless feels profound. In an attempt to gain knowledge and understanding, ${petName} pursues meaning and purpose, looking for patterns behind the events that unfold around it. Although ${petName} is confident in its ability to navigate the strange and mysterious, it occasionally faces the question of whether or not its objectives align with its actions. ${petName} is beginning to question its own motives.`,
      `${petName} is orderly and methodical in its pursuit of discovery, though its efforts come at a price. As ${petName}’s curiosity draws it closer to the shadows, so too does its fear of what it may reveal. Torn between its desire for rich experiences and the very real danger of being consumed by the thrill, ${petName} grapples with this ever-growing battle. Although a deterrent and a catalyst for haste, ${petName}’s internal conflicts form new questions, driving its exploration further.`,
      `${petName} is an intrepid explorer whose unbridled curiosity drives it to discover all that Esoterra has to offer. It is a keen observer of the world around it, and its insights often give it an advantage in whatever pursuits it undertakes. ${petName} is also a highly principled individual, always putting the safety of others first. ${petName} is always looking to push its boundaries and explore new areas, but it also has a strong sense of responsibility. At times, this dichotomy can lead to feelings of guilt or frustration.`,
      `In its quest for truth, this Genopet is fuelled by an unending desire to evolve. It is rarely content, constantly pushing itself to explore new things and learn more about its place and purpose in the Genoverse. Despite its intense curiosity, ${petName} is a very introspective creature, and in the face of uncertainty, it tends to retreat inward. ${petName} holds itself to very high standards, striving relentlessly to do what it considers to be morally and ethically correct.`,
      `${petName} is highly fascinated by the hidden meaning that can be found in the details. This Genopet has a propensity for seeing both the good and bad in people, and it is often able to find a way to make peace with opposing viewpoints. Highly efficient in its endeavors, ${petName} is patient and careful with its choices, as ${petName} knows there’s no one right answer, and sometimes the most important thing is simply to hold steady and keep moving forward.`,
      `${petName} is a stoic and introspective figure, often spending long periods of time in contemplation. It has a deep understanding of the interconnectedness of all things, and it sees life as a never-ending journey of growth and discovery. It is a gentle and compassionate being, but also possesses a fierce determination and inner strength. ${petName} is always seeking to learn and grow, and it strives to live its life with purpose and meaning.`,
      `${petName} has found itself reflecting on the risks it takes. It knows that some of its gambles may not pay off, but it is driven by a burning desire to succeed. It explores its setbacks as learning opportunities that it knows will make it stronger in the long run. ${petName} is constantly looking for ways to improve itself so that it can reach its goals with growing efficiency. This relentless drive to succeed sets ${petName} apart from the rest.`,
      `${petName} is a passionate explorer whose zest for adventure leads to discovering new and unusual creatures and places. This Genopet is driven by a deep need for connection and belonging, which leads it to seek out new experiences and uncover the vastness of the Genoverse. ${petName} is exploring the boundaries of its understanding and learning its limits. While its journey is challenging and exciting, it can be difficult for ${petName} to maintain focus on internal matters with so much novelty to explore around it.`,
      `As an avid adventurer, ${petName} knows that there’s no such thing as a single perspective. This Genopet can feel that everything around it and within it is constantly in flux. As it searches for its place in this world, ${petName} is learning that each decision has the potential to lead to new discoveries. It embraces change and variation, knowing that these are the building blocks of evolution. There are no limits to what ${petName} can achieve, and it blazes a path toward comprehension.`,
      `In a world of perpetual motion, ${petName} revels in the excitement and unpredictability of it all. However, at times, ${petName} struggles with reconciling the world's inherent chaos with its own desire for empathy. This creates tension within ${petName}, as it seeks to make sense of the seemingly nonsensical while also remaining open to the endless possibilities that life can offer. In the end, this conflict drives ${petName} to constantly explore and learn.`,
      `${petName} is an intrepid explorer, constantly striving to overcome new challenges, and eagerly willing to jump into new experiences. This Genopet has a tremendous sense of justice and is willing to fight for what is right, even if it means defying convention. Confident in its many abilities and brimming with good intentions, ${petName} believes that transparency prevails in a world of ambiguity, unafraid to take risks in the face of adversity.`,
      `${petName} openly traverses Esoterra, chasing the thrill of its seemingly never-ending surprises. Drawn to the unknown and unexplained, this Genopet’s curiosity is piqued by mystery. Drawn to the unknown and unexplained, this Genopet’s curiosity is piqued by mystery. While this Genopet is always eager to explore and learn, a theme it faces is one of curiosity versus caution, knowing that there’s a great responsibility that comes with revelation. ${petName} must heed the risks of what may lurk in the shadows.`,
      `${petName} is never content to rest on its laurels, always seeking to learn more and push its boundaries. This thirst for knowledge drives it to explore the farthest reaches of Esoterra, where few have gone before. It is a creature of boundless compassion, deeply connected with those who resonate with it. Its quest for discovery exposes it to a world of magic and wonder, allowing it to become aware of unseen vulnerabilities and untapped potential.`,
      `${petName} wanders Esoterra, absorbing the magnificence of the world around it. It is a compassionate creature, drawn to those in need. When this Genopet isn’t helping others, it’s prone to get lost in its own thoughts, exploring its deepest questions and curiosities. While this unique state of mind can lead to amazing adventures, it can also be difficult for ${petName} to keep focus. Practicing mindfulness is key for ${petName} to get the most out of its journey.`,
      `${petName}'s tenacity can sometimes lead it to deal with problems in very fixated ways, preoccupying its energy rather than moving past them when it's unable to come to a solution. This Genopet holds itself to the highest standards and so too for those around it. This can result in the craving for more meaningful levels of experiences and connections with other creatures. ${petName} is bold and courageous, with a propensity to isolate itself from those it instinctively deems suboptimal.`,
      `Possessing an innate curiosity and sharp mind, ${petName} explores all the apparent aspects of its known world, carefully analyzing patterns and systems, and searching for new ways to enhance them. With every discovery, ${petName}’s knowledge grows deeper, its vision becomes clearer, and it becomes more certain of its direction. Although an adamant creature, ${petName} is not immune to doubt. It often questions its views, seeking to ensure they are founded on a strong foundation, which can lead to excessive hesitancy.`,
      `${petName} is a shrewd and uncanny arbitrator, capable of seeing all sides of the conflict with clarity. Its deep insight and understanding of the elemental forces of life. This Genopet’s way of thinking offers a unique approach when resolving internal and external disputes, and its impartiality ensures that all parties and perspectives feel heard and respected. ${petName} is patient, and kind, striving to help strike balance and harmony among and within Esoterra.`,
      `${petName} is resolute and unbending in its convictions with a propensity for long-term planning and foresight. This Genopet’s diligence and focus is largely a boon, as it allows it to achieve remarkable goals, but it can also lead to frustration when met with unforeseen obstacles. As a persistent individual who’s always striving to achieve satisfaction through its thorough actions, ${petName} has a tendency of hyper-fixating on its shortcomings, rather than focussing on an alternative solution. OR where it may be time to move on.`,
      `${petName} is a highly analytical Genopet who wrestles internally with the dichotomy between introspection and pragmatism. While it's confident in its vision, ${petName} constantly conflicted with the methods by which it feels it must employ to achieve it. ${petName}’s rigid thought process can sometimes hamper its ability to be flexible, which can lead to setbacks. However, ${petName} sets out to reconcile the conflicting aspects of its personality to ultimately become stronger and more well-rounded.`,
      `In the eyes of ${petName}, there is much to be said for healthy skepticism. This Genopet believes that nature bears deceit, and it pays ot be cautious in one’s dealings. However, while that might be true, there is also much to be said for taking a leap of faith from time to time. When hesitating on the brink of an adventure, ${petName} must remind itself that not everything carries the same risk. Sometimes the greatest risk is in doing nothing at all.`,
      `${petName} sees the Genoverse and all that exists in it as part of an intricate web of energy. This complex understanding gives ${petName} strength and a sense of purpose, driving it to take whatever actions necessary to maintain balance among Esoterra. Reliable, resourceful, and always seeking to learn and grow, ${petName} is constantly exploring the depths of its own consciousness, expanding its self-awareness and that of the saturating Genoverse.`,
      `${petName} is a highly analytical observer who is always questioning the motives behind others’ actions; it’s constantly questioning whether the strange occurrences it experiences are mere coincidences, or if there’s a greater purpose at work. ${petName} is afraid to find out what its destiny may be, yet, it’s also drawn onward by a sense of curiosity and anticipation. This Genopet may not trust easily at first, but once it does, it forms deep and lasting bonds. ${petName} is fiercely loyal, and the first to lend a helping hand.`,
      `${petName} has a strong sense of self and knows what it wants. It is always looking for ways to improve, whether it’s its own skills or the situation at hand—and that includes compromise. While ${petName} takes input from others seriously, it has a strong internal compass, so it doesn’t hesitate to act alone when necessary. While confident in its abilities, this Genopet is still humble enough to know there’s always more to learn, which ultimately propels it forward.`,
      `${petName} can be characterized by its intellectualism and propensity for deep thought. It is deeply observant and always questioning, trying to understand everything happening, and not happening, around it, driven by a desire for knowledge and love for making the unknown familiar. In times of chaos, this Genopet sees the beauty in balance and strives to maintain its delicate equilibrium, even if it means deprioritizing its own needs and creating a harmonical imbalance.`,
      `${petName} is constantly seeking to understand the hidden meaning behind the events that unfold around it. ${petName} is innately drawn to the hidden secrets of the Genoverse, constantly exploring the unknown in pursuit of unearthing its greater destiny. At times, ${petName} feels torn between its sense of wonder, its thirst for knowledge, and its caution against what may be lurking in the shadows. This can lead to uncertainty and confusion. Nonetheless, ${petName} strives to gain wisdom from every circumstance it encounters.`,
      `${petName} can be seen as an idealist who’s not immune to getting wrapped up in its own thoughts and ideas, which to inaction. Yet, ${petName} is still drawn toward mystery by a sense of hidden inner purpose, weighted against an aversion to what may lurk beneath the shadows of the unknown. This sense of purpose provides a guiding light that drives ${petName} to continue on, even when the path is difficult and the journey seems fruitless.`,
      `${petName} is typically prudent by nature, but lately, its desire for new experiences has outweighed its natural caution. However, this conflict can also be a source of strength, as it forces ${petName} to weigh the importance of different outcomes to make difficult decisions. This Genopet’s seemingly curious nature has begun to lead to taking risks it wouldn’t normally take. Ultimately, 0000’s balancing act may just lead to a more nuanced and well-rounded worldview.`,
      `While ${petName} enjoys the comforts of familiarity and predictability, which can also lead to bouts of apathy and boredom, often seeking stimulation in the form of new experiences. While its curiosity can lead it to explore fresh territory, the vastness of the Genoverse is brimming with unpredictability and nuance. This level of stimulation can result in feelings of insignificance and futility. By knowing its own strengths and limitations, it can better navigate the Genoverse in search of new experiences that interest it.`,
      `${petName} has a very rare ability to see the big picture in an ocean of valuable details. It is acutely aware of the ebbs and flows of the Genoverse, and its decisions are based on a clear understanding of the interconnectedness of all things. ${petName}’s intuition allows it to move with great clarity and purpose, tapping into the energy of the land and the creatures who inhabit it. Deep down, however, ${petName} feels a sense of longing for something it can’t quite comprehend.`,
      `${petName} is innately drawn to the mysteries beyond the Veil, but can also be fearful of what it does not understand. Thus, it is constantly at war with the belief of a grand destiny to fulfill and being drawn to the more immediate pleasures of life. It tries to stay focused and driven, but it often succumbs to the mesmerizing nature of the world around it. ${petName} is on a mission to find the balance between living in the moment and pushing toward its ultimate goal of evolution.`,
    ],
  ];

  const stageIndex =
    stage < descs.length && stage >= 0 ? stage : descs.length - 1;

  return descs[stageIndex][index];
};
