import { StatusIndicator } from '@/components/battle/status-effect/StatusIndicator';
import { StatusEffectEntity } from 'genopets-utils';
import { Text } from '../../ui/atoms/Text';
import { Flex } from '@chakra-ui/react';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';

export const StatusIndicators = ({
  status,
}: {
  status?: StatusEffectEntity[];
}) => {
  if (!status) return null;

  return (
    <>
      <Text>
        <TranslatedText translationKey={`status`} defaultMessage={`Status`} />
      </Text>
      <Flex flexWrap={'wrap'} gap={1}>
        {status.map((s, i) => (
          <StatusIndicator key={i} status={s} />
        ))}
      </Flex>
    </>
  );
};
