import { ClaimDailyTicketsModal } from '@/components/battle/ClaimDailyTicketsModal';
import { NotCoinModal } from '@/components/notcoin/NotCoinModal';
import {
  GAME_SETTINGS_TELEGRAM_TICKETS_PER_DAY,
  useGameSettings,
} from '@/components/battle/hooks/useGameSettings';
import { useUserData } from '@/context/FirestoreContext';

/**
 * Place any modal messages that need to be displayed to the user here.
 * Add code for specific modals in the order they should be displayed.
 */
export const ModalMessageManager = () => {
  const { userData } = useUserData();
  const notCoinBonusTickets =
    userData?.NotcoinReferral?.bonusTelegramTickets ?? 0;
  const telegramNextDayTimestampMs = userData?.telegramNextDayTimestampMs ?? 0;
  // backend will prevent bad actors that change their system time
  // to after `telegramNextDayTimestampMs` since validation is done there
  const canClaimTickets = telegramNextDayTimestampMs < Date.now();

  const settings = useGameSettings();

  // wait for settings to load
  if (canClaimTickets && settings) {
    return (
      <ClaimDailyTicketsModal
        isOpen={canClaimTickets}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClose={() => {}}
        ticketsToClaim={settings[GAME_SETTINGS_TELEGRAM_TICKETS_PER_DAY] ?? 3}
      />
    );
  }

  if (notCoinBonusTickets > 0) {
    return <NotCoinModal ticketAmount={notCoinBonusTickets} />;
  }

  return null;
};
