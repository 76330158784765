import { Box } from '@chakra-ui/react';
import { BattleMoveEntity } from 'genopets-utils';
import { Text } from '../../ui/atoms/Text';
import { Button } from '../../ui/molecules/Button';
import { Card } from '../../ui/molecules/Card';
import { MenuItemPop } from '../../ui/molecules/MenuItemPop';
import { useUIBattleContext } from '../context/useBattleContext';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { useTranslation } from 'react-i18next';

export const MenuChooseMoveItem = ({
  move,
  cooldown,
  selected,
  onClick,
  onReplace,
}: {
  move: BattleMoveEntity;
  cooldown?: number;
  selected: boolean;
  // eslint-disable-next-line no-unused-vars
  onClick: (name: string) => void;
  onReplace?: () => void;
}) => {
  const { t: translateText } = useTranslation();
  const { loading } = useUIBattleContext();

  return (
    <Box position="relative" w="full">
      {selected ? (
        <Card
          position="absolute"
          bottom={'30px'}
          right={'30px'}
          zIndex={2}
          maxW={'calc(100% - 60px)'}
        >
          <Text colorId="Black" whiteSpace={'nowrap'}>
            <TranslatedText
              translationKey={`moveStatTextType`}
              defaultMessage={`type`}
            />
            {`: ${(move.categories ?? move.types ?? []).join(',')}`}
          </Text>
          <Text colorId="Black" whiteSpace={'nowrap'}>
            <TranslatedText
              translationKey={`moveStatTextPower`}
              defaultMessage={`power`}
            />
            {`: ${move.power ?? ''}`}
          </Text>
          <Text colorId="Black" whiteSpace={'nowrap'}>
            <TranslatedText
              translationKey={`coolDown`}
              defaultMessage={`cooldown`}
            />
            {`: ${move.cooldown ?? ''}`}
          </Text>

          <Text my="16px" colorId="Black">
            {translateText(move.lokaliseKeyDescription as string, {
              defaultValue: move.description as string,
            }) || move.description}
          </Text>

          {onReplace ? (
            <Button
              colorId="Black"
              minWidth={0}
              w="full"
              isLoading={loading}
              onClick={() => {
                onReplace();
              }}
            >
              <TranslatedText
                translationKey={`replace`}
                defaultMessage={`Replace`}
              />
            </Button>
          ) : (
            <Button
              colorId="Black"
              minWidth={0}
              w="full"
              isLoading={loading}
              onClick={() => {
                onClick(move.name);
              }}
            >
              <TranslatedText
                translationKey={`close`}
                defaultMessage={`Close`}
              />
            </Button>
          )}
        </Card>
      ) : null}
      <MenuItemPop
        color={
          move.categories?.includes('PWR')
            ? '#B7A500'
            : selected
              ? 'white'
              : 'black'
        }
        colorId={selected ? 'Black' : undefined}
        width="full"
        disabled={!!cooldown}
        onClick={() => {
          if (cooldown) return;
          onClick(move.name);
        }}
      >
        {translateText(move.lokaliseKeyName as string, {
          defaultValue: move.name as string,
        }) || move.name}
      </MenuItemPop>
    </Box>
  );
};
