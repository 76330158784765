import { Box, Flex } from '@chakra-ui/react';
import { getReferralMultiplier } from 'genopets-utils';
import { useGameSettings } from '../battle/hooks/useGameSettings';
import { Text } from '../ui/atoms/Text';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { Energy } from '../ui/icons/Energy';
import { Mail } from '../ui/icons/Mail';
import { Next } from '../ui/icons/Next';
import { Ticket } from '../ui/icons/Ticket';
import { Button } from '../ui/molecules/Button';

export const ReferralData = ({
  referredUsersCount,
  referralPoints,
  changeView,
}: {
  referredUsersCount?: number;
  referralPoints?: number;
  changeView: () => void;
}) => {
  const gameSettings = useGameSettings();
  const referralEnergyPointsBonusConfig =
    gameSettings?.referralEnergyPointsBonusConfig;

  const myMultiplier = getReferralMultiplier(
    referredUsersCount ?? 0,
    referralEnergyPointsBonusConfig,
  );

  return (
    <Box borderTop={`solid 3px black`} padding={`0 8px`}>
      <Button
        colorId="White"
        w="full"
        paddingBottom={`8px`}
        onClick={() => {
          changeView();
        }}
      >
        <Flex justifyContent={`space-between`} w={`full`}>
          <Text colorId={`Black`} textAlign={`left`}>
            {' '}
            <TranslatedText
              translationKey={`inviteAFren`}
              defaultMessage={`Invite a fren`}
            />
          </Text>
          <Flex alignItems={`center`}>
            <Ticket />
            <Text colorId="Black" w="full" width={`auto`}>
              3
            </Text>
            <Next color={`var(--Black)`} />
          </Flex>
        </Flex>
      </Button>

      <Box pl={`6px`}>
        {referralEnergyPointsBonusConfig && (
          <Flex alignItems={`center`} mb={`8px`} gap={`4px`}>
            <Flex alignItems={`center`}>
              <Mail color={`Green`} />
              <Text color={`Green`} ml="4px">
                {referredUsersCount ?? '...'}
              </Text>
              <Text color={`Green`} ml={`4px`}>
                <TranslatedText
                  translationKey={`referral`}
                  defaultMessage={`Referral`}
                  count={referredUsersCount}
                />
              </Text>
            </Flex>
            {(referralPoints ?? 0) > 0 && (
              <Flex
                alignItems={`center`}
                justifyContent="flex-end"
                width="full"
              >
                <Text color={`Green`}>+</Text> <Energy color={`Green`} />{' '}
                <Text color={`Green`}>
                  {(referralPoints ?? 0).toLocaleString()}
                </Text>
              </Flex>
            )}
          </Flex>
        )}

        <Flex minHeight={`85px`} flexDirection={`column`} gap={`8px`}>
          {!referralEnergyPointsBonusConfig ? (
            <Text colorId={`Black`}>
              {' '}
              <TranslatedText
                translationKey={`loading`}
                defaultMessage={`Loading`}
              />{' '}
              ...
            </Text>
          ) : (
            Object.entries(referralEnergyPointsBonusConfig || {}).map(
              ([referredCountTemp, multiplier]) => {
                const referredCount = +referredCountTemp;
                if (referredCount <= 0) return null;

                return (
                  <Flex
                    key={referredCount}
                    opacity={multiplier === myMultiplier ? 1 : 0.5}
                    justifyContent={`space-between`}
                  >
                    <Flex alignItems={`center`} gap={`4px`}>
                      <Mail color={`Black`} />
                      <Text colorId={`Black`}>{referredCount}</Text>
                    </Flex>

                    <Flex alignItems={`center`} gap={`4px`}>
                      <Energy color={`black`} />
                      <Text colorId={`Black`}>{multiplier}% bonus</Text>
                    </Flex>
                  </Flex>
                );
              },
            )
          )}
        </Flex>
      </Box>
    </Box>
  );
};
