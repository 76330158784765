import { MainContainer } from '@/components/battle/MainContainer';
import { PetStatistic } from '@/components/battle/PetStatistic';
import { bgSounds, initialOptions } from '@/lib/sounds';
import { useEffect } from 'react';
import useSound from 'use-sound';

export const BattleStatsPage = () => {
  const [play, { stop, sound }] = useSound(bgSounds.stats, initialOptions);

  useEffect(() => {
    if (sound) {
      play();
      return () => stop();
    }
  }, [sound]);

  return (
    <MainContainer background={'none'}>
      <PetStatistic />
    </MainContainer>
  );
};
