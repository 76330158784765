import { VITE_SHOW_BATTLE_INFO } from '@/config/constants';
import { useAlert } from '@/hooks/useAlert';
import { useFirestore } from '@/lib/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { BATTLES_COLLECTION, BattleEntity } from 'genopets-utils';
import { useEffect, useState } from 'react';
import { Unsubscribe } from 'redux';

export const useReadBattleFromFirestore = ({
  battleId,
}: {
  battleId?: string;
}) => {
  const [battle, setBattle] = useState<BattleEntity>();

  const db = useFirestore();
  const alert = useAlert();

  useEffect(() => {
    let unsub: Unsubscribe;
    if (db && battleId) {
      unsub = onSnapshot(
        doc(db, BATTLES_COLLECTION, battleId),
        (document) => {
          if (document.exists()) {
            const data = document.data();

            const serializableData = {
              ...data,
            } as BattleEntity;

            if (VITE_SHOW_BATTLE_INFO) {
              console.log(`battle from db`, serializableData);
            }

            setBattle(serializableData);
          }
        },
        (error) => {
          console.error(error);
          alert({ title: error.message });
        },
      );
    }
    return () => {
      unsub?.();
    };
  }, [db, battleId]);

  return battle;
};
