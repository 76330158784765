import crypto from 'crypto';

/**
 *
 * @param n number to roll from 0 - n (non-inclusive of n)
 * @returns
 */
export const randomNumber = (n: number) => {
  if (!n) return 0;

  const array = new Uint32Array(1);

  // this is to make the code universal in nodejs or the browser.
  if (typeof window !== `undefined` && window.crypto) {
    window.crypto.getRandomValues(array);
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    crypto.getRandomValues(array);
  }
  return array[0] % n;
};

/**
 * returns true x:1 of the time.
 *
 * e.g:
 *  - x = 2 -> 2:1 so 2/3rds of the time
 *  - x = 1 -> 1:1 so 1/2 of the time
 *  - x = 9 -> 9:1 so 9/10 of the time, and so on
 *
 * @param x
 * @returns
 */
export const rollXtoOne = (x = 1) => randomNumber(x + 1) < x;

/**
 * Given an array of probability weight numbers, return the index of
 * a randomly selected index based on that probablity weight of the indexes.
 * @param weights
 * @returns
 */
export const randomIndex = (weights: number[]) => {
  const total = weights.reduce((a, b) => a + b);
  const number = randomNumber(total);
  let sum = 0;
  for (let i = 0; i < weights.length; i++) {
    sum += weights[i];
    if (number < sum) {
      return i;
    }
  }
  return weights.length - 1;
};

/**
 * Generates a random number with a specified number of digits.
 *
 * @param digits The number of digits for the random number
 */
export const generateRandomNumbers = (digits = 6) => {
  // Ensure the number of digits is between 1 and 10
  // eslint-disable-next-line no-param-reassign
  digits = Math.max(1, Math.min(10, digits));

  // Calculate the minimum and maximum for the specified number of digits
  const min = 10 ** (digits - 1);
  const max = 10 ** digits - 1;

  const array = new Uint32Array(1);

  // this is to make the code universal in nodejs or the browser.
  if (typeof window !== `undefined` && window.crypto) {
    window.crypto.getRandomValues(array);
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    crypto.getRandomValues(array);
  }

  // Adjust the number to fit in the desired range
  return Math.floor((array[0] / (0xffffffff + 1)) * (max - min + 1)) + min;
};
