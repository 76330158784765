import { Text } from '@/components/ui/atoms/Text';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Energy } from '@/components/ui/icons/Energy';
import { Ticket } from '@/components/ui/icons/Ticket';
import { Button } from '@/components/ui/molecules/Button';
import { Modal } from '@/components/ui/molecules/Modal';
import { useUserData } from '@/context/FirestoreContext';
import { Box, Center } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import WhittakrImg from '../../../images/whittakr-no-margin.png';
import { PixeltonSpinnerPrize } from '../hooks/useSpinWheelSettings';

export const PrizeModal = ({
  isOpen,
  onClose,
  selectedOutcome,
  onSpinAgain,
  leveledUp,
  winGifSrc,
  setNumOfTickets,
  numOfTickets,
}: {
  onSpinAgain: () => void;
  isOpen: boolean;
  selectedOutcome: PixeltonSpinnerPrize | null;
  onClose: () => void;
  leveledUp: boolean;
  winGifSrc: string;
  numOfTickets?: number;
  setNumOfTickets: (num: number) => void;
}) => {
  const navigate = useNavigate();

  const { userData } = useUserData();

  useEffect(() => {
    if (numOfTickets !== userData?.telegramTickets && isOpen) {
      setNumOfTickets(userData?.telegramTickets ?? 0);
    }
  }, [userData, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      showBackButton={false}
    >
      <Box
        background={`var(--Yellow)`}
        w="calc(100% + 32px)"
        marginTop={-6}
        marginLeft={-4}
        padding={4}
        position="relative"
      >
        <Box position="absolute" top="-75px" left="-15px">
          <img
            src={WhittakrImg}
            alt="Witakar"
            style={{ height: '120px', width: 'auto' }}
          />
        </Box>
        <Text colorId="Black" w="full" textAlign={'center'}>
          <TranslatedText
            translationKey={`youWin`}
            defaultMessage={`you Win`}
          />
          !
        </Text>
        <Center alignItems={'center'} gap={1} w="full">
          {selectedOutcome?.type === 'tickets' ? (
            <Ticket />
          ) : (
            <Energy color="var(--Black)" />
          )}
          <Text colorId="Black" fontSize={'20px'}>
            {selectedOutcome?.amount}
          </Text>
        </Center>
      </Box>
      <Center w="full" mt={4}>
        <img
          src={winGifSrc}
          alt="wheel"
          style={{ width: 'auto', height: '240px' }}
        />
      </Center>

      {leveledUp ? (
        <Button
          onClick={() => {
            navigate('/battle-level-up?redirection=spinner');
          }}
          mt={2}
          w="full"
          colorId="Black"
        >
          <TranslatedText translationKey={`ok`} defaultMessage={`ok`} />
        </Button>
      ) : (
        <>
          <Button colorId="Black" mt={2} onClick={onSpinAgain} w="full">
            <TranslatedText
              translationKey={`spinAgain`}
              defaultMessage={`Spin Again`}
            />
            !
          </Button>
          <Button
            colorId="White"
            mt={2}
            onClick={() => navigate('/home')}
            w="full"
          >
            <TranslatedText
              translationKey={`goHome`}
              defaultMessage={`go home`}
            />
          </Button>
        </>
      )}
    </Modal>
  );
};
