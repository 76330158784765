import { useState, useEffect } from 'react';

export const useCountdown = (expiryTime?: number, onComplete?: () => void) => {
  const [seconds, setSeconds] = useState<number>();

  useEffect(() => {
    if (seconds === undefined) return;

    if (seconds > 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timer);
    } else if (onComplete) {
      onComplete();
    }
  }, [seconds, onComplete]);

  useEffect(() => {
    if (expiryTime && typeof seconds === 'undefined') {
      const time = Math.trunc(Math.max(0, expiryTime - Date.now()) / 1000);
      setSeconds(time);
    }
  }, [expiryTime]);

  return { seconds };
};
