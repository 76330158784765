import { Section, Cell, Image, List } from '@telegram-apps/telegram-ui';
import type { FC } from 'react';

import { Link } from '@/components/Link/Link.tsx';

import tonSvg from './ton.svg';

import './IndexPage.css';
import { useLaunchParams } from '@tma.js/sdk-react';
import { useNavigate } from 'react-router-dom';

export const IndexPage: FC = () => {
  const { startParam } = useLaunchParams();

  if (startParam) {
    const navigate = useNavigate();
    navigate('/battle');
  }

  return (
    <List className="index-page__list">
      <Section
        header="Features"
        footer="You can use these pages to learn more about features, provided by Telegram Mini Apps and other useful projects"
      >
        <Link to="/ton-connect">
          <Cell
            before={
              <Image src={tonSvg} style={{ backgroundColor: '#007AFF' }} />
            }
            subtitle="Connect your TON wallet"
          >
            TON Connect
          </Cell>
        </Link>
      </Section>
      <Section
        header="Application Launch Data"
        footer="These pages help developer to learn more about current launch information"
      >
        <Link to="/init-data">
          <Cell subtitle="User data, chat information, technical data">
            Init Data
          </Cell>
        </Link>
        <Link to="/launch-params">
          <Cell subtitle="Platform identifier, Mini Apps version, etc.">
            Launch Parameters
          </Cell>
        </Link>
        <Link to="/theme-params">
          <Cell subtitle="Telegram application palette information">
            Theme Parameters
          </Cell>
        </Link>
        <Link to="/test-components">
          <Cell subtitle="Telegram application palette information">Tests</Cell>
        </Link>
        <Link to="/home">
          <Cell subtitle="Home">Home</Cell>
        </Link>
        <Link to="/battle-level-up">
          <Cell subtitle="Level up">Level up</Cell>
        </Link>
        <Link to="/create-baby">
          <Cell subtitle="Create Baby">Create Baby</Cell>
        </Link>
        <Link to="/battle">
          <Cell subtitle="Battle">Battle</Cell>
        </Link>
      </Section>
    </List>
  );
};
