export const BattleSword = ({ ...props }) => (
  <svg width="24" height="25" viewBox="0 0 24 25" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 12.0215H16V10.0215H18V12.0215ZM14 8.02148H12V6.02148H14V8.02148ZM16 14.0215H14V12.0215H16V14.0215ZM12 10.0215H10V8.02148H12V10.0215ZM10 12.0215H8V10.0215H10V12.0215ZM8 14.0215H6V12.0215H8V14.0215ZM8 16.0215H6V14.0215H8V16.0215ZM14 16.0215H12V14.0215H14V16.0215ZM12 18.0215H10V16.0215H12V18.0215ZM10 18.0215H8V16.0215H10V18.0215ZM8 20.0215H6V18.0215H8V20.0215ZM6 18.0215H4V16.0215H6V18.0215ZM4 20.0215H2V18.0215H4V20.0215ZM6 22.0215H4V20.0215H6V22.0215ZM10 22.0215H8V20.0215H10V22.0215ZM4 16.0215H2V14.0215H4V16.0215ZM6 20.0215H4V18.0215H6V20.0215ZM4 22.0215H2V20.0215H4V22.0215ZM4 24.0215H2V22.0215H4V24.0215ZM2 22.0215H0V20.0215H2V22.0215ZM2 24.0215H0V22.0215H2V24.0215ZM10 20.0215H8V18.0215H10V20.0215ZM6 16.0215H4V14.0215H6V16.0215ZM8 18.0215H6V16.0215H8V18.0215ZM16 6.02148H14V4.02148H16V6.02148ZM18 4.02148H16V2.02148H18V4.02148ZM20 2.02148H18V0.0214844H20V2.02148ZM22 2.02148H20V0.0214844H22V2.02148ZM24 2.02148H22V0.0214844H24V2.02148ZM22 8.02148H20V6.02148H22V8.02148ZM20 10.0215H18V8.02148H20V10.0215ZM14 22.0215H12V20.0215H14V22.0215ZM6 14.0215H4V12.0215H6V14.0215ZM12 20.0215H10V18.0215H12V20.0215ZM4 14.0215H2V12.0215H4V14.0215ZM12 22.0215H10V20.0215H12V22.0215ZM24 6.02148H22V4.02148H24V6.02148ZM24 4.02148H22V2.02148H24V4.02148ZM14 20.0215H12V18.0215H14V20.0215ZM6 12.0215H4V10.0215H6V12.0215ZM4 12.0215H2V10.0215H4V12.0215Z"
      fill="currentColor"
    />
  </svg>
);
