/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isFunction, isNil, isSet } from 'lodash';

/**
 * The function is used to make sure the input returns an array. The value to return in case of undefined can be transmitted as second arg
 */
export function coerceArray<T>(variable: T | T[], fallback: T[] = []): T[] {
  return !isNil(variable)
    ? Array.isArray(variable)
      ? variable
      : [variable]
    : fallback;
}

/**
 * The function coerceFunction is used to make sure that the input variable is a function.
 * If variable is not a function, the newly created function returns this input argument.
 */
type BasicReturningFunction<I, O> = (...args: I[]) => O;
export function coerceFunction<O>(
  variable: O,
  fallback?: O,
): BasicReturningFunction<any, O>;
export function coerceFunction<O, I = any>(
  variable: O,
  fallback?: O,
): BasicReturningFunction<I, O>;
export function coerceFunction<O, I = any>(
  variable: BasicReturningFunction<I, O>,
  fallback?: O,
): BasicReturningFunction<I, O>;
export function coerceFunction<O, I = any>(
  variable: O,
  fallback?: BasicReturningFunction<I, O>,
): BasicReturningFunction<I, O>;
export function coerceFunction<O, I = any>(
  variable: BasicReturningFunction<I, O>,
  fallback?: BasicReturningFunction<I, O>,
): BasicReturningFunction<I, O>;
export function coerceFunction<O, I = any>(
  variable: O | BasicReturningFunction<I, O>,
  fallback: O | BasicReturningFunction<I, O>,
): BasicReturningFunction<I, O> {
  return !isNil(variable)
    ? isFunction(variable)
      ? variable
      : () => variable
    : isFunction(fallback)
      ? fallback
      : () => fallback;
}

/**
 * The function is used to make sure the input returns a set. The value to return in case of undefined can be transmitted as second arg.
 * If variable is a scalar or an array of values, the function returns a set initialized with these elements inside it (with the elements of
 * the array)
 */
export function coerceSet<T>(
  variable: T | T[] | Set<T>,
  fallback: Set<T> = new Set<T>(),
): Set<T> {
  return !isNil(variable)
    ? isSet(variable)
      ? variable
      : Array.isArray(variable)
        ? new Set<T>(variable)
        : new Set<T>([variable])
    : fallback;
}
