import { MainContainer } from '@/components/battle/MainContainer';
import { BlinkingText } from '@/components/ui/atoms/BlinkingText';
import { parseStartParams } from '@/utils/utils';
import { Center } from '@chakra-ui/react';
import { useLaunchParams } from '@tma.js/sdk-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Renders the landing page component.
 * This is where the telegram app comes in, and we use this component
 * to determine where to navigate the user.
 *
 * @return {JSX.Element} The JSX element representing the landing page.
 */
export const LandingPage = () => {
  const { startParam } = useLaunchParams();

  const params = parseStartParams(startParam);

  const navigate = useNavigate();

  console.log('landing page', params);

  useEffect(() => {
    // if there is a battle param, go straight to battle
    if (params?.battle) {
      return navigate(`/battle/${params.battle}`);
    }

    return navigate(`/home`);
  }, [params]);

  return (
    <MainContainer>
      <Center w="full" h="full">
        <BlinkingText textAlign="center">Entering Genoverse...</BlinkingText>
      </Center>
    </MainContainer>
  );
};
