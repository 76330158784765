import { keys } from 'lodash';
import { BattleMoveEntity } from '../models/battle-action.entity';
import { BattlePet } from '../models/battle-pet';

/**
 * Apply the move cooldown on the pet
 * When a pet use a move, the move is put in cooldown for X turn(s)
 * The information about the cooldown is on the pet itself (see BattlePetModel)
 * @param pet pet that used the move
 * @param move move used
 * @returns pet stats updated with the cooldown of the move used
 */
export function applyCooldown(
  pet: BattlePet,
  move: BattleMoveEntity,
  onOpponentTurn = false,
): BattlePet {
  return {
    ...pet,
    // add one turn to cooldown because cooldown are ticked on opponent turn
    cooldowns: {
      ...pet.cooldowns,
      [move.name]: move.cooldown + (onOpponentTurn ? 0 : 1),
    },
  };
}

/**
 * Tick all moves cooldown of the pet by 1
 * The cooldowns represent the number of turn where the move cannot be used again
 * @param pet pet to update
 * @returns pet with stats updated
 */
export function tickCoolDowns(pet: BattlePet): BattlePet {
  if (!pet.cooldowns) return pet;
  const cooldownKeys = keys(pet.cooldowns);
  return {
    ...pet,
    cooldowns: cooldownKeys.reduce(
      (prev: Record<string, number>, curr: string) => ({
        ...prev,
        [curr]: Math.max(pet.cooldowns![curr] - 1, 0),
      }),
      {},
    ),
  };
}
