import { randomNumber } from '../../random';

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 */
export function getRandomInt(min: number, max: number): number {
  const ceilmin = Math.ceil(min);
  const floormax = Math.floor(max);
  return randomNumber(floormax - ceilmin + 1) + ceilmin;
}
