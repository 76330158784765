import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useAirdropData } from '@/components/airdrop/hooks/useAirdropData';
import { VITE_AIRDROP_TIME_OVERRIDE } from '@/config/constants';
import { getStartOfNextWeek } from 'genopets-utils';

export const useAirdropTimeLeft = () => {
  const { amount } = useAirdropData();
  const [timeLeft, setTimeLeft] = useState('00:00:00');

  useEffect(() => {
    if (amount) {
      const interval = setInterval(() => {
        const airdropTimeOverride = new Date(VITE_AIRDROP_TIME_OVERRIDE);
        const now = DateTime.utc();

        // weekly airdrop starts at the upcoming Monday UTC
        const nextMonday = getStartOfNextWeek();

        // Convert to UTC
        const nextMondayUTC =
          airdropTimeOverride.getTime() > nextMonday.toMillis()
            ? DateTime.fromJSDate(airdropTimeOverride)
            : nextMonday.toUTC();

        const duration = nextMondayUTC.diff(now, [
          'hours',
          'minutes',
          'seconds',
        ]);

        setTimeLeft(duration.toFormat('hh:mm:ss'));
      });

      return () => clearInterval(interval);
    }
  }, [amount]);

  return timeLeft;
};
