import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import {
  BattleState,
  PetStatistics,
  RootState,
  StatusEffectEntity,
} from 'genopets-utils';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Text } from '../../ui/atoms/Text';
import { Up } from '../../ui/icons/Up';
import { Button } from '../../ui/molecules/Button';
import { Card } from '../../ui/molecules/Card';
import { MenuStatus } from '../status-effect/MenuStatus';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';

export const StatusInformationModal = ({
  isOpen,
  onClose,
  status,
  playerId,
}: {
  isOpen: boolean;
  onClose: () => void;
  status?: StatusEffectEntity[];
  playerId: string;
}) => {
  const battleState = useSelector<RootState>(
    (state: RootState) => state.battle,
  ) as BattleState;

  const pet = useMemo(
    () =>
      battleState?.player1?.id === playerId
        ? battleState?.player1.pet
        : battleState?.player2.pet,
    [battleState],
  );

  return (
    // use chakra modal instead of genopets modal to have two card side by side
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent padding={'16px'} background={'transparent'}>
        <ModalBody padding={0} w="full">
          <Card>
            <Text colorId="Black">
              <TranslatedText
                translationKey={`statusModifiers`}
                defaultMessage={`Status Modifiers`}
              />
            </Text>
            {pet.statusModifier && (
              <Flex mt={2} flexDir={'column'} w="full">
                {Object.keys(pet.statusModifier).map((key) => {
                  const value = pet.statusModifier![key as PetStatistics];
                  if (!value) return null;

                  return (
                    <Flex key={key} alignItems="center" gap={1}>
                      <Text colorId="Black">{key}</Text>
                      {Array.from({ length: Math.abs(value) }, (v, i) => i).map(
                        (_, ind) => {
                          return value > 0 ? (
                            <Up key={ind} color="var(--Green)" />
                          ) : (
                            <Up
                              key={ind}
                              color="var(--Red)"
                              style={{ transform: 'rotate(180deg)' }}
                            />
                          );
                        },
                      )}
                    </Flex>
                  );
                })}
              </Flex>
            )}
          </Card>
          <Card>
            <Text colorId="Black">
              <TranslatedText
                translationKey={`statusEffects`}
                defaultMessage={`Status Effects`}
              />
            </Text>
            <MenuStatus status={status} />
            <Button colorId="Black" w="full" onClick={onClose}>
              <TranslatedText
                translationKey={`close`}
                defaultMessage={`Close`}
              />
            </Button>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
