import { SPIN_WHEEL_SETTINGS_ENDPOINT } from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { useEffect, useState } from 'react';

export type PixeltonSpinnerPrizeType = `energy` | `tickets`;
export type PixeltonSpinnerPrize = {
  type: PixeltonSpinnerPrizeType;
  amount: number;
  probabilityWeight: number;
};
export type PixeltonSpinnerSettings = {
  prizes: PixeltonSpinnerPrize[];
  numOfTicketsRequired: number;
};

export const useSpinWheelSettings = () => {
  const [gameSettings, setGameSettings] = useState<PixeltonSpinnerSettings>();

  const { apiGet } = useAPI();

  useEffect(() => {
    if (!gameSettings) {
      apiGet(SPIN_WHEEL_SETTINGS_ENDPOINT)
        .then((data) => {
          setGameSettings(data);
        })
        .catch((error: any) => console.error(error));
    }
  }, [gameSettings, apiGet]);

  return gameSettings;
};
