import { GAME_SETTINGS_ENDPOINT } from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { useEffect, useState } from 'react';

export const GAME_SETTINGS_TELEGRAM_TICKETS_PER_DAY = `telegramTicketsPerDay`;

export const useGameSettings = () => {
  const [gameSettings, setGameSettings] = useState<{
    XPs: number[];
    globalEnergyBonusConfig?: Record<string, number>;
    referralEnergyPointsBonusConfig?: Record<string, number>;
    [GAME_SETTINGS_TELEGRAM_TICKETS_PER_DAY]?: number;
  }>();

  const { apiGet } = useAPI();

  useEffect(() => {
    if (!gameSettings) {
      apiGet(GAME_SETTINGS_ENDPOINT)
        .then((data) => {
          setGameSettings(data);
        })
        .catch((error: any) => console.error(error));
    }
  }, [gameSettings, apiGet]);

  return gameSettings;
};
