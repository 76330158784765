/* eslint-disable max-classes-per-file */
import { cloneDeep } from 'lodash';
import {
  EARTH_CRYSTAL_ADDRESS,
  FIRE_CRYSTAL_ADDRESS,
  METAL_CRYSTAL_ADDRESS,
  WATER_CRYSTAL_ADDRESS,
  WOOD_CRYSTAL_ADDRESS,
} from '../../constants';
import { ItemMetaData, ItemsMap } from '../../items';
import { ElementEnum } from '../../models';
import { Pet } from '../../pet';
import { AugmentSetBonusDefinitionsRecord } from '../../pet/stats/augment-set-bonus-definition.entity';
import { UserData } from '../../types';
import {
  BattleActionEntity,
  BattleMoveEntity,
} from '../models/battle-action.entity';
import { BattlePlayerEntity } from '../models/battle-player.entity';
import { BattleState } from '../models/battle-state';
import { BattleEntity, BattleStatus } from '../models/battle.entity';
import { PetStatisticsEnum } from '../models/statistics';
import { StatusEffectEntity } from '../models/status-effect.entity';
import { initializeBreak } from './break';
import { calculatePetStats } from './pet';

// use as opponent to create a solo battle
export const SECOND_PLAYER_ID = `self`;
export const SECOND_PLAYER_PET_ID = `pet-self`;

export const BATTLES_COLLECTION = `battles`;

export const CRITICAL_HIT_VALUE = 4;

export class InitialGodModeData {
  p1: Record<PetStatisticsEnum, number> & { level: number };

  p2: Record<PetStatisticsEnum, number> & { level: number };
}

export class PlayerGameStateInGodMode {
  HP?: string;

  statusEffect?: StatusEffectEntity;

  level?: number;

  petConfig?: Pet['petConfigV2'];

  items?: ItemMetaData[];

  augmentSetBonusDefinitionsRecord?: AugmentSetBonusDefinitionsRecord;

  element?: ElementEnum;

  move1?: BattleMoveEntity;

  move2?: BattleMoveEntity;

  move3?: BattleMoveEntity;

  move4?: BattleMoveEntity;

  breakMove?: BattleMoveEntity;
}
export class BattleGameStateInGodModeEntity {
  p1: PlayerGameStateInGodMode;

  p2: PlayerGameStateInGodMode;
}

export const crystalsMint: Record<string, ElementEnum> = {
  [WATER_CRYSTAL_ADDRESS]: ElementEnum.Water,
  [FIRE_CRYSTAL_ADDRESS]: ElementEnum.Fire,
  [WOOD_CRYSTAL_ADDRESS]: ElementEnum.Wood,
  [EARTH_CRYSTAL_ADDRESS]: ElementEnum.Earth,
  [METAL_CRYSTAL_ADDRESS]: ElementEnum.Metal,
};

export const getCrystalsMint: Record<ElementEnum, string> = {
  [ElementEnum.Water]: WATER_CRYSTAL_ADDRESS,
  [ElementEnum.Fire]: FIRE_CRYSTAL_ADDRESS,
  [ElementEnum.Wood]: WOOD_CRYSTAL_ADDRESS,
  [ElementEnum.Earth]: EARTH_CRYSTAL_ADDRESS,
  [ElementEnum.Metal]: METAL_CRYSTAL_ADDRESS,
};

export function createPlayerEntity(
  user: UserData,
  pet: Pet,
  moves: BattleMoveEntity[],
  breakMove: BattleMoveEntity,
  minorMove: BattleMoveEntity,
  items: ItemsMap,
  options?: {
    augSetBonusDefinitionsRecord?: AugmentSetBonusDefinitionsRecord;
    initDataForGodMode?: InitialGodModeData['p1'];
    joined?: boolean;
    telegramStats?: boolean;
  },
): BattlePlayerEntity {
  const {
    augSetBonusDefinitionsRecord,
    initDataForGodMode,
    joined,
    telegramStats,
  } = options || {};

  const petInfo = {
    id: pet.id,
    level: telegramStats
      ? pet.telegramLevel
      : initDataForGodMode?.level || pet.level,
    name: pet.nickname || pet.name || `Unknown Genopet`,
    petConfigV2: { ...pet.petConfigV2 },
  };

  return {
    id: user.id ?? ``,
    moves: [],
    minorMove,
    break: { ...initializeBreak(), move: breakMove },
    dodgeCooldown: 0,
    petInfo: {
      ...petInfo,
      moves,
    },
    pet: calculatePetStats(pet, items, {
      augSetBonusDefinitionsRecord,
      initDataForGodMode,
      telegramStats,
    }),
    joined: joined ?? false,
    telegramName: user.telegramName,
  };
}

export function getStartingPlayer(
  player1: BattlePlayerEntity,
  player2: BattlePlayerEntity,
) {
  if (player1.pet.base.SPD > player2.pet.base.SPD) {
    return player1.id;
  }
  if (player1.pet.base.SPD === player2.pet.base.SPD) {
    const sumStats = Object.values(player1.pet.base).reduce(
      (acc, cur) => acc + cur,
      0,
    );
    const opponentSumStats = Object.values(player2.pet.base).reduce(
      (acc, cur) => acc + cur,
      0,
    );
    // no random function could be used as it break the replay
    // higher sumStats start first or if equal player 1 start first
    return sumStats < opponentSumStats ? player2.id : player1.id;
  }
  return player2.id;
}

export function getPlayerData(
  state: BattleState | BattleEntity,
  playerId: string,
) {
  return state.player1.id === playerId ? state.player1 : state.player2;
}

export function getOpponentData(
  state: BattleState | BattleEntity,
  playerId: string,
) {
  return state.player1.id === playerId ? state.player2 : state.player1;
}

export function getPlayersData(state: BattleState, action: BattleActionEntity) {
  const playerThatPlayedTheAction = getPlayerData(state, action.playerId);

  const { pet } = playerThatPlayedTheAction;

  const opponent = getOpponentData(state, action.playerId);

  const { pet: opponentPet } = opponent;
  return { playerThatPlayedTheAction, pet, opponent, opponentPet };
}

export function checkVictory(state: BattleState): BattleState {
  const newState = cloneDeep(state);

  // check if the PET is KO
  if (state.player1.pet.HP <= 0) {
    newState.winner = newState.player2.id;
    newState.status = BattleStatus.ENDED;
    newState.nextAction = undefined;
    newState.nextPlayer = undefined;
  }

  // check if the other PET is KO
  if (newState.player2.pet.HP <= 0) {
    newState.winner = newState.player1.id;
    newState.status = BattleStatus.ENDED;
    newState.nextAction = undefined;
    newState.nextPlayer = undefined;
  }

  return newState;
}
