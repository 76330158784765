import React, { useEffect, useState } from 'react';
import { Modal } from '../../ui/molecules/Modal';
import { CreateOpenBattleModalContent } from './CreateOpenBattleModalContent';
import useAPI from '@/hooks/useAPI';
import { TELEGRAM_BATTLE_ENDPOINT } from '@/config/endpoints';
import { Text } from '../../ui/atoms/Text';
import { useAlert } from '@/hooks/useAlert';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';

export const CreateOpenBattleModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [battleId, setBattleId] = useState('');

  const alert = useAlert();

  useEffect(() => {
    if (isOpen && !isLoading && !battleId) {
      createBattle();
    }
  }, [isOpen, battleId, isLoading]);

  const { apiPost } = useAPI();

  const createBattle = async () => {
    if (isLoading) return;

    setIsLoading(true);

    const payload = {
      open: true,
    };

    apiPost(TELEGRAM_BATTLE_ENDPOINT, payload)
      .then((resp) => {
        console.log('created battle', resp);
        setBattleId(resp);
      })
      .catch((error: any) => {
        console.error(error);
        alert({ title: error?.message ?? 'Error' });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        cardProps={{ gap: 0 }}
        footerProps={{ mt: 2 }}
        closeOnOverlayClick={false}
      >
        {battleId ? (
          <CreateOpenBattleModalContent onClose={onClose} battleId={battleId} />
        ) : (
          <Text colorId="Black">
            <TranslatedText
              translationKey={`searchingForOpponentText`}
              defaultMessage={`Searching`}
            />
            ...
          </Text>
        )}
      </Modal>
    </>
  );
};
