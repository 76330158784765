import { PayloadAction } from '@reduxjs/toolkit';
import { BattleActionEntity } from '../models/battle-action.entity';
import { BattleState, BattleNextAction } from '../models/battle-state';

export function commonErrorCheck(
  state: BattleState,
  action: PayloadAction<BattleActionEntity>,
) {
  if (state.nextAction === BattleNextAction.INIT || !state.player1?.id) {
    return `The battle have NOT been initialized`;
  }
  if (state.winner) {
    return `You cannot perform this action, Battle is over`;
  }

  if (!state.player2?.id) {
    return `Missing opponent player`;
  }

  if (state.nextAction === BattleNextAction.MOVE_SELECTION) {
    return `All player must chose a move set to use before starting the battle`;
  }

  if (state.nextPlayer && action.payload.playerId !== state.nextPlayer) {
    return `It is not your turn to play`;
  }

  return null;
}
