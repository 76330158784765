const defaultError = `An unknown error has occurred. Please try again later.`;

export interface CustomRequestInit extends RequestInit {
  shouldReturnRawResponse?: boolean;
  agent?: any; // for nodejs env only
  allowUnauthenticated?: boolean;
}

// A small abstraction built on `fetch` to handle status code-specific errors and house other boilerplate fetch response code
export const boomerang = async (
  input: RequestInfo,
  init?: CustomRequestInit | undefined,
) => {
  try {
    const res = await fetch(input, {
      ...init,
      headers: new Headers({
        ...(init?.headers ?? {}),
        'ngrok-skip-browser-warning': `69420`,
      }),
    });

    if (init?.shouldReturnRawResponse) {
      return res;
    }

    if (res.ok) {
      const text = (await res.text())?.trim();
      try {
        // some returned data from third party API are not JSON format and this causes "JSON parse error"
        // so, get the text version of the returned data, if empty string then return undefined
        if (!text) {
          // eslint-disable-next-line consistent-return
          return;
        }

        // manually parse and return the text if it's not JSON format - https://github.com/Genopets/genopets-web/pull/2431/files#r1117015689
        return JSON.parse(text);
      } catch (err: any) {
        return text;
      }
    }

    switch (res.status) {
      case 429:
        throw new Error(`Too many requests. Please wait and try again.`);
      case 503:
        throw new Error(`Service Unavailable. Please wait and try again.`);
      default:
    }

    const resBody = await res.json();
    throw new Error(resBody?.message || `${res.status} ${res.statusText}`);
  } catch (err: any) {
    // do not throw error if http request is aborted (user left the page, or component with request unmounted)
    // eslint-disable-next-line consistent-return
    if (err?.message?.includes(`abort`)) return;

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(defaultError);
  }
};
