import { Flex, FlexProps } from '@chakra-ui/react';
import { isString } from 'lodash';
import { Text } from '../atoms/Text';
import { ColorId } from '../colors';
import { forwardRef } from 'react';

export const MenuItemDefault = forwardRef(
  (
    {
      children,
      colorId,
      color = 'var(--White)',
      disabled,
      onClick,
      ...props
    }: { colorId?: ColorId; disabled?: boolean } & FlexProps,
    ref,
  ) => {
    return (
      <Flex
        ref={ref}
        justifyContent={'flex-start'}
        alignItems={'center'}
        width={`343px`}
        color={disabled ? 'black' : color}
        background={disabled ? 'transparent' : `var(--${colorId})`}
        padding={`8px 0px`}
        opacity={disabled ? 0.3 : 1}
        cursor={disabled ? 'not-allowed' : 'pointer'}
        onClick={disabled ? undefined : onClick}
        position="relative"
        {...props}
      >
        {isString(children) ? (
          <Text color={disabled ? 'black' : color} lineHeight={'14px'}>
            {children}
          </Text>
        ) : (
          children
        )}
      </Flex>
    );
  },
);

MenuItemDefault.displayName = 'MenuItemDefault';
