import { PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep, keys } from 'lodash';
import {
  BattleActionEntity,
  BattleItemEntity,
} from '../../models/battle-action.entity';
import { BattleNextAction, BattleState } from '../../models/battle-state';
import {
  checkVictory,
  crystalsMint,
  getPlayerData,
} from '../../utils/battle-utils';
import {
  applyElementalStatus,
  getElementalStatus,
} from '../../utils/elemental';
import { commonErrorCheck } from '../../utils/error';
import { useWrapperReducer } from '../../utils/useWrapperReducer';

/**
 * Verify common error that could occur when passing an useBlock action
 * this function verify that the battle is executed as expected (player order, block allowed, etc)
 * the error is stored in the state and then is thrown by the store consumer id needed
 * @param state current state
 * @param action new action
 * @returns the error if any or null
 */
function checkUseItemError(
  state: BattleState,
  action: PayloadAction<BattleActionEntity>,
) {
  const error = commonErrorCheck(state, action);
  if (error) {
    return error;
  }

  if (state.nextAction !== BattleNextAction.PLAYER_CHOOSE) {
    return `You cannot perform this action, action should be ${state.nextAction}`;
  }

  const player = state.nextPlayer && getPlayerData(state, state.nextPlayer);
  const elStatus = player && getElementalStatus(player.pet);

  // crystal use have a 3 turns cooldown so as the duration of the status is 3 turns
  // cannot use another crystal until the status is gone
  if (
    keys(crystalsMint).includes(
      (action.payload.data as BattleItemEntity).mint,
    ) &&
    elStatus
  ) {
    return `You cannot use another crystal as it is in cooldown for ${elStatus.duration} turn(s)`;
  }

  return null;
}

// useBlock is always the end of the player turn (used or not)
// but sometime this action is not needed (break move, attack dodged)
export function handleAction(
  state: BattleState,
  actionStore: PayloadAction<BattleActionEntity>,
) {
  const action = actionStore.payload;

  const newState = cloneDeep(state) as BattleState;

  const playerThatPlayedTheAction = getPlayerData(newState, action.playerId);

  const item = action.data as BattleItemEntity;

  if (keys(crystalsMint).includes(item.mint)) {
    playerThatPlayedTheAction.pet = applyElementalStatus(
      item,
      playerThatPlayedTheAction,
    );
  } else {
    // TODO implement other handlers for others item if needed
    newState.error = `Cannot use this type of item in battle`;
    return newState;
  }

  newState.actions.push({ ...actionStore, payload: { ...action } });

  return checkVictory(newState);
}

export const useItemReducer = (
  state: Partial<BattleState>,
  action: PayloadAction<BattleActionEntity>,
) => useWrapperReducer(state, action, handleAction, checkUseItemError);
