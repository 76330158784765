import { Home } from '@/components/battle/Home';
import { MainContainer } from '@/components/battle/MainContainer';
import { postEvent } from '@tma.js/sdk-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserData } from '@/context/FirestoreContext';
import { bgSounds, initialOptions } from '@/lib/sounds';
import useSound from 'use-sound';
import { PreloadSounds } from '@/components/Sounds/PreloadSounds';

export const HomePage = () => {
  const { userData } = useUserData();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    postEvent(`web_app_setup_closing_behavior`, { need_confirmation: false });
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (userData && !userData.telegramPet) {
      navigate(`/create-baby`);
    } else if (userData) {
      setInitialized(true);
    }
  }, [userData]);

  const [play, { stop, sound }] = useSound(bgSounds.menu, initialOptions);

  useEffect(() => {
    if (sound) {
      play();
      return () => stop();
    }
  }, [sound]);

  return (
    <MainContainer padding={0}>
      <PreloadSounds />
      {initialized && <Home />}
    </MainContainer>
  );
};
