import { CDN_HOST } from '../constants';
import { PetConfig, getTelegramPetRenderId } from '../pet';

export const getPetImage = (petId: string) => {
  const ENVIRONMENT =
    process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.NODE_ENV;

  if (ENVIRONMENT === `development` || ENVIRONMENT === `local`) {
    return `${CDN_HOST}/transparent-pet-renders/development/${petId}.png`;
  }
  return `${CDN_HOST}/transparent-pet-renders/${petId}.png`;
};

export const getTelegramPetImage = (
  petConfig: PetConfig,
  side: 'front' | 'back' = `front`,
) => {
  // override the pet stage to be 0 regardless of the pet level
  const renderId = getTelegramPetRenderId({ ...petConfig, petStage: 0 });

  return `${CDN_HOST}/8bit-renders/${renderId}_8bit_${side}.png`;
};

export const petImageMultiplier: Record<number, number> = {
  0: 0.5,
  1: 0.55,
  2: 0.6,
  3: 0.65,
  4: 0.7,
  5: 0.75,
  6: 0.8,
  7: 0.85,
  8: 0.9,
  9: 0.95,
  10: 1,
  11: 1,
  12: 1,
};

export const getPetImageSize = (petStage?: number) =>
  petImageMultiplier[petStage || 1];

export const getPetMarketplaceImage = (petId: string) =>
  `${CDN_HOST}/PetRenders/${petId}.png`;

export const getPetMarketplaceAnimated = (petId: string) =>
  `${CDN_HOST}/PetRenders/${petId}.gif`;

export const DEFAULT_TELEGRAM_PET = `DefaultTelegramPet`;
