import { Text } from '@/components/ui/atoms/Text';
import { Button } from '@/components/ui/molecules/Button';
import { Card } from '@/components/ui/molecules/Card';
import { MenuItemPop } from '@/components/ui/molecules/MenuItemPop';
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { PetStatisticsEnum, StatusEffectEntity } from 'genopets-utils';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';

const SUFFIX_ICON_WHITE_FILLED_TO_BLACK = `_filled-1`;

export const MenuStatusItem = ({ status }: { status: StatusEffectEntity }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!status.name) return null;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent padding={'16px'} background={'transparent'}>
          <Card>
            <ModalHeader padding={0}>
              <Text
                colorId={
                  parseInt(status.statAffected ?? '0') > 0 ? 'Green' : 'Red'
                }
              >
                {status.name}
              </Text>
            </ModalHeader>

            <ModalBody padding={0} w="full">
              <Text colorId="Black">{status.description}</Text>
            </ModalBody>

            <ModalFooter padding={0} w="full" mt={2}>
              <Button colorId="Black" w="full" onClick={onClose}>
                <TranslatedText
                  translationKey={`textBack`}
                  defaultMessage={`Back`}
                />
              </Button>
            </ModalFooter>
          </Card>
        </ModalContent>
      </Modal>
      <MenuItemPop color={'black'} w="full" onClick={onOpen}>
        <Flex justifyContent={'space-between'} alignItems={'center'} w="full">
          <Flex alignItems={'center'}>
            <Box boxSize={'16px'}>
              <img
                src={status.icon?.replace(
                  SUFFIX_ICON_WHITE_FILLED_TO_BLACK,
                  '',
                )}
                alt={status.name}
              />
            </Box>
            <Text
              color={
                status.target === 'self'
                  ? `var(--Green)`
                  : status.statAffected === PetStatisticsEnum.HP
                    ? `var(--Red)`
                    : `var(--Black)`
              }
            >
              {status.name || `Unknown Effect`}
            </Text>
          </Flex>
          <Text color={`var(--Black)`}>{`turns:${
            typeof status.duration === 'undefined' ? '∞' : status.duration
          }`}</Text>
        </Flex>
      </MenuItemPop>
    </>
  );
};
