import { Box, BoxProps, keyframes } from '@chakra-ui/react';
import { ColorId } from '../colors';

export interface LinearProgressBarProps {
  progressBarHeight?: number;
  fromProgress?: string | 0;
  toProgress: string | 0;
  wrapperProps?: BoxProps;
  progressBarProps?: BoxProps;
  colorId?: ColorId;
}

export const Bar = ({
  progressBarHeight = 14,
  fromProgress,
  toProgress,
  colorId = 'Green',
  wrapperProps,
  progressBarProps,
}: LinearProgressBarProps) => {
  const progressAnimation = keyframes`
    from {width: ${fromProgress}}
    to {width: ${toProgress}}
  `;
  return (
    <Box
      w="100%"
      h={`${progressBarHeight}px`}
      minH={`${progressBarHeight}px`}
      border="3px solid var(--Black)"
      background="var(--White)"
      {...wrapperProps}
    >
      <Box
        background={`var(--${colorId})`}
        h="100%"
        w={toProgress}
        transition="width 1s ease-in-out"
        animation={
          typeof fromProgress !== 'undefined'
            ? `${progressAnimation} 1s linear`
            : undefined
        }
        {...progressBarProps}
      />
    </Box>
  );
};
