import { Box, BoxProps } from '@chakra-ui/react';
import InitBackground from '../../images/main-menu-bg.jpg';
import { ReactNode } from 'react';

export const MainContainer = ({
  children,
  background,
  ...props
}: {
  children: ReactNode;
  background?: string;
} & BoxProps) => {
  return (
    <Box
      background={`url(${background ?? InitBackground})`}
      w="full"
      h="100lvh"
      minH="100lvh"
      backgroundRepeat="no-repeat"
      backgroundPosition="left"
      backgroundSize="cover"
      padding="8px"
      display={'flex'}
      flexDirection={'column'}
      {...props}
    >
      {children}
    </Box>
  );
};
