export const Mail = ({ ...props }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.5698 4H22.5662H21.5704H20.5667H19.5709H18.5673H17.5715H16.5678H15.572H14.5684H13.5726H12.569V4.0013H11.5656H10.5698H9.56614H8.57032H7.56695H6.57113H5.5675H4.57168H3.56832H2.57249H1.56809H0.572266V4.99998V6.00101V6.99943V8.00046V8.99888V10.0002V10.9986V11.9999V13.0032V13.9991V15.0024V15.9982V17.0019V18.0006V19.0016V20H1.56809H2.57171H3.56754H4.5709H5.56672H6.57035H7.56617H8.56954H9.56536H10.569H11.5648H12.569V19.9987H13.5723H14.5681H15.5718H16.5676H17.5709H18.5668H19.5704H20.5662H21.5696H22.5654H23.569H24.5649V19V17.999V17.0006V15.9972V15.0014V13.9978V13.0019V11.9983V10.9973V9.99886V8.99784V7.99915V6.99813V5.99945V4.99842V4H23.5698ZM22.5659 6.99813H21.5701V7.99915H20.5667V8.99784H19.5709V9.99886H18.5673V10.9973H17.5715V11.9983V13.0019H18.5673V13.9978H19.5709V15.0014H20.5667V15.9972H21.5701V17.0006H22.5659V17.999H21.5701H20.5667V17.0006H19.5709V15.9972H18.5673V15.0014H17.5715V13.9978H16.5678V13.0019H15.572V13.9978H14.5684V15.0014H13.5726V15.9972H12.569V15.9982H11.5656V15.0024H10.5698V13.9991H9.56614V13.0032H8.57032V13.9991H7.56695V15.0024H6.57113V15.9982H5.5675V17.0019H4.57168V18.0006H3.56832H2.57249V17.0019H3.56832V15.9982H4.57168V15.0024H5.5675V13.9991H6.57113V13.0032H7.56695V11.9999V10.9986H6.57113V10.0002H5.5675V8.99888H4.57168V8.00046H3.56832V6.99943H2.57249V6.00101H3.56832H4.57168V6.99943H5.5675V8.00046H6.57113V8.99888H7.56695V10.0002H8.57032V10.9986H9.56614V11.9999H10.5698V13.0032H11.5656V13.9991H12.569V13.9978H13.5723V13.0019H14.5681V11.9983H15.5718V10.9973H16.5676V9.99886H17.5709V8.99784H18.5668V7.99915H19.5704V6.99813H20.5662V5.99945H21.5696H22.5654V6.99813H22.5659Z"
        fill={`currentColor`}
      />
    </svg>
  );
};
