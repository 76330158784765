export const Mute = ({ ...props }) => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M18.1 8H14.9V11.2H18.1V8Z" fill="white" />
    <path d="M18.1 20.7998H14.9V23.9998H18.1V20.7998Z" fill="white" />
    <path
      d="M22.8999 11.2001V1.6001H19.6999V4.8001H16.4999V8.0001L14.9 11.2001H13.3L9.96411 14.4313V17.4713L13.3 20.8001H14.9L16.4999 24.0001V27.2001H19.6999V30.4001H22.8999V20.8001H26.0999V11.2001H22.8999Z"
      fill="white"
    />
    <path
      d="M10.1 17.6002V14.4002H13.2999V11.2002H6.90002V20.8002H10.0999H10.1H13.2999V17.6002H10.1Z"
      fill="white"
    />
    <rect x="28" y="11" width="2" height="10" fill="white" />
    <rect x="31" y="10" width="2" height="12" fill="white" />
  </svg>
);
