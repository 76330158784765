/**
 * Miro board: https://miro.com/app/board/uXjVOpWAPeI=/?moveToWidget=3458764529781389154&cot=14
 *
 * Petting affects the Love factor of a Pet's overall Mood Score.  It ticks down 50 points every 24
 * hours until it reaches 0.
 *
 * Constraints:
 *  - cannot be lower than 0
 *  - cannot be greater than 100
 *
 * Petting Action:
 *  - Energy consumption: 100 per use
 *  - Effect: Restores 50 Love points (this is fixed)
 *
 */
import { ONE_HOUR_IN_MS } from '../../time/time.constants';
import { createActionDecayFunction } from '../action-decay';

export const DECAY_IN_HRS_LOVE = 24;
export const DECAY_TICK_INTERVAL_LOVE = DECAY_IN_HRS_LOVE * ONE_HOUR_IN_MS;
export const DECAY_VALUE_LOVE = 100;
export const MAX_SCORE_LOVE = 200;

export const getLoveScore = createActionDecayFunction(
  DECAY_TICK_INTERVAL_LOVE,
  DECAY_VALUE_LOVE,
  MAX_SCORE_LOVE,
  0,
  `deferred-capped`,
);
