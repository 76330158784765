import { ItemMetaData } from './Item';

export type ItemsMap = Map<string, ItemMetaData>;
export function itemMetaDataArrayToMap(items: ItemMetaData[]): ItemsMap {
  return new Map(items.map((augment) => [augment.key as string, augment]));
}

const AugmentClassVariantRanking = {
  commonBasic: 1,
  commonEnhanced: 2,
  commonSuperior: 3,
  uncommonBasic: 4,
  uncommonEnhanced: 5,
  uncommonSuperior: 6,
  rareBasic: 7,
  rareEnhanced: 8,
  rareSuperior: 9,
} as const;

export type AugmentClassVariant = keyof typeof AugmentClassVariantRanking;

export function isAugmentClassVariantGreaterOrEqual(
  givenValue: AugmentClassVariant,
  comparisonValue: AugmentClassVariant,
): boolean {
  return (
    AugmentClassVariantRanking[givenValue] >=
    AugmentClassVariantRanking[comparisonValue]
  );
}

export function isAugmentClassVariantGreaterOrEqualByItem(
  item: ItemMetaData,
  comparisonValue: AugmentClassVariant,
): boolean {
  return (
    getAugmentClassVariantRanking(item) >=
    AugmentClassVariantRanking[comparisonValue]
  );
}

export function getAugmentClassVariantRanking(item: ItemMetaData) {
  if (!item.class || !item.variant) return 0;

  // normalize to follow rankings format
  const classVariantKey = formatClassVariantRanking(item.class, item.variant);
  return AugmentClassVariantRanking[classVariantKey];
}

export function formatClassVariantRanking(
  itemClass: string,
  variant: string,
): AugmentClassVariant {
  return `${itemClass?.toLowerCase()}${variant
    ?.charAt(0)
    ?.toUpperCase?.()}${variant
    ?.slice?.(1)
    ?.toLowerCase?.()}` as AugmentClassVariant;
}

export enum ItemAttributeTypes {
  EnergyBoost = `Boost Value`,
  PartReroll = `Part Reroll`,
  Part = `Part`,
  Style = `Style`,
  Palette = `Palette`,
  Color = `Color`,
}

export const DefaultReferralMultiplierGlobalEnergyBonus: Record<
  number,
  number
> = {
  0: 1,
  1: 1.2,
  5: 1.5,
  7: 2,
};

export const DefaultReferralBonusEnergyPointsPercentage: Record<
  number,
  number
> = {
  0: 0,
  1: 2,
  10: 3,
  100: 4,
  1000: 5,
};

/**
 * This is a function that returns the bonus value for the passed referred users count
 * This is both used by global energy bonus and referral bonus
 * @param count
 * @param multiplierRecord
 */
export const getReferralMultiplier = (
  count: number,
  multiplierRecord: Record<
    number,
    number
  > = DefaultReferralMultiplierGlobalEnergyBonus,
): number => {
  let keys = Object.keys(multiplierRecord)
    .map(Number)
    .sort((a, b) => a - b);

  for (let i = keys.length - 1; i >= 0; i--) {
    if (count >= keys[i]) {
      return multiplierRecord[keys[i]];
    }
  }

  return multiplierRecord[keys[0]];
};
