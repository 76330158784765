import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { VITE_SHOW_BATTLE_INFO } from '@/config/constants';
import { useAlert } from '@/hooks/useAlert';
import { sounds, startBattle } from '@/lib/sounds';
import { getPetName } from '@/utils/utils';
import { Box, Center, Collapse, Flex } from '@chakra-ui/react';
import {
  BattleNextAction,
  BattleState,
  BattleStatus,
  RootState,
  copyToClipboard,
  getPlayerData,
  initBattle,
  resetBattle,
  store,
  useDodge,
  useMove,
} from 'genopets-utils';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useSound from 'use-sound';
import { BlinkingText } from '../ui/atoms/BlinkingText';
import { Button } from '../ui/molecules/Button';
import { Battle } from './Battle';
import { BattleReplay } from './replay/BattleReplay';
import { WaitForPlayerToJoin } from './WaitForPlayerToJoin';
import { BattleMode, useUIBattleContext } from './context/useBattleContext';
import { useEndTurn } from './hooks/useEndTurn';
import { usePrintMove } from './hooks/usePrintMove';
import { useReadBattleFromFirestore } from './hooks/useReadBattleFromFirestore';
import { BattleRewardsCard } from './rewards/BattleRewardsCard';

const ToBeRemovedBattleInformation = ({ battleId, user }: any) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        position="fixed"
        display={show ? 'none' : 'block'}
        zIndex={100}
        top={0}
        left={0}
        width="fit-content"
        minW={0}
        onClick={() => setShow(true)}
      >{`INFO`}</Button>
      <Collapse in={show}>
        <Flex gap={2}>
          <Button
            minW={'20px'}
            padding={1}
            onClick={() => setShow(false)}
          >{`X`}</Button>
          <Flex flexDirection={'column'}>
            <Box>{'BID => ' + (battleId ?? 'No battle started')}</Box>
            <Box>{'UID:' + (user?.uid ?? 'No user id')}</Box>
          </Flex>
          <Button
            colorId={'Green'}
            onClick={() => {
              copyToClipboard(battleId);
            }}
            width="50px"
            minW={'50px'}
          >
            copy
          </Button>
        </Flex>
      </Collapse>
    </>
  );
};

export const BattleContainer = () => {
  const { t: translateText } = useTranslation();
  const [initialized, setInitialized] = useState(false); // used to prevent the battle from replaying all animation when rejoin
  const {
    battleId,
    setLoading,
    user,
    mode,
    setMode,
    setPlayMoveAnimation,
    setMoveAnimationPlayed,
    busyUi,
  } = useUIBattleContext();

  const battle = useReadBattleFromFirestore({ battleId });

  const battleState = useSelector<RootState>(
    (state: RootState) => state.battle,
  ) as BattleState;

  const alert = useAlert();

  const [playStartBattleSound] = useSound(startBattle, {
    volume: 0.8,
    interrupt: false,
    loop: false,
  });

  const [playWinSound] = useSound(sounds.win, {
    volume: 0.8,
    interrupt: false,
    loop: false,
  });
  const [playLoseSound] = useSound(sounds.lose, {
    volume: 0.8,
    interrupt: false,
    loop: false,
  });

  useEffect(() => {
    if (!battleState.winner || !user?.uid) return;

    setTimeout(() => {
      if (battleState.winner === user?.uid) {
        playWinSound();
      } else {
        playLoseSound();
      }
      // pet dying has few sec animation so add delay to time the sound playing with showing "you win/lose" label
    }, 3000);
  }, [battleState.winner, user?.uid]);

  useEffect(() => {
    // if we do not have a battle and the battle state is not in the initial state, reset the store
    if (!battle && battleState.nextAction !== BattleNextAction.INIT) {
      console.log(`reset battle no battle from database`);
      store.dispatch(resetBattle());
    }

    if (battle?.id && battleState?.id && battle.id !== battleState.id) {
      console.log(`reset battle due to battle id changed`);
      store.dispatch(resetBattle());
    }

    if (
      battle?.status === BattleStatus.ONGOING ||
      battle?.status === BattleStatus.ENDED
    ) {
      if (
        isEmpty(battleState.actions) &&
        battleState.nextAction === BattleNextAction.INIT
      ) {
        setInitialized(false);
        store.dispatch(
          initBattle({
            ...battle,
            startTime: { ...battle.startTime } as any,
            endTime: { ...battle.endTime } as any,
            withHistory: mode === BattleMode.REPLAY ? true : false,
          }),
        );
        if (mode !== BattleMode.REPLAY) {
          setMode(BattleMode.BATTLE);
        }
      }

      if (mode === BattleMode.REPLAY) {
        setInitialized(true);
      } else {
        if (isEmpty(battle.actions) && !initialized) {
          alert({
            duration: 3000,
            title: translateText(`opponentAppearedFkEmUp`, {
              name: battle.player1.id === user?.uid ? '2' : '1',
              defaultValue: `player ${
                battle.player1.id === user?.uid ? '2' : '1'
              } appeared! f*ck em up!`,
            }),
          });
          playStartBattleSound();
        }

        if (
          (battle.actions?.length ?? 0) > (battleState?.actions?.length ?? 0)
        ) {
          // replay all missing events compare to the document' events,
          // allow to rejoin a ongoing battle by replaying all events saved
          let breakLoop = false;
          for (
            let i = 0;
            i <
            (battle.actions?.length ?? 0) - (battleState?.actions?.length ?? 0);
            i++
          ) {
            if (!busyUi) {
              const action =
                battle.actions[(battleState.actions.length ?? 0) + i];
              store.dispatch(action);
              if (action.type === useMove.type && initialized) {
                if (!action.payload.rolls?.paralyze) {
                  setMoveAnimationPlayed(false);
                  setPlayMoveAnimation(action.payload);
                  breakLoop = true;
                } else {
                  const pet = getPlayerData(
                    battleState,
                    action.payload.playerId,
                  ).petInfo;
                  alert({
                    duration: 3000,
                    title: translateText('paralyzedCannotMove', {
                      defaultMessage: `${getPetName(pet)} Paralyzed! Cannot move!`,
                      petName: getPetName(pet),
                    }),
                  });
                }
              } else if (action.type === useDodge.type && breakLoop) {
                // avoid to play to move in the row, this is to prevent to miss a move
                break;
              }
            }
          }
          setInitialized(true);
          setLoading(false);
        } else if (!initialized && battle.actions.length === 0) {
          setInitialized(true);
        } else if (
          (battle.actions?.length ?? 0) ===
            (battleState?.actions?.length ?? 0) &&
          battle.status === BattleStatus.ENDED &&
          !busyUi
        ) {
          setMode(BattleMode.ENDED);
        }
      }
    }
  }, [battle, busyUi]);

  usePrintMove();

  useEndTurn();

  const player = useMemo(
    () =>
      user?.uid &&
      battleState?.player1 &&
      battleState?.player2 &&
      getPlayerData(battleState, user.uid),
    [user, battleState],
  );

  return (
    <>
      {!battleId ? null : (
        <>
          {VITE_SHOW_BATTLE_INFO ? (
            <ToBeRemovedBattleInformation battleId={battleId} user={user} />
          ) : null}
          {battle?.status === BattleStatus.WAITING ? (
            <WaitForPlayerToJoin battle={battle} />
          ) : null}
          {battle && mode === BattleMode.BATTLE ? (
            <Battle player={player} battle={battle} />
          ) : null}
          {battle && mode === BattleMode.REPLAY ? (
            <BattleReplay battle={battle} />
          ) : null}
          {battle && mode === BattleMode.ENDED ? (
            <>
              <Center
                top={0}
                right={0}
                left={0}
                bottom={0}
                position="absolute"
                pointerEvents={'none'}
              >
                <BlinkingText>
                  {battleState.winner === user?.uid ? (
                    <TranslatedText
                      translationKey={`youWin`}
                      defaultMessage={`You win`}
                    />
                  ) : (
                    <TranslatedText
                      translationKey={`youLose`}
                      defaultMessage={`You lose`}
                    />
                  )}
                  !
                </BlinkingText>
              </Center>
              <BattleRewardsCard battle={battle} player={player} />
            </>
          ) : null}
        </>
      )}
    </>
  );
};
