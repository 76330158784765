import { Flex } from '@chakra-ui/react';
import { Energy } from '../ui/icons/Energy';
import { MailWhite } from '../ui/icons/MailWhite';
import { Ticket } from '../ui/icons/Ticket';
import { Text } from '../ui/atoms/Text';
import { useUserData } from '@/context/FirestoreContext';
import { EnergyPointsSummary } from 'genopets-utils';

export const ReferralInfo = ({
  energyPointsSummary,
}: {
  energyPointsSummary?: EnergyPointsSummary;
}) => {
  const { userData } = useUserData();

  return (
    <Flex
      justifyContent={`center`}
      alignItems={`center`}
      gap={`12px`}
      position={`absolute`}
      top={-8}
      left={0}
      right={0}
    >
      <Flex alignItems={`center`}>
        <Ticket />
        <Text colorId="White">{userData?.telegramTickets ?? '...'}</Text>
      </Flex>
      <Flex alignItems={`center`}>
        <Energy color={`#FFF`} />
        <Text colorId="White">
          {energyPointsSummary?.energyPoints ?? '...'}
        </Text>
      </Flex>
      <Flex alignItems={`center`}>
        <MailWhite />
        <Text colorId="White" ml={`4px`}>
          {energyPointsSummary?.referredUsersCount ?? '...'}
        </Text>
      </Flex>
    </Flex>
  );
};
