import { useEffect, type FC } from 'react';

import { BattleContainer } from '@/components/battle/BattleContainer';
import { BattleJoinErrorModal } from '@/components/battle/BattleJoinErrorModal';
import { BattleStore } from '@/components/battle/BattleStore';
import { UIBattleProvider } from '@/components/battle/context/useBattleContext';
import { getRandomBattleBg } from '@/images/battle-bg/getRandomBattleBg';
import { bgSounds, initialOptions } from '@/lib/sounds';
import { Box } from '@chakra-ui/react';
import { postEvent } from '@tma.js/sdk-react';
import useSound from 'use-sound';

export const BattlePage: FC = () => {
  useEffect(() => {
    postEvent(`web_app_setup_closing_behavior`, { need_confirmation: true });
  }, []);

  const [play, { stop, sound }] = useSound(bgSounds.battle, initialOptions);

  useEffect(() => {
    if (sound) {
      play();
      return () => stop();
    }
  }, [sound]);

  return (
    <BattleStore>
      <UIBattleProvider>
        <BattleJoinErrorModal />
        <Box w="full" position={'absolute'} left={0} right={0}>
          <img
            src={getRandomBattleBg()}
            alt="battle-background"
            style={{
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>

        <Box w="100vw" h="100lvh" minH="100lvh" padding={2}>
          <BattleContainer />
        </Box>
      </UIBattleProvider>
    </BattleStore>
  );
};
