import { useAlert } from '@/hooks/useAlert';
import { useFirestore } from '@/lib/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { Pet } from 'genopets-utils';
import { useEffect, useState } from 'react';
import { Unsubscribe } from 'redux';

const PETS_COLLECTION = `pets`;

export const useReadPetFromFirestore = ({ petId }: { petId?: string }) => {
  const [pet, setPet] = useState<Pet>();

  const db = useFirestore();
  const alert = useAlert();

  useEffect(() => {
    let unsub: Unsubscribe;
    if (db && petId) {
      unsub = onSnapshot(
        doc(db, PETS_COLLECTION, petId),
        (document) => {
          if (document.exists()) {
            const data = document.data() as Pet;

            setPet(new Pet(data));
          }
        },
        (error) => {
          console.error(error);
          alert({ title: error.message });
        },
      );
    }
    return () => {
      unsub?.();
    };
  }, [db, petId]);

  return { pet };
};
