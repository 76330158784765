// Importing each jpegs
import bg1 from './bg-1-min.jpg';
import bg2 from './bg-2-min.jpg';
import bg3 from './bg-3-min.jpg';
import bg4 from './bg-4-min.jpg';
import bg from './bg-min.jpg';

// Exporting all jpegs as an object
export default {
  bg1,
  bg2,
  bg3,
  bg4,
  bg,
};
