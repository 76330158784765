import { Box, Flex, FlexProps } from '@chakra-ui/react';
import { Card } from '../molecules/Card';
import { Text } from '../atoms/Text';
import { useCountdown } from '@/hooks/useCountdown';
import { Hand } from '../icons/Hand';

export const CommandCard = ({
  children,
  expiryTime,
  onComplete,
  printHand,
  altHand,
  ...props
}: {
  expiryTime?: number;
  onComplete?: () => void;
  printHand: boolean;
  altHand?: boolean;
} & FlexProps) => {
  const { seconds } = useCountdown(expiryTime, onComplete);

  return (
    <Flex flexDirection={'column'} {...props}>
      <Text w="full" textAlign="right">
        {seconds === undefined
          ? null
          : `0:${seconds < 10 ? '0' : ''}${seconds}`}
      </Text>
      <Card position="relative">
        {printHand || altHand ? (
          <Box
            className={altHand ? 'animate-poke-alt' : 'animate-poke'}
            position={'absolute'}
            top={'4px'}
            left={'-86px'}
            zIndex={2}
          >
            <Hand />
          </Box>
        ) : null}
        {children}
      </Card>
    </Flex>
  );
};
