import { BattlePlayerEntity } from 'genopets-utils';
import { MenuMoveItem } from './MenuMoveItem';
import { useState } from 'react';
import { Card } from '@/components/ui/molecules/Card';

export const MenuMoves = ({ player }: { player: BattlePlayerEntity }) => {
  const [selectedMove, setSelectedMove] = useState('');

  return (
    <>
      {player.break.level >= player.break.triggerLevel && player.break.move && (
        <Card
          width="calc(100% + 38px)"
          marginTop="-19px"
          marginLeft="-19px"
          colorId="Yellow"
        >
          <MenuMoveItem
            move={player.break.move}
            selected={selectedMove === player.break.move.name}
            onClick={(move) =>
              setSelectedMove(move === selectedMove ? '' : move)
            }
          />
        </Card>
      )}
      <MenuMoveItem
        move={player.minorMove}
        selected={selectedMove === player.minorMove.name}
        onClick={(move) => setSelectedMove(move === selectedMove ? '' : move)}
      />
      {player.moves.map((move) => {
        return (
          <MenuMoveItem
            key={move.name}
            move={move}
            cooldown={player.pet.cooldowns?.[move.name]}
            selected={selectedMove === move.name}
            onClick={(move) =>
              setSelectedMove(move === selectedMove ? '' : move)
            }
          />
        );
      })}
    </>
  );
};
