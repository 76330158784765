export const VITE_SHOW_BATTLE_INFO = import.meta.env.VITE_SHOW_BATTLE_INFO;

export const VITE_SHOW_LOCAL_SIGN_IN = import.meta.env.VITE_SHOW_LOCAL_SIGN_IN;

export const ENV = import.meta.env.VITE_VERCEL_ENV;

export const VITE_BATTLE_REPLAY_ENABLED =
  import.meta.env.VITE_BATTLE_REPLAY_ENABLED === 'true';

export const VITE_BATTLE_AIRDROP_ENABLED =
  import.meta.env.VITE_BATTLE_AIRDROP_ENABLED === 'true';

export const VITE_SHOW_WEEKLY_LEADERBOARD =
  import.meta.env.VITE_SHOW_WEEKLY_LEADERBOARD === 'true';

export const VITE_AIRDROP_TIME_OVERRIDE =
  import.meta.env.VITE_AIRDROP_TIME_OVERRIDE;
