import { MainContainer } from '@/components/battle/MainContainer';
import InitBackground from '../../images/bg-viral-loop-page.png';
import { ViralLoop } from '@/components/viralLoop/ViralLoop';
import { useState, useEffect } from 'react';
import { ReferralShare } from '@/components/viralLoop/ReferralShare';
import useSound from 'use-sound';
import { bgSounds, initialOptions } from '@/lib/sounds';

export const ViralLoopPage = () => {
  const [play, { stop, sound }] = useSound(
    bgSounds.viralLoopPage,
    initialOptions,
  );

  useEffect(() => {
    if (sound) {
      play();
      return () => stop();
    }
  }, [sound]);

  const [view, setView] = useState<'viral' | 'referral'>('viral');
  return (
    <MainContainer
      padding={0}
      background={InitBackground}
      backgroundPosition="top"
      backgroundSize="100% auto"
    >
      {view === 'viral' ? (
        <ViralLoop changeView={() => setView('referral')} />
      ) : (
        <ReferralShare changeView={() => setView('viral')} />
      )}
    </MainContainer>
  );
};
