import { Link } from '@/components/Link/Link.tsx';
import { Text } from '@/components/ui/atoms/Text';
import { Button } from '@/components/ui/molecules/Button';
import { Card } from '@/components/ui/molecules/Card';
import { useTelegramPet } from '@/context/FirestoreContext';
import { Box, Center, Flex } from '@chakra-ui/react';
import { getTelegramPetImage } from 'genopets-utils';
import { Pet, PetStats } from 'genopets-utils/src/pet';
import { useEffect, useState } from 'react';
import { Energy } from '../ui/icons/Energy';
import { BattleLevelBar } from './BattleLevelBar';
import { MenuChooseMoves } from './MenuChooseMoves/MenuChooseMoves';
import { RenameButton } from './RenameButton';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { useTranslation } from 'react-i18next';
import BgImage from '@/images/bg-pet-stats.png';

const Stats = ({ name, stats = 0 }: { name: string; stats?: number }) => {
  return (
    <Flex gap={1}>
      <Text colorId="Black">{`${name}:`}</Text>
      <Text colorId="Black">{stats}</Text>
    </Flex>
  );
};

export const PetStatistic = () => {
  const { t: translateText } = useTranslation();
  const { pet } = useTelegramPet();
  const [stats, setStats] = useState<PetStats>();

  useEffect(() => {
    if (pet) {
      const stat = new PetStats(new Pet(pet), new Map(), {
        telegramStats: true,
      });
      setStats(stat);
    }
  }, [pet]);

  return (
    <>
      <Flex
        w="full"
        h="full"
        flexDirection={'column'}
        justifyContent={'flex-end'}
      >
        {pet?.renderId && (
          <Center
            h="full"
            w="calc(100% + 32px)"
            margin="-16px -16px 0 -16px"
            overflow={'hidden'}
            position="relative"
          >
            <Box
              background={`url(${BgImage})`}
              backgroundRepeat={'no-repeat'}
              backgroundSize={'cover'}
              position="absolute"
              left="50%"
              bottom="0"
              width="800px"
              height="540px"
              transform={'translateX(-50%)'}
            />
            <img
              src={getTelegramPetImage(pet.petConfigV2)}
              alt="pet"
              style={{
                width: '200px',
                height: '200px',
                position: 'absolute',
                bottom: '0',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            />
          </Center>
        )}
        {pet && (
          <Card
            w="calc(100% + 32px)"
            flexDirection={'column'}
            gap={2}
            display={'flex'}
            margin="0 -16px -16px -16px"
          >
            <Flex justifyContent={'space-between'} w="full">
              <Text colorId="Black">{pet.nickname || pet.name}</Text>
              <Flex gap={1}>
                <Text colorId="Black">{pet.currentXp}</Text>
                <Energy color="black" />
              </Flex>
            </Flex>

            <Flex w="full" justifyContent={'space-between'}>
              <Flex flexDirection={'column'}>
                <Stats
                  name={translateText(`petStatTextHP`, {
                    defaultValue: 'HP',
                  })}
                  stats={stats?.hp}
                />
                <Stats
                  name={translateText(`petStatTextATK`, {
                    defaultValue: 'ATK',
                  })}
                  stats={stats?.atk}
                />
              </Flex>
              <Flex flexDirection={'column'}>
                <Stats
                  name={translateText(`petStatTextDEF`, {
                    defaultValue: 'DEF',
                  })}
                  stats={stats?.def}
                />
                <Stats
                  name={translateText(`petStatTextSPD`, {
                    defaultValue: 'SPD',
                  })}
                  stats={stats?.spd}
                />
              </Flex>
            </Flex>

            <BattleLevelBar pet={pet} energyRewarded={0} />

            <MenuChooseMoves pet={pet} />
            <RenameButton pet={pet} />
            <Link to="/home" style={{ width: '100%' }}>
              <Button colorId="Black" w="full">
                <TranslatedText
                  translationKey={`goHome`}
                  defaultMessage={`Go home`}
                />
              </Button>
            </Link>
          </Card>
        )}
      </Flex>
    </>
  );
};
