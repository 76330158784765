import useAPI from '@/hooks/useAPI';
import { USER_EMIT_EVENT_ENDPOINT } from '@/config/endpoints';
import { useUserData } from '@/context/FirestoreContext';
import { useCallback } from 'react';

export const useTrackEvent = () => {
  const { apiPost } = useAPI();
  const { userData } = useUserData();

  return useCallback(
    (eventName: string, eventData: Record<string, any> = {}) => {
      apiPost(USER_EMIT_EVENT_ENDPOINT, {
        userId: userData?.id,
        eventName,
        eventData,
      });
    },
    [apiPost, userData?.id],
  );
};
