import { useNavigate } from 'react-router-dom';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { Button } from '../ui/molecules/Button';
import { Gift } from '../ui/icons/Gift';
import { Flex } from '@chakra-ui/react';
import { useTrackEvent } from './hooks/useTrackEvent';

export const FeelingLuckyButton = () => {
  const trackEvent = useTrackEvent();
  const navigate = useNavigate();
  return (
    <Button
      className="color-box"
      border="3px solid var(--Black)"
      color="var(--Black)"
      padding="10px"
      onClick={() => {
        trackEvent(`telegramFeelingLuckyButtonClicked`, {});
        navigate('/spinner');
      }}
    >
      <Flex gap={1} alignItems={'center'}>
        <Gift />
        <TranslatedText
          translationKey={'spinToEarn'}
          defaultMessage={'Spin to earn'}
        />
        !
      </Flex>
    </Button>
  );
};
