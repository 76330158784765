import { Box, BoxProps, Center, CenterProps } from '@chakra-ui/react';
import WhittakrImg from '../../../images/whittakr-no-margin.png';

export const Whittakr = ({
  children,
  textBoxProps,
  imgStyle,
  triangleProps,
  ...props
}: {
  children?: BoxProps['children'];
  textBoxProps?: BoxProps;
  triangleProps?: BoxProps;
  imgStyle?: BoxProps['style'];
} & CenterProps) => {
  return (
    <Center position={'relative'} mt={4} {...props}>
      <img
        src={WhittakrImg}
        alt="Witakar"
        style={{ width: '120px', position: 'relative', ...imgStyle }}
      />
      <Box
        background={`var(--Black)`}
        position={'absolute'}
        top={'-30px'}
        left="25px"
        padding={1}
        {...textBoxProps}
      >
        {children}

        <Box
          width={0}
          height={0}
          borderLeft="20px solid transparent"
          borderRight="20px solid transparent"
          borderTop="20px solid black"
          position={'absolute'}
          right={'20%'}
          {...triangleProps}
        />
      </Box>
    </Center>
  );
};
