import { Box, BoxProps } from '@chakra-ui/react';
import { ColorId } from '../colors';

export const Text = ({
  children,
  colorId = 'White',
  ...props
}: { colorId?: ColorId } & BoxProps) => {
  return (
    <Box
      color={`var(--${colorId})`}
      fontFamily="'Press Start 2P'"
      fontSize="14px"
      fontWeight={400}
      lineHeight="20px"
      textTransform={'uppercase'}
      {...props}
    >
      {children}
    </Box>
  );
};
