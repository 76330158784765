// IMPORTANT: do not destucture any vars from the process.env object.  This is because Vercel will not substitute the env vars correctly.
/* eslint-disable prefer-destructuring */
/* eslint-disable no-global-assign */
// To make compatible with storybook.
// Using the commented approach right below doesn't allow Vercel to substitute env vars correctly.
// const env = process?.env || {};
if (!globalThis?.process?.env) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  globalThis.process = { env: {} };
}

const NEXT_PUBLIC_VERCEL_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV;
const NODE_ENV = process.env.NODE_ENV;
const NEXT_PUBLIC_GENE_TOKEN_MINT = process.env.NEXT_PUBLIC_GENE_TOKEN_MINT;
const NEXT_PUBLIC_TREASURE_HUNT_ADDRESS =
  process.env.NEXT_PUBLIC_TREASURE_HUNT_ADDRESS;
const NEXT_PUBLIC_STAKING_ADDRESS = process.env.NEXT_PUBLIC_STAKING_ADDRESS;
const NEXT_PUBLIC_HABITAT_MINTING_ADDRESS =
  process.env.NEXT_PUBLIC_HABITAT_MINTING_ADDRESS;
const NEXT_PUBLIC_SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const NEXT_PUBLIC_SOLANA_NETWORK = process.env.NEXT_PUBLIC_SOLANA_NETWORK;
const NEXT_PUBLIC_KI_TOKEN_MINT = process.env.NEXT_PUBLIC_KI_TOKEN_MINT;
const NEXT_PUBLIC_KI_TOKEN_OFFERING_ACCOUNT =
  process.env.NEXT_PUBLIC_KI_TOKEN_OFFERING_ACCOUNT;
const NEXT_PUBLIC_WATER_TOKEN_MINT = process.env.NEXT_PUBLIC_WATER_TOKEN_MINT;
const NEXT_PUBLIC_HABITAT_MANAGEMENT_ADDRESS =
  process.env.NEXT_PUBLIC_HABITAT_MANAGEMENT_ADDRESS;
const NEXT_PUBLIC_WATER_CRYSTAL_ADDRESS =
  process.env.NEXT_PUBLIC_WATER_CRYSTAL_ADDRESS;
const NEXT_PUBLIC_FIRE_CRYSTAL_ADDRESS =
  process.env.NEXT_PUBLIC_FIRE_CRYSTAL_ADDRESS;
const NEXT_PUBLIC_WOOD_CRYSTAL_ADDRESS =
  process.env.NEXT_PUBLIC_WOOD_CRYSTAL_ADDRESS;
const NEXT_PUBLIC_EARTH_CRYSTAL_ADDRESS =
  process.env.NEXT_PUBLIC_EARTH_CRYSTAL_ADDRESS;
const NEXT_PUBLIC_METAL_CRYSTAL_ADDRESS =
  process.env.NEXT_PUBLIC_METAL_CRYSTAL_ADDRESS;
const NEXT_PUBLIC_SGENE_TOKEN_MINT = process.env.NEXT_PUBLIC_SGENE_TOKEN_MINT;
const NEXT_PUBLIC_CRAFTING_MANAGER_ADDRESS =
  process.env.NEXT_PUBLIC_CRAFTING_MANAGER_ADDRESS;
const NEXT_PUBLIC_EAR_REAGENT_ADDRESS =
  process.env.NEXT_PUBLIC_EAR_REAGENT_ADDRESS;
const NEXT_PUBLIC_WING_REAGENT_ADDRESS =
  process.env.NEXT_PUBLIC_WING_REAGENT_ADDRESS;
const NEXT_PUBLIC_HORN_REAGENT_ADDRESS =
  process.env.NEXT_PUBLIC_HORN_REAGENT_ADDRESS;
const NEXT_PUBLIC_TERRAFORM_SEED_GRADE_1_ADDRESS =
  process.env.NEXT_PUBLIC_TERRAFORM_SEED_GRADE_1_ADDRESS;
const NEXT_PUBLIC_TERRAFORM_SEED_GRADE_2_ADDRESS =
  process.env.NEXT_PUBLIC_TERRAFORM_SEED_GRADE_2_ADDRESS;
const NEXT_PUBLIC_TERRAFORM_SEED_GRADE_3_ADDRESS =
  process.env.NEXT_PUBLIC_TERRAFORM_SEED_GRADE_3_ADDRESS;
const NEXT_PUBLIC_TERRAFORM_SEED_GRADE_4_ADDRESS =
  process.env.NEXT_PUBLIC_TERRAFORM_SEED_GRADE_4_ADDRESS;
const NEXT_PUBLIC_TERRAFORM_SEED_GRADE_5_ADDRESS =
  process.env.NEXT_PUBLIC_TERRAFORM_SEED_GRADE_5_ADDRESS;
const NEXT_PUBLIC_TERRAFORM_SEED_GRADE_6_ADDRESS =
  process.env.NEXT_PUBLIC_TERRAFORM_SEED_GRADE_6_ADDRESS;
const NEXT_PUBLIC_GENOVERSE_NFT_PORTAL_ADDRESS =
  process.env.NEXT_PUBLIC_GENOVERSE_NFT_PORTAL_ADDRESS;
const NEXT_PUBLIC_MARKETPLACE_WRAPPER_ADDRESS =
  process.env.NEXT_PUBLIC_MARKETPLACE_WRAPPER_ADDRESS;

export const RPC_HOST = process.env.NEXT_PUBLIC_SOLANA_RPC_HOST;

export const CDN_HOST = `https://cdn.genopets.me`;

export const RENDER_GENOPET_EVENT_NAME = `render-genopet`;

// environment types = [`local`, `preview`, `development`, `staging`, `production`];

const devEnv = [`local`, `preview`, `development`];

// production & production like envs:  [`staging`, `production`];

const mainframeEnv = NEXT_PUBLIC_VERCEL_ENV;
const apiEnv = NODE_ENV;

export const currentEnv = mainframeEnv ?? apiEnv;

export const isEnvProduction = currentEnv === `production`;
export const isEnvProductionOrDev =
  currentEnv === `production` || currentEnv === `development`;

export const GENE_TOKEN_MINT = isEnvProduction
  ? `GENEtH5amGSi8kHAtQoezp1XEXwZJ8vcuePYnXdKrMYz`
  : `GENEFwqpFZWERFqKodmQ1sVSd3ygVge8H7zYdvjuFNGy`;

export const SGENE_TOKEN_MINT =
  NEXT_PUBLIC_SGENE_TOKEN_MINT ||
  `GkpbHQu2zYmJxyp93p9wTX3uHjsFt8ZGeomVwZkGwXLH`;

export const KI_TOKEN_OFFERING_ACCOUNT =
  NEXT_PUBLIC_KI_TOKEN_OFFERING_ACCOUNT ||
  `Eyt55eXUJjSH24s9neXPudNT3sc7tkpePAzPJw7Kjdgg`;

export const KI_TOKEN_MINT =
  NEXT_PUBLIC_KI_TOKEN_MINT || `kiGenopAScF8VF31Zbtx2Hg8qA5ArGqvnVtXb83sotc`;

export const USDC_TOKEN_MINT = isEnvProduction
  ? `EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v`
  : `4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU`;

export const WATER_TOKEN_MINT =
  NEXT_PUBLIC_WATER_TOKEN_MINT ||
  `4JjHY4nK4AawoKdaCXyBspUYGXXqUgyC23N7oWcAq5zA`;

export const WATER_CRYSTAL_ADDRESS =
  NEXT_PUBLIC_WATER_CRYSTAL_ADDRESS ||
  `WATErpZ2ZBjgAxyttoEjckuTuCe9pEckSabCeENLTYq`;

export const FIRE_CRYSTAL_ADDRESS =
  NEXT_PUBLIC_FIRE_CRYSTAL_ADDRESS ||
  `FireKR7LgjyzjsLnxaNZwa7dnJncDSidD4cXGhTGz2eU`;

export const WOOD_CRYSTAL_ADDRESS =
  NEXT_PUBLIC_WOOD_CRYSTAL_ADDRESS ||
  `woodN5KSiHEAhaCrZVh3vScGta7u6r5Vp3UbqDFuD4e`;

export const EARTH_CRYSTAL_ADDRESS =
  NEXT_PUBLIC_EARTH_CRYSTAL_ADDRESS ||
  `EaRtHRxHp1ftdfnJFds9UrCDNaSGxhdnRUucevNr1DzA`;

export const METAL_CRYSTAL_ADDRESS =
  NEXT_PUBLIC_METAL_CRYSTAL_ADDRESS ||
  `Meta1cQ29N8S4cSwJScHZYtXV6J5Cy55oEA8vRVhh8K`;

export const TERRAFORM_SEED_GRADE_1_ADDRESS =
  NEXT_PUBLIC_TERRAFORM_SEED_GRADE_1_ADDRESS ||
  `9XSc83dUz9jReYEfnTfP4QvPXeXDytNHSUMQS8yFPrSL`;

export const TERRAFORM_SEED_GRADE_2_ADDRESS =
  NEXT_PUBLIC_TERRAFORM_SEED_GRADE_2_ADDRESS ||
  `4XRxBL6874hNkdJ6cpAup2ebLdc967dFG764hauHJDve`;

export const TERRAFORM_SEED_GRADE_3_ADDRESS =
  NEXT_PUBLIC_TERRAFORM_SEED_GRADE_3_ADDRESS ||
  `8LH5tTDumu1BDCTWiSfjaezzBfgocpnUjWm2Xh4dw5wM`;

export const TERRAFORM_SEED_GRADE_4_ADDRESS =
  NEXT_PUBLIC_TERRAFORM_SEED_GRADE_4_ADDRESS ||
  `F81gUbyuZvejhf8gEB1yLbET9Qqr6EwtGxZ4AzwetVRW`;

export const TERRAFORM_SEED_GRADE_5_ADDRESS =
  NEXT_PUBLIC_TERRAFORM_SEED_GRADE_5_ADDRESS ||
  `HLPT6EcNmCPxEyw4bLNV12io6UmDwEtmGnL8Wsik2RhD`;

export const TERRAFORM_SEED_GRADE_6_ADDRESS =
  NEXT_PUBLIC_TERRAFORM_SEED_GRADE_6_ADDRESS ||
  `Eobok6bgM6RYMCaXDD6j73wmjR4oC3yafP4Yrswh6Rs9`;

export const HORN_REAGENT_ADDRESS = NEXT_PUBLIC_HORN_REAGENT_ADDRESS || `HORN`;

export const WING_REAGENT_ADDRESS = NEXT_PUBLIC_WING_REAGENT_ADDRESS || `WING`;

export const EAR_REAGENT_ADDRESS = NEXT_PUBLIC_EAR_REAGENT_ADDRESS || `EAR`;

export const TREASURE_HUNT_ADDRESS =
  NEXT_PUBLIC_TREASURE_HUNT_ADDRESS ||
  `huntVk4a8JKqNm3yYQTJJPWJgcWyhEEyVtZ4Kh14jSb`;

export const STAKING_ADDRESS =
  NEXT_PUBLIC_STAKING_ADDRESS || `StaKe9nb7aUjXpjpZ45o6uJBsZxj2BWCDBtjk8LCg2v`;

export const HABITAT_MINTING_ADDRESS =
  NEXT_PUBLIC_HABITAT_MINTING_ADDRESS ||
  `HABitaBFxaU7wtLuSX6zdtDfz4uvoJ1NUvhHoeDsyrwn`;

export const HABITAT_MANAGEMENT_ADDRESS =
  NEXT_PUBLIC_HABITAT_MANAGEMENT_ADDRESS ||
  `HAbiTatJVqoCJd9asyr6RxMEdwtfrQugwp7VAFyKWb1g`;

export const GENOVERSE_NFT_PORTAL_ADDRESS =
  NEXT_PUBLIC_GENOVERSE_NFT_PORTAL_ADDRESS ||
  `nftpSLhPxHPX6ZE8uaArJJeBND5YN3JyQ4iFKDtTXiq`;

export const CRAFTING_MANAGER_ADDRESS =
  NEXT_PUBLIC_CRAFTING_MANAGER_ADDRESS ||
  `CrAFTUv7zKXBaS5471aCwHx7mq9Jp1eQQB5FQgdiSLyi`;

export const MARKETPLACE_WRAPPER_ADDRESS =
  NEXT_PUBLIC_MARKETPLACE_WRAPPER_ADDRESS ||
  `MrkTtX78X1M2zLaDTm8LdzYSUKGBD6QuVWfKxc4Z9Jz`;

export const TOKEN_PROGRAM_ID = `TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA`;

export const SENTRY_DSN = NEXT_PUBLIC_SENTRY_DSN;

export const SOLANA_VIEW_KEY = new Uint8Array(
  Buffer.from(process.env.SOLANA_VIEW_KEY ?? ``, `base64`),
);

export const SOLANA_NETWORK =
  NEXT_PUBLIC_SOLANA_NETWORK ||
  (devEnv.find((i) => currentEnv === i) ? `devnet` : `mainnet-beta`);

export const LEVELS_PER_STAGE = 7;

// we're using this variable in landing, which is written in gatsby & env var prefix is different with next
// https://github.com/Genopets/genopets-web/pull/2338
export const ONE_SIGNAL_APP_ID =
  (currentEnv || process.env.GATSBY_VERCEL_ENV) === `production`
    ? `4127be32-455a-416e-9a7c-80b6f6e5c92a`
    : // This is the app id for 'genopets-staging' which allows https://tesoterra.genopets.me to be used as the site url.
      `2e46f516-ad90-42d4-b6df-64dc66fe06cd`;

const ZEROBOUNCE_API_KEY =
  process.env.GATSBY_ZEROBOUNCE_API_KEY || `b6682f14b60846cebd3043fda5a9ccf8`;

export const ZEROBOUNCE_ENDPOINT = (email: string) =>
  `https://api.zerobounce.net/v2/validate?api_key=${ZEROBOUNCE_API_KEY}&email=${email}&ip_address=`;

// Only bitcoin babies have this color
export const BITCOIN_BABY_COLOR = `FFBE00`;

export const TYPESENSE_COLLECTIONS = {
  PETS: `pets`,
  STEP_BATTLES: `step-battles`,
  USERS: `users`,
};

export const QUBIT_TO_USD_RATE = 4000;

export const PROD_TEST_USER_ID = `4vnbEWJUWpbD5VIKBijcyrtKqU42`;

export const CLOUD_TASK_USER_ID = `CLOUD_TASK_USER_ID`;

export const LEADERBOARD_PAGE_LIMIT = 4;
export const LEADERBOARD_PAGE_SIZE = 25;

export const TELEGRAM_LEADERBOARD_PAGE_LIMIT = 5; // top 500
export const TELEGRAM_LEADERBOARD_PAGE_SIZE = 100;

export const EXPIRY_BATTLE_MESSAGE = `The battle has expired you cannot join anymore`;

export const NO_BATTLE_MESSAGE = `The battle does not exist`;

export const TELEGRAM_REFERRAL_CODE_NOTCOIN = `XeJ9zQCn`;
export const TELEGRAM_REFERRAL_CODE_BCOIN_2048 = `212Jzi1pw`;

// partners whose referral code conversion event will be triggered on battle completion
export const TELEGRAM_REFERRAL_PARTNERS_ON_BATTLE_COMPLETED = [
  TELEGRAM_REFERRAL_CODE_BCOIN_2048,
];
// partners whose referral code conversion event will be triggered on user creation
export const TELEGRAM_REFERRAL_PARTNERS_ON_CODE_REDEEMED = [
  TELEGRAM_REFERRAL_CODE_NOTCOIN,
];
