import { Link } from '@/components/Link/Link';
import { Text } from '@/components/ui/atoms/Text';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Next } from '@/components/ui/icons/Next';
import { Ticket } from '@/components/ui/icons/Ticket';
import { Button } from '@/components/ui/molecules/Button';
import {
  TELEGRAM_TASKS_ENDPOINT,
  UPDATE_USER_TELEGRAM_TASK_RECORD_ENDPOINT,
  USER_EMIT_EVENT_ENDPOINT,
  USER_ENERGY_POINTS_SUMMARY,
} from '@/config/endpoints';
import { useUserData } from '@/context/FirestoreContext';
import {
  TaskVerificationProvider,
  useTaskVerification,
} from '@/context/TaskContext';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { EnergyPointsSummary, TelegramTask } from 'genopets-utils';
import { useCallback, useEffect, useState } from 'react';
import { Energy } from '../ui/icons/Energy';
import { BuyTicketsModal } from './BuyTicketsModal';
import { ReferralInfo } from './ReferalInfo';
import { ReferralData } from './ReferralData';

const TaskItem = ({
  task,
  mutateEnergyPointsSummary,
  isCompleted,
}: {
  task: TelegramTask;
  isCompleted: boolean;
  mutateEnergyPointsSummary: any;
}) => {
  const { apiPut } = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const { processTask, verifyTask } = useTaskVerification();
  const { userData } = useUserData();

  const updateTaskCompletion = async () => {
    await apiPut(
      UPDATE_USER_TELEGRAM_TASK_RECORD_ENDPOINT,
      {
        telegramTaskId: task.id,
      },
      true,
    ).finally(() => {
      mutateEnergyPointsSummary();
      setIsLoading(false);
    });
  };

  const completeTask = async () => {
    try {
      setIsLoading(true);

      // if user has already connected their ton wallet, we simply mark this task as completed
      if (task.id === 'connecttonwallet-t20yWz07' && userData?.tonWallet) {
        await updateTaskCompletion();
      } else {
        processTask(task.link);

        // if this task does not require verification
        // for example tasks where we cannot verify the user has actually done
        // we mark this task as completed ourselves
        if (!task.verificationPath) {
          await updateTaskCompletion();
        } else {
          // Set up visibility change listener for verification
          const handleVisibilityChange = () => {
            if (!document.hidden) {
              // once user returns to the app, we verify the task
              verifyTask(task.link).then((result: boolean) => {
                if (result) {
                  updateTaskCompletion();
                  window.removeEventListener('focus', handleVisibilityChange);
                }
              });
            }
          };
          window.addEventListener('focus', handleVisibilityChange);
        }
      }
    } catch (error: any) {
      console.error(error);
      alert({ title: error?.message ?? 'Error' });
    }
  };

  return (
    <Button
      colorId={`White`}
      padding={`6px 4px`}
      w={`full`}
      isDisabled={isLoading || isCompleted}
      onClick={completeTask}
    >
      <Flex w={`full`} justifyContent={'space-between'}>
        <Text colorId="Black" whiteSpace={`initial`} textAlign={`left`}>
          {isLoading ? `validating...` : task.name}
        </Text>
        <Flex alignItems={`center`} justifyContent={`flex-end`}>
          <Ticket />
          <Text colorId="Black" w="full" width={`auto`}>
            {task.tickets}
          </Text>
          <Next color={`var(--Black)`} />
        </Flex>
      </Flex>
    </Button>
  );
};

const Tasks = ({
  mutateEnergyPointsSummary,
  userTelegramTaskData,
}: {
  mutateEnergyPointsSummary: () => void;
  userTelegramTaskData?: Record<string, boolean>;
}) => {
  const { apiGet } = useAPI();
  const alert = useAlert();

  const [tasks, setTasks] = useState<undefined | TelegramTask[]>();

  useEffect(() => {
    if (!apiGet) return;

    if (!tasks) {
      apiGet(TELEGRAM_TASKS_ENDPOINT, true)
        .then((data) => {
          setTasks(data);
        })
        .catch((error: any) => {
          console.error(error);
          alert({ title: error?.message ?? 'Error' });
        });
    }
  }, [apiGet]);

  return (
    <Box
      borderTop={`3px solid black`}
      paddingTop={`4px`}
      h="full"
      display={'flex'}
      flexDirection={'column'}
      overflow={'hidden'}
    >
      <Text colorId="Black" w="full" paddingLeft={`16px`}>
        <TranslatedText translationKey={`tasks`} defaultMessage={`Tasks`} />
      </Text>

      <Box h="full" overflow={'auto'} px={2}>
        <Box position={'relative'} w="full">
          {!tasks || !userTelegramTaskData ? (
            <Text colorId="Black" w="full">
              <TranslatedText
                translationKey={`loading`}
                defaultMessage={`Loading`}
              />
              ...
            </Text>
          ) : (
            <TaskVerificationProvider>
              {tasks
                ?.sort((a: TelegramTask, b: TelegramTask) => {
                  return a.position - b.position;
                })
                // not completed tasks should be on the list first
                ?.sort((a: TelegramTask, b: TelegramTask) => {
                  const aCompleted = userTelegramTaskData?.[a.id] ?? false;
                  const bCompleted = userTelegramTaskData?.[b.id] ?? false;

                  // only move completed tasks to the end, retain their relative order
                  if (aCompleted && !bCompleted) return 1;
                  if (!aCompleted && bCompleted) return -1;
                  return 0;
                })
                ?.map((task: TelegramTask) => {
                  return (
                    <TaskItem
                      mutateEnergyPointsSummary={mutateEnergyPointsSummary}
                      key={task.id}
                      task={task}
                      isCompleted={userTelegramTaskData?.[task.id] ?? false}
                    />
                  );
                })}
            </TaskVerificationProvider>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const BuyTickets = () => {
  const { userData } = useUserData();
  const [isLoading, setIsLoading] = useState(false);
  const { apiPost } = useAPI();

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        // there's no event when telegram IAP modal is closed ie when user cancelled the IAP modal
        // so adding this to enable the buttons after few seconds
        setIsLoading(false);
      }, 5000);
    }
  }, [isLoading]);

  return (
    <Box padding={`8px 8px 4px`}>
      <BuyTicketsModal isOpen={isOpen} onClose={onClose} />
      <Button
        colorId="Black"
        w="full"
        onClick={() => {
          onOpen();

          apiPost(USER_EMIT_EVENT_ENDPOINT, {
            userId: userData?.id,
            eventName: `telegramBuyTicketsClicked`,
            eventData: {},
          });
        }}
      >
        <TranslatedText
          translationKey={`buyTickets`}
          defaultMessage={`Buy tickets`}
          count={1}
        />
      </Button>
    </Box>
  );
};

export const ViralLoop = ({ changeView }: { changeView: () => void }) => {
  const { apiGet } = useAPI();

  const alert = useAlert();

  const [energyPointsSummary, setEnergyPointsSummary] =
    useState<EnergyPointsSummary>();

  const mutateEnergyPointsSummary = useCallback(() => {
    if (!apiGet) return;
    return apiGet(USER_ENERGY_POINTS_SUMMARY + `?isTelegram=true`, true)
      .then((data) => {
        setEnergyPointsSummary(data);
      })
      .catch((error: any) => {
        console.error(error);
        alert({ title: error?.message ?? 'Error' });
      });
  }, [apiGet]);

  useEffect(() => {
    if (!apiGet) return;

    mutateEnergyPointsSummary();
  }, [apiGet]);

  return (
    <Flex
      background={`var(--White)`}
      flexDirection={'column'}
      position={'absolute'}
      bottom={0}
      maxHeight={'calc(100% - 36px)'}
    >
      <ReferralInfo energyPointsSummary={energyPointsSummary} />
      <Box padding={`12px 16px 0px 16px`}>
        <Text colorId={`Black`}>
          <TranslatedText
            translationKey={`getTicketsToBattle`}
            defaultMessage={`Get Tickets to Battle for Airdrops`}
          />
          !
        </Text>
        <Box mt={1}>
          <Text colorId={`Black`} as="span">
            <TranslatedText translationKey={`earn`} defaultMessage={`Earn`} />
          </Text>
          <Box
            display={'inline-block'}
            position={'relative'}
            mx={1}
            width={'24px'}
            height={'24px'}
          >
            <Energy
              color="var(--Black)"
              style={{ position: 'absolute', top: '4px', left: 0 }}
            />
          </Box>
          <Text colorId={`Black`} as="span">
            <TranslatedText
              translationKey={`forInvitingFrens`}
              defaultMessage={`for inviting frens`}
            />
          </Text>
        </Box>
      </Box>
      <BuyTickets />
      <ReferralData
        referredUsersCount={energyPointsSummary?.referredUsersCount}
        referralPoints={energyPointsSummary?.referralPoints}
        changeView={changeView}
      />
      <Tasks
        userTelegramTaskData={energyPointsSummary?.telegramTask}
        mutateEnergyPointsSummary={mutateEnergyPointsSummary}
      />
      <Box padding={`8px 8px 4px 8px`}>
        <Link to="/home" style={{ width: '100%' }}>
          <Button colorId="Black" w="full">
            <TranslatedText
              translationKey={`goHome`}
              defaultMessage={`Go Home`}
            />
          </Button>
        </Link>
      </Box>
    </Flex>
  );
};
