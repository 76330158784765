import { omit, cloneDeep } from 'lodash';
import { BattleState, RawBattleState } from '../models/battle-state';

export function withHistory(
  state: Partial<BattleState>,
  newState: BattleState,
) {
  if (state.withHistory && newState.history) {
    newState.history.push(omit(cloneDeep(state), `history`) as RawBattleState);
  }

  return newState;
}
