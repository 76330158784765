import { Box, Flex } from '@chakra-ui/react';
import { Text } from '../atoms/Text';
import { Bar } from './Bar';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';

export const LevelBar = ({
  from,
  to,
  level,
}: {
  to: string;
  from: string;
  level: number;
}) => {
  return (
    <Box w="full">
      <Bar fromProgress={from || '0'} toProgress={to || '0'} />
      <Flex justifyContent={'space-between'} w="full">
        <Text colorId="Black">
          <TranslatedText translationKey={`textLvl`} defaultMessage={`Lvl`} />
          {` ${level}`}
        </Text>
        <Text colorId="Black">
          <TranslatedText translationKey={`textLvl`} defaultMessage={`Lvl`} />
          {` ${level + 1}`}
        </Text>
      </Flex>
    </Box>
  );
};
