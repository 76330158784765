export const StarYellow = ({ ...props }) => {
  return (
    <svg
      width="172"
      height="172"
      viewBox="0 0 172 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.2748 23.5616H23.5625V28.274H28.2748V23.5616Z"
        fill="#896A1B"
      />
      <path
        d="M32.9867 23.5616H28.2744V28.274H32.9867V23.5616Z"
        fill="#593C02"
      />
      <path
        d="M37.6987 23.5616H32.9863V28.274H37.6987V23.5616Z"
        fill="#593C02"
      />
      <path
        d="M28.2748 28.274H23.5625V32.9863H28.2748V28.274Z"
        fill="#FFC000"
      />
      <path
        d="M23.5619 28.274H18.8496V32.9863H23.5619V28.274Z"
        fill="#896A1B"
      />
      <path
        d="M32.9867 28.274H28.2744V32.9863H32.9867V28.274Z"
        fill="#FFC000"
      />
      <path
        d="M37.6987 28.274H32.9863V32.9863H37.6987V28.274Z"
        fill="#CC9600"
      />
      <path
        d="M42.4106 28.274H37.6982V32.9863H42.4106V28.274Z"
        fill="#896A1B"
      />
      <path
        d="M28.2748 32.9863H23.5625V37.6986H28.2748V32.9863Z"
        fill="#FFC000"
      />
      <path
        d="M23.5619 32.9863H18.8496V37.6986H23.5619V32.9863Z"
        fill="#593C02"
      />
      <path
        d="M32.9867 32.9863H28.2744V37.6986H32.9867V32.9863Z"
        fill="#F1FF34"
      />
      <path
        d="M37.6987 32.9863H32.9863V37.6986H37.6987V32.9863Z"
        fill="#CC9600"
      />
      <path
        d="M42.4106 32.9863H37.6982V37.6986H42.4106V32.9863Z"
        fill="#896A1B"
      />
      <path
        d="M28.2748 37.6986H23.5625V42.411H28.2748V37.6986Z"
        fill="#CC9600"
      />
      <path
        d="M23.5619 37.6986H18.8496V42.411H23.5619V37.6986Z"
        fill="#593C02"
      />
      <path
        d="M32.9867 37.6986H28.2744V42.411H32.9867V37.6986Z"
        fill="#CC9600"
      />
      <path
        d="M37.6987 37.6986H32.9863V42.411H37.6987V37.6986Z"
        fill="#FFC000"
      />
      <path
        d="M42.4106 37.6986H37.6982V42.411H42.4106V37.6986Z"
        fill="#7F5B00"
      />
      <path
        d="M28.2748 42.4109H23.5625V47.1233H28.2748V42.4109Z"
        fill="#896A1B"
      />
      <path
        d="M32.9867 42.4109H28.2744V47.1233H32.9867V42.4109Z"
        fill="#896A1B"
      />
      <path
        d="M37.6987 42.4109H32.9863V47.1233H37.6987V42.4109Z"
        fill="#7F5B00"
      />
      <path
        d="M42.4106 42.4109H37.6982V47.1233H42.4106V42.4109Z"
        fill="#7F5B00"
      />
      <path
        d="M47.1244 37.6986H42.4121V42.411H47.1244V37.6986Z"
        fill="#896A1B"
      />
      <path
        d="M47.1244 42.4109H42.4121V47.1233H47.1244V42.4109Z"
        fill="#896A1B"
      />
      <path
        d="M37.6987 47.1233H32.9863V51.8356H37.6987V47.1233Z"
        fill="#896A1B"
      />
      <path
        d="M42.4106 47.1233H37.6982V51.8356H42.4106V47.1233Z"
        fill="#896A1B"
      />
      <path
        d="M47.1244 47.1233H42.4121V51.8356H47.1244V47.1233Z"
        fill="#7F5B00"
      />
      <path
        d="M51.8364 47.1233H47.124V51.8356H51.8364V47.1233Z"
        fill="#896A1B"
      />
      <path
        d="M47.1244 51.8356H42.4121V56.548H47.1244V51.8356Z"
        fill="#896A1B"
      />
      <path
        d="M51.8364 51.8356H47.124V56.548H51.8364V51.8356Z"
        fill="#593C02"
      />
      <path
        d="M56.5483 56.5479H51.8359V61.2603H56.5483V56.5479Z"
        fill="#593C02"
      />
      <path
        d="M61.2612 61.2603H56.5488V65.9726H61.2612V61.2603Z"
        fill="#593C02"
      />
      <path
        d="M70.685 70.6849H65.9727V75.3973H70.685V70.6849Z"
        fill="#593C02"
      />
      <path
        d="M28.2748 143.726H23.5625V148.438H28.2748V143.726Z"
        fill="#896A1B"
      />
      <path
        d="M32.9867 143.726H28.2744V148.438H32.9867V143.726Z"
        fill="#593C02"
      />
      <path
        d="M37.6987 143.726H32.9863V148.438H37.6987V143.726Z"
        fill="#593C02"
      />
      <path
        d="M28.2748 139.014H23.5625V143.726H28.2748V139.014Z"
        fill="#FFC000"
      />
      <path
        d="M23.5619 139.014H18.8496V143.726H23.5619V139.014Z"
        fill="#896A1B"
      />
      <path
        d="M32.9867 139.014H28.2744V143.726H32.9867V139.014Z"
        fill="#FFC000"
      />
      <path
        d="M37.6987 139.014H32.9863V143.726H37.6987V139.014Z"
        fill="#CC9600"
      />
      <path
        d="M42.4106 139.014H37.6982V143.726H42.4106V139.014Z"
        fill="#896A1B"
      />
      <path
        d="M28.2748 134.301H23.5625V139.014H28.2748V134.301Z"
        fill="#FFC000"
      />
      <path
        d="M23.5619 134.301H18.8496V139.014H23.5619V134.301Z"
        fill="#593C02"
      />
      <path
        d="M32.9867 134.301H28.2744V139.014H32.9867V134.301Z"
        fill="#F1FF34"
      />
      <path
        d="M37.6987 134.301H32.9863V139.014H37.6987V134.301Z"
        fill="#CC9600"
      />
      <path
        d="M42.4106 134.301H37.6982V139.014H42.4106V134.301Z"
        fill="#896A1B"
      />
      <path
        d="M28.2748 129.589H23.5625V134.301H28.2748V129.589Z"
        fill="#CC9600"
      />
      <path
        d="M23.5619 129.589H18.8496V134.301H23.5619V129.589Z"
        fill="#593C02"
      />
      <path
        d="M32.9867 129.589H28.2744V134.301H32.9867V129.589Z"
        fill="#CC9600"
      />
      <path
        d="M37.6987 129.589H32.9863V134.301H37.6987V129.589Z"
        fill="#FFC000"
      />
      <path
        d="M42.4106 129.589H37.6982V134.301H42.4106V129.589Z"
        fill="#7F5B00"
      />
      <path
        d="M28.2748 124.877H23.5625V129.589H28.2748V124.877Z"
        fill="#896A1B"
      />
      <path
        d="M32.9867 124.877H28.2744V129.589H32.9867V124.877Z"
        fill="#896A1B"
      />
      <path
        d="M37.6987 124.877H32.9863V129.589H37.6987V124.877Z"
        fill="#7F5B00"
      />
      <path
        d="M42.4106 124.877H37.6982V129.589H42.4106V124.877Z"
        fill="#7F5B00"
      />
      <path
        d="M47.1244 129.589H42.4121V134.301H47.1244V129.589Z"
        fill="#896A1B"
      />
      <path
        d="M47.1244 124.877H42.4121V129.589H47.1244V124.877Z"
        fill="#896A1B"
      />
      <path
        d="M37.6987 120.164H32.9863V124.877H37.6987V120.164Z"
        fill="#896A1B"
      />
      <path
        d="M42.4106 120.164H37.6982V124.877H42.4106V120.164Z"
        fill="#896A1B"
      />
      <path
        d="M47.1244 120.164H42.4121V124.877H47.1244V120.164Z"
        fill="#7F5B00"
      />
      <path
        d="M51.8364 120.164H47.124V124.877H51.8364V120.164Z"
        fill="#896A1B"
      />
      <path
        d="M47.1244 115.452H42.4121V120.164H47.1244V115.452Z"
        fill="#896A1B"
      />
      <path
        d="M51.8364 115.452H47.124V120.164H51.8364V115.452Z"
        fill="#593C02"
      />
      <path
        d="M56.5483 110.74H51.8359V115.452H56.5483V110.74Z"
        fill="#593C02"
      />
      <path
        d="M61.2612 106.027H56.5488V110.74H61.2612V106.027Z"
        fill="#593C02"
      />
      <path
        d="M70.685 96.6027H65.9727V101.315H70.685V96.6027Z"
        fill="#593C02"
      />
      <path
        d="M143.726 148.438H148.438V143.726H143.726V148.438Z"
        fill="#896A1B"
      />
      <path
        d="M139.013 148.438H143.726V143.726H139.013V148.438Z"
        fill="#593C02"
      />
      <path
        d="M134.301 148.438H139.014V143.726H134.301V148.438Z"
        fill="#593C02"
      />
      <path
        d="M143.726 143.726H148.438V139.014H143.726V143.726Z"
        fill="#FFC000"
      />
      <path
        d="M148.438 143.726H153.15V139.014H148.438V143.726Z"
        fill="#896A1B"
      />
      <path
        d="M139.013 143.726H143.726V139.014H139.013V143.726Z"
        fill="#FFC000"
      />
      <path
        d="M134.301 143.726H139.014V139.014H134.301V143.726Z"
        fill="#CC9600"
      />
      <path
        d="M129.589 143.726H134.302V139.014H129.589V143.726Z"
        fill="#896A1B"
      />
      <path
        d="M143.726 139.014H148.438V134.301H143.726V139.014Z"
        fill="#FFC000"
      />
      <path
        d="M148.438 139.014H153.15V134.301H148.438V139.014Z"
        fill="#593C02"
      />
      <path
        d="M139.013 139.014H143.726V134.301H139.013V139.014Z"
        fill="#F1FF34"
      />
      <path
        d="M134.301 139.014H139.014V134.301H134.301V139.014Z"
        fill="#CC9600"
      />
      <path
        d="M129.589 139.014H134.302V134.301H129.589V139.014Z"
        fill="#896A1B"
      />
      <path
        d="M143.726 134.301H148.438V129.589H143.726V134.301Z"
        fill="#CC9600"
      />
      <path
        d="M148.438 134.301H153.15V129.589H148.438V134.301Z"
        fill="#593C02"
      />
      <path
        d="M139.013 134.301H143.726V129.589H139.013V134.301Z"
        fill="#CC9600"
      />
      <path
        d="M134.301 134.301H139.014V129.589H134.301V134.301Z"
        fill="#FFC000"
      />
      <path
        d="M129.589 134.301H134.302V129.589H129.589V134.301Z"
        fill="#7F5B00"
      />
      <path
        d="M143.726 129.589H148.438V124.877H143.726V129.589Z"
        fill="#896A1B"
      />
      <path
        d="M139.013 129.589H143.726V124.877H139.013V129.589Z"
        fill="#896A1B"
      />
      <path
        d="M134.301 129.589H139.014V124.877H134.301V129.589Z"
        fill="#7F5B00"
      />
      <path
        d="M129.589 129.589H134.302V124.877H129.589V129.589Z"
        fill="#7F5B00"
      />
      <path
        d="M124.878 134.301H129.59V129.589H124.878V134.301Z"
        fill="#896A1B"
      />
      <path
        d="M124.878 129.589H129.59V124.877H124.878V129.589Z"
        fill="#896A1B"
      />
      <path
        d="M134.301 124.877H139.014V120.164H134.301V124.877Z"
        fill="#896A1B"
      />
      <path
        d="M129.589 124.877H134.302V120.164H129.589V124.877Z"
        fill="#896A1B"
      />
      <path
        d="M124.878 124.877H129.59V120.164H124.878V124.877Z"
        fill="#7F5B00"
      />
      <path
        d="M120.165 124.877H124.877V120.164H120.165V124.877Z"
        fill="#896A1B"
      />
      <path
        d="M124.878 120.164H129.59V115.452H124.878V120.164Z"
        fill="#896A1B"
      />
      <path
        d="M120.165 120.164H124.877V115.452H120.165V120.164Z"
        fill="#593C02"
      />
      <path
        d="M115.452 115.452H120.164V110.74H115.452V115.452Z"
        fill="#593C02"
      />
      <path d="M110.74 110.74H115.452V106.027H110.74V110.74Z" fill="#593C02" />
      <path
        d="M101.315 101.315H106.027V96.6027H101.315V101.315Z"
        fill="#593C02"
      />
      <path
        d="M143.726 28.274H148.438V23.5617H143.726V28.274Z"
        fill="#896A1B"
      />
      <path
        d="M139.013 28.274H143.726V23.5617H139.013V28.274Z"
        fill="#593C02"
      />
      <path
        d="M134.301 28.274H139.014V23.5617H134.301V28.274Z"
        fill="#593C02"
      />
      <path
        d="M143.726 32.9863H148.438V28.274H143.726V32.9863Z"
        fill="#FFC000"
      />
      <path
        d="M148.438 32.9863H153.15V28.274H148.438V32.9863Z"
        fill="#896A1B"
      />
      <path
        d="M139.013 32.9863H143.726V28.274H139.013V32.9863Z"
        fill="#FFC000"
      />
      <path
        d="M134.301 32.9863H139.014V28.274H134.301V32.9863Z"
        fill="#CC9600"
      />
      <path
        d="M129.589 32.9863H134.302V28.274H129.589V32.9863Z"
        fill="#896A1B"
      />
      <path
        d="M143.726 37.6986H148.438V32.9863H143.726V37.6986Z"
        fill="#FFC000"
      />
      <path
        d="M148.438 37.6986H153.15V32.9863H148.438V37.6986Z"
        fill="#593C02"
      />
      <path
        d="M139.013 37.6986H143.726V32.9863H139.013V37.6986Z"
        fill="#F1FF34"
      />
      <path
        d="M134.301 37.6986H139.014V32.9863H134.301V37.6986Z"
        fill="#CC9600"
      />
      <path
        d="M129.589 37.6986H134.302V32.9863H129.589V37.6986Z"
        fill="#896A1B"
      />
      <path
        d="M143.726 42.4109H148.438V37.6986H143.726V42.4109Z"
        fill="#CC9600"
      />
      <path
        d="M148.438 42.4109H153.15V37.6986H148.438V42.4109Z"
        fill="#593C02"
      />
      <path
        d="M139.013 42.4109H143.726V37.6986H139.013V42.4109Z"
        fill="#CC9600"
      />
      <path
        d="M134.301 42.4109H139.014V37.6986H134.301V42.4109Z"
        fill="#FFC000"
      />
      <path
        d="M129.589 42.4109H134.302V37.6986H129.589V42.4109Z"
        fill="#7F5B00"
      />
      <path
        d="M143.726 47.1233H148.438V42.411H143.726V47.1233Z"
        fill="#896A1B"
      />
      <path
        d="M139.013 47.1233H143.726V42.411H139.013V47.1233Z"
        fill="#896A1B"
      />
      <path
        d="M134.301 47.1233H139.014V42.411H134.301V47.1233Z"
        fill="#7F5B00"
      />
      <path
        d="M129.589 47.1233H134.302V42.411H129.589V47.1233Z"
        fill="#7F5B00"
      />
      <path
        d="M124.878 42.4109H129.59V37.6986H124.878V42.4109Z"
        fill="#896A1B"
      />
      <path
        d="M124.878 47.1233H129.59V42.411H124.878V47.1233Z"
        fill="#896A1B"
      />
      <path
        d="M134.301 51.8356H139.014V47.1233H134.301V51.8356Z"
        fill="#896A1B"
      />
      <path
        d="M129.589 51.8356H134.302V47.1233H129.589V51.8356Z"
        fill="#896A1B"
      />
      <path
        d="M124.878 51.8356H129.59V47.1233H124.878V51.8356Z"
        fill="#7F5B00"
      />
      <path
        d="M120.165 51.8356H124.877V47.1233H120.165V51.8356Z"
        fill="#896A1B"
      />
      <path
        d="M124.878 56.5479H129.59V51.8356H124.878V56.5479Z"
        fill="#896A1B"
      />
      <path
        d="M120.165 56.5479H124.877V51.8356H120.165V56.5479Z"
        fill="#593C02"
      />
      <path
        d="M115.452 61.2603H120.164V56.548H115.452V61.2603Z"
        fill="#593C02"
      />
      <path
        d="M110.74 65.9726H115.452V61.2603H110.74V65.9726Z"
        fill="#593C02"
      />
      <path
        d="M101.315 75.3973H106.027V70.6849H101.315V75.3973Z"
        fill="#593C02"
      />
      <path
        d="M88.3559 61.2603H83.6436V65.9726H88.3559V61.2603Z"
        fill="#593C02"
      />
      <path
        d="M88.3559 56.5479H83.6436V61.2603H88.3559V56.5479Z"
        fill="#593C02"
      />
      <path
        d="M88.3559 51.8356H83.6436V56.548H88.3559V51.8356Z"
        fill="#593C02"
      />
      <path
        d="M88.3559 47.1233H83.6436V51.8356H88.3559V47.1233Z"
        fill="#593C02"
      />
      <path
        d="M88.3559 42.4109H83.6436V47.1233H88.3559V42.4109Z"
        fill="#593C02"
      />
      <path
        d="M88.3559 37.6986H83.6436V42.411H88.3559V37.6986Z"
        fill="#593C02"
      />
      <path
        d="M88.3559 32.9863H83.6436V37.6986H88.3559V32.9863Z"
        fill="#7F5B00"
      />
      <path
        d="M88.3559 28.274H83.6436V32.9863H88.3559V28.274Z"
        fill="#7F5B00"
      />
      <path
        d="M88.3559 23.5616H83.6436V28.274H88.3559V23.5616Z"
        fill="#7F5B00"
      />
      <path
        d="M88.3559 18.8493H83.6436V23.5616H88.3559V18.8493Z"
        fill="#7F5B00"
      />
      <path
        d="M88.3559 14.137H83.6436V18.8493H88.3559V14.137Z"
        fill="#FFC000"
      />
      <path
        d="M88.3559 9.42465H83.6436V14.137H88.3559V9.42465Z"
        fill="#F1FF34"
      />
      <path
        d="M88.3559 4.71234H83.6436V9.42467H88.3559V4.71234Z"
        fill="#FFC000"
      />
      <path d="M88.3559 0H83.6436V4.71233H88.3559V0Z" fill="#593C02" />
      <path
        d="M93.0688 37.6986H88.3564V42.411H93.0688V37.6986Z"
        fill="#593C02"
      />
      <path
        d="M93.0688 32.9863H88.3564V37.6986H93.0688V32.9863Z"
        fill="#593C02"
      />
      <path
        d="M93.0688 28.274H88.3564V32.9863H93.0688V28.274Z"
        fill="#593C02"
      />
      <path
        d="M93.0688 23.5616H88.3564V28.274H93.0688V23.5616Z"
        fill="#896A1B"
      />
      <path
        d="M93.0688 18.8493H88.3564V23.5616H93.0688V18.8493Z"
        fill="#CC9600"
      />
      <path
        d="M93.0688 14.137H88.3564V18.8493H93.0688V14.137Z"
        fill="#CC9600"
      />
      <path
        d="M93.0688 9.42465H88.3564V14.137H93.0688V9.42465Z"
        fill="#FFC000"
      />
      <path
        d="M93.0688 4.71234H88.3564V9.42467H93.0688V4.71234Z"
        fill="#CC9600"
      />
      <path d="M93.0688 0H88.3564V4.71233H93.0688V0Z" fill="#593C02" />
      <path
        d="M97.7817 23.5616H93.0693V28.274H97.7817V23.5616Z"
        fill="#593C02"
      />
      <path
        d="M97.7817 18.8493H93.0693V23.5616H97.7817V18.8493Z"
        fill="#593C02"
      />
      <path
        d="M97.7817 14.137H93.0693V18.8493H97.7817V14.137Z"
        fill="#7F5B00"
      />
      <path
        d="M97.7817 9.42465H93.0693V14.137H97.7817V9.42465Z"
        fill="#7F5B00"
      />
      <path
        d="M97.7817 4.71234H93.0693V9.42467H97.7817V4.71234Z"
        fill="#593C02"
      />
      <path d="M83.644 37.6986H78.9316V42.411H83.644V37.6986Z" fill="#593C02" />
      <path
        d="M83.644 32.9863H78.9316V37.6986H83.644V32.9863Z"
        fill="#593C02"
      />
      <path d="M83.644 28.274H78.9316V32.9863H83.644V28.274Z" fill="#593C02" />
      <path d="M83.644 23.5616H78.9316V28.274H83.644V23.5616Z" fill="#896A1B" />
      <path
        d="M83.644 18.8493H78.9316V23.5616H83.644V18.8493Z"
        fill="#CC9600"
      />
      <path d="M83.644 14.137H78.9316V18.8493H83.644V14.137Z" fill="#CC9600" />
      <path d="M83.644 9.42465H78.9316V14.137H83.644V9.42465Z" fill="#FFC000" />
      <path
        d="M83.644 4.71234H78.9316V9.42467H83.644V4.71234Z"
        fill="#CC9600"
      />
      <path d="M83.644 0H78.9316V4.71233H83.644V0Z" fill="#593C02" />
      <path
        d="M78.9311 23.5616H74.2188V28.274H78.9311V23.5616Z"
        fill="#593C02"
      />
      <path
        d="M78.9311 18.8493H74.2188V23.5616H78.9311V18.8493Z"
        fill="#593C02"
      />
      <path
        d="M78.9311 14.137H74.2188V18.8493H78.9311V14.137Z"
        fill="#7F5B00"
      />
      <path
        d="M78.9311 9.42465H74.2188V14.137H78.9311V9.42465Z"
        fill="#7F5B00"
      />
      <path
        d="M78.9311 4.71234H74.2188V9.42467H78.9311V4.71234Z"
        fill="#593C02"
      />
      <path
        d="M88.3559 106.027H83.6436V110.74H88.3559V106.027Z"
        fill="#593C02"
      />
      <path
        d="M88.3559 110.74H83.6436V115.452H88.3559V110.74Z"
        fill="#593C02"
      />
      <path
        d="M88.3559 115.452H83.6436V120.164H88.3559V115.452Z"
        fill="#593C02"
      />
      <path
        d="M88.3559 120.164H83.6436V124.877H88.3559V120.164Z"
        fill="#593C02"
      />
      <path
        d="M88.3559 124.877H83.6436V129.589H88.3559V124.877Z"
        fill="#593C02"
      />
      <path
        d="M88.3559 129.589H83.6436V134.301H88.3559V129.589Z"
        fill="#593C02"
      />
      <path
        d="M88.3559 134.301H83.6436V139.014H88.3559V134.301Z"
        fill="#7F5B00"
      />
      <path
        d="M88.3559 139.014H83.6436V143.726H88.3559V139.014Z"
        fill="#7F5B00"
      />
      <path
        d="M88.3559 143.726H83.6436V148.438H88.3559V143.726Z"
        fill="#7F5B00"
      />
      <path
        d="M88.3559 148.438H83.6436V153.151H88.3559V148.438Z"
        fill="#7F5B00"
      />
      <path
        d="M88.3559 153.151H83.6436V157.863H88.3559V153.151Z"
        fill="#FFC000"
      />
      <path
        d="M88.3559 157.863H83.6436V162.575H88.3559V157.863Z"
        fill="#F1FF34"
      />
      <path
        d="M88.3559 162.575H83.6436V167.288H88.3559V162.575Z"
        fill="#FFC000"
      />
      <path d="M88.3559 167.288H83.6436V172H88.3559V167.288Z" fill="#593C02" />
      <path
        d="M93.0688 129.589H88.3564V134.301H93.0688V129.589Z"
        fill="#593C02"
      />
      <path
        d="M93.0688 134.301H88.3564V139.014H93.0688V134.301Z"
        fill="#593C02"
      />
      <path
        d="M93.0688 139.014H88.3564V143.726H93.0688V139.014Z"
        fill="#593C02"
      />
      <path
        d="M93.0688 143.726H88.3564V148.438H93.0688V143.726Z"
        fill="#896A1B"
      />
      <path
        d="M93.0688 148.438H88.3564V153.151H93.0688V148.438Z"
        fill="#CC9600"
      />
      <path
        d="M93.0688 153.151H88.3564V157.863H93.0688V153.151Z"
        fill="#CC9600"
      />
      <path
        d="M93.0688 157.863H88.3564V162.575H93.0688V157.863Z"
        fill="#FFC000"
      />
      <path
        d="M93.0688 162.575H88.3564V167.288H93.0688V162.575Z"
        fill="#CC9600"
      />
      <path d="M93.0688 167.288H88.3564V172H93.0688V167.288Z" fill="#593C02" />
      <path
        d="M97.7817 143.726H93.0693V148.438H97.7817V143.726Z"
        fill="#593C02"
      />
      <path
        d="M97.7817 148.438H93.0693V153.151H97.7817V148.438Z"
        fill="#593C02"
      />
      <path
        d="M97.7817 153.151H93.0693V157.863H97.7817V153.151Z"
        fill="#7F5B00"
      />
      <path
        d="M97.7817 157.863H93.0693V162.575H97.7817V157.863Z"
        fill="#7F5B00"
      />
      <path
        d="M97.7817 162.575H93.0693V167.288H97.7817V162.575Z"
        fill="#593C02"
      />
      <path
        d="M83.644 129.589H78.9316V134.301H83.644V129.589Z"
        fill="#593C02"
      />
      <path
        d="M83.644 134.301H78.9316V139.014H83.644V134.301Z"
        fill="#593C02"
      />
      <path
        d="M83.644 139.014H78.9316V143.726H83.644V139.014Z"
        fill="#593C02"
      />
      <path
        d="M83.644 143.726H78.9316V148.438H83.644V143.726Z"
        fill="#896A1B"
      />
      <path
        d="M83.644 148.438H78.9316V153.151H83.644V148.438Z"
        fill="#CC9600"
      />
      <path
        d="M83.644 153.151H78.9316V157.863H83.644V153.151Z"
        fill="#CC9600"
      />
      <path
        d="M83.644 157.863H78.9316V162.575H83.644V157.863Z"
        fill="#FFC000"
      />
      <path
        d="M83.644 162.575H78.9316V167.288H83.644V162.575Z"
        fill="#CC9600"
      />
      <path d="M83.644 167.288H78.9316V172H83.644V167.288Z" fill="#593C02" />
      <path
        d="M78.9311 143.726H74.2188V148.438H78.9311V143.726Z"
        fill="#593C02"
      />
      <path
        d="M78.9311 148.438H74.2188V153.151H78.9311V148.438Z"
        fill="#593C02"
      />
      <path
        d="M78.9311 153.151H74.2188V157.863H78.9311V153.151Z"
        fill="#7F5B00"
      />
      <path
        d="M78.9311 157.863H74.2188V162.575H78.9311V157.863Z"
        fill="#7F5B00"
      />
      <path
        d="M78.9311 162.575H74.2188V167.288H78.9311V162.575Z"
        fill="#593C02"
      />
      <path
        d="M110.739 88.3562V83.6438H106.027V88.3562H110.739Z"
        fill="#593C02"
      />
      <path
        d="M115.452 88.3562V83.6438H110.74V88.3562H115.452Z"
        fill="#593C02"
      />
      <path
        d="M120.164 88.3562V83.6438H115.452V88.3562H120.164Z"
        fill="#593C02"
      />
      <path
        d="M124.877 88.3562V83.6438H120.165V88.3562H124.877Z"
        fill="#593C02"
      />
      <path
        d="M129.59 88.3562V83.6438H124.878V88.3562H129.59Z"
        fill="#593C02"
      />
      <path
        d="M134.302 88.3562V83.6438H129.589V88.3562H134.302Z"
        fill="#593C02"
      />
      <path
        d="M139.014 88.3562V83.6438H134.301V88.3562H139.014Z"
        fill="#7F5B00"
      />
      <path
        d="M143.726 88.3562V83.6438H139.013V88.3562H143.726Z"
        fill="#7F5B00"
      />
      <path
        d="M148.438 88.3562V83.6438H143.726V88.3562H148.438Z"
        fill="#7F5B00"
      />
      <path
        d="M153.15 88.3562V83.6438H148.438V88.3562H153.15Z"
        fill="#7F5B00"
      />
      <path
        d="M157.863 88.3562V83.6438H153.151V88.3562H157.863Z"
        fill="#FFC000"
      />
      <path
        d="M162.576 88.3562V83.6438H157.864V88.3562H162.576Z"
        fill="#F1FF34"
      />
      <path
        d="M167.288 88.3562V83.6438H162.576V88.3562H167.288Z"
        fill="#FFC000"
      />
      <path d="M172 88.3562V83.6438H167.288V88.3562H172Z" fill="#593C02" />
      <path
        d="M134.302 93.0685V88.3562H129.589V93.0685H134.302Z"
        fill="#593C02"
      />
      <path
        d="M139.014 93.0685V88.3562H134.301V93.0685H139.014Z"
        fill="#593C02"
      />
      <path
        d="M143.726 93.0685V88.3562H139.013V93.0685H143.726Z"
        fill="#593C02"
      />
      <path
        d="M148.438 93.0685V88.3562H143.726V93.0685H148.438Z"
        fill="#896A1B"
      />
      <path
        d="M153.15 93.0685V88.3562H148.438V93.0685H153.15Z"
        fill="#CC9600"
      />
      <path
        d="M157.863 93.0685V88.3562H153.151V93.0685H157.863Z"
        fill="#CC9600"
      />
      <path
        d="M162.576 93.0685V88.3562H157.864V93.0685H162.576Z"
        fill="#FFC000"
      />
      <path
        d="M167.288 93.0685V88.3562H162.576V93.0685H167.288Z"
        fill="#CC9600"
      />
      <path d="M172 93.0685V88.3562H167.288V93.0685H172Z" fill="#593C02" />
      <path
        d="M148.438 97.7808V93.0685H143.726V97.7808H148.438Z"
        fill="#593C02"
      />
      <path
        d="M153.15 97.7808V93.0685H148.438V97.7808H153.15Z"
        fill="#593C02"
      />
      <path
        d="M157.863 97.7808V93.0685H153.151V97.7808H157.863Z"
        fill="#7F5B00"
      />
      <path
        d="M162.576 97.7808V93.0685H157.864V97.7808H162.576Z"
        fill="#7F5B00"
      />
      <path
        d="M167.288 97.7808V93.0685H162.576V97.7808H167.288Z"
        fill="#593C02"
      />
      <path
        d="M134.302 83.6438V78.9315H129.589V83.6438H134.302Z"
        fill="#593C02"
      />
      <path
        d="M139.014 83.6438V78.9315H134.301V83.6438H139.014Z"
        fill="#593C02"
      />
      <path
        d="M143.726 83.6438V78.9315H139.013V83.6438H143.726Z"
        fill="#593C02"
      />
      <path
        d="M148.438 83.6438V78.9315H143.726V83.6438H148.438Z"
        fill="#896A1B"
      />
      <path
        d="M153.15 83.6438V78.9315H148.438V83.6438H153.15Z"
        fill="#CC9600"
      />
      <path
        d="M157.863 83.6438V78.9315H153.151V83.6438H157.863Z"
        fill="#CC9600"
      />
      <path
        d="M162.576 83.6438V78.9315H157.864V83.6438H162.576Z"
        fill="#FFC000"
      />
      <path
        d="M167.288 83.6438V78.9315H162.576V83.6438H167.288Z"
        fill="#CC9600"
      />
      <path d="M172 83.6438V78.9315H167.288V83.6438H172Z" fill="#593C02" />
      <path
        d="M148.438 78.9315V74.2192H143.726V78.9315H148.438Z"
        fill="#593C02"
      />
      <path
        d="M153.15 78.9315V74.2192H148.438V78.9315H153.15Z"
        fill="#593C02"
      />
      <path
        d="M157.863 78.9315V74.2192H153.151V78.9315H157.863Z"
        fill="#7F5B00"
      />
      <path
        d="M162.576 78.9315V74.2192H157.864V78.9315H162.576Z"
        fill="#7F5B00"
      />
      <path
        d="M167.288 78.9315V74.2192H162.576V78.9315H167.288Z"
        fill="#593C02"
      />
      <path
        d="M65.9727 88.3562V83.6438H61.2603V88.3562H65.9727Z"
        fill="#593C02"
      />
      <path
        d="M61.2607 88.3562V83.6438H56.5484V88.3562H61.2607Z"
        fill="#593C02"
      />
      <path
        d="M56.5488 88.3562V83.6438H51.8365V88.3562H56.5488Z"
        fill="#593C02"
      />
      <path
        d="M51.8359 88.3562V83.6438H47.1236V88.3562H51.8359Z"
        fill="#593C02"
      />
      <path
        d="M47.124 88.3562V83.6438H42.4117V88.3562H47.124Z"
        fill="#593C02"
      />
      <path
        d="M42.4121 88.3562V83.6438H37.6998V88.3562H42.4121Z"
        fill="#593C02"
      />
      <path
        d="M37.6982 88.3562V83.6438H32.9859V88.3562H37.6982Z"
        fill="#7F5B00"
      />
      <path
        d="M32.9863 88.3562V83.6438H28.274V88.3562H32.9863Z"
        fill="#7F5B00"
      />
      <path
        d="M28.2744 88.3562V83.6438H23.5621V88.3562H28.2744Z"
        fill="#7F5B00"
      />
      <path
        d="M23.5625 88.3562V83.6438H18.8502V88.3562H23.5625Z"
        fill="#7F5B00"
      />
      <path
        d="M18.8496 88.3562V83.6438H14.1373V88.3562H18.8496Z"
        fill="#FFC000"
      />
      <path
        d="M14.1367 88.3562V83.6438H9.42439V88.3562H14.1367Z"
        fill="#F1FF34"
      />
      <path
        d="M9.4248 88.3562V83.6438H4.71248V88.3562H9.4248Z"
        fill="#FFC000"
      />
      <path
        d="M4.71191 88.3562V83.6438H-0.000414848V88.3562H4.71191Z"
        fill="#593C02"
      />
      <path
        d="M42.4121 93.0685V88.3562H37.6998V93.0685H42.4121Z"
        fill="#593C02"
      />
      <path
        d="M37.6982 93.0685V88.3562H32.9859V93.0685H37.6982Z"
        fill="#593C02"
      />
      <path
        d="M32.9863 93.0685V88.3562H28.274V93.0685H32.9863Z"
        fill="#593C02"
      />
      <path
        d="M28.2744 93.0685V88.3562H23.5621V93.0685H28.2744Z"
        fill="#896A1B"
      />
      <path
        d="M23.5625 93.0685V88.3562H18.8502V93.0685H23.5625Z"
        fill="#CC9600"
      />
      <path
        d="M18.8496 93.0685V88.3562H14.1373V93.0685H18.8496Z"
        fill="#CC9600"
      />
      <path
        d="M14.1367 93.0685V88.3562H9.42439V93.0685H14.1367Z"
        fill="#FFC000"
      />
      <path
        d="M9.4248 93.0685V88.3562H4.71248V93.0685H9.4248Z"
        fill="#CC9600"
      />
      <path
        d="M4.71191 93.0685V88.3562H-0.000414848V93.0685H4.71191Z"
        fill="#593C02"
      />
      <path
        d="M28.2744 97.7808V93.0685H23.5621V97.7808H28.2744Z"
        fill="#593C02"
      />
      <path
        d="M23.5625 97.7808V93.0685H18.8502V97.7808H23.5625Z"
        fill="#593C02"
      />
      <path
        d="M18.8496 97.7808V93.0685H14.1373V97.7808H18.8496Z"
        fill="#7F5B00"
      />
      <path
        d="M14.1367 97.7808V93.0685H9.42439V97.7808H14.1367Z"
        fill="#7F5B00"
      />
      <path
        d="M9.4248 97.7808V93.0685H4.71248V97.7808H9.4248Z"
        fill="#593C02"
      />
      <path
        d="M42.4121 83.6438V78.9315H37.6998V83.6438H42.4121Z"
        fill="#593C02"
      />
      <path
        d="M37.6982 83.6438V78.9315H32.9859V83.6438H37.6982Z"
        fill="#593C02"
      />
      <path
        d="M32.9863 83.6438V78.9315H28.274V83.6438H32.9863Z"
        fill="#593C02"
      />
      <path
        d="M28.2744 83.6438V78.9315H23.5621V83.6438H28.2744Z"
        fill="#896A1B"
      />
      <path
        d="M23.5625 83.6438V78.9315H18.8502V83.6438H23.5625Z"
        fill="#CC9600"
      />
      <path
        d="M18.8496 83.6438V78.9315H14.1373V83.6438H18.8496Z"
        fill="#CC9600"
      />
      <path
        d="M14.1367 83.6438V78.9315H9.42439V83.6438H14.1367Z"
        fill="#FFC000"
      />
      <path
        d="M9.4248 83.6438V78.9315H4.71248V83.6438H9.4248Z"
        fill="#CC9600"
      />
      <path
        d="M4.71191 83.6438V78.9315H-0.000414848V83.6438H4.71191Z"
        fill="#593C02"
      />
      <path
        d="M28.2744 78.9315V74.2192H23.5621V78.9315H28.2744Z"
        fill="#593C02"
      />
      <path
        d="M23.5625 78.9315V74.2192H18.8502V78.9315H23.5625Z"
        fill="#593C02"
      />
      <path
        d="M18.8496 78.9315V74.2192H14.1373V78.9315H18.8496Z"
        fill="#7F5B00"
      />
      <path
        d="M14.1367 78.9315V74.2192H9.42439V78.9315H14.1367Z"
        fill="#7F5B00"
      />
      <path
        d="M9.4248 78.9315V74.2192H4.71248V78.9315H9.4248Z"
        fill="#593C02"
      />
    </svg>
  );
};
