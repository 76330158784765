import { MenuItemPop } from '@/components/ui/molecules/MenuItemPop';
import { useAlert } from '@/hooks/useAlert';
import { getPetName } from '@/utils/utils';
import { Flex, FlexProps, useDisclosure } from '@chakra-ui/react';
import * as dateFns from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import {
  BattleEntity,
  BattleState,
  BattleStatus,
  RootState,
  getPlayerData,
  store,
  undo,
  useMove,
} from 'genopets-utils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../ui/atoms/Text';
import { TranslatedText } from '../../ui/atoms/TranslatedText';
import { Button } from '../../ui/molecules/Button';
import { Card } from '../../ui/molecules/Card';
import { Modal } from '../../ui/molecules/Modal';
import { useUIBattleContext } from '../context/useBattleContext';
import { ReplayBattleStateContent } from './ReplayBattleStateModalContent';

export const ReplayControls = ({
  battle,
  ...props
}: { battle: BattleEntity } & FlexProps) => {
  const { busyUi, setMoveAnimationPlayed, setPlayMoveAnimation } =
    useUIBattleContext();

  const battleState = useSelector<RootState>(
    (state: RootState) => state.battle,
  ) as BattleState;

  const alert = useAlert();

  const navigate = useNavigate();

  const { onOpen, isOpen, onClose } = useDisclosure();

  const {
    onOpen: onOpenStateModal,
    isOpen: isOpenStateModal,
    onClose: onCloseStateModal,
  } = useDisclosure();

  const startDate =
    battle && battle.startTime && (battle.startTime as Timestamp).toDate();
  const endDate =
    battle && battle.endTime && (battle.endTime as Timestamp).toDate();

  const battleDuration =
    battle &&
    startDate &&
    endDate &&
    dateFns.intervalToDuration({
      start: startDate,
      end: endDate,
    });

  return (
    <Flex flexDirection={'column'} w="full" {...props}>
      <Modal isOpen={isOpenStateModal} onClose={onCloseStateModal}>
        <ReplayBattleStateContent battleState={battleState} />
      </Modal>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Flex flexDirection="column" w="full">
          <Text
            colorId="Black"
            w="full"
          >{`Actions Replayed: ${battleState.actions.length}`}</Text>
          <Text colorId="Black" w="full">{`Actions To Replay: ${
            battle && battle.actions.length - battleState.actions.length
          }`}</Text>
          <Text
            colorId="Black"
            w="full"
            mt={2}
            borderTop={'3px solid var(--Black)'}
            pt={2}
          >{`Battle Total Time:`}</Text>
          <Text colorId="Black" w="full">{`${
            battleDuration ? dateFns.formatDuration(battleDuration) : `unknown`
          }`}</Text>
          <Text colorId="Black" w="full" mt={2}>{`Battle Start Time:`}</Text>
          <Text colorId="Black" w="full">{`${
            startDate
              ? (battle.startTime as Timestamp).toDate().toUTCString()
              : `unknown`
          }`}</Text>
          <Text colorId="Black" w="full" mt={2}>{`Battle End Time:`}</Text>
          <Text colorId="Black" w="full">{`${
            endDate
              ? (battle.endTime as Timestamp).toDate().toUTCString()
              : `unknown`
          }`}</Text>
          <Text
            colorId="Black"
            w="full"
            mt={2}
            pt={2}
            borderTop={'3px solid var(--Black)'}
          >{`Battle Status: `}</Text>

          <MenuItemPop
            onClick={() => onOpenStateModal()}
            color="var(--Black)"
            w="full"
          >
            {`${battle ? battle.status : `unknown`}`}
          </MenuItemPop>
        </Flex>
      </Modal>
      <Card>
        <Button
          width="full"
          colorId="White"
          variant={'secondary'}
          disabled={!battleState.history?.length || busyUi}
          minW={0}
          onClick={() => {
            onOpen();
          }}
        >
          {`Info`}
        </Button>
        <Button
          width="full"
          colorId="Black"
          disabled={!battleState.history?.length || busyUi}
          minW={0}
          onClick={() => {
            if (battle) {
              const prev = battleState.history?.length;
              if (!prev) {
                alert({
                  title: `No more actions to undo`,
                });
                return;
              }
              store.dispatch(undo());
            }
          }}
        >
          {`undo action`}
        </Button>
        <Button
          width="full"
          isDisabled={
            busyUi || battleState.actions.length === battle.actions.length
          }
          minW={0}
          onClick={() => {
            if (battle) {
              const nextActionIndex = battleState.actions.length;
              const action = battle.actions[nextActionIndex];
              if (!action) {
                alert({
                  title: `No more actions to replay`,
                });
                return;
              }
              store.dispatch(action);
              if (action.type === useMove.type) {
                if (!action.payload.rolls?.paralyze) {
                  setMoveAnimationPlayed(false);
                  setPlayMoveAnimation(action.payload);
                } else {
                  const pet = getPlayerData(
                    battleState,
                    action.payload.playerId,
                  ).petInfo;
                  alert({
                    duration: 3000,
                    title: `${getPetName(pet)} Paralyzed! Cannot move!`,
                  });
                }
                // also dispatch the dodge action or the ui will not be able to play all element of the move
                const dodgeAction = battle.actions[nextActionIndex + 1];
                if (!dodgeAction) {
                  alert({
                    title: `No more actions to replay`,
                  });
                  return;
                }
                store.dispatch(dodgeAction);
              }
            }
          }}
        >
          {battleState.status === BattleStatus.ENDED ? `End` : `next action`}
        </Button>
        <Button
          onClick={() => navigate('/home')}
          width="full"
          colorId={'White'}
          minW={0}
        >
          <TranslatedText
            translationKey={`goHome`}
            defaultMessage={`Go Home`}
          />
        </Button>
      </Card>
    </Flex>
  );
};
