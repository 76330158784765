import { AIRDROP_PRIZES_ENDPOINT } from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { TelegramAirdropEntity } from 'genopets-utils';
import { useEffect, useState } from 'react';

export type TierInfo = { amount: number; position: number }[];

export type Tier = Record<
  string,
  {
    color: string;
    isUniformPrize: boolean;
    information: TierInfo;
  }
>;

export const useAirdropData = () => {
  const [amount, setAmount] = useState<number>();
  const [prizes, setPrizes] = useState<Tier>();

  const { apiGet } = useAPI();

  useEffect(() => {
    if (typeof amount === 'undefined') {
      apiGet(AIRDROP_PRIZES_ENDPOINT).then((resp: any) => {
        if (resp) {
          setAmount(
            (resp.data as TelegramAirdropEntity[]).reduce((acc, curr) => {
              return acc + curr.prizeAmount;
            }, 0),
          );
          const tiers: Tier = (resp.data as TelegramAirdropEntity[])
            .sort((a, b) => a.rank - b.rank)
            .reduce((acc, curr) => {
              if (!acc[curr.tier]) {
                acc[curr.tier] = {
                  color: curr.color,
                  isUniformPrize: true,
                  information: [
                    {
                      amount: curr.prizeAmount,
                      position: curr.rank,
                    },
                  ],
                };
              } else {
                acc[curr.tier].information.push({
                  amount: curr.prizeAmount,
                  position: curr.rank,
                });
                acc[curr.tier].isUniformPrize = acc[
                  curr.tier
                ].information.every(
                  (inf) => inf.amount === acc[curr.tier].information[0].amount,
                );
              }
              return acc;
            }, {} as Tier);
          setPrizes(tiers);
        }
      });
    }
  }, [apiGet]);

  return {
    amount,
    prizes,
  };
};
