import { MainContainer } from '@/components/battle/MainContainer';
import { PetLevelUp } from '@/components/battle/PetLevelUp';
import { bgSounds, initialOptions } from '@/lib/sounds';
import { useEffect } from 'react';
import useSound from 'use-sound';

export const BattleLevelUpPage = () => {
  const [play, { stop, sound }] = useSound(bgSounds.summon, initialOptions);

  useEffect(() => {
    if (sound) {
      play();
      return () => stop();
    }
  }, [sound]);

  return (
    <MainContainer>
      <PetLevelUp />
    </MainContainer>
  );
};
