import { DateTime } from 'luxon';
import { UserData } from '../types';
import { ONE_MINUTE_IN_MS } from './time.constants';

/**
 * Returns the current date in a DateTime object
 * If no timezone is passed, it's UTC by default
 * @param timezone
 */
export const now = (timezone?: string): DateTime =>
  timezone ? DateTime.now().setZone(timezone) : DateTime.now();

/**
 * Returns the current date in ISO format YYYY-MM-DD
 * If no timezone is passed, it's UTC by default
 * @param timezone
 */
export const nowISO = (timezone?: string): string =>
  (timezone ? now(timezone).toISODate() : now().toISODate()) as string;

/**
 * Returns the midnight timestamp in milliseconds using timezone
 * If no timezone is passed, it's UTC by default
 * @param timezone
 */
export const midnightTzMsInUTC = (timezone?: string): number =>
  now(timezone).endOf(`day`).toUTC().toMillis();

/**
 * Explanation:
 * - Date.now() returns the current timestamp in milliseconds since the Unix epoch (January 1, 1970 00:00:00 UTC).
 * - Dividing the timestamp by 60000 (the number of milliseconds in a minute) gives us the number of minutes since the epoch.
 * - Rounding this number down using Math.floor() gives us the number of the current minute.
 * - Multiplying this number by 60000 again gives us the timestamp of the start of the current minute, rounded down to the nearest minute.
 *
 * @param timestamp
 */
export function getTimeToNearestFlooredMinute(
  timestamp: number = Date.now(),
): number {
  return Math.floor(timestamp / ONE_MINUTE_IN_MS) * ONE_MINUTE_IN_MS;
}

export function isDateTimeAfter(
  dateTime1: DateTime,
  dateTime2: DateTime,
): boolean {
  return dateTime1.diff(dateTime2).toMillis() > 0;
}

export function isDateTimeBefore(
  dateTime1: DateTime,
  dateTime2: DateTime,
): boolean {
  return dateTime1.diff(dateTime2).toMillis() < 0;
}

export function getGameDateForUser(user: UserData, timezoneOverride?: string) {
  const { timezone: timezoneDB, currentDateOverride } = user;

  const timezone = timezoneOverride ?? timezoneDB;
  const timestampDateTime = now(timezone);
  const tmpStartOfGameDateDateTime = timestampDateTime.startOf(`day`);
  const maybeStartOfGameDateDateTime = currentDateOverride
    ? DateTime.fromISO(currentDateOverride, { zone: timezone }).startOf(`day`)
    : tmpStartOfGameDateDateTime;

  // if there is currentDateOverride and it is later than today, use that day
  // this is for timezone change purposes
  const gameDate = isDateTimeAfter(
    maybeStartOfGameDateDateTime,
    tmpStartOfGameDateDateTime,
  )
    ? currentDateOverride
    : tmpStartOfGameDateDateTime.toISODate();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const startOfGameDateDateTime = DateTime.fromISO(gameDate, {
    zone: timezone,
  }).startOf(`day`);

  return { gameDate, startOfGameDateDateTime, timestampDateTime, timezone };
}

export function getMonthlyKey(date: DateTime): string {
  return date.toFormat(`LLLL`) + date.toFormat(`yyyy`);
}

export function getStartOfCurrentWeek() {
  // weekly airdrop starts at the upcoming Monday UTC
  const monday = now().startOf(`week`);

  return monday;
}

export function getStartOfNextWeek() {
  // weekly airdrop starts at the upcoming Monday UTC
  const nextMonday = DateTime.utc().startOf(`week`).plus({ week: 1 });

  return nextMonday;
}
