import { Center, CenterProps } from '@chakra-ui/react';
import { ColorId } from '../colors';

export type CardProps = { colorId?: ColorId } & CenterProps;

export const Card = ({ children, colorId = 'White', ...props }: CardProps) => {
  return (
    <Center
      padding="16px"
      flexDirection={'column'}
      alignItems="flex-start"
      gap="8px"
      border="3px solid var(--Black, var(--Black))"
      background={`var(--${colorId})`}
      {...props}
    >
      {children}
    </Center>
  );
};
