import { Pet, BattlePetInformation } from 'genopets-utils';

/**
 * Parses the startApp input string and returns an object containing key-value pairs.
 *
 * @param {string} input - The input string to be parsed.
 * @return {Record<string, string>} An object containing the parsed key-value pairs.
 */
export function parseStartParams(
  input: string | undefined,
): Record<string, string> | null {
  if (!input) return null;

  const result: Record<string, string> = {};
  const parts = input.split('_');

  for (let i = 0; i < parts.length; i += 2) {
    const key = parts[i];
    const value = parts[i + 1];

    if (key && value !== undefined) {
      result[key] = value;
    }
  }

  return result;
}

export function paramsToObject(entries: IterableIterator<[string, string]>) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    // @ts-ignore
    result[key] = value;
  }
  return result;
}

/**
 * Function that return the name of the pet or fallback if the pet doesn't have a name
 * max name returned is 16 characters due avoid to have a long name in the app (not using ellipsis due to name presence in a lot of alert)
 * @param pet
 * @param fallback
 * @returns
 */
export function getPetName(
  pet?: Pet | BattlePetInformation,
  fallback: string = '',
): string | undefined {
  const name = getFullPetName(pet, fallback);
  if (name && name.length > 16) {
    return name.substring(0, 16) + '...';
  }
  return name;
}

export function getFullPetName(
  pet?: Pet | BattlePetInformation,
  fallback: string = '',
): string | undefined {
  if (pet) {
    if ('nickname' in pet) {
      if (pet.nickname) {
        return pet.nickname;
      }
    }

    if ('name' in pet) {
      if (pet.name) {
        return pet.name;
      }
    }
  }

  return fallback;
}
