import { ONE_HOUR_IN_MS } from '../time/time.constants';

export type Action = {
  itemId?: string;
  timestamp: number; // unix number
  points: number;
};

export type DecayType =
  | 'deferred'
  | 'absolute'
  | 'deferred-capped'
  | 'absolute-capped';

export function createActionDeferredDecayFunction(
  decayTick = ONE_HOUR_IN_MS,
  decayValue = 10,
  maxScore = 100,
  minScore = 0,
) {
  return function getActionScore(
    inputActions: Action[] = [],
    now = Date.now(),
  ) {
    let score = 0;
    const actions: Action[] = [];

    if (inputActions.length === 0) {
      return { score: 0, actions };
    }

    for (let i = 0; i < inputActions.length; i += 1) {
      const playing = inputActions[i];
      const { points, timestamp } = playing;
      const nextPlaying = inputActions[i + 1];

      score += points;

      if (nextPlaying?.timestamp <= timestamp + decayTick) {
        // since the next action takes place before a decayTick, no decay occurs so do nothing.
      } else {
        const endTime = Math.min(
          now,
          nextPlaying?.timestamp ?? Number.MAX_VALUE,
        );
        const decayCost =
          Math.floor((endTime - timestamp) / decayTick) * decayValue;
        score -= decayCost;
      }

      // since the score is positive, we want add it to the final actions list since
      // these actions matter for the actual score.
      if (score > 0) {
        actions.push(playing);
      } else {
        // the current set of actions have no meaningful effect since the score is <= 0 and can just be removed.
        score = 0;
        actions.splice(0, actions.length);
      }
    }

    // round to the nearest integer so that the score is a whole number for the mobile frontend.
    const finalScore = Math.round(
      Math.max(minScore, Math.min(score, maxScore)),
    );

    return { score: finalScore, actions };
  };
}

export function createActionAbsoluteDecayFunction(
  decayTick = ONE_HOUR_IN_MS,
  decayValue = 10,
  maxScore = 100,
  minScore = 0,
  capped = false,
) {
  return function getActionScore(
    inputActions: Action[] = [],
    now = Date.now(),
  ) {
    let score = 0;
    const actions: Action[] = [];

    if (inputActions.length === 0) {
      return { score: 0, actions };
    }

    // The timestamp the actions so far has "paid" for.
    // This serves as the "anchor" point in which the decay is calculated from.
    let baseTimestampForDecay = inputActions[0].timestamp;

    for (let i = 0; i < inputActions.length; i += 1) {
      const playing = inputActions[i];
      const { points, timestamp } = playing;

      let tmpNutureValue = points;
      // the difference the previous action's adjusted timestamp vs the current
      // action's timestamp.
      let timestampDelta = baseTimestampForDecay - timestamp;
      const prevPlaying = inputActions[i - 1];

      // if the current action is outside the previous actions' effect
      // and the score is currently 0, then all of the actons prior have
      // no meaningful effect and can just be removed.
      if (
        score <= 0 &&
        (timestampDelta <= 0 ||
          ((prevPlaying?.timestamp ?? 0) + decayTick < timestamp &&
            // the baseTimestampForDecay is less than the current action's timestamp so it
            // can be reset.
            baseTimestampForDecay <= timestamp))
      ) {
        score = 0;
        timestampDelta = 0;
        baseTimestampForDecay = timestamp;
        actions.splice(0, actions.length);
      }

      while (baseTimestampForDecay + decayTick <= now && tmpNutureValue > 0) {
        tmpNutureValue -= decayValue;
        baseTimestampForDecay += decayTick;
      }

      const nextPlaying = inputActions[i + 1];

      // if this action contributes any points or its effect overlaps with the next
      // action, then account for it.
      if (
        tmpNutureValue > 0 ||
        nextPlaying?.timestamp < baseTimestampForDecay
      ) {
        score += tmpNutureValue;
        actions.push(playing);
      }
    }

    // round to the nearest integer so that the score is a whole number for the mobile frontend.
    const finalScore = Math.round(
      Math.max(minScore, Math.min(score, maxScore)),
    );

    // since finalScore is 0, then all the actions don't contribute anything meaningful
    // and can thus be removed.
    if (finalScore === 0) {
      actions.splice(0, actions.length);
    }

    // for capped, if score is greater than the maxScore, then we need to adjust the last action's points
    if (capped && score > maxScore) {
      const delta = score - maxScore;
      actions[actions.length - 1].points -= delta;
    }

    return { score: finalScore, actions };
  };
}

/**
 * This is a capped version of the deferred decay function that will cap the score at the maxScore.
 * This means that the actions list will only contain one action that has the current score
 * after the computation is executed which effectively caps the points at the maxScore and does not
 * allow the player to "pile up" points behind the scenes for a particular nurture type which would allow
 * them to not have to "maintain" their pet on a periodic basis.
 *
 * @param decayTick
 * @param decayValue
 * @param maxScore
 * @param minScore
 * @returns
 */
export function createCappedActionDeferredDecayFunction(
  decayTick = ONE_HOUR_IN_MS,
  decayValue = 10,
  maxScore = 100,
  minScore = 0,
) {
  const getActionScore = createActionDeferredDecayFunction(
    decayTick,
    decayValue,
    maxScore,
    minScore,
  );

  return function getActionScoreWithCapping(
    inputActions: Action[] = [],
    now = Date.now(),
  ) {
    const { score } = getActionScore(inputActions, now);

    // this effectively caps the score at the maxScore by pruning the actions list to just
    // one action that has the current score with the current timestamp.
    return {
      score,
      actions: (score > 0
        ? [{ points: score, timestamp: now }]
        : []) as Action[],
    };
  };
}

export function createCappedActionAbsoluteDecayFunction(
  decayTick = ONE_HOUR_IN_MS,
  decayValue = 10,
  maxScore = 100,
  minScore = 0,
) {
  const getActionScore = createActionAbsoluteDecayFunction(
    decayTick,
    decayValue,
    maxScore,
    minScore,
    true,
  );

  return function getActionScoreWithCapping(
    inputActions: Action[] = [],
    now = Date.now(),
  ) {
    return getActionScore(inputActions, now);
  };
}

export function createActionDecayFunction(
  decayTick = ONE_HOUR_IN_MS,
  decayValue = 10,
  maxScore = 100,
  minScore = 0,
  type: DecayType = `absolute-capped`,
) {
  if (type === `deferred`) {
    return createActionDeferredDecayFunction(
      decayTick,
      decayValue,
      maxScore,
      minScore,
    );
  }

  if (type === `absolute`) {
    return createActionAbsoluteDecayFunction(
      decayTick,
      decayValue,
      maxScore,
      minScore,
    );
  }

  if (type === `deferred-capped`) {
    return createCappedActionDeferredDecayFunction(
      decayTick,
      decayValue,
      maxScore,
      minScore,
    );
  }

  return createCappedActionAbsoluteDecayFunction(
    decayTick,
    decayValue,
    maxScore,
    minScore,
  );
}
