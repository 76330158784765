/* eslint-disable no-unused-vars */
export function ApiProperty() {
  return function (target, key) {
    // Implement your mock decorator behavior here
    // Optionally, you can return a modified target or perform other mocking actions
  };
}

export function ApiPropertyOptional() {
  return function (target, key) {
    // Implement your mock decorator behavior here
    // Optionally, you can return a modified target or perform other mocking actions
  };
}
