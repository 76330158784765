import { Text } from '@/components/ui/atoms/Text';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Button } from '@/components/ui/molecules/Button';
import {
  REFERRAL_LINK_ENDPOINT,
  USER_EMIT_EVENT_ENDPOINT,
  USER_ENERGY_POINTS_SUMMARY,
} from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import { Box, Flex } from '@chakra-ui/react';
import { EnergyPointsSummary, copyToClipboard } from 'genopets-utils';
import { useCallback, useEffect, useState } from 'react';
import { Mail } from '../ui/icons/Mail';
import { initUtils } from '@tma.js/sdk-react';
import { useUserData } from '@/context/FirestoreContext';
import { Ticket } from '../ui/icons/Ticket';
import { Energy } from '../ui/icons/Energy';
import { ReferralInfo } from './ReferalInfo';

function extractLink(link: string): string {
  const url = new URL(link);
  const params = new URLSearchParams(url.search);
  return params.get('url') ?? '';
}

export const ReferralShare = ({ changeView }: { changeView: () => void }) => {
  const { apiGet } = useAPI();
  const [telegramLink, setTelegramLink] = useState('');
  const [referralLink, setReferralLink] = useState('');

  const alert = useAlert();

  const { apiPost } = useAPI();

  const { userData } = useUserData();

  const [energyPointsSummary, setEnergyPointsSummary] =
    useState<EnergyPointsSummary>();

  const mutateEnergyPointsSummary = useCallback(() => {
    if (!apiGet) return;
    return apiGet(USER_ENERGY_POINTS_SUMMARY + `?isTelegram=true`, true)
      .then((data) => {
        setEnergyPointsSummary(data);
      })
      .catch((error: any) => {
        console.error(error);
        alert({ title: error?.message ?? 'Error' });
      });
  }, [apiGet]);

  const getReferralLink = useCallback(() => {
    if (!apiGet) return;
    return apiGet(REFERRAL_LINK_ENDPOINT)
      .then((resp) => {
        if (resp?.link) {
          setTelegramLink(resp.link);

          setReferralLink(extractLink(resp.link));

          apiPost(USER_EMIT_EVENT_ENDPOINT, {
            userId: userData?.id,
            eventName: `telegramReferralLinkGenerated`,
            eventData: {
              referralCode: resp.code,
            },
          });
        }
      })
      .catch((error: any) => {
        console.error(error);
        alert({ title: error?.message ?? 'Error' });
      });
  }, [apiGet]);

  const share = () => {
    const utils = initUtils();
    utils.openTelegramLink(telegramLink);
  };

  useEffect(() => {
    if (!apiGet) return;

    mutateEnergyPointsSummary();
    getReferralLink();
  }, [apiGet]);

  return (
    <Box
      position={`absolute`}
      bottom={0}
      background={`#FFF`}
      border={`solid 2px black`}
    >
      <ReferralInfo energyPointsSummary={energyPointsSummary} />
      <Box padding={`12px 16px`}>
        <Text colorId={`Black`}>
          <TranslatedText
            translationKey={`youHaveInvited`}
            defaultMessage={`you've invited`}
          />
        </Text>
        <Flex alignItems={`center`}>
          <Mail color={`Black`} />
          <Text color={`Black`} ml="4px">
            {energyPointsSummary?.referredUsersCount ?? '...'}
          </Text>
          <Text color={`Black`} ml={`4px`}>
            <TranslatedText
              translationKey={`frens`}
              defaultMessage={`frens`}
              count={energyPointsSummary?.referredUsersCount}
            />
          </Text>
        </Flex>
      </Box>
      <Flex
        flexDir={'column'}
        padding={`12px 16px`}
        borderTop={`solid 3px black`}
        w="full"
        gap={1}
      >
        <Text colorId={`Black`}>
          <TranslatedText
            translationKey={`inviteFrensToHaveRewards`}
            defaultMessage={`invite frens to battle moar Rewards`}
          />
          !
        </Text>
        <Flex border={`solid 3px black`}>
          <Text
            colorId={`Black`}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}
            maxW="calc(100vw - 38px)"
            px="4px"
          >
            {referralLink || 'Loading ...'}
          </Text>
        </Flex>
        <Button
          colorId="Black"
          w="full"
          onClick={() => {
            copyToClipboard(referralLink);
          }}
        >
          copy
        </Button>
        <Button colorId="Black" w="full" onClick={() => share()}>
          share
        </Button>
      </Flex>
      <Box padding={`12px 16px`} borderTop={`solid 3px black`}>
        <Text colorId={`Black`}>
          <TranslatedText
            translationKey={`rewards`}
            defaultMessage={`rewards`}
            count={2}
          />
        </Text>
        <Box>
          <Text colorId={`Black`} as="span" mr="8px">
            {'1)'}
          </Text>
          <Text colorId={`Black`} as="span">
            <TranslatedText translationKey={`earn`} defaultMessage={`earn`} />
          </Text>
          <Box
            height="24px"
            display="inline-block"
            position="relative"
            minWidth="40px"
          >
            <Ticket style={{ position: 'absolute', top: '4px' }} />
          </Box>
          <Text colorId={`Black`} as="span">
            <TranslatedText
              translationKey={`threeForReferral`}
              defaultMessage={`3 for every referral`}
            />
            !
          </Text>
        </Box>
        <Box>
          <Text colorId={`Black`} as="span" mr="8px">
            {'2)'}
          </Text>

          <Text colorId={`Black`} as="span">
            <TranslatedText
              translationKey={`earnPercentage`}
              defaultMessage={`Earn % of fren's`}
            />
          </Text>
          <Box
            height="24px"
            display="inline-block"
            position="relative"
            minWidth="24px"
            color="var(--Black)"
            mx="4px"
          >
            <Energy style={{ position: 'absolute', top: '4px' }} />
          </Box>
          <Text colorId={`Black`} as="span">
            <TranslatedText
              translationKey={`everyTimeTheyBattle`}
              defaultMessage={`Every time they battle`}
            />
            !
          </Text>
        </Box>
      </Box>
      <Box padding={`8px 8px 4px`} borderTop={`solid 3px black`}>
        <Button colorId="Black" w="full" onClick={() => changeView()}>
          <TranslatedText
            translationKey={`goBack`}
            defaultMessage={`Go Back`}
          />
        </Button>
      </Box>
    </Box>
  );
};
