import { AirdropDetails } from '@/components/airdrop/AirdropDetails';
import { MainContainer } from '@/components/battle/MainContainer';
import { bgSounds, initialOptions } from '@/lib/sounds';
import { useEffect } from 'react';
import useSound from 'use-sound';
import InitBackground from '../../images/leaderboard-bg.png';

export const AirdropDetailsPage = () => {
  const [play, { stop, sound }] = useSound(
    bgSounds.leaderboard,
    initialOptions,
  );

  useEffect(() => {
    if (sound) {
      play();
      return () => stop();
    }
  }, [sound]);

  return (
    <MainContainer
      padding={0}
      background={InitBackground}
      backgroundPosition="top"
      backgroundSize="100% auto"
    >
      <AirdropDetails />
    </MainContainer>
  );
};
