export const StarGreen = ({ ...props }) => {
  return (
    <svg
      width="178"
      height="178"
      viewBox="0 0 178 178"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.2605 24.3835H24.3838V29.2603H29.2605V24.3835Z"
        fill="#00A556"
      />
      <path
        d="M34.1375 24.3835H29.2607V29.2603H34.1375V24.3835Z"
        fill="#008E6C"
      />
      <path
        d="M39.0134 24.3835H34.1367V29.2603H39.0134V24.3835Z"
        fill="#008E6C"
      />
      <path
        d="M29.2605 29.2603H24.3838V34.137H29.2605V29.2603Z"
        fill="#03EAB3"
      />
      <path
        d="M24.3835 29.2603H19.5068V34.137H24.3835V29.2603Z"
        fill="#00A556"
      />
      <path
        d="M34.1375 29.2603H29.2607V34.137H34.1375V29.2603Z"
        fill="#03EAB3"
      />
      <path
        d="M39.0134 29.2603H34.1367V34.137H39.0134V29.2603Z"
        fill="#00DDA8"
      />
      <path
        d="M43.8904 29.2603H39.0137V34.137H43.8904V29.2603Z"
        fill="#00A556"
      />
      <path
        d="M29.2605 34.137H24.3838V39.0137H29.2605V34.137Z"
        fill="#03EAB3"
      />
      <path
        d="M24.3835 34.137H19.5068V39.0137H24.3835V34.137Z"
        fill="#008E6C"
      />
      <path
        d="M34.1375 34.137H29.2607V39.0137H34.1375V34.137Z"
        fill="#04FFC8"
      />
      <path
        d="M39.0134 34.137H34.1367V39.0137H39.0134V34.137Z"
        fill="#00DDA8"
      />
      <path
        d="M43.8904 34.137H39.0137V39.0137H43.8904V34.137Z"
        fill="#00A556"
      />
      <path
        d="M29.2605 39.0137H24.3838V43.8904H29.2605V39.0137Z"
        fill="#00DDA8"
      />
      <path
        d="M24.3835 39.0137H19.5068V43.8904H24.3835V39.0137Z"
        fill="#008E6C"
      />
      <path
        d="M34.1375 39.0137H29.2607V43.8904H34.1375V39.0137Z"
        fill="#00DDA8"
      />
      <path
        d="M39.0134 39.0137H34.1367V43.8904H39.0134V39.0137Z"
        fill="#03EAB3"
      />
      <path
        d="M43.8904 39.0137H39.0137V43.8904H43.8904V39.0137Z"
        fill="#00C96E"
      />
      <path
        d="M29.2605 43.8904H24.3838V48.7671H29.2605V43.8904Z"
        fill="#00A556"
      />
      <path
        d="M34.1375 43.8904H29.2607V48.7671H34.1375V43.8904Z"
        fill="#00A556"
      />
      <path
        d="M39.0134 43.8904H34.1367V48.7671H39.0134V43.8904Z"
        fill="#00C96E"
      />
      <path
        d="M43.8904 43.8904H39.0137V48.7671H43.8904V43.8904Z"
        fill="#00C96E"
      />
      <path
        d="M48.7673 39.0137H43.8906V43.8904H48.7673V39.0137Z"
        fill="#00A556"
      />
      <path
        d="M48.7673 43.8904H43.8906V48.7671H48.7673V43.8904Z"
        fill="#00A556"
      />
      <path
        d="M39.0134 48.7671H34.1367V53.6438H39.0134V48.7671Z"
        fill="#00A556"
      />
      <path
        d="M43.8904 48.7671H39.0137V53.6438H43.8904V48.7671Z"
        fill="#00A556"
      />
      <path
        d="M48.7673 48.7671H43.8906V53.6438H48.7673V48.7671Z"
        fill="#00C96E"
      />
      <path
        d="M53.6443 48.7671H48.7676V53.6438H53.6443V48.7671Z"
        fill="#00A556"
      />
      <path
        d="M48.7673 53.6438H43.8906V58.5205H48.7673V53.6438Z"
        fill="#00A556"
      />
      <path
        d="M53.6443 53.6438H48.7676V58.5205H53.6443V53.6438Z"
        fill="#008E6C"
      />
      <path
        d="M58.5203 58.5205H53.6436V63.3972H58.5203V58.5205Z"
        fill="#008E6C"
      />
      <path
        d="M63.3972 63.3972H58.5205V68.2739H63.3972V63.3972Z"
        fill="#008E6C"
      />
      <path
        d="M73.1511 73.1506H68.2744V78.0273H73.1511V73.1506Z"
        fill="#008E6C"
      />
      <path
        d="M29.2605 148.74H24.3838V153.616H29.2605V148.74Z"
        fill="#00A556"
      />
      <path
        d="M34.1375 148.74H29.2607V153.616H34.1375V148.74Z"
        fill="#008E6C"
      />
      <path
        d="M39.0134 148.74H34.1367V153.616H39.0134V148.74Z"
        fill="#008E6C"
      />
      <path
        d="M29.2605 143.863H24.3838V148.74H29.2605V143.863Z"
        fill="#03EAB3"
      />
      <path
        d="M24.3835 143.863H19.5068V148.74H24.3835V143.863Z"
        fill="#00A556"
      />
      <path
        d="M34.1375 143.863H29.2607V148.74H34.1375V143.863Z"
        fill="#03EAB3"
      />
      <path
        d="M39.0134 143.863H34.1367V148.74H39.0134V143.863Z"
        fill="#00DDA8"
      />
      <path
        d="M43.8904 143.863H39.0137V148.74H43.8904V143.863Z"
        fill="#00A556"
      />
      <path
        d="M29.2605 138.986H24.3838V143.863H29.2605V138.986Z"
        fill="#03EAB3"
      />
      <path
        d="M24.3835 138.986H19.5068V143.863H24.3835V138.986Z"
        fill="#008E6C"
      />
      <path
        d="M34.1375 138.986H29.2607V143.863H34.1375V138.986Z"
        fill="#04FFC8"
      />
      <path
        d="M39.0134 138.986H34.1367V143.863H39.0134V138.986Z"
        fill="#00DDA8"
      />
      <path
        d="M43.8904 138.986H39.0137V143.863H43.8904V138.986Z"
        fill="#00A556"
      />
      <path
        d="M29.2605 134.11H24.3838V138.986H29.2605V134.11Z"
        fill="#00DDA8"
      />
      <path
        d="M24.3835 134.11H19.5068V138.986H24.3835V134.11Z"
        fill="#008E6C"
      />
      <path
        d="M34.1375 134.11H29.2607V138.986H34.1375V134.11Z"
        fill="#00DDA8"
      />
      <path
        d="M39.0134 134.11H34.1367V138.986H39.0134V134.11Z"
        fill="#03EAB3"
      />
      <path
        d="M43.8904 134.11H39.0137V138.986H43.8904V134.11Z"
        fill="#00C96E"
      />
      <path
        d="M29.2605 129.233H24.3838V134.11H29.2605V129.233Z"
        fill="#00A556"
      />
      <path
        d="M34.1375 129.233H29.2607V134.11H34.1375V129.233Z"
        fill="#00A556"
      />
      <path
        d="M39.0134 129.233H34.1367V134.11H39.0134V129.233Z"
        fill="#00C96E"
      />
      <path
        d="M43.8904 129.233H39.0137V134.11H43.8904V129.233Z"
        fill="#00C96E"
      />
      <path
        d="M48.7673 134.11H43.8906V138.986H48.7673V134.11Z"
        fill="#00A556"
      />
      <path
        d="M48.7673 129.233H43.8906V134.11H48.7673V129.233Z"
        fill="#00A556"
      />
      <path
        d="M39.0134 124.356H34.1367V129.233H39.0134V124.356Z"
        fill="#00A556"
      />
      <path
        d="M43.8904 124.356H39.0137V129.233H43.8904V124.356Z"
        fill="#00A556"
      />
      <path
        d="M48.7673 124.356H43.8906V129.233H48.7673V124.356Z"
        fill="#00C96E"
      />
      <path
        d="M53.6443 124.356H48.7676V129.233H53.6443V124.356Z"
        fill="#00A556"
      />
      <path
        d="M48.7673 119.479H43.8906V124.356H48.7673V119.479Z"
        fill="#00A556"
      />
      <path
        d="M53.6443 119.479H48.7676V124.356H53.6443V119.479Z"
        fill="#008E6C"
      />
      <path
        d="M58.5203 114.603H53.6436V119.479H58.5203V114.603Z"
        fill="#008E6C"
      />
      <path
        d="M63.3972 109.726H58.5205V114.603H63.3972V109.726Z"
        fill="#008E6C"
      />
      <path
        d="M73.1511 99.9727H68.2744V104.849H73.1511V99.9727Z"
        fill="#008E6C"
      />
      <path
        d="M148.739 153.616H153.616V148.74H148.739V153.616Z"
        fill="#00A556"
      />
      <path
        d="M143.863 153.616H148.739V148.74H143.863V153.616Z"
        fill="#008E6C"
      />
      <path
        d="M138.987 153.616H143.863V148.74H138.987V153.616Z"
        fill="#008E6C"
      />
      <path
        d="M148.739 148.74H153.616V143.863H148.739V148.74Z"
        fill="#03EAB3"
      />
      <path
        d="M153.616 148.74H158.493V143.863H153.616V148.74Z"
        fill="#00A556"
      />
      <path
        d="M143.863 148.74H148.739V143.863H143.863V148.74Z"
        fill="#03EAB3"
      />
      <path
        d="M138.987 148.74H143.863V143.863H138.987V148.74Z"
        fill="#00DDA8"
      />
      <path d="M134.11 148.74H138.986V143.863H134.11V148.74Z" fill="#00A556" />
      <path
        d="M148.739 143.863H153.616V138.986H148.739V143.863Z"
        fill="#03EAB3"
      />
      <path
        d="M153.616 143.863H158.493V138.986H153.616V143.863Z"
        fill="#008E6C"
      />
      <path
        d="M143.863 143.863H148.739V138.986H143.863V143.863Z"
        fill="#04FFC8"
      />
      <path
        d="M138.987 143.863H143.863V138.986H138.987V143.863Z"
        fill="#00DDA8"
      />
      <path
        d="M134.11 143.863H138.986V138.986H134.11V143.863Z"
        fill="#00A556"
      />
      <path
        d="M148.739 138.986H153.616V134.11H148.739V138.986Z"
        fill="#00DDA8"
      />
      <path
        d="M153.616 138.986H158.493V134.11H153.616V138.986Z"
        fill="#008E6C"
      />
      <path
        d="M143.863 138.986H148.739V134.11H143.863V138.986Z"
        fill="#00DDA8"
      />
      <path
        d="M138.987 138.986H143.863V134.11H138.987V138.986Z"
        fill="#03EAB3"
      />
      <path d="M134.11 138.986H138.986V134.11H134.11V138.986Z" fill="#00C96E" />
      <path
        d="M148.739 134.11H153.616V129.233H148.739V134.11Z"
        fill="#00A556"
      />
      <path
        d="M143.863 134.11H148.739V129.233H143.863V134.11Z"
        fill="#00A556"
      />
      <path
        d="M138.987 134.11H143.863V129.233H138.987V134.11Z"
        fill="#00C96E"
      />
      <path d="M134.11 134.11H138.986V129.233H134.11V134.11Z" fill="#00C96E" />
      <path
        d="M129.233 138.986H134.109V134.11H129.233V138.986Z"
        fill="#00A556"
      />
      <path
        d="M129.233 134.11H134.109V129.233H129.233V134.11Z"
        fill="#00A556"
      />
      <path
        d="M138.987 129.233H143.863V124.356H138.987V129.233Z"
        fill="#00A556"
      />
      <path
        d="M134.11 129.233H138.986V124.356H134.11V129.233Z"
        fill="#00A556"
      />
      <path
        d="M129.233 129.233H134.109V124.356H129.233V129.233Z"
        fill="#00C96E"
      />
      <path
        d="M124.356 129.233H129.232V124.356H124.356V129.233Z"
        fill="#00A556"
      />
      <path
        d="M129.233 124.356H134.109V119.479H129.233V124.356Z"
        fill="#00A556"
      />
      <path
        d="M124.356 124.356H129.232V119.479H124.356V124.356Z"
        fill="#008E6C"
      />
      <path
        d="M119.48 119.479H124.356V114.603H119.48V119.479Z"
        fill="#008E6C"
      />
      <path
        d="M114.603 114.603H119.479V109.726H114.603V114.603Z"
        fill="#008E6C"
      />
      <path
        d="M104.849 104.849H109.726V99.9727H104.849V104.849Z"
        fill="#008E6C"
      />
      <path
        d="M148.739 29.2603H153.616V24.3835H148.739V29.2603Z"
        fill="#00A556"
      />
      <path
        d="M143.863 29.2603H148.739V24.3835H143.863V29.2603Z"
        fill="#008E6C"
      />
      <path
        d="M138.987 29.2603H143.863V24.3835H138.987V29.2603Z"
        fill="#008E6C"
      />
      <path
        d="M148.739 34.137H153.616V29.2603H148.739V34.137Z"
        fill="#03EAB3"
      />
      <path
        d="M153.616 34.137H158.493V29.2603H153.616V34.137Z"
        fill="#00A556"
      />
      <path
        d="M143.863 34.137H148.739V29.2603H143.863V34.137Z"
        fill="#03EAB3"
      />
      <path
        d="M138.987 34.137H143.863V29.2603H138.987V34.137Z"
        fill="#00DDA8"
      />
      <path d="M134.11 34.137H138.986V29.2603H134.11V34.137Z" fill="#00A556" />
      <path
        d="M148.739 39.0137H153.616V34.137H148.739V39.0137Z"
        fill="#03EAB3"
      />
      <path
        d="M153.616 39.0137H158.493V34.137H153.616V39.0137Z"
        fill="#008E6C"
      />
      <path
        d="M143.863 39.0137H148.739V34.137H143.863V39.0137Z"
        fill="#04FFC8"
      />
      <path
        d="M138.987 39.0137H143.863V34.137H138.987V39.0137Z"
        fill="#00DDA8"
      />
      <path d="M134.11 39.0137H138.986V34.137H134.11V39.0137Z" fill="#00A556" />
      <path
        d="M148.739 43.8904H153.616V39.0137H148.739V43.8904Z"
        fill="#00DDA8"
      />
      <path
        d="M153.616 43.8904H158.493V39.0137H153.616V43.8904Z"
        fill="#008E6C"
      />
      <path
        d="M143.863 43.8904H148.739V39.0137H143.863V43.8904Z"
        fill="#00DDA8"
      />
      <path
        d="M138.987 43.8904H143.863V39.0137H138.987V43.8904Z"
        fill="#03EAB3"
      />
      <path
        d="M134.11 43.8904H138.986V39.0137H134.11V43.8904Z"
        fill="#00C96E"
      />
      <path
        d="M148.739 48.7671H153.616V43.8904H148.739V48.7671Z"
        fill="#00A556"
      />
      <path
        d="M143.863 48.7671H148.739V43.8904H143.863V48.7671Z"
        fill="#00A556"
      />
      <path
        d="M138.987 48.7671H143.863V43.8904H138.987V48.7671Z"
        fill="#00C96E"
      />
      <path
        d="M134.11 48.7671H138.986V43.8904H134.11V48.7671Z"
        fill="#00C96E"
      />
      <path
        d="M129.233 43.8904H134.109V39.0137H129.233V43.8904Z"
        fill="#00A556"
      />
      <path
        d="M129.233 48.7671H134.109V43.8904H129.233V48.7671Z"
        fill="#00A556"
      />
      <path
        d="M138.987 53.6438H143.863V48.7671H138.987V53.6438Z"
        fill="#00A556"
      />
      <path
        d="M134.11 53.6438H138.986V48.7671H134.11V53.6438Z"
        fill="#00A556"
      />
      <path
        d="M129.233 53.6438H134.109V48.7671H129.233V53.6438Z"
        fill="#00C96E"
      />
      <path
        d="M124.356 53.6438H129.232V48.7671H124.356V53.6438Z"
        fill="#00A556"
      />
      <path
        d="M129.233 58.5205H134.109V53.6438H129.233V58.5205Z"
        fill="#00A556"
      />
      <path
        d="M124.356 58.5205H129.232V53.6438H124.356V58.5205Z"
        fill="#008E6C"
      />
      <path
        d="M119.48 63.3972H124.356V58.5205H119.48V63.3972Z"
        fill="#008E6C"
      />
      <path
        d="M114.603 68.2739H119.479V63.3972H114.603V68.2739Z"
        fill="#008E6C"
      />
      <path
        d="M104.849 78.0273H109.726V73.1506H104.849V78.0273Z"
        fill="#008E6C"
      />
      <path
        d="M91.4382 63.3972H86.5615V68.2739H91.4382V63.3972Z"
        fill="#008E6C"
      />
      <path
        d="M91.4382 58.5205H86.5615V63.3972H91.4382V58.5205Z"
        fill="#008E6C"
      />
      <path
        d="M91.4382 53.6438H86.5615V58.5205H91.4382V53.6438Z"
        fill="#008E6C"
      />
      <path
        d="M91.4382 48.7671H86.5615V53.6438H91.4382V48.7671Z"
        fill="#008E6C"
      />
      <path
        d="M91.4382 43.8904H86.5615V48.7671H91.4382V43.8904Z"
        fill="#008E6C"
      />
      <path
        d="M91.4382 39.0137H86.5615V43.8904H91.4382V39.0137Z"
        fill="#008E6C"
      />
      <path
        d="M91.4382 34.137H86.5615V39.0137H91.4382V34.137Z"
        fill="#00C96E"
      />
      <path
        d="M91.4382 29.2603H86.5615V34.137H91.4382V29.2603Z"
        fill="#00C96E"
      />
      <path
        d="M91.4382 24.3835H86.5615V29.2603H91.4382V24.3835Z"
        fill="#00C96E"
      />
      <path
        d="M91.4382 19.5068H86.5615V24.3835H91.4382V19.5068Z"
        fill="#00C96E"
      />
      <path
        d="M91.4382 14.6301H86.5615V19.5068H91.4382V14.6301Z"
        fill="#03EAB3"
      />
      <path
        d="M91.4382 9.75342H86.5615V14.6301H91.4382V9.75342Z"
        fill="#04FFC8"
      />
      <path
        d="M91.4382 4.87671H86.5615V9.75342H91.4382V4.87671Z"
        fill="#03EAB3"
      />
      <path d="M91.4382 0H86.5615V4.87671H91.4382V0Z" fill="#008E6C" />
      <path
        d="M96.3152 39.0137H91.4385V43.8904H96.3152V39.0137Z"
        fill="#008E6C"
      />
      <path
        d="M96.3152 34.137H91.4385V39.0137H96.3152V34.137Z"
        fill="#008E6C"
      />
      <path
        d="M96.3152 29.2603H91.4385V34.137H96.3152V29.2603Z"
        fill="#008E6C"
      />
      <path
        d="M96.3152 24.3835H91.4385V29.2603H96.3152V24.3835Z"
        fill="#00A556"
      />
      <path
        d="M96.3152 19.5068H91.4385V24.3835H96.3152V19.5068Z"
        fill="#00DDA8"
      />
      <path
        d="M96.3152 14.6301H91.4385V19.5068H96.3152V14.6301Z"
        fill="#00DDA8"
      />
      <path
        d="M96.3152 9.75342H91.4385V14.6301H96.3152V9.75342Z"
        fill="#03EAB3"
      />
      <path
        d="M96.3152 4.87671H91.4385V9.75342H96.3152V4.87671Z"
        fill="#00DDA8"
      />
      <path d="M96.3152 0H91.4385V4.87671H96.3152V0Z" fill="#008E6C" />
      <path
        d="M101.192 24.3835H96.3154V29.2603H101.192V24.3835Z"
        fill="#008E6C"
      />
      <path
        d="M101.192 19.5068H96.3154V24.3835H101.192V19.5068Z"
        fill="#008E6C"
      />
      <path
        d="M101.192 14.6301H96.3154V19.5068H101.192V14.6301Z"
        fill="#00C96E"
      />
      <path
        d="M101.192 9.75342H96.3154V14.6301H101.192V9.75342Z"
        fill="#00C96E"
      />
      <path
        d="M101.192 4.87671H96.3154V9.75342H101.192V4.87671Z"
        fill="#008E6C"
      />
      <path
        d="M86.5613 39.0137H81.6846V43.8904H86.5613V39.0137Z"
        fill="#008E6C"
      />
      <path
        d="M86.5613 34.137H81.6846V39.0137H86.5613V34.137Z"
        fill="#008E6C"
      />
      <path
        d="M86.5613 29.2603H81.6846V34.137H86.5613V29.2603Z"
        fill="#008E6C"
      />
      <path
        d="M86.5613 24.3835H81.6846V29.2603H86.5613V24.3835Z"
        fill="#00A556"
      />
      <path
        d="M86.5613 19.5068H81.6846V24.3835H86.5613V19.5068Z"
        fill="#00DDA8"
      />
      <path
        d="M86.5613 14.6301H81.6846V19.5068H86.5613V14.6301Z"
        fill="#00DDA8"
      />
      <path
        d="M86.5613 9.75342H81.6846V14.6301H86.5613V9.75342Z"
        fill="#03EAB3"
      />
      <path
        d="M86.5613 4.87671H81.6846V9.75342H86.5613V4.87671Z"
        fill="#00DDA8"
      />
      <path d="M86.5613 0H81.6846V4.87671H86.5613V0Z" fill="#008E6C" />
      <path
        d="M81.6853 24.3835H76.8086V29.2603H81.6853V24.3835Z"
        fill="#008E6C"
      />
      <path
        d="M81.6853 19.5068H76.8086V24.3835H81.6853V19.5068Z"
        fill="#008E6C"
      />
      <path
        d="M81.6853 14.6301H76.8086V19.5068H81.6853V14.6301Z"
        fill="#00C96E"
      />
      <path
        d="M81.6853 9.75342H76.8086V14.6301H81.6853V9.75342Z"
        fill="#00C96E"
      />
      <path
        d="M81.6853 4.87671H76.8086V9.75342H81.6853V4.87671Z"
        fill="#008E6C"
      />
      <path
        d="M91.4382 109.726H86.5615V114.603H91.4382V109.726Z"
        fill="#008E6C"
      />
      <path
        d="M91.4382 114.603H86.5615V119.479H91.4382V114.603Z"
        fill="#008E6C"
      />
      <path
        d="M91.4382 119.479H86.5615V124.356H91.4382V119.479Z"
        fill="#008E6C"
      />
      <path
        d="M91.4382 124.356H86.5615V129.233H91.4382V124.356Z"
        fill="#008E6C"
      />
      <path
        d="M91.4382 129.233H86.5615V134.11H91.4382V129.233Z"
        fill="#008E6C"
      />
      <path
        d="M91.4382 134.11H86.5615V138.986H91.4382V134.11Z"
        fill="#008E6C"
      />
      <path
        d="M91.4382 138.986H86.5615V143.863H91.4382V138.986Z"
        fill="#00C96E"
      />
      <path
        d="M91.4382 143.863H86.5615V148.74H91.4382V143.863Z"
        fill="#00C96E"
      />
      <path
        d="M91.4382 148.74H86.5615V153.616H91.4382V148.74Z"
        fill="#00C96E"
      />
      <path
        d="M91.4382 153.616H86.5615V158.493H91.4382V153.616Z"
        fill="#00C96E"
      />
      <path
        d="M91.4382 158.493H86.5615V163.37H91.4382V158.493Z"
        fill="#03EAB3"
      />
      <path
        d="M91.4382 163.37H86.5615V168.247H91.4382V163.37Z"
        fill="#04FFC8"
      />
      <path
        d="M91.4382 168.247H86.5615V173.123H91.4382V168.247Z"
        fill="#03EAB3"
      />
      <path d="M91.4382 173.123H86.5615V178H91.4382V173.123Z" fill="#008E6C" />
      <path
        d="M96.3152 134.11H91.4385V138.986H96.3152V134.11Z"
        fill="#008E6C"
      />
      <path
        d="M96.3152 138.986H91.4385V143.863H96.3152V138.986Z"
        fill="#008E6C"
      />
      <path
        d="M96.3152 143.863H91.4385V148.74H96.3152V143.863Z"
        fill="#008E6C"
      />
      <path
        d="M96.3152 148.74H91.4385V153.616H96.3152V148.74Z"
        fill="#00A556"
      />
      <path
        d="M96.3152 153.616H91.4385V158.493H96.3152V153.616Z"
        fill="#00DDA8"
      />
      <path
        d="M96.3152 158.493H91.4385V163.37H96.3152V158.493Z"
        fill="#00DDA8"
      />
      <path
        d="M96.3152 163.37H91.4385V168.247H96.3152V163.37Z"
        fill="#03EAB3"
      />
      <path
        d="M96.3152 168.247H91.4385V173.123H96.3152V168.247Z"
        fill="#00DDA8"
      />
      <path d="M96.3152 173.123H91.4385V178H96.3152V173.123Z" fill="#008E6C" />
      <path
        d="M101.192 148.74H96.3154V153.616H101.192V148.74Z"
        fill="#008E6C"
      />
      <path
        d="M101.192 153.616H96.3154V158.493H101.192V153.616Z"
        fill="#008E6C"
      />
      <path
        d="M101.192 158.493H96.3154V163.37H101.192V158.493Z"
        fill="#00C96E"
      />
      <path
        d="M101.192 163.37H96.3154V168.247H101.192V163.37Z"
        fill="#00C96E"
      />
      <path
        d="M101.192 168.247H96.3154V173.123H101.192V168.247Z"
        fill="#008E6C"
      />
      <path
        d="M86.5613 134.11H81.6846V138.986H86.5613V134.11Z"
        fill="#008E6C"
      />
      <path
        d="M86.5613 138.986H81.6846V143.863H86.5613V138.986Z"
        fill="#008E6C"
      />
      <path
        d="M86.5613 143.863H81.6846V148.74H86.5613V143.863Z"
        fill="#008E6C"
      />
      <path
        d="M86.5613 148.74H81.6846V153.616H86.5613V148.74Z"
        fill="#00A556"
      />
      <path
        d="M86.5613 153.616H81.6846V158.493H86.5613V153.616Z"
        fill="#00DDA8"
      />
      <path
        d="M86.5613 158.493H81.6846V163.37H86.5613V158.493Z"
        fill="#00DDA8"
      />
      <path
        d="M86.5613 163.37H81.6846V168.247H86.5613V163.37Z"
        fill="#03EAB3"
      />
      <path
        d="M86.5613 168.247H81.6846V173.123H86.5613V168.247Z"
        fill="#00DDA8"
      />
      <path d="M86.5613 173.123H81.6846V178H86.5613V173.123Z" fill="#008E6C" />
      <path
        d="M81.6853 148.74H76.8086V153.616H81.6853V148.74Z"
        fill="#008E6C"
      />
      <path
        d="M81.6853 153.616H76.8086V158.493H81.6853V153.616Z"
        fill="#008E6C"
      />
      <path
        d="M81.6853 158.493H76.8086V163.37H81.6853V158.493Z"
        fill="#00C96E"
      />
      <path
        d="M81.6853 163.37H76.8086V168.247H81.6853V163.37Z"
        fill="#00C96E"
      />
      <path
        d="M81.6853 168.247H76.8086V173.123H81.6853V168.247Z"
        fill="#008E6C"
      />
      <path
        d="M114.603 91.4384V86.5616H109.726V91.4384H114.603Z"
        fill="#008E6C"
      />
      <path
        d="M119.479 91.4384V86.5616H114.603V91.4384H119.479Z"
        fill="#008E6C"
      />
      <path
        d="M124.356 91.4384V86.5616H119.48V91.4384H124.356Z"
        fill="#008E6C"
      />
      <path
        d="M129.232 91.4384V86.5616H124.356V91.4384H129.232Z"
        fill="#008E6C"
      />
      <path
        d="M134.109 91.4384V86.5616H129.233V91.4384H134.109Z"
        fill="#008E6C"
      />
      <path
        d="M138.986 91.4384V86.5616H134.11V91.4384H138.986Z"
        fill="#008E6C"
      />
      <path
        d="M143.863 91.4384V86.5616H138.987V91.4384H143.863Z"
        fill="#00C96E"
      />
      <path
        d="M148.739 91.4384V86.5616H143.863V91.4384H148.739Z"
        fill="#00C96E"
      />
      <path
        d="M153.616 91.4384V86.5616H148.739V91.4384H153.616Z"
        fill="#00C96E"
      />
      <path
        d="M158.493 91.4384V86.5616H153.616V91.4384H158.493Z"
        fill="#00C96E"
      />
      <path
        d="M163.37 91.4384V86.5616H158.493V91.4384H163.37Z"
        fill="#03EAB3"
      />
      <path
        d="M168.246 91.4384V86.5616H163.369V91.4384H168.246Z"
        fill="#04FFC8"
      />
      <path
        d="M173.123 91.4384V86.5616H168.246V91.4384H173.123Z"
        fill="#03EAB3"
      />
      <path d="M178 91.4384V86.5616H173.123V91.4384H178Z" fill="#008E6C" />
      <path
        d="M138.986 96.3151V91.4384H134.11V96.3151H138.986Z"
        fill="#008E6C"
      />
      <path
        d="M143.863 96.3151V91.4384H138.987V96.3151H143.863Z"
        fill="#008E6C"
      />
      <path
        d="M148.739 96.3151V91.4384H143.863V96.3151H148.739Z"
        fill="#008E6C"
      />
      <path
        d="M153.616 96.3151V91.4384H148.739V96.3151H153.616Z"
        fill="#00A556"
      />
      <path
        d="M158.493 96.3151V91.4384H153.616V96.3151H158.493Z"
        fill="#00DDA8"
      />
      <path
        d="M163.37 96.3151V91.4384H158.493V96.3151H163.37Z"
        fill="#00DDA8"
      />
      <path
        d="M168.246 96.3151V91.4384H163.369V96.3151H168.246Z"
        fill="#03EAB3"
      />
      <path
        d="M173.123 96.3151V91.4384H168.246V96.3151H173.123Z"
        fill="#00DDA8"
      />
      <path d="M178 96.3151V91.4384H173.123V96.3151H178Z" fill="#008E6C" />
      <path
        d="M153.616 101.192V96.3151H148.739V101.192H153.616Z"
        fill="#008E6C"
      />
      <path
        d="M158.493 101.192V96.3151H153.616V101.192H158.493Z"
        fill="#008E6C"
      />
      <path
        d="M163.37 101.192V96.3151H158.493V101.192H163.37Z"
        fill="#00C96E"
      />
      <path
        d="M168.246 101.192V96.3151H163.369V101.192H168.246Z"
        fill="#00C96E"
      />
      <path
        d="M173.123 101.192V96.3151H168.246V101.192H173.123Z"
        fill="#008E6C"
      />
      <path
        d="M138.986 86.5616V81.6849H134.11V86.5616H138.986Z"
        fill="#008E6C"
      />
      <path
        d="M143.863 86.5616V81.6849H138.987V86.5616H143.863Z"
        fill="#008E6C"
      />
      <path
        d="M148.739 86.5616V81.6849H143.863V86.5616H148.739Z"
        fill="#008E6C"
      />
      <path
        d="M153.616 86.5616V81.6849H148.739V86.5616H153.616Z"
        fill="#00A556"
      />
      <path
        d="M158.493 86.5616V81.6849H153.616V86.5616H158.493Z"
        fill="#00DDA8"
      />
      <path
        d="M163.37 86.5616V81.6849H158.493V86.5616H163.37Z"
        fill="#00DDA8"
      />
      <path
        d="M168.246 86.5616V81.6849H163.369V86.5616H168.246Z"
        fill="#03EAB3"
      />
      <path
        d="M173.123 86.5616V81.6849H168.246V86.5616H173.123Z"
        fill="#00DDA8"
      />
      <path d="M178 86.5616V81.6849H173.123V86.5616H178Z" fill="#008E6C" />
      <path
        d="M153.616 81.6849V76.8082H148.739V81.6849H153.616Z"
        fill="#008E6C"
      />
      <path
        d="M158.493 81.6849V76.8082H153.616V81.6849H158.493Z"
        fill="#008E6C"
      />
      <path
        d="M163.37 81.6849V76.8082H158.493V81.6849H163.37Z"
        fill="#00C96E"
      />
      <path
        d="M168.246 81.6849V76.8082H163.369V81.6849H168.246Z"
        fill="#00C96E"
      />
      <path
        d="M173.123 81.6849V76.8082H168.246V81.6849H173.123Z"
        fill="#008E6C"
      />
      <path
        d="M68.2744 91.4384V86.5616H63.3977V91.4384H68.2744Z"
        fill="#008E6C"
      />
      <path
        d="M63.3975 91.4384V86.5616H58.5207V91.4384H63.3975Z"
        fill="#008E6C"
      />
      <path
        d="M58.5205 91.4384V86.5616H53.6438V91.4384H58.5205Z"
        fill="#008E6C"
      />
      <path
        d="M53.6436 91.4384V86.5616H48.7668V91.4384H53.6436Z"
        fill="#008E6C"
      />
      <path
        d="M48.7676 91.4384V86.5616H43.8909V91.4384H48.7676Z"
        fill="#008E6C"
      />
      <path
        d="M43.8906 91.4384V86.5616H39.0139V91.4384H43.8906Z"
        fill="#008E6C"
      />
      <path
        d="M39.0137 91.4384V86.5616H34.137V91.4384H39.0137Z"
        fill="#00C96E"
      />
      <path
        d="M34.1367 91.4384V86.5616H29.26V91.4384H34.1367Z"
        fill="#00C96E"
      />
      <path
        d="M29.2607 91.4384V86.5616H24.384V91.4384H29.2607Z"
        fill="#00C96E"
      />
      <path
        d="M24.3838 91.4384V86.5616H19.5071V91.4384H24.3838Z"
        fill="#00C96E"
      />
      <path
        d="M19.5068 91.4384V86.5616H14.6301V91.4384H19.5068Z"
        fill="#03EAB3"
      />
      <path
        d="M14.6299 91.4384V86.5616H9.75317V91.4384H14.6299Z"
        fill="#04FFC8"
      />
      <path
        d="M9.75391 91.4384V86.5616H4.87719V91.4384H9.75391Z"
        fill="#03EAB3"
      />
      <path
        d="M4.87695 91.4384V86.5616H0.000240803V91.4384H4.87695Z"
        fill="#008E6C"
      />
      <path
        d="M43.8906 96.3151V91.4384H39.0139V96.3151H43.8906Z"
        fill="#008E6C"
      />
      <path
        d="M39.0137 96.3151V91.4384H34.137V96.3151H39.0137Z"
        fill="#008E6C"
      />
      <path
        d="M34.1367 96.3151V91.4384H29.26V96.3151H34.1367Z"
        fill="#008E6C"
      />
      <path
        d="M29.2607 96.3151V91.4384H24.384V96.3151H29.2607Z"
        fill="#00A556"
      />
      <path
        d="M24.3838 96.3151V91.4384H19.5071V96.3151H24.3838Z"
        fill="#00DDA8"
      />
      <path
        d="M19.5068 96.3151V91.4384H14.6301V96.3151H19.5068Z"
        fill="#00DDA8"
      />
      <path
        d="M14.6299 96.3151V91.4384H9.75317V96.3151H14.6299Z"
        fill="#03EAB3"
      />
      <path
        d="M9.75391 96.3151V91.4384H4.87719V96.3151H9.75391Z"
        fill="#00DDA8"
      />
      <path
        d="M4.87695 96.3151V91.4384H0.000240803V96.3151H4.87695Z"
        fill="#008E6C"
      />
      <path
        d="M29.2607 101.192V96.3151H24.384V101.192H29.2607Z"
        fill="#008E6C"
      />
      <path
        d="M24.3838 101.192V96.3151H19.5071V101.192H24.3838Z"
        fill="#008E6C"
      />
      <path
        d="M19.5068 101.192V96.3151H14.6301V101.192H19.5068Z"
        fill="#00C96E"
      />
      <path
        d="M14.6299 101.192V96.3151H9.75317V101.192H14.6299Z"
        fill="#00C96E"
      />
      <path
        d="M9.75391 101.192V96.3151H4.87719V101.192H9.75391Z"
        fill="#008E6C"
      />
      <path
        d="M43.8906 86.5616V81.6849H39.0139V86.5616H43.8906Z"
        fill="#008E6C"
      />
      <path
        d="M39.0137 86.5616V81.6849H34.137V86.5616H39.0137Z"
        fill="#008E6C"
      />
      <path
        d="M34.1367 86.5616V81.6849H29.26V86.5616H34.1367Z"
        fill="#008E6C"
      />
      <path
        d="M29.2607 86.5616V81.6849H24.384V86.5616H29.2607Z"
        fill="#00A556"
      />
      <path
        d="M24.3838 86.5616V81.6849H19.5071V86.5616H24.3838Z"
        fill="#00DDA8"
      />
      <path
        d="M19.5068 86.5616V81.6849H14.6301V86.5616H19.5068Z"
        fill="#00DDA8"
      />
      <path
        d="M14.6299 86.5616V81.6849H9.75317V86.5616H14.6299Z"
        fill="#03EAB3"
      />
      <path
        d="M9.75391 86.5616V81.6849H4.87719V86.5616H9.75391Z"
        fill="#00DDA8"
      />
      <path
        d="M4.87695 86.5616V81.6849H0.000240803V86.5616H4.87695Z"
        fill="#008E6C"
      />
      <path
        d="M29.2607 81.6849V76.8082H24.384V81.6849H29.2607Z"
        fill="#008E6C"
      />
      <path
        d="M24.3838 81.6849V76.8082H19.5071V81.6849H24.3838Z"
        fill="#008E6C"
      />
      <path
        d="M19.5068 81.6849V76.8082H14.6301V81.6849H19.5068Z"
        fill="#00C96E"
      />
      <path
        d="M14.6299 81.6849V76.8082H9.75317V81.6849H14.6299Z"
        fill="#00C96E"
      />
      <path
        d="M9.75391 81.6849V76.8082H4.87719V81.6849H9.75391Z"
        fill="#008E6C"
      />
    </svg>
  );
};
