import { Flex } from '@chakra-ui/react';
import { Text } from '../atoms/Text';
import { ColorId } from '../colors';

export const SummaryInfoRow = ({
  leftComponent,
  rightComponent,
  colorId = 'White',
  bgColorId = 'Black',
}: {
  leftComponent: any;
  rightComponent: any;
  colorId?: ColorId;
  bgColorId?: ColorId;
}) => {
  return (
    <Flex
      alignItems={`center`}
      justifyContent={`space-between`}
      w={`full`}
      position={`relative`}
      overflow={`hidden`}
    >
      <Flex
        w={`full`}
        position={`absolute`}
        top={0.5}
        left={0.5}
        right={0}
        zIndex={1}
      >
        {Array.from({ length: 30 }, (_, i) => (
          <Text key={i} colorId={colorId}>
            .
          </Text>
        ))}
      </Flex>
      <Flex
        alignItems={`center`}
        gap={`4px`}
        bg={`var(--${bgColorId})`}
        paddingRight={`4px`}
        zIndex={2}
      >
        {leftComponent}
      </Flex>
      <Flex
        alignItems={`center`}
        gap={`4px`}
        bg={`var(--${bgColorId})`}
        paddingLeft={`8px`}
        zIndex={2}
      >
        {rightComponent}
      </Flex>
    </Flex>
  );
};
