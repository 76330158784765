export const Hand = ({ ...props }) => (
  <svg
    width="97"
    height="73"
    viewBox="0 0 97 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="4" y="23" width="89" height="9" fill="currentColor" />
    <rect x="12" y="20" width="36" height="9" fill="currentColor" />
    <rect x="20" y="16" width="28" height="9" fill="currentColor" />
    <rect x="28" y="12" width="24" height="9" fill="currentColor" />
    <rect x="39" y="8" width="17" height="8" fill="currentColor" />
    <rect x="44" y="4" width="15" height="8" fill="currentColor" />
    <rect x="4" y="32" width="66" height="12" fill="currentColor" />
    <rect x="4" y="44" width="61" height="12" fill="currentColor" />
    <rect x="4" y="53" width="57" height="12" fill="currentColor" />
    <rect x="15" y="57" width="46" height="12" fill="currentColor" />
    <path d="M0 20H12V24H0L0 20Z" fill="black" />
    <path d="M12 16H20V20H12L12 16Z" fill="black" />
    <path d="M20 12H28V16H20V12Z" fill="black" />
    <path d="M28 8H40V12H28V8Z" fill="black" />
    <path d="M40 4L45 4V8H40V4Z" fill="black" />
    <path d="M56 4H60V12H56V4Z" fill="black" />
    <path d="M69 36H73V44H69V36Z" fill="black" />
    <path d="M0 24H4L4 61H0L0 24Z" fill="black" />
    <path d="M65 44H69V56H65V44Z" fill="black" />
    <path d="M61 60L65 60V65H61L61 60Z" fill="black" />
    <path d="M57 65H61V69H57V65Z" fill="black" />
    <path d="M93 24L97 24V32H93V24Z" fill="black" />
    <path d="M52 12H56V16H52V12Z" fill="black" />
    <path d="M48 16H52V20H48V16Z" fill="black" />
    <path d="M36 20H93V24H36V20Z" fill="black" />
    <path d="M52 32L93 32V36L52 36V32Z" fill="black" />
    <path d="M53 44L65 44V48L53 48V44Z" fill="black" />
    <path d="M49 56L65 56V60L49 60V56Z" fill="black" />
    <path d="M24 69H57V73H24V69Z" fill="black" />
    <path d="M12 65H24V69H12V65Z" fill="black" />
    <path d="M0 61H12V65H0L0 61Z" fill="black" />
    <path d="M45 0L56 0V4L45 4V0Z" fill="black" />
  </svg>
);
