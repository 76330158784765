import { useDisclosure, Flex } from '@chakra-ui/react';
import { Text } from '../ui/atoms/Text';
import { Modal } from '../ui/molecules/Modal';
import useAPI from '@/hooks/useAPI';
import { useUserData } from '@/context/FirestoreContext';
import { UserIcon } from '@/components/ui/icons/UserIcon';
import { Button } from '@/components/ui/molecules/Button';
import {
  toUserFriendlyAddress,
  useTonWallet,
  useTonConnectUI,
  useTonConnectModal,
} from '@tonconnect/ui-react';
import {
  ADD_TON_WALLET_ENDPOINT,
  REMOVE_TON_WALLET_ENDPOINT,
} from '@/config/endpoints';
import { useAlert } from '@/hooks/useAlert';
import { useState, useEffect } from 'react';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';

export const ManageWalletModal = () => {
  const alert = useAlert();
  const [isSaving, setIsSaving] = useState(false);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { apiPut } = useAPI();
  const { userData } = useUserData();
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const { open: openConnectTonWalletModal } = useTonConnectModal();

  const friendlyWalletAddress = userData?.tonWallet
    ? toUserFriendlyAddress(userData?.tonWallet)
    : undefined;

  const addWallet = async (walletAddress: string) => {
    try {
      setIsSaving(true);
      await apiPut(ADD_TON_WALLET_ENDPOINT, {
        walletAddress,
      });
    } catch (error: any) {
      console.error(error);
      alert({ title: error?.message ?? 'Error' });
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (wallet?.account.address) {
      addWallet(wallet?.account.address);
    }
  }, [wallet]);

  useEffect(() => {
    const unsubscribe = tonConnectUI.onStatusChange((wallet) => {
      // this is the event that gets fired after connecting wallet flow and goes back to home page
      if (wallet) {
        console.log(`wallet connected`, wallet);
        // open modal again after connecting wallet
        onOpen();

        addWallet(wallet.account.address);
      } else {
        console.log(`wallet not connected`);
      }
    });
    return () => unsubscribe();
  }, [tonConnectUI]);

  return (
    <>
      <Button
        padding={`8px`}
        height={`56px`}
        width={`52px`}
        minWidth={`inherit`}
        border="3px solid var(--Black)"
        background={isOpen ? `#FFF` : `#EBDC53`}
        onClick={() => {
          onOpen();
        }}
      >
        <Flex justifyContent={`center`} alignItems={`center`} display={`flex`}>
          <UserIcon fill={isOpen ? `#FFF` : `black`} />
        </Flex>
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        showBackButton={false}
        contentProps={{ maxWidth: `320px` }}
        cardProps={{ padding: `4px` }}
      >
        <Flex
          justifyContent={`center`}
          flexDirection={`column`}
          alignItems={`center`}
          gap={`12px`}
        >
          <Text colorId="Black">
            <TranslatedText
              translationKey={`wallet`}
              defaultMessage={`wallet`}
            />
          </Text>
          {userData?.tonWallet ? (
            <>
              <Text colorId="Green">
                <TranslatedText
                  translationKey={`connected`}
                  defaultMessage={`connected`}
                />
              </Text>
              {friendlyWalletAddress && (
                <Text colorId={`Black`}>
                  {friendlyWalletAddress.substring(0, 4)}...
                  {friendlyWalletAddress.substring(
                    friendlyWalletAddress.length - 4,
                  )}
                </Text>
              )}
              <Button
                colorId={`Black`}
                isDisabled={isSaving}
                whiteSpace={'pre-wrap'}
                onClick={async () => {
                  try {
                    setIsSaving(true);
                    await apiPut(REMOVE_TON_WALLET_ENDPOINT, {}, true);
                    await tonConnectUI.disconnect();
                  } catch (error: any) {
                    console.error(error);
                    alert({ title: error?.message ?? 'Error' });
                  } finally {
                    setIsSaving(false);
                  }
                }}
              >
                <TranslatedText
                  translationKey={`disconnectWallet`}
                  defaultMessage={`disconnect wallet`}
                />
              </Button>
            </>
          ) : (
            <>
              <Text colorId="Red">
                <TranslatedText
                  translationKey={`notConnected`}
                  defaultMessage={`not connected`}
                />
              </Text>
              <Button
                colorId={`Black`}
                isDisabled={isSaving}
                whiteSpace={'pre-wrap'}
                onClick={async () => {
                  // open connect ton wallet drawer
                  openConnectTonWalletModal();

                  // close wallet management modal when opening connect wallet drawer because of overlay issue (zIndex)
                  // the modal will open again after connecting
                  onClose();
                }}
              >
                <TranslatedText
                  translationKey={`connectWallet`}
                  defaultMessage={`connect wallet`}
                />
              </Button>
            </>
          )}

          <Button
            colorId={`White`}
            onClick={() => {
              onClose();
            }}
          >
            <TranslatedText
              translationKey={`goBack`}
              defaultMessage={`Go Back`}
            />
          </Button>
        </Flex>
      </Modal>
    </>
  );
};
