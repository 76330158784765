import { MenuItemPop } from '@/components/ui/molecules/MenuItemPop';
import { BattlePlayerEntity, getStatusMultiplier } from 'genopets-utils';
import { Text } from '../../ui/atoms/Text';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { Modal } from '@/components/ui/molecules/Modal';

export const ReplayPlayerState = ({
  player,
  playerNumber,
}: {
  player: BattlePlayerEntity;
  playerNumber: number;
}) => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Text colorId="Black" w="full">
          {`HP: ${player.pet.HP}`}
        </Text>
        <Text colorId="Black" w="full">
          {`ATK: ${player.pet.base.ATK * getStatusMultiplier(player.pet.statusModifier?.ATK ?? 0)} (x${getStatusMultiplier(player.pet.statusModifier?.ATK ?? 0)})`}
        </Text>
        <Text colorId="Black" w="full">
          {`DEF: ${player.pet.base.DEF * getStatusMultiplier(player.pet.statusModifier?.DEF ?? 0)} (x${getStatusMultiplier(player.pet.statusModifier?.DEF ?? 0)})`}
        </Text>
        <Text colorId="Black" w="full">
          {`SPD: ${player.pet.base.SPD * getStatusMultiplier(player.pet.statusModifier?.SPD ?? 0)} (x${getStatusMultiplier(player.pet.statusModifier?.SPD ?? 0)})`}
        </Text>
        <Text colorId="Black" w="full">
          {`STB: ${player.pet.base.STB * getStatusMultiplier(player.pet.statusModifier?.STB ?? 0)} (x${getStatusMultiplier(player.pet.statusModifier?.STB ?? 0)})`}
        </Text>
        <Text colorId="Black" w="full">
          {`AGL: ${player.pet.base.STB * getStatusMultiplier(player.pet.statusModifier?.AGL ?? 0)} (x${getStatusMultiplier(player.pet.statusModifier?.AGL ?? 0)})`}
        </Text>

        <Text
          mt={2}
          pt={2}
          borderTop={'3px solid var(--Black)'}
          colorId="Black"
        >
          Status
        </Text>
        {player.pet.status.length ? (
          player.pet.status.map((status, i) => {
            return (
              <Flex key={i} gap={1}>
                <Text colorId="Black" fontSize={'10px'}>
                  {status.statAffected
                    ? `${status.statAffected} ${status.value ? parseInt(status.value, 10) / 10 : 'no value'}`
                    : status.name}
                </Text>
                <Text
                  colorId="Black"
                  fontSize={'10px'}
                >{`(${status.duration ? `${status.duration} turn${(status.duration ?? 0) > 1 ? 's' : ''}` : 'no duration'})`}</Text>
              </Flex>
            );
          })
        ) : (
          <Text colorId="Black" fontSize={'10px'}>
            No status applied on the pet
          </Text>
        )}
      </Modal>
      <Flex flexDirection="column" w="full">
        <Text colorId="Black" w="full">
          {`player ${playerNumber} id:`}
        </Text>
        <MenuItemPop color={`var(--Black)`} w="full" onClick={onOpen}>
          <Text
            colorId="Black"
            w="full"
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            fontSize={'10px'}
          >
            {player.id}
          </Text>
        </MenuItemPop>
      </Flex>
    </>
  );
};
