import { BattleMoveEntity, useMove } from 'genopets-utils';
import { Text } from '../../ui/atoms/Text';
import { Button } from '../../ui/molecules/Button';
import { Card } from '../../ui/molecules/Card';
import { MenuItemPop } from '../../ui/molecules/MenuItemPop';
import { useUIBattleContext } from '../context/useBattleContext';
import { Box } from '@chakra-ui/react';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { useTranslation } from 'react-i18next';

export const MenuMoveItem = ({
  move,
  cooldown,
  selected,
  onClick,
}: {
  move: BattleMoveEntity;
  cooldown?: number;
  selected: boolean;
  // eslint-disable-next-line no-unused-vars
  onClick: (name: string) => void;
}) => {
  const { t: translateText } = useTranslation();
  const { sendAction, loading } = useUIBattleContext();

  return (
    <Box position="relative" w="full">
      {selected ? (
        <Card
          position="absolute"
          bottom={'30px'}
          left={0}
          transform={'translateX(-75%)'}
          zIndex={6}
        >
          <Text colorId="Black" whiteSpace={'nowrap'}>
            <TranslatedText
              translationKey={`moveStatTextType`}
              defaultMessage={`type`}
            />
            {`: ${(move.categories ?? move.types).join(',')}`}
          </Text>
          <Text colorId="Black" whiteSpace={'nowrap'}>
            <TranslatedText
              translationKey={`coolDown`}
              defaultMessage={`cooldown`}
            />
            {`: ${move.cooldown}`}
          </Text>
          <Button
            colorId="Black"
            minWidth={0}
            w="full"
            isLoading={loading}
            onClick={() => {
              sendAction(useMove.type, move);
            }}
            whiteSpace={'pre-wrap'}
            height={'auto'}
          >
            <TranslatedText
              translationKey={`useMove`}
              defaultMessage={`Use Move`}
            />
          </Button>
        </Card>
      ) : null}
      <MenuItemPop
        color={selected ? 'white' : 'black'}
        colorId={selected ? 'Black' : undefined}
        width="full"
        disabled={!!cooldown}
        onClick={() => {
          if (cooldown) return;
          onClick(move.name);
        }}
      >
        {translateText(
          // Poke is hardcoded as a default move and isn't saved in firestore
          // manually check if it's Poke and assign the correct localise key
          move.name === `Poke`
            ? `moveNamePoke`
            : (move.lokaliseKeyName as string),
          {
            defaultValue: move.name as string,
          },
        ) || move.name}
      </MenuItemPop>
    </Box>
  );
};
