import { useReadPetFromFirestore } from '@/hooks/useReadPetFromFirestore';
import { useReadUserFromFirestore } from '@/hooks/useReadUserFromFirestore';
import { Pet, UserData } from 'genopets-utils';
import { ReactNode, createContext, useContext } from 'react';
import { useAuthState } from './UserContext';

type FirestoreContextProps = {
  userData?: UserData;
  pet?: Pet;
};

export const FirestoreContext = createContext<FirestoreContextProps>({
  userData: undefined,
  pet: undefined,
});

const FirestoreProvider = ({ children }: { children: ReactNode }) => {
  const { state } = useAuthState();
  const user = state.currentUser;

  const userData = useReadUserFromFirestore({ userId: user?.uid });

  const { pet } = useReadPetFromFirestore({
    petId: userData?.telegramPet,
  });

  return (
    <FirestoreContext.Provider value={{ userData, pet }}>
      {children}
    </FirestoreContext.Provider>
  );
};

const useUserData = () => {
  const { userData } = useContext(FirestoreContext);

  return {
    userData,
  };
};

const useTelegramPet = () => {
  const { pet } = useContext(FirestoreContext);

  return {
    pet,
  };
};

export { FirestoreProvider, useTelegramPet, useUserData };
