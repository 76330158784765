/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { ApiProperty } from '@nestjs/swagger';
import { Exclude, Expose, Type } from 'class-transformer';
import {
  ArrayMaxSize,
  ArrayUnique,
  IsBoolean,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsNumberString,
  IsOptional,
  IsString,
  IsUrl,
} from 'class-validator';
import { startCase } from 'lodash';
import 'reflect-metadata';
import { getPetDescription } from '..';
import { PARTITION_DELIMITER } from '../../inventory/inventory.constants';
import { NurtureAction } from '../../models/entities/nurture-action.entity';
import {
  Augments,
  TELEGRAM_XP_CURVE,
  levelToStage,
  telegramXpToLevel,
} from '../../models/game/settings';
import { getJoyScore, getLoveScore, getNutritionScore } from '../../nurture';
import { getPetImage } from '../../utils';

export type RGB = {
  r: number;
  b: number;
  g: number;
  a: number;
};

export class NFTAttribute {
  @ApiProperty({
    example: `color`,
    description: `The trait type of the attribute`,
  })
  trait_type: string;

  @ApiProperty({
    example: `red`,
    description: `The value of the attribute, which can be a string or a number`,
  })
  value: string | number;
}

class Modifier {
  // timestamp to the nearest second, of when the modifier will expire
  @ApiProperty({
    example: 1641919569,
    description: `Timestamp when the modifier will expire`,
  })
  expiresAt: number;

  // token-address of item
  @ApiProperty({
    example: `0x1234567890abcdef`,
    description: `Token address of the item`,
  })
  itemTokenMint: string;

  // known attribute type that can modify a pet (this will be hardcoded)
  @ApiProperty({
    example: `speed`,
    description: `Known attribute type that can modify a pet`,
  })
  attributeType: string;

  // based, on the attributeType, will apply the value in a predetermined way
  @ApiProperty({
    example: `2x`,
    description: `Value of the attribute that will be applied to the pet`,
  })
  attributeValue: string;
}

export const bodyParts = [
  `head`,
  `body`,
  `eyes`,
  `tail`,
  `mane`,
  `ears`,
  `antennae`,
  `horn`,
  `wings`,
];

export class PetColors {
  @IsNotEmpty()
  @IsString()
  @ApiProperty({
    description: `The primary color of the pet in HEX format`,
    type: String,
  })
  primaryColor: string;

  @IsNotEmpty()
  @IsString()
  @ApiProperty({
    description: `The secondary color of the pet in HEX format`,
    type: String,
  })
  secondaryColor: string;

  @IsOptional()
  @IsString()
  @ApiProperty({
    description: `The tertiary color of the pet in HEX format`,
    required: false,
    type: String,
  })
  tertiaryColor?: string;

  // This is currently needed but will be removed soon once `emissionColor` is working correctly.
  // https://linear.app/genopets/issue/UNI-2246/emissioncolor-prop-isnt-working-in-petconfig
  @IsOptional()
  @IsNumber()
  @ApiProperty({
    description: `The index of the emission color of the pet`,
    required: false,
    type: Number,
  })
  emissionColorIndex?: number;

  @IsNotEmpty()
  @IsString()
  @ApiProperty({
    description: `The emission color of the pet`,
    type: String,
  })
  emissionColor: string;
}

// The definitive definition of the actual properties that exist in a petConfig.
export class PetConfigCore extends PetColors {
  constructor(data: Partial<PetConfigCore>) {
    super();
    Object.assign(this, data);
  }

  @IsString()
  @IsNotEmpty()
  @ApiProperty({
    description: `The head of the pet`,
    type: String,
  })
  head: string;

  @IsNotEmpty()
  @IsString()
  @ApiProperty({
    description: `The body of the pet`,
    type: String,
  })
  body: string;

  @IsNotEmpty()
  @IsString()
  @ApiProperty({
    description: `The eyes of the pet`,
    type: String,
  })
  eyes: string;

  @IsNotEmpty()
  @IsString()
  @ApiProperty({
    description: `The ID of the eyes of the pet`,
    type: String,
  })
  eyesId: string;

  @IsOptional()
  @IsString()
  @ApiProperty({
    description: `The tail of the pet`,
    type: String,
    required: false,
  })
  tail?: string;

  @IsOptional()
  @IsString()
  @ApiProperty({
    description: `The ID of the tail of the pet`,
    type: String,
    required: false,
  })
  tailId?: string;

  @IsOptional()
  @IsString()
  @ApiProperty({
    description: `The mane of the pet`,
    type: String,
    required: false,
  })
  mane?: string;

  @IsOptional()
  @IsString()
  @ApiProperty({
    description: `The ID of the mane of the pet`,
    type: String,
    required: false,
  })
  maneId?: string;

  @IsOptional()
  @IsString()
  @ApiProperty({
    description: `The ears of the pet`,
    type: String,
    required: false,
  })
  ears?: string;

  @IsOptional()
  @IsString()
  @ApiProperty({
    description: `The ID of the ears of the pet`,
    type: String,
    required: false,
  })
  earsId?: string;

  @IsOptional()
  @IsString()
  @ApiProperty({
    description: `The antennae of the pet`,
    type: String,
    required: false,
  })
  antennae?: string;

  @IsOptional()
  @IsString()
  @ApiProperty({
    description: `The ID of the antennae of the pet`,
    type: String,
    required: false,
  })
  antennaeId?: string;

  @IsOptional()
  @IsString()
  @ApiProperty({
    description: `The horn of the pet`,
    type: String,
    required: false,
  })
  horn?: string;

  @IsOptional()
  @IsString()
  @ApiProperty({
    description: `The ID of the horn of the pet`,
    type: String,
    required: false,
  })
  hornId?: string;

  @IsOptional()
  @IsString()
  @ApiProperty({
    description: `The wings of the pet`,
    type: String,
    required: false,
  })
  wings?: string;

  @IsOptional()
  @IsString()
  @ApiProperty({
    description: `The ID of the wings of the pet`,
    type: String,
    required: false,
  })
  wingsId?: string;

  @IsOptional()
  @IsNumber()
  @ApiProperty({
    description: `The index of the tattoo of the pet`,
    required: false,
    type: Number,
    deprecated: true,
  })
  tattooIndex?: number;

  @IsOptional()
  @IsString()
  @ApiProperty({
    description: `The ID of the tattoo on the pet`,
    type: String,
    required: false,
  })
  tattooId?: string;

  @IsOptional()
  @IsString()
  @ApiProperty({
    description: `name of tattoo on the pet.`,
    required: false,
    type: String,
  })
  tattoo?: string;

  @IsOptional()
  @IsNumberString()
  @ApiProperty({
    description: `The algorithm used to generate the pet`,
    required: false,
    type: String,
  })
  algorithm?: string;

  @IsOptional()
  @IsNumber()
  @ApiProperty({
    description: `The pet stage`,
    required: false,
    type: Number,
  })
  petStage?: number;

  @IsOptional()
  @IsString()
  @ApiProperty({
    description: `The ID of the material (shell) of the pet`,
    type: String,
    required: false,
  })
  materialId?: string;

  @IsOptional()
  @IsString()
  @ApiProperty({
    description: `The material of the pet, e.g. "bitcoin".`,
    required: false,
    type: String,
  })
  material?: string;

  static excludedPropertiesFromMetadata = [`emissionColorIndex`, `tattooIndex`];
}

// This class is defined this way to allow properties where the keys are strings
// to exist so that variable names can be used to assign values to PetConfig instances
// using bracket notation, i.e `petConfig[someTrait] = 'someValue'`.
// This is a workaround, otherwise we will get typescript errors that are hard to get rid of.
export class PetConfig extends PetConfigCore {
  // we need this
  [trait: string]: string | number | undefined;
}

export type PetConfigPartKey = keyof PetConfigCore;

export class Pet {
  constructor(data: Partial<Pet>) {
    Object.assign(this, data);

    const petStageIndex = levelToStage(this.level) - 1;

    // have to derive petStage for petConfigv2 so that the mobile app
    // does not evolve the pet prematurely if the petStage was being updated to
    // the pet document directly.
    this.petConfigV2.petStage = petStageIndex;

    const petDescription = getPetDescription(
      this.nickname ?? this.name,
      parseInt(this.petConfigV2.algorithm!, 2),
      petStageIndex,
    );

    // only replace the pet's description if one can be successfully derived, otherwise fallback to the one in the pet doc.
    this.description = petDescription ?? this.description;
    this.image = getPetImage(this.renderId ?? this.id);
  }

  @IsNotEmpty()
  @IsString()
  @ApiProperty({
    example: `123`,
    description: `Unique ID of the pet`,
  })
  readonly id!: string;

  @IsOptional()
  @IsString()
  @Exclude()
  @ApiProperty({
    example: `someRandomSeedString`,
    description: `Seed used to generate the pet`,
  })
  readonly seed: string;

  @IsOptional()
  @IsString()
  @ApiProperty({
    example: `mintAddress`,
    description: `Potential mint address of the pet, used to indicate that the pet is being minted`,
  })
  readonly tmpMint: string;

  @IsNotEmpty()
  @IsInt()
  @ApiProperty({
    example: 3,
    description: `Level of the pet`,
  })
  readonly level: number;

  @IsNotEmpty()
  @IsInt()
  @ApiProperty({
    example: 123,
    description: `Current xp of the pet`,
  })
  readonly currentXp?: number;

  @IsNotEmpty()
  @IsString()
  @ApiProperty({
    example: `Fluffy`,
    description: `Name of the pet`,
  })
  readonly name: string = `Unknown Pet`;

  // this is derived now and doesn't need to be stored in the database
  @IsOptional()
  @IsString()
  @ApiProperty({
    example: `Long text to describe the pet`,
    description: `Description of the pet`,
  })
  description?: string;

  @IsOptional()
  @IsUrl()
  @ApiProperty({
    example: `https://example.com`,
    description: `Url of the image of the pet`,
  })
  image?: string;

  @IsBoolean()
  @ApiProperty({
    example: true,
    description: `Define if the pet is a NFT`,
  })
  nonNFT: boolean;

  @IsNotEmpty()
  @IsBoolean()
  @ApiProperty({
    example: true,
    description: `Define if the pet is in game`,
  })
  inGame: boolean;

  @IsOptional()
  @IsString()
  @ApiProperty({
    example: `Genesis`,
    description: `The default tattoo of the pet when none is equipped`,
  })
  defaultTattoo?: string;

  @IsOptional()
  @IsString()
  @ApiProperty({
    example: `bitcoin`,
    description: `The default material (shell) of the pet when none is equipped`,
  })
  defaultMaterial?: string;

  @IsNotEmpty()
  @Type(() => PetConfig)
  readonly petConfigV2: PetConfig;

  @IsBoolean()
  @IsOptional()
  @ApiProperty({
    example: true,
    description: `Define if the pet is active`,
  })
  active?: boolean;

  @IsOptional()
  @IsString()
  owner?: string;

  @IsOptional()
  @IsBoolean()
  @ApiProperty({
    example: true,
    description: `Define if the pet is a genesis Pet`,
  })
  isGenesis?: boolean;

  @IsOptional()
  @IsInt()
  @ApiProperty({
    example: 10000,
    description: `???`,
  })
  newOwnerCooldownUntilMs?: number;

  @IsOptional()
  @IsString()
  @ApiProperty({
    example: `Super Fluffy`,
    description: `Nickname of the pet`,
  })
  readonly nickname?: string;

  @IsOptional()
  @ApiProperty({
    description: `Array of nutrition actions`,
    type: [NurtureAction],
    required: false,
  })
  nutritionActions?: NurtureAction[];

  @ApiProperty({
    description: `Array of joy actions`,
    type: [NurtureAction],
    required: false,
  })
  @IsOptional()
  joyActions?: NurtureAction[];

  @ApiProperty({
    description: `Array of love actions`,
    type: [NurtureAction],
    required: false,
  })
  @IsOptional()
  loveActions?: NurtureAction[];

  @IsOptional()
  @ApiProperty({
    description: `Array of modifiers`,
    type: [Modifier],
    required: false,
  })
  modifiers?: Modifier[];

  @IsOptional()
  @ApiProperty({
    description: `Id of the moves that are available in the telegram game for the pet`,
  })
  telegramMoves?: string[];

  @ApiProperty({
    example: `RandomMove`,
    description: `A move pet rolled after battling in telegram`,
  })
  telegramRolledMove?: string;

  @ApiProperty({
    example: 100,
    description: `Energy gained by pet after telegram battle. Used for XP conversion after battle.`,
  })
  telegramEnergy?: number;

  // renderId is the unique fingerprint of the pet's current visual appearance.
  @IsOptional()
  @IsString()
  @ApiProperty({
    description: `Unique fingerprint of the pet's current visual appearance`,
    required: false,
  })
  renderId?: string;

  @Exclude()
  attributes!: NFTAttribute[];

  @IsOptional()
  @Exclude()
  lastNameChange?: { seconds?: number };

  @Exclude()
  ref?: any; // should be DocumentReference<DocumentData>, but error adding type reference from firebase-admin

  @IsOptional()
  @Exclude()
  // eslint-disable-next-line no-use-before-define
  beforeResetState?: Partial<Pet>;

  @IsOptional()
  @Exclude()
  previousStakedOwner?: string;

  @IsOptional()
  @Exclude()
  isStaked?: boolean;

  @IsOptional()
  @ArrayMaxSize(9)
  @ArrayUnique()
  @ApiProperty({
    description: `Array of active step battles`,
    type: [String],
    required: false,
  })
  activeStepBattles?: string[];

  @IsOptional()
  @IsString()
  @ApiProperty({
    example: `E3mXPuyZzKzdcWUaewiYWspeVFhyoskppCmwgwLe9yEB`,
    description: `The token account that an NFT pet belongs to`,
  })
  readonly tokenAccount?: string;

  public get telegramLevel() {
    const level = telegramXpToLevel(this.currentXp);

    return level === -1 ? TELEGRAM_XP_CURVE.length : level;
  }

  // used to get derived metadata from pet entity
  @Expose({ name: `attributes` })
  @ApiProperty({
    description: `Array of pet' attributes`,
    type: [NFTAttribute],
  })
  public get getAttributes(): NFTAttribute[] {
    const augmentIdsSet = new Set(
      Augments.map((augment) => `${augment.traitName}Id` as PetConfigPartKey),
    );
    const attributes = (
      Object.keys(this.petConfigV2 || {}) as PetConfigPartKey[]
    )
      .map((key) => {
        if (PetConfig.excludedPropertiesFromMetadata.includes(key)) return null;

        return {
          trait_type: startCase(key),
          // we want to remove the 'noc::' or 'poc::' prefix from the value for augment ids.
          value: augmentIdsSet.has(key)
            ? (this.petConfigV2[key] as string)?.split(PARTITION_DELIMITER)?.[1]
            : this.petConfigV2[key],
        };
      })
      .filter(Boolean);

    if (!attributes.find((attr) => attr?.trait_type === `Level`)) {
      attributes.push({ trait_type: `Level`, value: this.level });
    }

    if (!attributes.find((attr) => attr?.trait_type === `Current XP`)) {
      attributes.push({ trait_type: `Current XP`, value: this.currentXp });
    }

    if (this.nickname)
      attributes.push({ trait_type: `Nickname`, value: this.nickname });

    const petStage = attributes.find(
      (attr) => attr?.trait_type === `Pet Stage`,
    );

    if (petStage) petStage.value = levelToStage(this.level);

    attributes.push({
      trait_type: `Joy Score`,
      value: getJoyScore(this.joyActions).score,
    });

    attributes.push({
      trait_type: `Love Score`,
      value: getLoveScore(this.loveActions).score,
    });

    attributes.push({
      trait_type: `Nutrition Score`,
      value: getNutritionScore(this.nutritionActions).score,
    });

    // must sort the attributes otherwise it gets listed in random order which is jarring
    // in UIs.
    attributes.sort((a, b) => a!.trait_type.localeCompare(b!.trait_type));

    return attributes as NFTAttribute[];
  }

  /**
   * This is used to determine whether to display mediating render in the mobile app.
   * This is also used to determine whether an NFT is withdrawable from the NFT Portal. Only idle pets should be allowed
   */
  get isBusy() {
    return (this.activeStepBattles?.length ?? 0) > 0;
  }

  getAugments() {
    const attributes = this.getAttributes;

    const augments = attributes.filter((attr) =>
      bodyParts.includes(attr.trait_type.toLowerCase()),
    );

    return augments;
  }

  getEquippedAugment() {
    return this.getAugments().filter((augment) => augment.value);
  }

  getMappedEquippedAugmentName() {
    return this.getEquippedAugment().map(
      (augment) => `${augment.value} ${augment.trait_type}`,
    );
  }

  getNFTMetadata(imageBaseUrl: string) {
    const attributes = this.getAttributes;

    const { name, description, id, renderId } = this;

    const image = `${imageBaseUrl}/${renderId ?? id}.gif`;
    const animation_url = `${imageBaseUrl}/${renderId ?? id}.mp4`;
    const staticImage = `${imageBaseUrl}/${renderId ?? id}.png`;

    const files = [
      {
        uri: image,
        type: `image/gif`,
      },
      { uri: staticImage, type: `image/png` },
      { uri: animation_url, type: `video/mp4` },
    ];

    return {
      name,
      symbol: `GENOPET`,
      description,
      external_url:
        process.env.NODE_ENV === `production`
          ? `https://mainframe.genopets.me/genodex/${id}`
          : `https://tesoterra.genopets.me/genodex/${id}`,
      image,
      animation_url,
      attributes,
      properties: {
        category: `video`,
        files,
      },
    };
  }

  getEquippedAugmentIds(): string[] {
    const { petConfigV2 } = this;
    return (
      [
        petConfigV2.antennaeId,
        petConfigV2.hornId,
        petConfigV2.earsId,
        petConfigV2.eyesId,
        petConfigV2.maneId,
        petConfigV2.wingsId,
        petConfigV2.tailId,
      ].filter(Boolean) as string[]
    ).map(Pet.getAugmentId);
  }

  // augment ids look like "poc::<token-address>" or "noc::<token-address>". We only want the token-address part.
  static getAugmentId(id: string) {
    const [, augmentId] = id.split(PARTITION_DELIMITER);
    return augmentId ?? id;
  }

  addStepBattle(battleId: string) {
    this.activeStepBattles?.push(battleId);
    return this.activeStepBattles;
  }
}

export function pruneModifiers(
  modifiers?: Modifier[],
  nowInSec = Math.floor(Date.now() / 1000),
) {
  return modifiers?.filter((modifier) => modifier.expiresAt > nowInSec);
}
