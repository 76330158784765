import {
  BattleDodgeEntity,
  BattleMoveEntity,
  DodgeResult,
  MoveType,
  Rolls,
} from '../models/battle-action.entity';
import { BattlePet } from '../models/battle-pet';
import { BattlePlayerEntity } from '../models/battle-player.entity';
import { getRandomInt } from './random';
import { checkIfParalyze, getStatusMultiplier } from './status';

/**
 * Tick the cooldown of dodge if needed
 * @param player player
 * @returns cooldown value
 */
export function tickDodgeCoolDown(player: BattlePlayerEntity) {
  return Math.max(player.dodgeCooldown - 1, 0);
}

/**
 * Add the cooldown on dodge
 * @param player player
 * @returns player
 */
export function applyDodgeCooldown(player: BattlePlayerEntity): number {
  // apply two turns cooldown as tick are done after opponent action
  // ex
  // user turn (useDodge) : dodgecount = 1
  // user turn (useMove) : dodgecount = 1
  // opponent turn (useDodge) : dodgecount = 1
  // opponent turn (useMove) : dodgecount = 0
  // user turn (useDodge): can dodge again

  // ex with 2
  // user turn (useDodge) : dodgecount = 2
  // user turn (useMove) : dodgecount = 2
  // opponent turn (useDodge) : dodgecount = 2
  // opponent turn (useMove) : dodgecount = 1
  // user turn (useDodge): cannot dodge again
  return (player.dodgeCooldown ?? 0) + 2;
}

export function calculateDamageReduction(dodge: BattleDodgeEntity): number {
  if (dodge.used === `auto`) {
    return 0;
  }

  if (dodge.used) {
    if (dodge.result === DodgeResult.CRITICAL_FAILED) {
      return -20;
    }
    if (dodge.result === DodgeResult.SUCCESS) {
      return 100;
    }
    return 0;
  }
  // TODO paralyze cannot block ?
  // TODO update with block Calculation
  return 0;
}

export function calculateDodge(
  defendingPet: BattlePet,
  attackingPet: BattlePet,
  previousAction: BattleMoveEntity,
  rolls?: Rolls,
) {
  if (
    checkIfParalyze(defendingPet) ||
    previousAction.types.includes(MoveType.Break)
  ) {
    return { isDodgeSuccessful: false };
  }

  const AGL =
    defendingPet.base.AGL *
    getStatusMultiplier(defendingPet.statusModifier?.AGL);
  const ACC =
    attackingPet.base.ACC *
    getStatusMultiplier(attackingPet.statusModifier?.ACC);

  // Generate random numbers between 1 and AGL, and between 1 and ACC
  const randomAgility =
    (rolls?.randomAgility as number) ?? getRandomInt(1, AGL);
  const randomAccuracy =
    (rolls?.randomAccuracy as number) ?? getRandomInt(1, ACC);

  // Apply the dodge formula
  const dodgeValue = randomAgility / 2 / (randomAccuracy * 2);

  // Determine if the dodge is successful
  const isDodgeSuccessful = dodgeValue > 1;

  return { isDodgeSuccessful, randomAccuracy, randomAgility };
}
