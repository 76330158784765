import { PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { Pet } from '../../../pet';
import { BattleActionEntity } from '../../models/battle-action.entity';
import { BattlePlayerEntity } from '../../models/battle-player.entity';
import { BattleNextAction, BattleState } from '../../models/battle-state';
import {
  PlayerGameStateInGodMode,
  checkVictory,
  getCrystalsMint,
  getStartingPlayer,
} from '../../utils/battle-utils';
import { applyElementalStatus } from '../../utils/elemental';
import { calculatePetStats } from '../../utils/pet';
import { useWrapperReducer } from '../../utils/useWrapperReducer';
import { updatePlayerGameState } from './battle';

export function updatePetWithGameState(
  player: BattlePlayerEntity,
  data: PlayerGameStateInGodMode,
) {
  const newPlayer = cloneDeep(player);
  if (data.HP) {
    newPlayer.pet.HP = +data.HP;
    if (+data.HP > newPlayer.pet.base.HP) {
      newPlayer.pet.base.HP = +data.HP;
    }
  }

  newPlayer.petInfo = {
    ...newPlayer.petInfo,
    level: data.level ?? newPlayer.petInfo.level,
    petConfigV2: data.petConfig ?? newPlayer.petInfo.petConfigV2,
  };

  if ((data.level || data.petConfig) && data.items) {
    // in theory we do not recalculate this when passing the level as the items are missing
    // this is not a problem as it will be recalculate later when the user choose the augments
    // anyway in order to keep the flexibility we allow to go in this case if level and items are provided
    const itemsMap = new Map(
      data.items.map((augment) => [augment.key as string, augment]),
    );

    newPlayer.pet = calculatePetStats(new Pet(newPlayer.petInfo), itemsMap, {
      augSetBonusDefinitionsRecord: data.augmentSetBonusDefinitionsRecord,
    });
  }

  if (data.statusEffect) {
    newPlayer.pet.status.push(data.statusEffect);
  }

  if (data.element) {
    newPlayer.pet = applyElementalStatus(
      { mint: getCrystalsMint[data.element] },
      newPlayer,
    );
  } else {
    if (data.move1) {
      newPlayer.moves[0] = data.move1;
    }
    if (data.move2) {
      if (newPlayer.moves[1]) {
        newPlayer.moves[1] = data.move2;
      } else {
        newPlayer.moves.push(data.move2);
      }
    }
    if (data.move3) {
      if (newPlayer.moves[2]) {
        newPlayer.moves[2] = data.move3;
      } else {
        newPlayer.moves.push(data.move3);
      }
    }
    if (data.move4) {
      if (newPlayer.moves[3]) {
        newPlayer.moves[3] = data.move4;
      } else {
        newPlayer.moves.push(data.move4);
      }
    }

    if (data.breakMove) {
      newPlayer.break.move = data.breakMove;
    }
  }

  return newPlayer;
}

// useBlock is always the end of the player turn (used or not)
// but sometime this action is not needed (break move, attack dodged)
export function handleAction(
  state: BattleState,
  actionStore: PayloadAction<BattleActionEntity>,
) {
  const action = actionStore.payload;

  const newState = cloneDeep(state) as BattleState;

  const data = action.data as PlayerGameStateInGodMode;

  if (state.player1.id === action.playerId) {
    newState.player1 = updatePetWithGameState(newState.player1, data);
  } else {
    newState.player2 = updatePetWithGameState(newState.player2, data);
  }

  if (data.breakMove) {
    const lastAction = newState?.actions.reduce((prev, curr) => {
      if (
        curr.payload.playerId !== action.playerId &&
        curr.type === updatePlayerGameState.type
      ) {
        return curr;
      }
      return prev;
    }, undefined as any)?.payload.data as PlayerGameStateInGodMode;
    if (lastAction?.breakMove) {
      // by pass move selection as both player choose move via the updatePlayerGameState
      newState.nextAction = BattleNextAction.PLAYER_CHOOSE;
      // recalculate next player with new pet stats
      newState.nextPlayer = getStartingPlayer(
        newState.player1,
        newState.player2,
      );
    }
  }

  newState.actions.push({ ...actionStore, payload: { ...action } });

  return checkVictory(newState);
}

export const updatePlayerGameStateReducer = (
  state: Partial<BattleState>,
  action: PayloadAction<BattleActionEntity>,
) => useWrapperReducer(state, action, handleAction);
