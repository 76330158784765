import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal as ChakraModal,
  ModalProps,
  ModalFooterProps,
  ModalContentProps,
} from '@chakra-ui/react';
import { Card, CardProps } from './Card';
import { ReactNode } from 'react';
import { Button } from './Button';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';

export const Modal = ({
  children,
  isOpen,
  onClose,
  header,
  showBackButton = true,
  cardProps,
  footerProps,
  contentProps,
  ...props
}: {
  header?: ReactNode;
  cardProps?: CardProps;
  showBackButton?: boolean;
  footerProps?: ModalFooterProps;
  contentProps?: ModalContentProps;
} & ModalProps) => {
  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} isCentered {...props}>
      <ModalOverlay />
      <ModalContent
        padding={'16px'}
        background={'transparent'}
        {...contentProps}
      >
        <Card {...cardProps}>
          <ModalHeader padding={0}>{header}</ModalHeader>

          <ModalBody padding={0} w="full">
            {children}
          </ModalBody>

          {showBackButton && (
            <ModalFooter padding={0} w="full" mt={2} {...footerProps}>
              <Button colorId="Black" w="full" onClick={onClose}>
                <TranslatedText
                  translationKey={`goBack`}
                  defaultMessage={`Go back`}
                />
              </Button>
            </ModalFooter>
          )}
        </Card>
      </ModalContent>
    </ChakraModal>
  );
};
