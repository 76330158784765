import { MenuItemPop } from '@/components/ui/molecules/MenuItemPop';
// import { SearchModal } from './SearchModal';
import { useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CreateOpenBattleModal } from './CreateOpenBattleModal';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Text } from '@/components/ui/atoms/Text';

export const MatchmakingButton = ({ auto }: { auto: boolean }) => {
  const [isLoading, setIsLoading] = useState(false);
  // const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: createOpenBattleModalIsOpen,
    onClose: createOpenBattleModalOnClose,
    onOpen: createOpenBattleModalOnOpen,
  } = useDisclosure();

  useEffect(() => {
    if (auto && !isLoading) {
      setIsLoading(true);
      createOpenBattleModalOnOpen();
    }
  }, [auto]);

  return (
    <>
      {/* <SearchModal
        isOpen={isOpen}
        onClose={(fromBack: boolean) => {
          onClose();
          if (!fromBack) {
            createOpenBattleModalOnOpen();
          } else {
            setIsLoading(false);
          }
        }}
      /> */}
      <CreateOpenBattleModal
        isOpen={createOpenBattleModalIsOpen}
        onClose={() => {
          setIsLoading(false);
          createOpenBattleModalOnClose();
        }}
      />
      <MenuItemPop
        width="full"
        color="black"
        onClick={() => {
          setIsLoading(true);
          // onOpen();
          createOpenBattleModalOnOpen();
        }}
        disabled={isLoading}
      >
        <Text colorId="Black">
          {isLoading ? (
            <>
              <TranslatedText
                translationKey={`loading`}
                defaultMessage={`Loading`}
              />
              ...
            </>
          ) : (
            <TranslatedText
              translationKey={`vsRando`}
              defaultMessage={`VS Rando`}
            />
          )}
        </Text>
      </MenuItemPop>
    </>
  );
};
