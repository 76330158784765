import { Box, Flex, Tr } from '@chakra-ui/react';
import { ColorId } from '../colors';
import { Hand } from '../icons/Hand';
import { Text } from '../atoms/Text';
import { Card } from './Card';
import { TranslatedText } from '../atoms/TranslatedText';
import { useEffect, useState } from 'react';

const MAX_LEVEL_BREAK_MOVE = 3;
// const TRIGGER_LEVEL_BREAK_MOVE = 3;

export const BreakIndicator = ({
  level = 0,
  maxLevel = MAX_LEVEL_BREAK_MOVE,
  // triggerLevel = TRIGGER_LEVEL_BREAK_MOVE,
  colorId = 'Yellow',
  reverse = false,
  printHand,
}: {
  level: number;
  maxLevel?: number;
  // triggerLevel?: number;
  colorId?: ColorId;
  reverse?: boolean;
  printHand: boolean;
}) => {
  const [printedHand, setPrintedHand] = useState(false);

  useEffect(() => {
    if (printHand && !printedHand && level) {
      const timer = setTimeout(() => {
        setPrintedHand(true);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [level, printHand]);

  return (
    <Flex
      justifyContent={reverse ? `flex-end` : `flex-start`}
      w="full"
      mt="4px"
      position={'relative'}
    >
      {printHand && !reverse && level && !printedHand ? (
        <>
          <Card
            colorId="Yellow"
            position={'absolute'}
            top={{ base: '-250px', sm: '-220px' }}
            left={0}
            zIndex={11}
          >
            <Text colorId="Black">
              <TranslatedText
                translationKey={`pokeThreeTime`}
                defaultMessage={`poke 3 times to use power move`}
              />
              !
            </Text>
          </Card>
          <Box
            position={'absolute'}
            top={'-85px'}
            left={'-30px'}
            zIndex={2}
            style={{ transform: 'rotate(100deg)', transformOrigin: 'center' }}
          >
            <Hand className="animate-poke" />
          </Box>
        </>
      ) : null}
      {[...Array(maxLevel ?? 0).keys()].map((i) => {
        const badge = (
          <Box
            key={i}
            w="14px"
            h="14px"
            background={`var(--${level > i ? colorId : 'White'})`}
            border="3px solid var(--Black)"
          />
        );

        return badge;
      })}
    </Flex>
  );
};
