import { useTranslation } from 'react-i18next';
import translations from '../../../../../../packages/utils/src/i18n/en/index.json';

export const TranslatedText = ({
  translationKey,
  defaultMessage,
  count = 1,
  props = {},
}: {
  translationKey: keyof typeof translations;
  defaultMessage: string;
  count?: number;
  props?: Record<string, any>;
}) => {
  const { t } = useTranslation();

  let translated = t(translationKey, {
    count,
    defaultValue: defaultMessage,
    ...props,
  });

  // plural keys from lokalise are nested object but the i18next package supports as prefix
  // this is a workaround to make it work for nested objects instead of transforming them to be a prefix
  if (
    translated?.toLowerCase()?.includes(`returned an object instead of string`)
  ) {
    const pluralKey = count > 1 ? `other` : `one`;
    translated = t(`${translationKey}.${pluralKey}`, {
      count,
      defaultValue: defaultMessage,
      ...props,
    });

    // currently supporting one level deep only since those are the only use case we have right now
    if (
      translated
        ?.toLowerCase()
        ?.includes(`returned an object instead of string`)
    ) {
      translated = defaultMessage;
    }
  }

  return translated || defaultMessage;
};
