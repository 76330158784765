import { Link } from '@/components/Link/Link';
import { Text } from '@/components/ui/atoms/Text';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Energy } from '@/components/ui/icons/Energy';
import { Card } from '@/components/ui/molecules/Card';
import { MenuItemPop } from '@/components/ui/molecules/MenuItemPop';
import { ENV, VITE_BATTLE_REPLAY_ENABLED } from '@/config/constants';
import { TELEGRAM_BATTLE_ENDPOINT } from '@/config/endpoints';
import { useTelegramPet, useUserData } from '@/context/FirestoreContext';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import {
  Box,
  Center,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { SECOND_PLAYER_ID, getTelegramPetImage } from 'genopets-utils';
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TitleGeno from '../../images/pixelton-arena-title.png';
import { TextWithShadow } from '../ui/atoms/TextWithShadow';
import { Ticket } from '../ui/icons/Ticket';
import { Button } from '../ui/molecules/Button';
import { ModalMessageManager } from '../ui/organisms/ModalMessageManager';
import { MatchmakingButton } from './Matchmacking/MatchmakingButton';
import { ReplayButton } from './replay/ReplayButton';
import { DateTime } from 'luxon';
import { AirdropCard } from '../airdrop/AirdropCard';
import { FeelingLuckyButton } from './FeelingLuckyButton';
import { ManageWalletModal } from '@/components/battle/ManageWalletModal';

const CountdownToNextDay = () => {
  const [timeLeft, setTimeLeft] = useState('00:00:00');

  useEffect(() => {
    const interval = setInterval(() => {
      const nowUTC = DateTime.utc();
      const nextMidnight = nowUTC.endOf('day');
      const duration = nextMidnight.diff(nowUTC, [
        'hours',
        'minutes',
        'seconds',
      ]);

      setTimeLeft(duration.toFormat('hh:mm:ss'));
    });

    return () => clearInterval(interval);
  }, []);

  return timeLeft;
};

export const Home = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { apiPost } = useAPI();

  const { pet } = useTelegramPet();

  const [searchParams] = useSearchParams();

  const { userData } = useUserData();

  const alert = useAlert();

  const navigate = useNavigate();

  const { onClose, isOpen, onToggle } = useDisclosure();

  const createBattle = async (vsCPU = true) => {
    if (isLoading) return;

    setIsLoading(true);

    const payload = vsCPU
      ? {
          opponent: SECOND_PLAYER_ID,
        }
      : {};

    apiPost(TELEGRAM_BATTLE_ENDPOINT, payload)
      .then((resp) => {
        navigate(`/battle/${resp}`);
      })
      .catch((error: any) => {
        console.error(error);
        alert({ title: error?.message ?? 'Error' });
      })
      .finally(() => setIsLoading(false));
  };

  const isHightScreen = window.innerHeight > 670;

  return (
    <>
      <Flex justifyContent={'space-between'}>
        <ManageWalletModal />
        <FeelingLuckyButton />
      </Flex>
      <Center
        w="full"
        h="full"
        flexDirection={'column'}
        justifyContent={isHightScreen ? 'center' : 'flex-end'}
        position={'relative'}
      >
        <Center>
          <img
            src={TitleGeno}
            alt="genopets"
            style={{
              height: isHightScreen ? '180px' : '140px',
              width: 'auto',
              position: isHightScreen ? 'relative' : 'absolute',
              top: '10px',
            }}
          />
        </Center>
        <Center flexDirection={'column'} zIndex={2}>
          {pet && (
            <img
              src={getTelegramPetImage(pet.petConfigV2)}
              alt="pet"
              style={{ width: '150px', height: '150px' }}
            />
          )}
          <Flex alignItems={'center'} gap={4} mt="-20px">
            <Flex alignItems={'center'}>
              <Ticket />
              <TextWithShadow>{userData?.telegramTickets}</TextWithShadow>
            </Flex>
            <Flex gap={1} alignItems={'center'}>
              <Energy color="white" />
              <TextWithShadow>{pet?.currentXp ?? '...'}</TextWithShadow>
            </Flex>
          </Flex>
          <TextWithShadow>{pet?.nickname ?? pet?.name ?? '...'}</TextWithShadow>
          <TextWithShadow>
            <TranslatedText translationKey={`textLvl`} defaultMessage={`Lvl`} />
            {` ${pet?.telegramLevel ?? '...'}`}
          </TextWithShadow>
          <TextWithShadow>
            <TranslatedText translationKey={`win`} defaultMessage={`Win`} />/
            <TranslatedText translationKey={`loss`} defaultMessage={`Loss`} />
            {`: ${userData ? userData?.telegramBattleWinCount ?? 0 : '-'}/${
              userData ? userData?.telegramBattleLossCount ?? 0 : '-'
            }`}
          </TextWithShadow>
          <Flex alignItems={'center'} gap={`4px`}>
            <TextWithShadow>
              <TranslatedText translationKey={`next`} defaultMessage={`next`} />
            </TextWithShadow>
            <Ticket />
            <TextWithShadow>
              <CountdownToNextDay />
            </TextWithShadow>
          </Flex>
        </Center>
      </Center>
      <Box w="full">
        <AirdropCard
          cardProps={{
            borderBottom: 'none',
          }}
        />
        <Card w="full" gap={0}>
          <Popover placement="bottom-end" onClose={onClose} isOpen={isOpen}>
            <PopoverTrigger>
              <MenuItemPop width="full" color="black" onClick={onToggle}>
                <Text color={`var(--Black)`}>
                  <TranslatedText
                    translationKey={`startBattle`}
                    defaultMessage={`Start Battle`}
                  />
                </Text>
              </MenuItemPop>
            </PopoverTrigger>
            <PopoverContent
              border={`none`}
              sx={{
                '&:focus, &:focus-visible': {
                  outline: 'none !important',
                  boxShadow: 'none !important',
                },
              }}
            >
              <Card>
                <MatchmakingButton auto={searchParams.get('auto') === 'true'} />
                <MenuItemPop
                  width="full"
                  color="black"
                  onClick={() => createBattle(false)}
                  disabled={isLoading}
                >
                  <Text colorId={`Black`}>
                    {isLoading ? (
                      <>
                        <TranslatedText
                          translationKey={`loading`}
                          defaultMessage={`Loading`}
                        />
                        ...
                      </>
                    ) : (
                      <TranslatedText
                        translationKey={`vsFren`}
                        defaultMessage={`VS Fren`}
                      />
                    )}
                  </Text>
                </MenuItemPop>
                <Button colorId="Black" w="full" onClick={onClose}>
                  <TranslatedText
                    translationKey={`textBack`}
                    defaultMessage={`Back`}
                  />
                </Button>
              </Card>
            </PopoverContent>
          </Popover>
          {pet?.telegramRolledMove && (
            <Link to="/battle-choose-move" style={{ width: '100%' }}>
              <MenuItemPop width="full" color="black">
                <Text color={`var(--Black)`}>
                  <TranslatedText
                    translationKey={`learnNewMove`}
                    defaultMessage={`Learn New Move`}
                    props={{ moveType: `` }}
                  />
                </Text>
              </MenuItemPop>
            </Link>
          )}
          {VITE_BATTLE_REPLAY_ENABLED && ENV === 'development' && (
            <ReplayButton />
          )}
          <Link to="/battle-leaderboard" style={{ width: '100%' }}>
            <MenuItemPop width="full" color="black">
              <Text color={`var(--Black)`}>
                <TranslatedText
                  translationKey={`leaderboard`}
                  defaultMessage={`Leaderboard`}
                />
              </Text>
            </MenuItemPop>
          </Link>
          <Link to="/battle-stats" style={{ width: '100%' }}>
            <MenuItemPop width="full" color="black">
              <Text color={`var(--Black)`}>
                <TranslatedText
                  translationKey={`petStats`}
                  defaultMessage={`Pet Stats`}
                />
              </Text>
            </MenuItemPop>
          </Link>
          <Link to="/viral-loop" style={{ width: '100%' }}>
            <MenuItemPop width="full" color="black">
              <Flex alignItems={'center'}>
                <Text color={`var(--Black)`}>
                  <TranslatedText
                    translationKey={`earnTickets`}
                    defaultMessage={`Earn Tickets`}
                  />
                </Text>
                <Ticket />
              </Flex>
            </MenuItemPop>
          </Link>
        </Card>
      </Box>
      <ModalMessageManager />
    </>
  );
};
