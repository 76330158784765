import { Flex } from '@chakra-ui/react';
import { BattleState } from 'genopets-utils';
import { Text } from '../../ui/atoms/Text';
import { ReplayLastActionState } from './ReplayLastActionState';
import { ReplayPlayerState } from './ReplayPlayerState';

export const ReplayBattleStateContent = ({
  battleState,
}: {
  battleState: BattleState;
}) => {
  return (
    <Flex flexDirection="column">
      <ReplayPlayerState player={battleState.player1} playerNumber={1} />

      <ReplayPlayerState player={battleState.player2} playerNumber={2} />

      <ReplayLastActionState actions={battleState.actions} />

      <Text
        colorId="Black"
        w="full"
        mt={2}
        pt={2}
        borderTop={`3px solid var(--Black)`}
      >
        {`Next Action:`}
      </Text>
      <Text colorId="Black" w="full" fontSize={'10px'}>
        {`${battleState.nextAction}`}
      </Text>

      <Text colorId="Black" w="full" mt={2}>
        {`Next Player:`}
      </Text>
      <Text
        colorId="Black"
        w="full"
        textOverflow={'ellipsis'}
        whiteSpace={'nowrap'}
        overflow={'hidden'}
        fontSize={'10px'}
      >
        {`${battleState.nextPlayer}`}
      </Text>

      <Text
        colorId="Black"
        w="full"
        mt={2}
        pt={2}
        borderTop={`3px solid var(--Black)`}
      >
        {`Winner:`}
      </Text>
      <Text
        colorId="Black"
        w="full"
        overflow={'hidden'}
        textOverflow={'ellipsis'}
        whiteSpace={'nowrap'}
        fontSize={'10px'}
      >
        {`${battleState.winner ?? 'to be determined'} `}
      </Text>

      <Text
        colorId="Black"
        w="full"
        mt={2}
        pt={2}
        borderTop={`3px solid var(--Black)`}
      >
        {`Error:`}
      </Text>
      <Text
        colorId={battleState.error ? 'Red' : 'Black'}
        fontSize={'10px'}
        w="full"
      >
        {`${battleState.error ?? 'None'} `}
      </Text>
    </Flex>
  );
};
