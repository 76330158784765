import { cloneDeep, isEqual, omit } from 'lodash';
import { BattleMoveEntity } from '../models/battle-action.entity';

export function checkThatMoveAreEqual(
  move1: BattleMoveEntity,
  move2: BattleMoveEntity,
): boolean {
  // state are particular object so we copy it to obtain a regular object to compare
  const m1 = cloneDeep(omit(move1, `augment`));
  const m2 = cloneDeep(omit(move2, `augment`));
  return isEqual(m1, m2);
}

/**
 * Returns an array of random BattleMoveEntity objects from the input array of moves.
 *
 * @param {BattleMoveEntity[]} moves - The array of BattleMoveEntity objects.
 * @param {number} count - The number of random BattleMoveEntity objects to return.
 * @throws {Error} If the count is greater than the number of moves.
 * @return {BattleMoveEntity[]} An array of random BattleMoveEntity objects.
 */
export function getRandomBattleMoves(
  moves: BattleMoveEntity[],
  count: number,
): BattleMoveEntity[] {
  if (count > moves.length) {
    throw new Error(
      `Count (${count}) cannot be greater than the number of moves (${moves.length})`,
    );
  }

  const randomIndices = new Set();
  while (randomIndices.size < count) {
    const randomIndex = Math.floor(Math.random() * moves.length);
    randomIndices.add(randomIndex);
  }

  return moves.filter((move, index) => randomIndices.has(index));
}
